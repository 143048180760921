import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import clsx from 'clsx';
import { Field } from '../forms/components/field';
import { Button } from '../typography/button/button';
import { ValidatorType, useValidation, validators } from '@/hooks/use-validation';
import { AppContext } from '@/contexts/app-context';
import { useGlobalQueries } from '@/hooks/use-global-queries';
import { useLocalization } from '@/hooks/use-localization';
import { useGtm } from '@/hooks/use-gtm';
import { GoogleRecaptchaInfo } from '../google-recaptcha-info/google-recaptcha-info';

type ContactMeProps = {
    formTitle: string;
    formSubTitle?: string;
    formId: string;
    buttonText: string;
    submitButtonText?: string;
    thankYouText: string;
    gtm?: string;
    fields: {
        label: string;
        placeholder: string;
        required: string[];
        validation_method: string;
    }[];
};

export const ContactMe = ({ formTitle, formSubTitle, formId, buttonText, submitButtonText, fields, thankYouText, gtm }: ContactMeProps) => {
    const { localize, getLanguage } = useLocalization();
    const { sendFormSubmitEvent } = useGtm();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { role, siteId, deviceType } = useContext(AppContext);
    const [expanded, setExpanded] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [finished, setFinished] = useState(false);
    const { getValidatorByInputName } = useValidation();
    const { getPermalink } = useGlobalQueries();
    const [errorMessage, setErrorMessage] = useState('');
    const errorDivRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (errorDivRef.current && !errorDivRef.current.contains(event.target as Node)) {
                // Click occurred outside the error message div
                // You can run your function here
                setErrorMessage('');
            }
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const initialFormValues = fields.reduce<Record<string, string>>((acc, field) => {
        acc[field.label] = '';
        return acc;
    }, {});

    const [formValues, setFormValues] = useState(initialFormValues);

    const handleInputChange = (name: string, value: string) => {
        setFormValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    const isSubmitDisabled = useMemo(() => {
        if (isSending) {
            return true;
        }

        for (const [key, value] of Object.entries(formValues)) {
            const formField = fields.find(({ label }) => label === key);
            if (!formField || !formField?.validation_method) {
                continue;
            }

            if (formField?.required?.includes('yes') && !value) {
                return true;
            }

            const validation = validators[formField.validation_method as keyof typeof validators];
            if (value && validation && !validation(value, getLanguage())) {
                return true;
            }
        }

        return false;
    }, [formValues, isSending]);

    const prepareFormData = (): FormData => {
        const formData = new FormData();
        Object.entries(formValues).forEach(([key, value]) => formData.append(key, value));
        formData.set('permalink', getPermalink());
        formData.set('formId', formId);
        return formData;
    };

    const handleSubmit = async () => {
        setIsSending(true);

        if (!executeRecaptcha) {
            setErrorMessage(localize('invalid-captcha'));
            return;
        }

        const token = await executeRecaptcha();
        if (!token) {
            setErrorMessage(localize('invalid-captcha'));
            return;
        }

        const response = await fetch('/api/contact-me-form', {
            headers: {
                Accept: 'application/json',
                credentials: 'include',
                'x-role': role,
                'x-site-id': siteId,
                'x-device-type': deviceType,
                'x-recaptcha-token': JSON.stringify(token),
            },
            method: 'POST',
            body: prepareFormData(),
        });

        setIsSending(false);
        if (!response.ok) {
            const data = await response.json();
            if (response.status === 498) {
                if (data?.error === 'INVALID_CAPTCHA_TOKEN') {
                    setErrorMessage(localize('invalid-captcha'));
                } else {
                    setErrorMessage(localize('invalid-token'));
                }
            } else {
                if (data.error) {
                    setErrorMessage(localize('error-generic'));
                }
            }
        } else {
            if (gtm) {
                sendFormSubmitEvent(gtm, formId);
            }

            setFinished(true);
            return response;
        }
    };

    const toggleExpanded = () => setExpanded(prev => !prev);

    function getGroupedValue(index: number) {
        if (index === 0) {
            return fields.length > 1 ? 'top' : '';
        }

        return index + 1 === fields.length ? 'bottom' : 'middle';
    }

    return (
        <div
            data-component="contact-me"
            className="r-fixed r-bottom-4 r-left-4 r-right-4 r-z-[1001] r-flex r-max-h-[calc(100vh-4rem)] r-flex-col r-items-center r-justify-between r-rounded-2xl r-bg-white r-bg-opacity-95 r-px-2 r-py-4 r-text-black r-shadow-[0_2px_16px_0_rgba(0,0,0,.15)] max-lg:r-max-w-[720px] sm:r-bottom-8 sm:r-left-8 sm:r-right-8 sm:r-translate-x-0 sm:r-translate-y-0 md:r-left-auto md:r-min-w-[528px]"
        >
            <div
                className={clsx('r-container r-px-2 sm:r-px-6', {
                    'scrollbar-hide r-overflow-visible r-overflow-y-scroll': expanded,
                })}
            >
                {finished ? (
                    <div className="r-flex r-items-center r-space-x-4">
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="48" height="48" rx="24" fill="#d6ebea" />
                            <path
                                d="M34.5 18C34.5 18.3838 34.3535 18.7678 34.0605 19.0603L22.0605 31.0603C21.7688 31.3547 21.3844 31.5 21 31.5C20.6156 31.5 20.2322 31.3535 19.9397 31.0605L13.9397 25.0605C13.6467 24.7688 13.5 24.3844 13.5 24C13.5 23.1431 14.2008 22.5 15 22.5C15.3838 22.5 15.7678 22.6465 16.0603 22.9395L21 27.8812L31.9406 16.9406C32.2312 16.6467 32.6156 16.5 33 16.5C33.8016 16.5 34.5 17.1422 34.5 18Z"
                                fill="#00807b"
                            />
                        </svg>
                        <p className="r-text-md r-font-bold md:r-text-lg">{thankYouText}</p>
                    </div>
                ) : (
                    <>
                        <div className="r-flex r-w-full r-items-center r-justify-between r-gap-4">
                            {expanded && (
                                <div className="r-absolute r--top-8 r-right-8 r-cursor-pointer" onClick={toggleExpanded}>
                                    <svg
                                        className="r-w-12 md:r-w-16"
                                        width="64"
                                        height="64"
                                        viewBox="0 0 64 64"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect x="0.5" y="0.5" width="63" height="63" rx="31.5" fill="#00807b" />
                                        <g clipPath="url(#clip0_2266_13532)">
                                            <path
                                                d="M39.0594 36.9418C39.6453 37.5277 39.6453 38.477 39.0594 39.0629C38.7688 39.3559 38.3844 39.5012 38 39.5012C37.6156 39.5012 37.2322 39.3547 36.9397 39.0617L32 34.1246L27.0608 39.0605C26.7678 39.3559 26.3839 39.5012 26 39.5012C25.6161 39.5012 25.2327 39.3559 24.9395 39.0605C24.3535 38.4746 24.3535 37.5254 24.9395 36.9395L29.8801 31.9988L24.9395 27.0605C24.3535 26.4746 24.3535 25.5254 24.9395 24.9395C25.5254 24.3535 26.4746 24.3535 27.0605 24.9395L32 29.8824L36.9406 24.9418C37.5266 24.3559 38.4758 24.3559 39.0617 24.9418C39.6477 25.5277 39.6477 26.477 39.0617 27.0629L34.1211 32.0035L39.0594 36.9418Z"
                                                fill="white"
                                            />
                                        </g>
                                        <rect x="0.5" y="0.5" width="63" height="63" rx="31.5" stroke="#00807b" />
                                        <defs>
                                            <clipPath id="clip0_2266_13532">
                                                <rect width="15" height="24" fill="white" transform="translate(24.5 20)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            )}
                            <div className={clsx({ 'r-pt-6': expanded })}>
                                <p className="r-text-l r-font-bold md:r-text-xl">{formTitle}</p>
                                {formSubTitle && expanded ? <p className="r-py-2 r-text-sm">{formSubTitle}</p> : null}
                            </div>
                            <div className="r-justify-self-end">
                                {!expanded && (
                                    <Button type="primary" size="small" onClick={toggleExpanded}>
                                        {buttonText}
                                    </Button>
                                )}
                            </div>
                        </div>
                        {expanded && !finished && (
                            <form onSubmit={e => e.preventDefault()}>
                                <div className="r-flex r-flex-col">
                                    <div className="r-my-5 r-w-full">
                                        {fields.map((field, index) => (
                                            <Field
                                                grouped={getGroupedValue(index)}
                                                validation={getValidatorByInputName(field.validation_method as ValidatorType)}
                                                inputClassName="r-text-black"
                                                showRequired={field.required.includes('yes')}
                                                required={field.required.includes('yes')}
                                                label={field.label}
                                                value={formValues[field.label]}
                                                onChange={value => handleInputChange(field.label, value)}
                                                key={index}
                                            />
                                        ))}
                                    </div>
                                    {errorMessage ? (
                                        <div ref={errorDivRef}>
                                            <p className="r-mb-1 r-text-sm r-text-red-500">{errorMessage}</p>
                                        </div>
                                    ) : null}
                                    <div className="r-flex r-justify-between r-gap-10">
                                        <GoogleRecaptchaInfo />
                                        <Button onClick={handleSubmit} disabled={isSubmitDisabled} type="primary">
                                            {submitButtonText || localize('submit')}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
