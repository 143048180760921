import queryString from 'query-string';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '@/contexts/app-context';

const HEARTBEAT_MIN_INTERVAL = 30000;

let lastBeat: Date;
let beatPending = false;

export const useHeartbeat = () => {
    const { siteId, role, deviceType, userAgent } = useContext(AppContext);
    const { pathname } = useLocation();

    const getQueryParams = (now: Date) => {
        return queryString.stringify({
            timestamp: now.getTime(),
            siteId,
            role,
            userAgent,
            deviceType,
        });
    };

    const startHeartbeat = async () => {
        const now = new Date();
        if (beatPending) {
            return;
        }

        if (!lastBeat || now.getTime() - lastBeat.getTime() > HEARTBEAT_MIN_INTERVAL) {
            lastBeat = now;

            beatPending = true;
            const response = await fetch(`/api/auth/heartbeat?${getQueryParams(now)}`, { credentials: 'include' });
            const beat = (await response.json()) as { authenticated: boolean; tokenExpiresIn: number };
            beatPending = false;

            if (!beat?.authenticated) {
                window.location.href = pathname;
            }
        }
    };

    return {
        startHeartbeat,
    };
};
