import { CountryType, FieldValuesType, KycType, KycValuesQuestionType, SsnType } from '../types';
import { useLocalization } from '@/hooks/use-localization';
import { getOwnership } from '../get-ownership';

export enum ApplicationResponseType {
    APPLICATION_CREATED = 'APPLICATION_CREATED',
    APPLICATION_DECLINED = 'APPLICATION_DECLINED',
}

export const useSubmitApplication = () => {
    const { getCountry } = useLocalization();

    const numberOnly = (value: string) => value.replace(/\D/g, '');
    const formatSsn = (ssn: SsnType) => ({
        ...ssn,
        id: numberOnly(ssn.id),
    });
    const formatKycAnswer = ({ id, question, answer }: KycValuesQuestionType) => ({ id, question, answers: answer.filter(a => a) });

    const submit = async ({ company, applicant, payoutAccount, keyRoles, signatoryCombinations, ...rest }: FieldValuesType, kyc: KycType) => {
        try {
            const payload = {
                ...rest,
                payoutAccount: {
                    ...payoutAccount,
                    accountNumber: numberOnly(payoutAccount.accountNumber),
                    clearingNumber: numberOnly(payoutAccount.clearingNumber),
                },
                company: {
                    ...company,
                    phoneNumber: numberOnly(company.phoneNumber),
                    governmentId: formatSsn(company.governmentId),
                },
                applicant: {
                    ...applicant,
                    phoneNumber: numberOnly(applicant.phoneNumber),
                    governmentId: formatSsn(applicant.governmentId),
                },
                ownerships: getOwnership(kyc, getCountry().toLocaleUpperCase() as CountryType).map(owner => ({
                    ...owner,
                    governmentId: formatSsn(owner.governmentId),
                })),
                keyRoles: [...keyRoles].map(keyRole => ({
                    ...keyRole,
                    phoneNumber: numberOnly(keyRole.phoneNumber),
                    governmentId: formatSsn(keyRole.governmentId),
                })),
                signatoryCombinations: [...signatoryCombinations].map(signatory => ({
                    ...signatory,
                    phoneNumber: numberOnly(signatory.phoneNumber),
                    governmentId: formatSsn(signatory.governmentId),
                })),
                beneficialOwnerKyc: Object.values(kyc.kycValues.OWNERSHIP_AND_CONTROL.questions)
                    .map(formatKycAnswer)
                    .filter(kyc => kyc.answers.filter(a => a).length),
                corporateKyc: [...Object.values(kyc.kycValues.COMPANY.questions), ...Object.values(kyc.kycValues.CORPORATE_SAVINGS.questions)]
                    .map(formatKycAnswer)
                    .filter(kyc => kyc.answers.filter(a => a).length),
            };

            const request = await fetch('/api/bank/corporate-registry-service/applications', {
                headers: {
                    'content-type': 'application/json',
                    credentials: 'include',
                },
                method: 'POST',
                body: JSON.stringify(payload),
            });

            const response = await request.json();
            if (response.statusCode === 400) {
                console.error(response);
                throw new Error(response.message ?? 'Submit Error');
            }

            return response as {
                response: {
                    applicationReference: string;
                    status: ApplicationResponseType;
                };
                status: string;
            };
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    return {
        submit,
    };
};
