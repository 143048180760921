import { AppContext } from '@/contexts/app-context';
import { useContext } from 'react';

export const GoogleRecaptchaInfo = () => {
    const { language } = useContext(AppContext);

    switch (language) {
        case 'sv':
            return (
                <div className="r-text-sm r-text-slate-400">
                    <p>Den här webbplatsen skyddas av reCAPTCHA och Googles</p>
                    <a className="r-text-blue-400" href="https://policies.google.com/privacy">
                        Sekretesspolicy
                    </a>{' '}
                    och&nbsp;
                    <a className="r-text-blue-400" href="https://policies.google.com/terms">
                        Användarvillkor
                    </a>{' '}
                    gäller.
                </div>
            );
        case 'no':
            return (
                <div className="r-text-sm r-text-slate-400">
                    <p>Denne nettsiden er beskyttet av reCAPTCHA og Googles</p>
                    <a className="r-text-blue-400" href="https://policies.google.com/privacy">
                        Personvern
                    </a>{' '}
                    og&nbsp;
                    <a className="r-text-blue-400" href="https://policies.google.com/terms">
                        brukervilkår
                    </a>{' '}
                    gjelder.
                </div>
            );
        case 'da':
            return (
                <div className="r-text-sm r-text-slate-400">
                    <p>Denne hjemmeside er beskyttet af reCAPTCHA og Googles</p>
                    <a className="r-text-blue-400" href="https://policies.google.com/privacy">
                        Fortrolighedspolitik
                    </a>{' '}
                    og&nbsp;
                    <a className="r-text-blue-400" href="https://policies.google.com/terms">
                        Servicevilkår
                    </a>{' '}
                    gælder.
                </div>
            );
        case 'fi':
            return (
                <div className="r-text-sm r-text-slate-400">
                    <p>Tämä sivusto on suojattu reCAPTCHA:lla ja Googlen</p>
                    <a className="r-text-blue-400" href="https://policies.google.com/privacy">
                        Tietosuojakäytäntö
                    </a>{' '}
                    ja&nbsp;
                    <a className="r-text-blue-400" href="https://policies.google.com/terms">
                        Käyttöehdot
                    </a>{' '}
                    ovat voimassa.
                </div>
            );
        default:
            return (
                <div className="r-text-sm r-text-slate-400">
                    <p>This site is protected by reCAPTCHA and the Google</p>
                    <a className="r-text-blue-400" href="https://policies.google.com/privacy">
                        Privacy Policy
                    </a>{' '}
                    and&nbsp;
                    <a className="r-text-blue-400" href="https://policies.google.com/terms">
                        Terms of Service
                    </a>{' '}
                    apply.
                </div>
            );
    }
};
