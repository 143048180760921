import clsx from 'clsx';
import { useGlobalQueries } from '@/hooks/use-global-queries';
import { FooterBottom } from './components/footer-bottom';
import { FooterTop } from './components/footer-top';
import { Wave } from './components/wave';
import { getTailwindColor } from '@/utils/colors';

export const Footer = () => {
    const { pageQuery } = useGlobalQueries();

    if (pageQuery?.data?.remove_footer) {
        return null;
    }

    return (
        <footer className={clsx(getTailwindColor(pageQuery.data?.page_background_color, 'bg'))}>
            <div className="r-relative">
                <Wave />
                <img
                    className="r-absolute r-left-1/2 r-top-12 r-w-32 r--translate-x-1/2 md:r-top-16 md:r-w-auto lg:r-top-44"
                    src="/assets/icons/Resurs_logo_text_vertical_WHITE.svg"
                    alt="Resursbank"
                />
            </div>

            <div className="-r-mt-1 r-bg-primary r-pt-10">
                <FooterTop />
                <FooterBottom />
            </div>
        </footer>
    );
};
