import { useContext } from 'react';
import { AppContext } from '@/contexts/app-context';

export const useLogout = () => {
    const context = useContext(AppContext);
    const logout = () => (context.fetch ? context.fetch<{ success: boolean }>('logout') : null);

    return {
        logout,
    };
};
