import { Rate } from '@/queries/rates';
import clsx from 'clsx';
import { monthsToYears } from './rate-calculator-vertical';
import './styles.css';

type Props = {
    disabled: boolean;
    onClick: () => void;
    index: number;
    selected: number;
    yearsLabel: string;
    rate: Rate;
    rateLabel: string;
    monthlyPaymentsLabel: string;
    showDecimals: boolean;
};

const formatAmount = (amount: number, showDecimals: boolean): string => {
    return showDecimals ? amount.toFixed(2).toLocaleString() : Math.round(amount).toLocaleString();
};

const RateIndicator = ({ isSelected }: { isSelected: boolean }) => (
    <div
        className={clsx(
            {
                'r-bg-primary-200': isSelected,
            },
            'r-flex r-h-4 r-w-4 r-items-center r-justify-center r-rounded-full'
        )}
    ></div>
);

export const RateItem = ({ onClick, index, selected, yearsLabel, rate, rateLabel, monthlyPaymentsLabel, showDecimals, disabled }: Props) => {
    const formattedAmount = formatAmount(rate.montlyAmount, showDecimals);
    const isSelected = index === selected;

    return (
        <div
            className={clsx('r-mb-[1px] r-flex r-items-center r-border-b r-border-primary-100 md:r-border-b-0', {
                'r-opacity-50': disabled,
                'r-cursor-pointer': !disabled,
            })}
            onClick={() => {
                if (!disabled) {
                    onClick();
                }
            }}
        >
            <div className="r-pr-3">
                <div className="r-flex r-h-6 r-w-6 r-items-center r-justify-center r-rounded-full r-border r-border-[#687078] r-bg-white">
                    <RateIndicator isSelected={isSelected} />
                </div>
            </div>
            <div className="r-flex r-flex-col r--space-y-1 md:r-flex-row md:r-space-x-4">
                <div className="r-flex r-items-end r-space-x-3">
                    <p className="r-text-lg r-font-bold">{formattedAmount} kr.</p>
                    <p className="r-mb-[4px] r-text-xs md:r-mb-[2px] md:r-text-base">{monthlyPaymentsLabel}</p>
                </div>
                <div className="r-flex r-items-end r-space-x-3">
                    <p className="r-font-medium md:r-text-lg">{rate.effectiveInterest.toLocaleString()} %</p>
                    <p className="r-mb-[2px] r-text-xs md:r-mb-[2px] md:r-text-base">{rateLabel}</p>
                </div>
            </div>
            <div id="box" className={`r-ml-auto r-flex r-items-center r-text-primary-100 before:-r-mr-1 ${isSelected && 'r-text-primary-300'}`}>
                <div
                    className={clsx(
                        {
                            'r-bg-primary-300': isSelected,
                        },
                        `r-flex r-h-[72px] r-w-[60px] r-items-center r-justify-center r-whitespace-nowrap r-bg-primary-100 r-p-5 r-text-sm r-font-bold r-text-white md:r-h-[54px] `
                    )}
                >
                    {monthsToYears(rate.numberOfMonths)} {yearsLabel}
                </div>
            </div>
        </div>
    );
};
