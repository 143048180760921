import clsx from 'clsx';
import { HeadlineProps } from '../headline/headline';
import { getTailwindColor } from '@/utils/colors';
import { ContentPadding, getParagraphPadding, getMargin, Size } from '@/utils/typography';

export type ParagraphProps = Pick<HeadlineProps, 'align' | 'color' | 'margin' | 'marginSize'> & {
    backgroundColor: string;
    contentPadding: ContentPadding;
    fontSize: Size;
    children: TrustedHTML;
};

export const Paragraph = ({ children, ...props }: ParagraphProps) => {
    const classNames = clsx(
        'styled-link',
        getTailwindColor(props.color, 'text'),
        getTailwindColor(props.backgroundColor, 'bg'),
        getMargin(props.margin, props.marginSize),
        getParagraphPadding(props.contentPadding),
        {
            'r-text-sm': props.fontSize === 'small',
            'r-text-2xl': props.fontSize === 'large',
            'r-text-center': props.align === 'center',
            'r-text-right': props.align === 'right',
        }
    );

    return <p className={classNames} dangerouslySetInnerHTML={{ __html: children }} />;
};
