import { useContext, useEffect, useMemo, useState } from 'react';
import queryString from 'query-string';
import { Field } from '@/isomorphic/components/forms/components/field';
import { RateItem } from './rate-item';
import { Button } from '@/isomorphic/components/typography/button/button';
import { useRateCalculator } from '@/hooks/use-rate-calculator';
import { AppContext } from '@/contexts/app-context';
import { useLocalization } from '@/hooks/use-localization';
import { useGtm } from '@/hooks/use-gtm';
import { Rates, getRates } from '@/queries/rates';

type InfoItem = {
    ID: number;
    content: string;
    guid: string;
    id: number;
    sticky: boolean;
    title: string;
    link_url?: string;
};

export type RateCalculatorVerticalProps = {
    labels: {
        apiErrorText: string;
        applyButtonText: string;
        infoButtonText: string;
        introText: string;
        introTitle: string;
        monthlyPaymentLabel: string;
        optionsText: string;
        paybackTimeAdditionalMessageText: string;
        rateLabel: string;
        wrongAmountText: string;
        yearLabel: string;
        yearsLabel: string;
    };
    applyButton?: {
        url: string;
        title: string;
        target?: string;
        rel?: string;
    };
    infoButton?: {
        url: string;
        title: string;
        target?: string;
        rel?: string;
    };
    decorated: {
        limits: {
            max: number;
            min: number;
        };
    };
    defaultPaybackTime: number;
    index: number;
    infoItems: InfoItem[];
    paybackTimeAdditionalMessage: boolean;
    showDecimals: boolean;
    showRateDetails: boolean;
    startAmount: number;
};

export const monthsToYears = (months: number): number => Math.floor(months / 12);

export const RateCalculatorVertical = (props: RateCalculatorVerticalProps) => {
    const [amount, setAmount] = useState<number | null>(props.startAmount);
    const { formatCurrency } = useLocalization();
    const [debouncedAmount, setDebouncedAmount] = useState<number | null>(props.startAmount);
    const [selected, setSelected] = useState(0);
    const [error, setError] = useState(false);
    const [openItemId, setOpenItemId] = useState(-1);
    const [initialStateLoaded, setInitialStateLoaded] = useState(false);
    const context = useContext(AppContext);
    const [data, setData] = useState<Rates>({ amount: props.startAmount, rates: [] });
    const { getCurrencySymbol } = useLocalization();
    const [isLoading, setIsLoading] = useState(false);
    const [infoItemActive, setInfoItemActive] = useState<InfoItem>();
    const { data: ratesInfo } = useRateCalculator();
    const { sendPageInteraction } = useGtm();

    const isAmountValid = useMemo(() => debouncedAmount && debouncedAmount >= 20000 && debouncedAmount <= 300000, [debouncedAmount]);

    const fetchRates = async (amount: number | null) => {
        if (isLoading || !amount) {
            return;
        }

        setError(false);
        setIsLoading(true);

        try {
            const response = await getRates(context, amount);
            setIsLoading(false);

            if (response === null) {
                setError(true);
                return;
            }

            setData(response);
        } catch (e) {
            setError(true);
            // Don't worry if we get an error, it's essentially 100% because the amount is too high or too low
        }

        setIsLoading(false);
    };

    useEffect(() => {
        if (!isAmountValid) {
            setError(true);
            return;
        }

        fetchRates(debouncedAmount);
    }, [debouncedAmount]);

    useEffect(() => {
        if (!data || initialStateLoaded) {
            return;
        }

        setInitialStateLoaded(true);
        const indexFound = data.rates.findIndex(rate => monthsToYears(rate.numberOfMonths) === props.defaultPaybackTime);

        if (indexFound !== -1) {
            setSelected(indexFound);
        }
    }, [data]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedAmount(amount);
        }, 150);

        return () => clearTimeout(timeoutId);
    }, [amount]);

    const getTime = () => {
        const rate = data ? data.rates[selected] : null;
        return rate ? Math.floor(rate.numberOfMonths / 12) : null;
    };

    const getApplyLink = () => {
        const time = getTime();
        const url = ratesInfo?.rate_calculator_apply_link || props.applyButton?.url || '';
        const parsedUrl = queryString.parseUrl(url);

        if (amount) {
            parsedUrl.query.amount = amount?.toString();
        }
        if (time) {
            parsedUrl.query.time = time.toString();
        }

        return `${parsedUrl.url}?${queryString.stringify(parsedUrl.query)}`;
    };

    useEffect(() => {
        sendPageInteraction({
            event: 'consumerloan_link_updated',
            category: 'Rate Calculator Vertical',
            amount,
            time: getTime(),
        });
    }, [amount, selected]);

    const infoText = useMemo(() => {
        const formattedInfoText = ratesInfo?.rate_calculator_info_text || '';
        if (!formattedInfoText || !data.rates || !isAmountValid || error) {
            return '';
        }

        return formattedInfoText
            .replace('{loanAmount}', formatCurrency(amount || 0))
            .replace('{yearlyInterest}', data?.rates[selected]?.yearlyInterest?.toString() || '')
            .replace('{years}', monthsToYears(data?.rates[selected]?.numberOfMonths || 0).toString())
            .replace('{effectiveInterest}', data?.rates[selected]?.effectiveInterest?.toString() || '')
            .replace('{adminFee}', formatCurrency(data?.rates[selected]?.adminFee))
            .replace('{arrangementFee}', formatCurrency(data?.rates[selected]?.arrangementFee))
            .replace('{monthlyAmount}', formatCurrency(data?.rates[selected]?.montlyAmount))
            .replace('{totalRepaymentAmount}', formatCurrency(data?.rates[selected]?.totalRepaymentAmount))
            .replace('{numberOfMonths}', data?.rates[selected]?.numberOfMonths?.toString() || '')
            .replace('{calculationDate}', new Date().toLocaleDateString())
            .replace('{totalLoanCost}', formatCurrency(data?.rates[selected]?.totalLoanCost));
    }, [data.rates, isAmountValid, error, ratesInfo?.rate_calculator_info_text]);

    return (
        <div data-component="rate-calculator-vertical" className="r-flex r-flex-col r-px-4 r-pt-10 md:r-px-12">
            <div className="r-flex r-flex-col r-justify-between r-space-y-5 md:r-flex-row md:r-space-x-8 md:r-space-y-0">
                <div className="r-flex-shrink r-flex-grow r-basis-[40%] r-space-y-2">
                    <p className="r-text-2xl r-font-medium md:r-mb-5">{props.labels.introTitle}</p>
                    <p className="r-text-sm">{props.labels.introText}</p>
                    <Field
                        inputMode="number"
                        value={amount || ''}
                        suffix={getCurrencySymbol()}
                        inputClassName="!r-pt-4 !r-pb-4"
                        onChange={value => setAmount(value ? parseInt(value) : null)}
                    />
                    {!isLoading && error && <p className="r-text-sm">{props.labels.wrongAmountText}</p>}

                    {/* These aren't used, so I'm just removing them for now. @richard
                    {!isLoading && error && props.labels.paybackTimeAdditionalMessageText && (
                        <p className="r-text-sm">{props.labels.paybackTimeAdditionalMessageText}</p>
                    )}
                    {!isLoading && error && <p className="r-text-sm">{props.labels.apiErrorText}</p>} */}
                </div>
                <div className="r-flex-shrink-0 r-flex-grow r-basis-[50%] r-pl-0 md:r-pl-10">
                    <p className="r-mb-4 r-mt-6 r-text-2xl r-font-medium md:r-mb-4 md:r-mt-0">{props.labels.optionsText}</p>
                    <div className="r-flex r-flex-col r-justify-end">
                        {data?.rates &&
                            data.rates.map((rate, index) => (
                                <RateItem
                                    disabled={error || isLoading}
                                    key={index}
                                    rate={rate}
                                    rateLabel={props.labels.rateLabel}
                                    yearsLabel={props.labels.yearsLabel}
                                    monthlyPaymentsLabel={props.labels.monthlyPaymentLabel}
                                    selected={selected}
                                    index={index}
                                    onClick={() => setSelected(index)}
                                    showDecimals={props.showDecimals}
                                />
                            ))}
                    </div>
                </div>
            </div>
            <div className="r-mt-8 r-flex r-justify-center r-space-x-8 md:r-ml-auto md:r-mt-14 md:r-w-80 md:r-flex-row md:r-justify-end md:r-space-x-7 md:r-space-y-0">
                <Button to={props.infoButton?.url} fullWidth className="r-max-w-xs" type="secondaryBorder">
                    {props.infoButton?.title}
                </Button>
                <Button fullWidth to={getApplyLink()} className="r-max-w-xs" target={props.applyButton?.target || '_self'}>
                    {props.applyButton?.title}
                </Button>
            </div>
            <div className="r-mt-16 r-text-sm r-leading-6" dangerouslySetInnerHTML={{ __html: infoText }} />
            <div className="r-flex r-flex-col r-flex-wrap r-space-y-6 md:r-flex-row md:r-space-x-1 md:r-space-y-0 md:r-pt-6">
                {props.infoItems.map((item, index) => {
                    if (item.link_url) {
                        return (
                            <a key={index} target="_blank" className="r-mt-8 r-flex-grow r-underline md:r-mt-0" href={item.link_url}>
                                {item.title}
                            </a>
                        );
                    }

                    return (
                        <div key={index} className="r-mt-8 r-flex-grow md:r-flex-col">
                            <div
                                className="r-cursor-pointer r-underline"
                                onClick={() => {
                                    setOpenItemId(openItemId === item.id ? -1 : item.id);
                                    setInfoItemActive(item);
                                }}
                            >
                                {item.title}
                            </div>
                            {openItemId === item.id && (
                                <div className="r-mb-6 r-mt-2 r-font-normal md:r-hidden">
                                    <img
                                        onClick={() => setOpenItemId(-1)}
                                        className="r-ml-auto r-cursor-pointer"
                                        src="assets/icons/close.svg"
                                        height={30}
                                        width={30}
                                        alt=""
                                    />
                                    <div dangerouslySetInnerHTML={{ __html: item.content }} />
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>

            {/* For desktop displayment of collapsible content */}
            {infoItemActive && infoItemActive.id === openItemId && (
                <div className="r-mb-6 r-mt-2 r-hidden r-font-normal md:r-block">
                    <img
                        onClick={() => setOpenItemId(-1)}
                        className="r-ml-auto r-cursor-pointer"
                        src="assets/icons/close.svg"
                        height={30}
                        width={30}
                        alt=""
                    />
                    <div dangerouslySetInnerHTML={{ __html: infoItemActive.content }} />
                </div>
            )}
        </div>
    );
};
