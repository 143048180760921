import clsx from 'clsx';
import { Button } from '../../typography/button/button';
import { useLocalization } from '@/hooks/use-localization';
import useIsMobile from '@/hooks/use-is-mobile';

type ClickableInfoboxProps = {
    title: string;
    subtext: string;
    linkTo: {
        url: string;
        title: string;
    };
};

export const ClickableInfobox = ({ title, subtext, linkTo }: ClickableInfoboxProps) => {
    const localization = useLocalization();
    const isMobile = useIsMobile();

    const handleClick = () => {
        if (linkTo?.url?.length && !isMobile) {
            window.open(linkTo?.url, '_blank');
        }
    };

    return (
        <div
            data-component="clickable-infobox"
            className={clsx(
                'r-flex r-w-full r-flex-col r-py-8 r-transition-colors r-duration-200 last-of-type:r-pb-0 md:r-px-6 md:r-py-16 md:r-shadow-xl',
                {
                    'md:r-cursor-pointer md:hover:r-bg-gray-100': linkTo?.url?.length,
                }
            )}
            onClick={handleClick}
        >
            <h2 className="r-mb-6 r-text-2xl r-font-bold">{title}</h2>
            {subtext.length ? <p>{subtext}</p> : null}
            {linkTo?.url?.length ? (
                <Button onClick={() => window.open(linkTo?.url, '_blank')} size="small" fullWidth className="r-mt-4 md:r-hidden">
                    {localization.localize('read-more')}
                </Button>
            ) : null}
        </div>
    );
};
