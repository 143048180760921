import { useContext } from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { AppContext } from '@/contexts/app-context';
import { DOCUMENT_QUERY_KEY, DocumentProps, getDocument } from '@/queries/document';

export function useDocument({ categoriesToDisplay, handlerId }: DocumentProps) {
    const context = useContext(AppContext);

    const documentData = useQuery({
        queryKey: [DOCUMENT_QUERY_KEY, categoriesToDisplay],
        queryFn: () => getDocument(context, { categoriesToDisplay, handlerId }),
        placeholderData: keepPreviousData,
    });

    return {
        ...documentData,
    };
}
