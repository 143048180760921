import { useLocalization } from '@/hooks/use-localization';
import { SavingsCalculatorProps } from './savings-calculator';
import { AppContextType } from '@/contexts/app-context';

const getRateText = (lang: AppContextType['language']) => {
    switch (lang) {
        case 'sv':
            return 'ränta';
        case 'da':
        case 'no':
            return 'rente';
        case 'fi':
            return 'vuosikorkona';
    }
};

export const SingleProductSubHeader = ({ singleProductHeader, interestRates, productName }: SavingsCalculatorProps) => {
    const { getLanguage } = useLocalization();
    if (!singleProductHeader) {
        return null;
    }

    /** @todo how is this supposed to work with different rates of interest? */
    const getText = () => {
        const rate = interestRates[0]?.rate;
        if (!rate) {
            return '';
        }

        const rateText = getRateText(getLanguage());
        const replacement = `${rate}% ${rateText}`;

        return singleProductHeader.replace('{interestRate}', `<strong>${replacement}</strong>`);
    };

    return (
        <div className="r-flex r-justify-center r-py-4">
            <div className="r-w-[560px] r-px-4 r-pt-8">
                <h3 className="r-pb-2 r-text-xl r-font-bold md:r-text-3xl">{productName}</h3>
                <p dangerouslySetInnerHTML={{ __html: getText() }} />
            </div>
        </div>
    );
};
