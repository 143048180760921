import { useLocalization } from '@/hooks/use-localization';

type SearchProps = {
    searchValue: string;
    setSearchValue: React.Dispatch<React.SetStateAction<string>>;
};

export const Search = ({ searchValue, setSearchValue }: SearchProps) => {
    const { localize } = useLocalization();

    return (
        <div className="r-relative r-my-6">
            <img src="/assets/icons/search-gray.svg" alt="" className="r-absolute r-left-4 r-top-1/2 r--translate-y-1/2 r--scale-x-100 r-transform" />
            <input
                onChange={e => setSearchValue(e.target.value)}
                value={searchValue}
                placeholder={localize('search')}
                className="r-w-full r-rounded-3xl r-border-2 r-p-2 r-pl-11"
            />
        </div>
    );
};
