import { useContext } from 'react';
import { AppContext } from '@/contexts/app-context';
import { DynamicWidget } from '@/isomorphic/components/dynamic-widget/dynamic-widget';

export type MyEconomyProps = { startCourse?: string };

export const MyEconomy = ({ startCourse = 'start' }: MyEconomyProps) => {
    const { url, language } = useContext(AppContext);

    const getLang = () => {
        switch (language) {
            case 'sv':
                return 'se';
            case 'no':
                return 'nb';
        }

        return language;
    };

    const cookiedomain = url.replace(/^https?:\/\//, '').replace(/draft\./g, '');

    return (
        <DynamicWidget
            widget="my-economy"
            dataAttributes={{
                start: startCourse,
                lang: getLang(),
                cookiedomain,
            }}
        />
    );
};
