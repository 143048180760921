import { useContext } from 'react';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { SUPREME_CARDS_QUERY_KEY, getSupremeCards } from '@/queries/supreme-cards';
import { AppContext } from '@/contexts/app-context';

export function useSupremeCards() {
    const context = useContext(AppContext);

    const supremeCards = useQuery({
        queryKey: [SUPREME_CARDS_QUERY_KEY],
        queryFn: () => getSupremeCards(context),
        placeholderData: keepPreviousData,
    });

    return {
        supremeCards,
    };
}
