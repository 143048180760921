import { Button } from '../../typography/button/button';
import { MonthlySavings } from './monthly-savings';
import { GoalSavings } from './goal-savings';
import { SingleProductSubHeader } from './single-product-sub-header';
import { Results } from './results';
import { SingleProductFooter } from './single-product-footer';
import { CalculatorStateType, useSavingsCalculator } from './use-savings-calculator';
import { InterestType } from './interest-calculations';
import { NoInterestRates } from './no-interest-rates';

import './styles.css';

export type SavingsCalculatorProps = {
    singleProductHeader?: string;
    singleProductFooter?: string;
    monthlySavingsTabName: string;
    goalSavingsTabName: string;
    goalSavingsAmountLabel: string;
    monthlySavingsYearLabel: string;
    goalSavingsYearLabel: string;
    monthlySavingsDefaultAmount: number;
    monthlySavingsMinAmount: number;
    monthlySavingsMaxAmount: number;
    monthlySavingsDefaultYear: number;
    monthlySavingsMinYear: number;
    monthlySavingsMaxYear: number;
    monthlySavingsStepYear: number;
    monthlySavingsCTAText: string;
    monthlySavingsCTALink: {
        url: string;
        title: string;
    };
    monthlySavingsStepAmount: number;
    monthlySavingsAmountLabel: string;
    monthlySavingsResultsText: string;
    goalSavingsDefaultAmount: number;
    goalSavingsMinAmount: number;
    goalSavingsMaxAmount: number;
    goalSavingsDefaultYear: number;
    goalSavingsMinYear: number;
    goalSavingsMaxYear: number;
    goalSavingsStepYear: number;
    goalSavingsCTAText: string;
    goalSavingsCTALink: {
        url: string;
        title: string;
    };
    goalSavingsResultsText: string;
    productName: string;
    productDescription: string;
    productAccountType: string;
    goalSavingsStepAmount: number;
    interestRates: InterestType;
};

export type SavingsCalculatorSelectorProps = SavingsCalculatorProps & {
    state: CalculatorStateType;
    updateState: (newState: Partial<CalculatorStateType>) => void;
};

export const SavingsCalculator = (props: SavingsCalculatorProps) => {
    const { activeTab, setActiveTab, state, setState, savingsInfo, ctaLink, getResults } = useSavingsCalculator(props);

    const componentProps = {
        ...props,
        state,
        updateState: (newState: Partial<CalculatorStateType>) => {
            setState(previousState => ({ ...previousState, ...newState }));
        },
    };

    const buttonClass = 'r-rounded-t-3xl r-bg-[#d6ebea] r-py-4 r-text-xl r-font-bold r-text-primary-500 r-w-1/2 r-px-4 md:r-rounded-t-[40px]';

    return (
        <div className="r-flex r-flex-col r-items-center">
            <div className="r-flex r-w-full r-items-center r-justify-center r-gap-4 r-px-4 md:r-w-[560px]">
                {activeTab === 1 ? (
                    <>
                        <button onClick={() => setActiveTab(1)} className={buttonClass}>
                            {props.monthlySavingsTabName}
                        </button>
                        <Button disabled onClick={() => setActiveTab(2)} className="r-w-1/2" type="secondaryBorder">
                            {props.goalSavingsTabName}
                        </Button>
                    </>
                ) : (
                    <>
                        <Button onClick={() => setActiveTab(1)} className="r-w-1/2" type="secondaryBorder">
                            {props.monthlySavingsTabName}
                        </Button>
                        <button onClick={() => setActiveTab(2)} className={buttonClass}>
                            {props.goalSavingsTabName}
                        </button>
                    </>
                )}
            </div>
            <div className="wave r-w-full">
                <NoInterestRates {...props} />
                <SingleProductSubHeader {...props} />
                <div className="r-flex r-justify-center">
                    <div className="r-relative r-w-[560px] r-px-6 r-pb-6 md:r-px-4">
                        <div className="r-space-y-12 r-pb-6">
                            {activeTab === 1 ? <MonthlySavings {...componentProps} /> : <GoalSavings {...componentProps} />}
                        </div>

                        <div className="r-flex r-flex-col">
                            <Results results={getResults()} />
                            <SingleProductFooter {...props} savingsInfo={savingsInfo} />
                            <div className="r-flex r-justify-end">
                                <Button to={ctaLink} target="_blank">
                                    {props.monthlySavingsCTAText}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wave-mobile" />
        </div>
    );
};
