import { useLocalization } from '@/hooks/use-localization';

export const SearchResultsText = ({ t10n = 'search-result-intro', searchValue = '' }) => {
    const { localize } = useLocalization();
    const parts = localize(t10n).split('{query}');

    return (
        <>
            {parts[0]} <span className="r-font-bold r-capitalize">{searchValue}</span> {parts?.[1] ?? ''}
        </>
    );
};
