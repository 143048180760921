import { useLocalization } from '@/hooks/use-localization';
import { SavingsCalculatorProps } from './savings-calculator';

type Props = SavingsCalculatorProps & {
    savingsInfo?: {
        totalSaved: number;
        totalGrowth: number;
    } | null;
};

export const SingleProductFooter = ({ singleProductFooter, savingsInfo }: Props) => {
    const { formatCurrency } = useLocalization();
    if (!singleProductFooter || !savingsInfo) {
        return null;
    }

    const getText = () => {
        const totalSaved = formatCurrency(savingsInfo.totalSaved);
        const totalGrowth = formatCurrency(savingsInfo.totalGrowth);
        return singleProductFooter.replace('{totalSaved}', totalSaved).replace('{totalGrowth}', `<strong>+${totalGrowth}</strong>`);
    };

    return (
        <div className="r-flex r-pb-8">
            <p dangerouslySetInnerHTML={{ __html: getText() }} />
        </div>
    );
};
