import { ProgressBar } from '../business-loan-application/components/progress-bar';
import { StepOne } from './components/step-one';
import { StepTwo } from './components/step-two';
import { StepThree } from './components/step-three';
import { SupremeError } from './components/supreme-error';
import { SupremeCardApplicationProps } from './types';
import { useSupremeCardApplication } from './use-supreme-card-application';

export const SupremeCardApplication = (props: SupremeCardApplicationProps) => {
    const { labels, defaultCard, deferredSigningUrl } = props;
    const { step, updateField, updateOffer, onSubmit, fields, isLoading, offer, updatedOffer, error, errorTitle } = useSupremeCardApplication({
        labels,
        defaultCard,
        deferredSigningUrl,
    });

    const onAmountChange = (amount: number) => {
        updateField({ amount });
    };

    const onAfterAmountChange = (amount: number) => {
        updateField({ amount });
        updateOffer(amount);
    };

    const renderCurrentComponent = () => {
        const stepProps = { fields, updateField, onSubmit, isLoading, ...props };

        switch (step) {
            case 1:
                return <StepOne {...stepProps} />;
            case 2:
                return (
                    <StepTwo
                        {...{
                            ...stepProps,
                            onAmountChange,
                            onAfterAmountChange,
                            offer,
                            updatedOffer,
                        }}
                    />
                );

            case 3:
                return <StepThree blocks={props.thankyouPageInnerBlocks} />;
        }
    };

    return (
        <div data-component="supreme-card-application">
            <ProgressBar step={step} oldSkool />
            <div className="r-mt-12">
                {error ? <SupremeError labels={props.labels} errorTitle={errorTitle} error={error} /> : renderCurrentComponent()}
            </div>
        </div>
    );
};
