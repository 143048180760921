import clsx from 'clsx';
import { useEffect, useState } from 'react';

export type IFrameProps = {
    bottomMargin?: boolean;
    fullscreen?: 'landscape' | 'none';
    iframe: string;
};

export const IFrame = (props: IFrameProps) => {
    const isClient = typeof window === 'object';
    const [hasMounted, setHasMounted] = useState(false);
    const [isLandscape, setIsLandscape] = useState(false);

    useEffect(() => {
        setHasMounted(true);

        if (!isClient) {
            return;
        }

        let timeoutId: NodeJS.Timeout | null = null;

        const handleResize = () => {
            if (timeoutId !== null) {
                clearTimeout(timeoutId);
            }

            timeoutId = setTimeout(() => {
                const orientation = typeof window.screen.orientation !== 'undefined' ? window.screen.orientation.type : null;
                if (orientation) {
                    setIsLandscape(orientation.includes('landscape'));
                }
                timeoutId = null;
            }, 200);
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isClient]);

    if (!hasMounted) {
        return null;
    }

    const wrapperClassNames = clsx('r-p-4 md:r-p-12 r-relative', {
        'r-mb-8': props.bottomMargin,
    });
    const iFrameClassNames = clsx('[&>iframe]:r-w-full');

    return (
        <div className={wrapperClassNames}>
            {props.fullscreen === 'landscape' && !isLandscape && (
                <div className="r-absolute r-h-full r-w-full r-border-2 r-bg-primary-100 r-opacity-80">
                    <div className="r-h-full">
                        <p className="r-mt-10 r-text-center r-text-2xl r-font-bold r-text-white">Vänd mobilen till horisontellt läge.</p>
                    </div>
                </div>
            )}
            <div className={iFrameClassNames} dangerouslySetInnerHTML={{ __html: props.iframe }} />
        </div>
    );
};
