import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import { Image } from '../../image/image';
import { CardContent } from '@/queries/supreme-cards';
import { Button } from '../../typography/button/button';
import useIsMobile from '@/hooks/use-is-mobile';

export type CardProps = {
    primary?: boolean;
    content: CardContent;
    buttonText: string;
};

export const Card = ({ primary = false, content, buttonText }: CardProps) => {
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const cardClass = clsx(
        'r-bg-white',
        'r-flex-1',
        'r-flex',
        'r-justify-center',
        'r-items-center',
        'r-flex-col',
        'r-shadow-2xl',
        'r-shrink-0',
        'max-sm:r-w-full',
        'sm:r-space-x-0',
        'sm:r-flex-[0_0_48%]',
        'lg:r-flex-1',
        {
            'lg:r-scale-110': primary,
            'r-order-first md:r-order-first lg:r-order-none': primary,
        }
    );

    const bannerClass = clsx(
        'r-w-full',
        'r-h-14',
        'r-text-center',
        'r-bg-[#008282]',
        'r-text-white',
        'r-flex',
        'r-items-center',
        'r-justify-center',
        'r-relative'
    );

    const arrowStyle: React.CSSProperties = {
        position: 'absolute',
        bottom: '-7.5px',
        height: '15px',
        width: '15px',
        left: 'calc(50% - 7.5px)',
        transform: 'rotate(45deg)',
        backgroundColor: 'rgb(0, 130, 130)',
    };

    return (
        <div data-component="card" className={cardClass} onClick={isMobile ? () => navigate(content.overview_link.url) : undefined}>
            {primary && (
                <div className={bannerClass}>
                    <div style={arrowStyle}></div>
                    <p className="r-text-sm r-font-bold">{content.campaign_details.campaign_title}</p>
                </div>
            )}
            <div className="r-flex r-w-full r-flex-row r-items-start r-bg-white r-pr-0 r-pt-4 sm:r-flex-col sm:r-items-center sm:r-p-0 sm:r-pb-8 lg:r-px-8">
                <div>
                    <Image noMargin src={content.image.src} width={180} height={180} noShadow size="225xAUTO" />
                </div>
                <div className="sm:hidden r-flex r-w-full r-flex-col r-items-start r-space-y-2 r-pt-4 sm:r--mt-6 sm:r-items-center">
                    <h2 className="r-w-full r-text-xl r-font-bold sm:r-text-center">{content.card_slogan}</h2>
                    <div className="r-flex r-flex-col">
                        <h2 className="r-font-extralight">{content.fullname}</h2>
                        <h2 className="r-text-xl r-font-medium">
                            {content.fee.price} kr/{content.fee.period}
                        </h2>
                    </div>

                    <div className="r-hidden r-pt-4 sm:r-block">
                        <Button to={content.overview_link.url} type={primary ? 'primary' : 'secondaryBorder'} size="small">
                            {buttonText}
                        </Button>
                    </div>
                </div>

                <Link className="r-self-center r-p-1 sm:r-hidden" to={content.overview_link.url}>
                    <img height={200} width={50} src="/assets/icons/arrow-right.svg" alt="" />
                </Link>
            </div>
        </div>
    );
};
