import { NameType } from '../types';

export const ApiUser = ({ name, label }: { name: NameType; label: string }) => (
    <div className="r-rounded-xl r-bg-[#e5f2f2] r-p-4">
        <strong className="r-block r-pb-4">
            {name.first} {name.last}
        </strong>
        <span>{label}</span>
    </div>
);
