import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useInsights } from '@/hooks/use-insights';
import { InsightCategoryType } from '@/queries/insights';

type Props = {
    terms?: InsightCategoryType[];
    context?: 'private' | 'corporate' | null;
};

export function Terms({ terms = [], context }: Props) {
    const { insightCategoriesData, insightsSettings } = useInsights();
    const { search } = useLocation();

    if (!terms || !terms.length || !insightCategoriesData.data?.length) {
        return null;
    }

    const colors = [
        'r-bg-primary',
        'r-bg-primary-600',
        'r-bg-[#00D7B4]',
        'r-bg-primary-100',
        'r-bg-primary-200',
        'r-bg-primary-300',
        'r-bg-primary-500',
        'r-bg-primary-400',
        'r-bg-primary',
        'r-bg-primary-100',
        'r-bg-primary-200',
        'r-bg-primary-300',
        'r-bg-primary-400',
        'r-bg-primary-500',
        'r-bg-primary-600',
    ];

    const colorfullTerms = insightCategoriesData.data.map((term, i) => {
        const index = i <= colors.length ? i : i - colors.length;

        return {
            ...term,
            color: colors[index],
        };
    });

    const rootSlug = () => insightsSettings.data?.slug.replace(/\/$/, '');
    const getTerms = () => colorfullTerms.filter(colorfulTerm => !!terms.find(term => term.id === colorfulTerm.id));
    const searchTag = context ? `?group=${context}` : search;

    return (
        <div data-component="terms" className="r-flex r-flex-wrap r-gap-2">
            {getTerms().map(({ id, slug, title, color }) => (
                <Link
                    key={id}
                    className={clsx(color, 'r-rounded-xl r-p-2 r-text-xs r-font-bold r-text-white')}
                    to={`${rootSlug()}/${slug}${searchTag}`}
                >
                    {title}
                </Link>
            ))}
        </div>
    );
}
