import { useLocalization } from '@/hooks/use-localization';
import { validators } from '@/hooks/use-validation';

type ValidatorType = keyof typeof validators;

const dictionary: Record<string, ValidatorType> = {
    socialSecurityNumber: 'socialSecurityNumber',
    governmentId: 'socialSecurityNumber',
    email: 'email',
    phone: 'swedishPhone',
    mobilePhoneNumber: 'swedishPhone',
    phoneNumber: 'swedishPhone',
    companyNumber: 'companyRegistrationNumber',
    organizationNumber: 'companyRegistrationNumber',
    postCode: 'postcode',
    zipCode: 'postcode',
    accountNumber: 'bankAccountAccountNumber',
    clearingNumber: 'bankAccountClearingNumber',
};

type DictionaryType = typeof dictionary;

export const useFieldValidation = () => {
    const { getLanguage } = useLocalization();

    const getValidatorFromFieldName = (key: keyof DictionaryType) => dictionary[key];

    const validate = (fieldName: string, value: string) => {
        if (fieldName in dictionary) {
            const validatorType = getValidatorFromFieldName(fieldName);
            return validators[validatorType](value, getLanguage());
        }

        return !!value;
    };

    return {
        validate,
    };
};
