import { getImageUrl } from '@/utils/images';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';

export type ImageSizes =
    | '1920xAUTO'
    | '1200xAUTO'
    | '1000xAUTO'
    | '960xAUTO'
    | '750xAUTO'
    | '500xAUTO'
    | '400xAUTO'
    | '368xAUTO'
    | '300xAUTO'
    | '225xAUTO'
    | '100xAUTO'
    | 'AUTOx1000'
    | 'AUTOx500'
    | '1920x1000'
    | '1200x800'
    | '960x500'
    | '750x500'
    | '600x400'
    | '480x250'
    | '450x340'
    | '229x300'
    | '225x170'
    | '200x150'
    | '110x82'
    | '100x65'
    | '7x16'
    | '7x12'
    | '7x10'
    | '7x9'
    | '7x8'
    | '7x7'
    | '7x6'
    | '7x5'
    | '7x4'
    | '7x3';

export type ImageProps = {
    src?: string;
    width?: number;
    height?: number;
    size?: ImageSizes;
    altText?: string;
    noShadow?: boolean;
    noMargin?: boolean;
    text?: null | string;
    link?: null | {
        title: string;
        url: string;
    };
    focus?: null | {
        x: number;
        y: number;
    };
};

export const Image = ({
    src,
    width,
    height,
    size = '1200xAUTO',
    noShadow = false,
    altText = '',
    text = null,
    link = null,
    focus = null,
    noMargin = false,
}: ImageProps) => {
    const { pathname } = useLocation();
    if (!src) {
        return null;
    }

    const style: React.CSSProperties = {};
    if (focus?.x && focus?.y) {
        style.objectPosition = `${focus.x * 100}% ${focus.y * 100}%`;
    }

    const renderImage = () => (
        <img
            style={style}
            loading="lazy"
            alt={altText}
            width={width}
            height={height}
            src={getImageUrl(src, size)}
            className={clsx('r-rounded-2xl', {
                'r-shadow-lg': !noShadow,
                'r-mb-6': !noMargin,
            })}
        />
    );

    return (
        <figure data-component="image">
            {link?.url ? <Link to={link?.url ?? pathname}>{renderImage()}</Link> : renderImage()}
            {text ? <p className="r-pt-1 r-text-center r-text-sm">{text}</p> : null}
        </figure>
    );
};
