import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '@/contexts/app-context';

export type ShareProps = {
    label: string;
    pageTitle: string;
    pageSummary?: string;
};

export const Share = ({ label, pageTitle, pageSummary }: ShareProps) => {
    const { pathname, search, hash } = useLocation();
    const context = useContext(AppContext);
    const searchParam = search === '?' ? '' : search;
    const url = `${context.url}${pathname}${searchParam}${hash}`;

    function generateUrl(baseUrl: string, params: Record<string, string | null | undefined>) {
        const searchParams = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
            if (value) {
                searchParams.append(key, value);
            }
        });
        return `${baseUrl}?${searchParams.toString()}`;
    }

    return (
        <div className="r-flex r-items-center r-gap-4">
            <p className="r-font-bold">{label}</p>
            <div className="">
                <a
                    href={generateUrl('http://www.linkedin.com/shareArticle', {
                        mini: 'true',
                        url,
                        title: pageTitle,
                        summary: pageSummary,
                    })}
                    target="_blank"
                >
                    <img width={30} height={30} src="/assets/icons/linkedin.svg" />
                </a>
            </div>
            <div className="">
                <a href={generateUrl('https://facebook.com/sharer/sharer.php', { u: url })} target="_blank">
                    <img width={30} height={30} src="/assets/icons/facebook.svg" />
                </a>
            </div>
            <div className="">
                <a href={generateUrl('https://twitter.com/intent/tweet', { url, text: pageTitle })} target="_blank">
                    <img width={30} height={30} src="/assets/icons/twitter.svg" />
                </a>
            </div>
        </div>
    );
};
