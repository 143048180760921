import { useGlobalQueries } from '@/hooks/use-global-queries';
import { Button } from '../../typography/button/button';
import { NewsItem } from './news-item';
import { useLocalization } from '@/hooks/use-localization';

export type FeedItem = {
    group_id: string;
    news_id: string;
    source: string;
    url: string;
    author: {
        brand_image_url: string;
        entity_id: string;
        name: string;
        slug: string;
        slugs: string[];
    };
    content: {
        html: string;
        preamble: string;
        pbulish_date: string;
        slug: string;
        title: string;
        attachments: {
            content_type: string;
            file_title: string;
            tags: string[];
            url: string;
        }[];
    };
};

export type CisionLandingProps = {
    backgroundValue: {
        url: string;
        height: number;
        width: number;
    };
    transformAttributes: {
        feed_link: string;
        single_item_link: string;
        feed: {
            feed_url: string;
            home_page_url: string;
            next_url: string;
            version: string;
            items: FeedItem[];
        };
    };
};

const MAX_INTRO_WORDS_MAIN = 26;
const MAX_INTRO_WORDS_SUBS = 18;

export const formatIntro = (introHtml: string, wordCount: number) => {
    const text = introHtml.replace(/(<([^>]+)>)/gi, '');
    return text.split(' ').slice(0, wordCount).join(' ');
};

export const CisionLanding = ({ backgroundValue, transformAttributes }: CisionLandingProps) => {
    const { localize } = useLocalization();
    const { getSetting } = useGlobalQueries();
    const feedLink = getSetting('mfn_landing_page_link') || transformAttributes.feed_link;

    if (!transformAttributes?.feed?.items?.length) {
        return null;
    }

    return (
        <div data-component="cision-landing" className="r-flex r-w-full r-flex-col r-bg-[#f8f9fa]">
            <div
                className="r-flex r-items-center r-justify-center"
                style={{
                    backgroundImage: `url(${backgroundValue.url})`,
                    height: backgroundValue.height,
                    backgroundSize: 'cover',
                }}
            >
                <a
                    href={`${transformAttributes.single_item_link}${transformAttributes.feed.items[0].news_id}`}
                    className="r-mr-0 r-flex r-max-w-sm r-flex-col r-space-y-2 r-bg-white r-p-8 md:r-mr-72 md:r-max-w-none"
                >
                    <p className="r-text-2xl r-font-bold">{transformAttributes.feed.items[0].content.title}</p>
                    <p className="r-max-w-lg">{formatIntro(transformAttributes.feed.items[0].content.html, MAX_INTRO_WORDS_MAIN)}</p>
                    <div>
                        <img
                            className="r-float-right"
                            src="/assets/icons/arrow.svg"
                            alt={`Arrow icon pointing towards the news item: ${transformAttributes.feed.items[0].content.title}`}
                        />
                    </div>
                </a>
            </div>
            <div className="r-flex r-flex-col r-items-start r-space-y-6 r-p-4 lg:r-flex-row lg:r-space-x-4 lg:r-space-y-0 lg:r-p-8">
                {transformAttributes.feed.items.slice(1).map(item => (
                    <NewsItem key={item.news_id} item={item} link={transformAttributes.single_item_link} maxWords={MAX_INTRO_WORDS_SUBS} />
                ))}
            </div>
            <div className="r-mx-auto r-pb-6 r-pt-2  lg:r-pb-8 lg:r-pt-0">
                <Button size="medium" className="r-px-8" to={feedLink}>
                    {localize('see-all')}
                </Button>
            </div>
        </div>
    );
};
