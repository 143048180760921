import { useEffect, useMemo, useState } from 'react';
import Slider from 'rc-slider';
import { Button } from '../../typography/button/button';
import { useClusterCalculator } from '@/hooks/use-cluster-calculator';
import { useLocalization } from '@/hooks/use-localization';
import { RangeSlider } from '@/isomorphic/components/forms/components/range-slider';

export type ClusterCalculatorProps = {
    monthlyFee: string;
    maxOpeningFee: string;
    minOpeningFee: string;
    openingFee: string;
    minAmount: string;
    maxAmount: string;
    defaultLoanAmount: string;
    durations: {
        duration: string;
        default: boolean;
        link?: string;
    }[];
    defaultLoanDuration: string;
    mdr: string;
    interestRate: string;
    stepValue: number;
    labels: {
        interestText: string;
        apply: string;
        months: string;
        loanAmount: string;
        monthlyCost: string;
        currency: string;
    };
};

type Duration = {
    duration: string;
    default?: boolean;
    link?: string;
};

export const ClusterCalculator = (props: ClusterCalculatorProps) => {
    const { formatNumber } = useLocalization();
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    const getDefaultLoanDuration = useMemo(() => {
        const defaultElement = props.durations.find(element => element.default);

        if (defaultElement !== undefined) {
            return defaultElement.duration;
        }

        if (props.durations.length === 0) {
            return '';
        }

        return props.durations.reduce((prev, curr) => (parseInt(prev.duration) < parseInt(curr.duration) ? prev : curr)).duration;
    }, [props.durations]);

    const getDefaultLoanAmount = useMemo(() => {
        if (props.defaultLoanAmount >= props.minAmount && props.defaultLoanAmount <= props.maxAmount) {
            return props.defaultLoanAmount;
        }

        return props.minAmount;
    }, []);

    const [amount, setAmount] = useState(getDefaultLoanAmount);
    const [debouncedAmount, setDebouncedAmount] = useState(getDefaultLoanAmount);
    const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);
    const [mdr, setMdr] = useState<string>(getDefaultLoanDuration);

    const sortedDurationsOnly = useMemo(() => {
        return props.durations
            .map((duration: Duration) => {
                return duration.duration;
            })
            .sort();
    }, []);

    const [mdrIndex, setMdrIndex] = useState(sortedDurationsOnly.indexOf(getDefaultLoanDuration));
    const { data } = useClusterCalculator({
        amount: debouncedAmount,
        mdr,
        monthlyFee: props.monthlyFee,
        interestRate: props.interestRate,
        openingFee: props.openingFee,
        minOpeningFee: props.minOpeningFee,
        maxOpeningFee: props.maxOpeningFee,
    });

    const getDefaultLinkValue = () => {
        const link = props.durations.find(({ duration }) => duration === mdr)?.link;
        return link !== undefined ? link : '';
    };

    const [link, setLink] = useState(getDefaultLinkValue());

    const handleOnAmountSliderChange = (value: string) => {
        setAmount(value);

        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }

        const newTimer = setTimeout(() => {
            setDebouncedAmount(value);
        }, 1000);

        setDebounceTimer(newTimer);
    };

    const handleOnDurationSliderChange = (value: number) => {
        const newMdr = sortedDurationsOnly[value];
        const link = props.durations.find(({ duration }) => duration === newMdr)?.link;
        const selectedLoanLink = link !== undefined ? link : '';

        setMdr(newMdr);
        setMdrIndex(value);
        setLink(selectedLoanLink);
    };

    const sliderProps = {
        style: {
            backgroundColor: 'transparent',
        },
        handleStyle: {
            background: '#e1bf00',
            opacity: 1,
            border: 'none',
            boxShadow: 'none',
            width: '30px',
            height: '30px',
            marginTop: '-12px',
        },
        railStyle: {
            height: '9px',
            background: 'rgb(0, 130, 130)',
        },
        trackStyle: {
            height: '9px',
            background: '#e1bf00',
        },
        dotStyle: {
            opacity: 0,
        },
    };

    return (
        <div className="r-mb-10 r-flex r-flex-col r-px-2 lg:r-px-44 lg:r-py-20" data-component="cluster-calculator">
            {isClient ? (
                <div className="r-mx-2 r-mt-3 r-flex r-flex-col r-rounded-t-2xl r-bg-primary-500 r-px-8 r-pt-12">
                    <RangeSlider
                        value={parseInt(amount)}
                        min={props.minAmount}
                        max={props.maxAmount}
                        label={props.labels.loanAmount}
                        step={props.stepValue}
                        setValue={value => handleOnAmountSliderChange(value.toString())}
                        currency
                        editable
                    />
                    <div className="r-mt-4 r-flex r-flex-col">
                        <div className="r-flex r-w-full r-items-center r-justify-between">
                            <p className="r-mb-5 r-flex r-text-xl r-font-semibold r-text-[#e1bf00]">MDR</p>
                            <p className="r-mb-5 r-flex r-text-xl r-font-semibold r-text-[#e1bf00]">
                                {mdr} {props.labels.months}
                            </p>
                        </div>
                        <Slider
                            onChange={value => handleOnDurationSliderChange(value as number)}
                            value={mdrIndex} // This is now the array index
                            step={1} // Steps are always 1 since we're working with array indices
                            min={0} // The lowest possible index in an array
                            max={sortedDurationsOnly.length - 1} // The highest possible index in an array
                            {...sliderProps}
                        />
                        <div className="r-flex r-justify-between r-py-6 r-text-yellow-400">
                            <p>{Math.min(...sortedDurationsOnly.map(Number))}</p>
                            <p>{Math.max(...sortedDurationsOnly.map(Number))}</p>
                        </div>
                    </div>
                </div>
            ) : null}
            <div className="r-mx-2 r-rounded-b-2xl r-bg-[#ccd8d8]">
                <div
                    className="-r-mt-1 r-h-[50px] r-bg-primary-500"
                    style={{
                        WebkitClipPath: 'polygon(100% 0%, 100% 30%, 50% 90%, 50% 90%, 0px 30%, 0px 0px)',
                        clipPath: 'polygon(100% 0%, 100% 30%, 50% 90%, 50% 90%, 0px 30%, 0px 0px)',
                    }}
                />
                <div className="r-flex r-flex-col  r-space-y-4 r-px-8 r-py-6 ">
                    <div className="r-flex r-justify-between  r-text-xl r-font-semibold">
                        <p>{props.labels.monthlyCost}</p>
                        <p>{formatNumber(data?.monthly_installment || 0)}</p>
                    </div>
                    <Button to={link} size="small" fullWidth>
                        {props.labels.apply}
                    </Button>
                </div>
            </div>
            <div
                className="r-mx-2 r-px-8 r-py-6"
                dangerouslySetInnerHTML={{
                    __html: data
                        ? props.labels.interestText
                              .replace('{amount to loan}', formatNumber(Math.round(parseInt(data.amount))))
                              .replace('{variable_debtor_interest}', data.variable_debtor_interest)
                              .replace('{apr}', data.apr)
                              .replace('{total_cost}', formatNumber(Math.round(parseInt(data.total_cost))))
                              .replace('{total_pay}', formatNumber(Math.round(parseInt(data.total_pay))))
                              .replace('{initial_fee}', `${Math.round(parseInt(data.initial_fee))}`)
                              .replace('{loan duration}', data.months)
                              .replace('{interest rate}', props.interestRate)
                              .replace('{monthly_installment}', formatNumber(Math.round(parseInt(data.monthly_installment))))
                        : '',
                }}
            ></div>
        </div>
    );
};
