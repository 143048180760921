import { useEffect, useState } from 'react';
import { CorporateRegistryType, FieldValuesType, KycGroupType, KycValuesType } from '../types';
import { KycSurvey, Question } from '@/queries/kyc-survey';

export type OnKycChangeArgs = {
    value: string;
    questionId: number;
    type: KycGroupType;
    isChecked?: boolean;
    isMultiple?: boolean;
};

const INITAL_KYC_VALUES: KycValuesType = {
    CORPORATE_SAVINGS: {
        id: '',
        group: 'CORPORATE_SAVINGS',
        questions: {},
    },
    COMPANY: {
        id: '',
        group: 'COMPANY',
        questions: {},
    },
    OWNERSHIP_AND_CONTROL: {
        id: '',
        group: 'OWNERSHIP_AND_CONTROL',
        questions: {},
    },
};

const INITIAL_KYC_SURVEY_DATA = {
    groups: [
        {
            id: 'new-account',
            title: 'Frågor om ert företags sparande',
            type: 'CORPORATE_SAVINGS',
            questions: [],
        },
        {
            id: 'COMPANY',
            title: 'Fyll i uppgifter om er verksamhet',
            type: 'COMPANY',
            questions: [],
        },
        {
            id: 'OWNERSHIP_AND_CONTROL',
            title: 'Ägarskap och kontroll',
            type: 'OWNERSHIP_AND_CONTROL',
            questions: [],
        },
    ],
};

export const KYC_SESSION_STORAGE_KEY = 'businessFormKycValues';

export const useKyc = () => {
    const [kycValues, setKycValues] = useState<KycValuesType>(INITAL_KYC_VALUES);
    const [kycSurveyData, setKycSurveyData] = useState<KycSurvey>(INITIAL_KYC_SURVEY_DATA);

    const getSessionValues = () => {
        const session = sessionStorage?.getItem(KYC_SESSION_STORAGE_KEY);
        if (!session || typeof session !== 'string') {
            return null;
        }

        try {
            return JSON.parse(session) ?? null;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const getParsedKycValues = (data: KycSurvey, corporateData: CorporateRegistryType, company: FieldValuesType['company']) => {
        const sessionValues = getSessionValues();

        return data?.groups?.reduce(
            (previous, currentGroup) => ({
                ...previous,
                [currentGroup.type]: {
                    id: currentGroup.id,
                    group: currentGroup.type,
                    questions: currentGroup.questions.reduce((previous, { id, text: question }) => {
                        const { answer, readOnly } = getAnswerForQuestion({
                            questionId: id,
                            group: currentGroup.type as KycGroupType,
                            data,
                            corporateData,
                            company,
                            sessionValues,
                        });

                        return {
                            ...previous,
                            [id]: {
                                id,
                                question,
                                answer,
                                readOnly,
                            },
                        };
                    }, {}),
                },
            }),
            {} as KycValuesType
        );
    };

    useEffect(() => {
        // Save to session storage every time values change, except the first time
        // this value is updated, as the questions won't exist yet!
        if (Object.values(kycValues['COMPANY'].questions).length) {
            sessionStorage?.setItem(KYC_SESSION_STORAGE_KEY, JSON.stringify(kycValues));
        }
    }, [kycValues]);

    function updateKycSurvyData(data: KycSurvey, corporateData: CorporateRegistryType, company: FieldValuesType['company']) {
        setKycSurveyData(data);
        setKycValues(getParsedKycValues(data, corporateData, company));
    }

    const isDisabled = (questionId: number, type: KycGroupType) => kycValues[type].questions[questionId]?.readOnly ?? false;
    const getValue = (questionId: number, type: KycGroupType) => kycValues[type].questions[questionId]?.answer?.[0] ?? '';
    const getValues = (questionId: number, type: KycGroupType) => kycValues[type].questions[questionId]?.answer ?? '';

    const onChange = ({ questionId, value, type, isMultiple = false, isChecked = false }: OnKycChangeArgs) => {
        if (!kycValues) {
            return;
        }

        const questionToUpdate = kycValues[type].questions?.[questionId];
        if (!questionToUpdate) {
            return;
        }

        if (isMultiple) {
            if (isChecked) {
                questionToUpdate.answer = [...questionToUpdate.answer, value];
            } else {
                questionToUpdate.answer = questionToUpdate.answer.filter(answer => answer !== value);
            }
        } else {
            questionToUpdate.answer = [value];
        }

        setKycValues(prev => ({
            ...prev,
            [type]: {
                ...prev[type],
                questions: {
                    ...prev[type].questions,
                    [questionId]: questionToUpdate,
                },
            },
        }));
    };

    const shouldDisplayQuestion = (group: KycSurvey['groups'][number], { displayCondition }: Question): boolean => {
        // No display condition, we can safely show the question
        if (!displayCondition) {
            return true;
        }

        // Find the parent question referred to in the displayCondition
        const parentQuestion = group.questions.find(question => question.id === displayCondition.questionId);
        if (!parentQuestion) {
            return true;
        }

        // Get the desired option's text value
        const optionText = parentQuestion?.options?.find(option => option.id === displayCondition.optionId)?.text;
        if (!optionText) {
            return false;
        }

        // Check if the value we're looking for is contained in the values for the question
        const shouldDisplay = getValues(displayCondition.questionId, group.type as KycGroupType).includes(optionText) || false;
        if (shouldDisplay) {
            // Ok, the value IS present, HOWEVER it's possible the parent also has a
            // displayCondition so we need to recursively check this display logic.
            // This way we can always make sure we don't show questions if the parent
            // is not visible.
            return shouldDisplayQuestion(group, parentQuestion);
        }

        return false;
    };

    const getGroupQuestions = (type: KycGroupType) => {
        // These questions are answered in the hard-coded fields for the application.
        // In order to follow the design in Figma, we hide these here and update
        // them in code when the user fills in the information in the fields on step one.
        const hiddenQuestionIds = new Set([100, 101, 102, 103]);
        const group = kycSurveyData?.groups?.find(group => group.type === type);

        if (!group) {
            return null;
        }

        return {
            ...group,
            questions: group.questions.filter(question => {
                if (hiddenQuestionIds.has(question.id)) {
                    return false;
                }

                if (!shouldDisplayQuestion(group, question)) {
                    return false;
                }

                return true;
            }),
        } as KycSurvey['groups'][number];
    };

    const isGroupValid = (type: KycGroupType) => {
        if (!kycValues) {
            return false;
        }

        const groupQuestions = getGroupQuestions(type);
        if (!groupQuestions) {
            return true;
        }

        return Object.keys(kycValues[type].questions)
            .map(id => parseInt(id))
            .every(questionId => {
                const isVisibleQuestion = !!groupQuestions.questions.find(groupQuestion => groupQuestion.id === questionId);
                // If the question is not visible it can't be answered or validated
                if (!isVisibleQuestion) {
                    return true;
                }

                // As all answers are in the form there is at least one answer that is not empty
                return kycValues[type].questions[questionId].answer.filter(a => a).length > 0;
            });
    };

    function getOptionForQuestion({
        questionId,
        data,
        group,
        optionId,
    }: {
        questionId: number;
        data: KycSurvey;
        group: KycGroupType;
        optionId: number;
    }) {
        const question = data?.groups?.find(({ type }) => type === group)?.questions?.find(({ id }) => id === questionId);
        const optionText = question?.options?.find(option => option.id === optionId)?.text ?? '';

        return [optionText];
    }

    function getAnswerForQuestion({
        questionId,
        data,
        group,
        corporateData: { beneficialOwners },
        company,
        sessionValues,
    }: {
        questionId: number;
        data: KycSurvey;
        group: KycGroupType;
        corporateData: CorporateRegistryType;
        company: FieldValuesType['company'];
        sessionValues: KycValuesType | null;
    }) {
        const companyAddress = [company.address.street, `${company.address.zipCode} ${company.address.city}`].filter(v => v).join(', ');

        let DICTIONARY: Record<number, string[]> = {
            100: [company.organizationName ?? ''],
            101: [company.governmentId.id ?? ''],
            102: [companyAddress],
            103: [company.organizationType ?? ''],
        };

        const answer = (optionId = 1) => getOptionForQuestion({ questionId, data, group, optionId });
        const firstOwner = beneficialOwners?.[0];
        const secondOwner = beneficialOwners?.[1] ?? null;
        const thirdOwner = beneficialOwners?.[2] ?? null;

        if (firstOwner) {
            DICTIONARY = {
                ...DICTIONARY,
                2000: answer(2),
                2201: answer(),
                2202: [firstOwner.name?.first ?? ''],
                2203: [firstOwner.name?.last ?? ''],
                2204: firstOwner?.governmentId?.id ? answer() : [''],
                2205: [firstOwner?.governmentId?.id ?? ''],
            };
        }

        if (secondOwner) {
            DICTIONARY = {
                ...DICTIONARY,
                2249: answer(),
                2250: answer(),
                2251: [secondOwner.name?.first ?? ''],
                2252: [secondOwner.name?.last ?? ''],
                2253: secondOwner?.governmentId?.id ? answer() : [''],
                2254: [secondOwner?.governmentId?.id ?? ''],
            };
        }

        if (thirdOwner) {
            DICTIONARY = {
                ...DICTIONARY,
                2298: answer(),
                2299: answer(),
                2300: [thirdOwner.name?.first ?? ''],
                2301: [thirdOwner.name?.last ?? ''],
                2302: thirdOwner?.governmentId?.id ? answer() : [''],
                2303: [thirdOwner?.governmentId?.id ?? ''],
            };
        }

        const response: { answer: string[]; readOnly: boolean } = {
            answer: [''],
            readOnly: false,
        };

        if (DICTIONARY[questionId]) {
            response.answer = DICTIONARY[questionId];
            response.readOnly = true;
        }

        const sessionValue = sessionValues?.[group]?.questions?.[questionId]?.answer ?? null;
        if (sessionValue) {
            response.answer = sessionValue;
        }

        return response;
    }

    return {
        getValue,
        onChange,
        getValues,
        kycValues,
        isDisabled,
        isGroupValid,
        setKycValues,
        kycSurveyData,
        getGroupQuestions,
        updateKycSurvyData,
    };
};
