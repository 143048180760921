import { Link } from 'react-router-dom';
import { SearchResult as SearchResultType } from '@/queries/search';

type SearchResultProps = {
    item: SearchResultType;
    onClick: () => void;
};

export const SearchResult = ({ item, onClick }: SearchResultProps) => {
    const formatPermalink = (permalink: string) => permalink.replace('/cluster_page', '').substring(1).replace(/\/$/, '');

    const getFullUrl = (permalink: string) => {
        const siteUrl = window?.location?.origin || 'http://localhost';
        const url = permalink ? `${siteUrl}${permalink}` : permalink;

        return url.replace('/cluster_page', '').replace(/\/$/, '');
    };

    const getFormattedContent = () =>
        item._formatted.post_content.toString().replace(/<em>([^<]*)<\/em>/g, '<span class="r-text-black r-bg-secondary-100">$1</span>');

    return (
        <div className="r-my-6 r-text-sm">
            <Link to={formatPermalink(item.permalink)} onClick={onClick}>
                <span className="r-font-bold" dangerouslySetInnerHTML={{ __html: item.post_title }} />
            </Link>
            <div className="r-mb-4 r-mt-1 r-text-gray-400" dangerouslySetInnerHTML={{ __html: getFormattedContent() }} />
            <div className="r-text-xs r-italic r-text-primary-500">
                <Link className="" to={formatPermalink(item.permalink)} onClick={onClick}>
                    {getFullUrl(item.permalink)}
                </Link>
            </div>
        </div>
    );
};
