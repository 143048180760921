import { Link, useNavigate } from 'react-router-dom';
import { useInsights } from '@/hooks/use-insights';

export type SearchProps = {
    subscribeLink?: {
        title: string;
        url: string;
    };
    labels: {
        showAllLabel: string;
    };
};

export const Search = ({ subscribeLink, labels }: SearchProps) => {
    const navigate = useNavigate();
    const { insightsSettings, insightCategoriesData, getSortedTerms } = useInsights();
    const rootSlug = () => insightsSettings.data?.slug.replace(/\/$/, '');
    const { privateTerms, corporateTerms } = getSortedTerms();

    const getSelectOptionsForGroup = (group: 'private' | 'corporate' = 'private') => {
        const terms = group === 'private' ? privateTerms : corporateTerms;

        return insightCategoriesData?.data
            ?.filter(({ id }) => terms.has(id))
            ?.sort((a, b) => a.title.localeCompare(b.title))
            ?.map(({ id, slug, title }) => (
                <option key={id} value={`${rootSlug()}/${slug}?group=${group}`}>
                    {title}
                </option>
            ));
    };

    return (
        <div data-component="search" className="r-container r-flex r-items-center r-justify-between r-py-4 r-text-white">
            {subscribeLink ? (
                <Link to={subscribeLink.url} className="r-rounded-xl r-border r-border-white r-px-4 r-py-2">
                    {subscribeLink.title}
                </Link>
            ) : null}
            <div className="max-md:r-hidden">
                <select
                    value=""
                    onChange={({ target }) => navigate(target.value)}
                    className="r-form-select r-w-full r-rounded r-border r-border-white r-bg-primary r-p-2 focus:r-border-white focus:r-ring-white"
                >
                    <option value={rootSlug()}>{labels.showAllLabel}</option>
                    {getSelectOptionsForGroup()}
                    {getSelectOptionsForGroup('corporate')}
                </select>
            </div>
        </div>
    );
};
