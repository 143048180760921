import { CardComparison } from './card-comparison';
import { CardContent } from '@/queries/supreme-cards';

type Props = {
    cardComparisonData: CardComparison;
    activeCard: CardContent;
};

export const BenefitDesktop = ({ cardComparisonData, activeCard }: Props) => {
    if (!cardComparisonData.cards.find(card => card.slug === activeCard.api_name.toLowerCase())) {
        return null;
    }

    return (
        <div className="r-flex r-w-full r-items-center r-justify-start r-space-x-3">
            <svg className="r-shrink-0" width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M21.5 2.0564C21.5 2.44021 21.3535 2.82421 21.0605 3.11671L9.06055 15.1167C8.76875 15.4111 8.38438 15.5564 8 15.5564C7.61562 15.5564 7.23219 15.4099 6.93969 15.1169L0.939688 9.11694C0.646719 8.82515 0.5 8.44077 0.5 8.0564C0.5 7.19952 1.20078 6.5564 2 6.5564C2.38381 6.5564 2.76781 6.70288 3.06031 6.99585L8 11.9376L18.9406 0.997022C19.2312 0.703115 19.6156 0.556396 20 0.556396C20.8016 0.556396 21.5 1.19858 21.5 2.0564Z"
                    fill="#27C0A8"
                />
            </svg>
            <div className="r-flex r-flex-col">
                <p className="r-pb-[2px]">{cardComparisonData.title}</p>
                <p className="r-text-xs">{cardComparisonData.subtext}</p>
            </div>
        </div>
    );
};
