import { useState, useEffect } from 'react';

interface DebouncedFunction {
    (): void;
}

export default function useIsMobile(): boolean {
    const [isMobile, setIsMobile] = useState<boolean>(getIsMobile);

    function getIsMobile(): boolean {
        if (typeof window === 'undefined') {
            return true;
        }

        return window.innerWidth <= 768;
    }

    function debounce(func: () => void, delay: number): DebouncedFunction {
        let debounceTimer: NodeJS.Timeout | null;

        return (): void => {
            if (debounceTimer) {
                clearTimeout(debounceTimer);
            }
            debounceTimer = setTimeout(func, delay);
        };
    }

    useEffect(() => {
        if (typeof window === 'undefined') {
            return undefined;
        }

        const handleResize: DebouncedFunction = debounce(function (): void {
            setIsMobile(getIsMobile());
        }, 250);

        window.addEventListener('resize', handleResize);

        return (): void => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return isMobile;
}
