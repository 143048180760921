import React from 'react';

type Props = {
    title: string;
    subtext: string;
};

export const Benefit = ({ title, subtext }: Props) => (
    <div className="r-flex r-items-center r-space-x-3">
        <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="r-shrink-0">
            <path
                d="M21.5 2C21.5 2.38381 21.3535 2.76781 21.0605 3.06031L9.06055 15.0603C8.76875 15.3547 8.38438 15.5 8 15.5C7.61562 15.5 7.23219 15.3535 6.93969 15.0605L0.939688 9.06055C0.646719 8.76875 0.5 8.38438 0.5 8C0.5 7.14313 1.20078 6.5 2 6.5C2.38381 6.5 2.76781 6.64648 3.06031 6.93945L8 11.8812L18.9406 0.940625C19.2312 0.646719 19.6156 0.5 20 0.5C20.8016 0.5 21.5 1.14219 21.5 2Z"
                fill="#27C0A8"
            />
        </svg>

        <div className="r-flex r-flex-col r-space-y-1">
            <p className="r-text-sm">{title}</p>
            <p className="r-text-xs">{subtext}</p>
        </div>
    </div>
);
