import { DownloadItem } from './download-item';

type Download = {
    file: string;
    fileId: number;
    filename: string;
    filesize: string;
    id: number;
    title: string;
};
export type DownloadModuleProps = {
    downloads: Download[];
    index: number;
    subtext: string;
    title: string;
    image: {
        src: string;
        __isimage: boolean;
    };
};

export const DownloadModule = (props: DownloadModuleProps) => {
    return (
        <div className="r-p-5">
            {props?.image?.src && props?.image?.__isimage ? (
                <div className="r-flex r-h-[140px] r-w-full r-justify-center r-rounded-lg">
                    <img src={props.image.src} alt="" className="r-w-full r-rounded-2xl r-object-cover " />
                </div>
            ) : null}
            <div className="r-mt-6 r-space-y-2">
                {props.title ? <p className="r-text-xl r-font-bold">{props.title}</p> : null}
                {props.subtext ? <p className="">{props.subtext}</p> : null}
            </div>

            <div className="r-mt-4 r-flex r-w-full r-flex-col r-space-y-2 r-px-2">
                {props.downloads.map((download, index: number) => (
                    <DownloadItem
                        title={download.title}
                        filepath={download.file}
                        filename={download.filename}
                        filesize={download.filesize}
                        key={index}
                    />
                ))}
            </div>
        </div>
    );
};
