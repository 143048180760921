import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AppContext } from '@/contexts/app-context';
import { KYC_SURVEY_QUERY_KEY, getKycSurvey } from '@/queries/kyc-survey';

export function useKycSurvey() {
    const context = useContext(AppContext);

    const { data, isLoading } = useQuery({
        queryKey: [KYC_SURVEY_QUERY_KEY],
        queryFn: () => getKycSurvey(),
        enabled: context?.user?.isAuthenticated ?? false,
    });

    return {
        data,
        isLoading,
    };
}
