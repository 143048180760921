import { useContext, useEffect, useMemo } from 'react';
import { AppContext } from '@/contexts/app-context';
import { useSupremeCards } from '@/hooks/use-supreme-cards';
import { Image } from '../../image/image';

type CardFields = {
    cardApplication: {
        stepOnePersonalData: {
            title: string;
            content: string;
        };
        stepTwoSetLimit: {
            title: string;
            content: string;
        };
        stepThreeGranted: {
            title: string;
            content: string;
            link: {
                enabled: boolean;
                text: string;
            };
        };
        error: {
            title: string;
            content: string;
        };
    };
    rejection: {
        stepThreeRejection: {
            title: string;
            content: string;
        };
    };
};

export type SupremeCardInfoProps = {
    name: string;
    defaultCard: string;
    cardInfoTexts: {
        CLASSIC: CardFields;
        GOLD: CardFields;
        WOMAN: CardFields;
        WORLD: CardFields;
    };
};

export const SupremeCardInfo = (props: SupremeCardInfoProps) => {
    const { supremeCardApplication, setSupremeCardApplication } = useContext(AppContext);
    const { supremeCards } = useSupremeCards();

    const currentCard = useMemo(
        () => supremeCards.data?.find(({ api_name: apiName }) => apiName === supremeCardApplication.selectedCard),
        [supremeCards, supremeCardApplication]
    );
    const cardTexts = useMemo(
        () => props.cardInfoTexts[supremeCardApplication.selectedCard as keyof typeof props.cardInfoTexts] ?? null,
        [supremeCardApplication, props]
    );

    const getCurrentData = useMemo(() => {
        switch (supremeCardApplication.step) {
            case 1:
                return cardTexts?.cardApplication?.stepOnePersonalData ?? null;
            case 2:
                return cardTexts?.cardApplication?.stepTwoSetLimit ?? null;
            case 3:
                return cardTexts?.cardApplication?.stepThreeGranted ?? null;
        }
    }, [supremeCardApplication.step, cardTexts]);

    useEffect(() => {
        setSupremeCardApplication({
            progressText: getCurrentData,
        });
    }, [supremeCardApplication.step, supremeCardApplication.selectedCard]);

    if (!supremeCardApplication?.progressText || !currentCard?.title) {
        return null;
    }

    return (
        <div className="max-md:r-hidden">
            {currentCard ? (
                <Image src={currentCard?.image?.src} height={252} width={160} altText={currentCard?.title} size="225xAUTO" noShadow />
            ) : null}
            <h4 className="r-mb-2 r-text-xl r-font-bold">{supremeCardApplication?.progressText?.title}</h4>
            <div className="styled-list" dangerouslySetInnerHTML={{ __html: supremeCardApplication?.progressText?.content }}></div>
        </div>
    );
};
