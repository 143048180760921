import { ClickableInfobox } from './clickable-infobox';
import clsx from 'clsx';

export type ClickableInfoboxesProps = {
    boxes: {
        title: string;
        subtext: string;
        linkTo: {
            url: string;
            title: string;
        };
    }[];
    numColumns: 2 | 3;
};
export const ClickableInfoboxes = (props: ClickableInfoboxesProps) => {
    const classNames = clsx('r-grid r-grid-cols-1 r-divide-y md:r-px-2 md:r-divide-none md:r-gap-4', {
        'md:r-grid-cols-2': props.numColumns === 2,
        'md:r-grid-cols-3': props.numColumns === 3,
    });

    return (
        <div className={classNames}>
            {props.boxes.map((box, index) => (
                <ClickableInfobox key={index} title={box.title} subtext={box.subtext} linkTo={box.linkTo} />
            ))}
        </div>
    );
};
