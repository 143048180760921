import { Item } from '@/queries/mfn';
import React, { useContext } from 'react';
import { formatIntro } from './cision-utils';
import { Link } from 'react-router-dom';
import { longDate } from '@/utils/format-date';
import { useLocalization } from '@/hooks/use-localization';
import { AppContext } from '@/contexts/app-context';

type FeedItemsProps = {
    items: Item[];
    singleItemLink: string;
};

export const FeedItems = ({ items, singleItemLink }: FeedItemsProps) => {
    const { localize } = useLocalization();
    const { locale } = useContext(AppContext);

    function getFeedItems(items: Item[], singleItemLink: string) {
        if (!items?.length) {
            return [];
        }

        let currentMonth: number | null = null;

        return items.reduce<React.ReactNode[]>((list, item, i) => {
            const itemDate = new Date(item.content.publish_date);

            if (currentMonth !== itemDate.getMonth()) {
                list.push(
                    <p key={`${itemDate.getFullYear()}-${currentMonth}-${i}`} className="r-border-b r-py-5 r-text-xl r-font-bold r-capitalize">
                        {itemDate.toLocaleString(locale, {
                            month: 'long',
                        })}
                    </p>
                );
                currentMonth = itemDate.getMonth();
            }

            const nextItem = items[i + 1];
            const nextItemMonth = nextItem ? new Date(nextItem.content.publish_date).getMonth() : null;
            const borderClass = currentMonth !== nextItemMonth ? '' : 'r-border-b';

            list.push(
                <div key={item.news_id} className={`styled-link r-space-y-2 r-py-5 last:r-border-b-0 ${borderClass}`}>
                    <p className="r-text-sm r-text-gray-500 ">{longDate(item.content.publish_date, locale)}</p>
                    <p className="r-text-bold r-text-xl r-font-bold">{item.content.title}</p>
                    <p>{formatIntro(item.content.html)}</p>
                    <div>
                        <Link to={`${singleItemLink}/${item.news_id}`}>{localize('learn-more')}</Link>
                    </div>
                </div>
            );
            return list;
        }, []);
    }
    return <>{getFeedItems(items, singleItemLink)}</>;
};
