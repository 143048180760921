import { useLocalization } from '@/hooks/use-localization';
import { NewSlider } from './slider';
import { SavingsCalculatorSelectorProps } from './savings-calculator';
import { SavingsInput } from './savings-input';

export const MonthlySavings = ({ state, updateState, ...props }: SavingsCalculatorSelectorProps) => {
    const { getCurrencySymbol, localize } = useLocalization();

    return (
        <>
            <div className="r-flex r-flex-col">
                <SavingsInput
                    label={props.monthlySavingsAmountLabel}
                    suffix={getCurrencySymbol()}
                    min={props.monthlySavingsMinAmount}
                    max={props.monthlySavingsMaxAmount}
                    value={state.monthlySavingsAmount}
                    onChange={value => updateState({ monthlySavingsAmount: value })}
                />
                <NewSlider
                    step={props.monthlySavingsStepAmount}
                    min={props.monthlySavingsMinAmount}
                    max={props.monthlySavingsMaxAmount}
                    value={state.monthlySavingsAmount}
                    onChange={value => updateState({ monthlySavingsAmount: value })}
                />
            </div>
            <div className="r-flex r-flex-col">
                <SavingsInput
                    label={props.monthlySavingsYearLabel}
                    suffix={localize('year').toLowerCase()}
                    min={props.monthlySavingsMinYear}
                    max={props.monthlySavingsMaxYear}
                    value={state.monthlySavingsYear}
                    onChange={value => updateState({ monthlySavingsYear: value })}
                />
                <NewSlider
                    step={props.goalSavingsStepYear}
                    min={props.monthlySavingsMinYear}
                    max={props.monthlySavingsMaxYear}
                    value={state.monthlySavingsYear}
                    onChange={value => updateState({ monthlySavingsYear: value })}
                />
            </div>
        </>
    );
};
