import { add, eachDayOfInterval, eachMonthOfInterval, endOfMonth, endOfYear, format, isSameYear, lastDayOfMonth, startOfMonth, sub } from 'date-fns';

export type InterestType = {
    from: number;
    to?: number;
    rate: number;
}[];

type IntervalType = {
    start: Date;
    end: Date;
};

export const getInterestRateForAmount = ({ amount, interestRates }: { amount: number; interestRates: InterestType }) =>
    interestRates.find(({ from, to }) => from <= amount && (!to || to >= amount));

export const getInterestForYear = ({
    startDate,
    interestRates,
    monthlyAmount,
    endDate,
    startAmount = 0,
}: {
    startDate: Date;
    interestRates: InterestType;
    monthlyAmount: number;
    endDate?: Date;
    startAmount?: number;
}) => {
    const start = startOfMonth(startDate);
    const end = endDate ? lastDayOfMonth(endDate) : endOfYear(startDate);

    const totals = eachMonthOfInterval({ start, end }).reduce(
        ({ amount, interest }, month, index, array) => {
            const interestRate = getInterestRateForAmount({ amount, interestRates });
            const newAmount = index === array.length - 1 ? amount : amount + monthlyAmount;

            // If we exceed the amount for interest, just return the monthly amounts
            if (!interestRate) {
                return {
                    amount: newAmount,
                    interest,
                };
            }

            const dailyInterestTotal = eachDayOfInterval({ start: month, end: endOfMonth(month) }).reduce(acc => {
                const dailyInterest = (amount * interestRate.rate) / 365;
                return dailyInterest + acc;
            }, 0);

            const newInterest = interest + dailyInterestTotal;

            return {
                amount: newAmount,
                interest: newInterest,
            };
        },
        {
            amount: startAmount + monthlyAmount,
            interest: 0,
        }
    );

    return {
        amount: totals.amount,
        interest: totals.interest,
    };
};

export const getYearlyPeriods = (startDate: Date, years: number) => {
    const periods: IntervalType[] = [];
    const actualStartDate = format(startDate, 'd') === '1' ? startDate : startOfMonth(add(startDate, { months: 1 }));
    const endDate = sub(add(actualStartDate, { years }), { months: 1 });

    eachMonthOfInterval({ start: actualStartDate, end: endDate }).forEach(month => {
        const index = periods.findIndex(({ start }) => isSameYear(start, month));
        if (index > -1) {
            return periods.splice(index, 1, { ...periods[index], end: month });
        }

        periods.push({ start: month, end: month });
    });

    return periods;
};

export const calculateInterest = ({
    monthlyAmount,
    interestRates = [],
    years = 1,
    startDate = new Date(),
}: {
    monthlyAmount: number;
    interestRates: InterestType;
    years?: number;
    startDate?: Date;
}) => {
    // If it's not the first of the month, start the payments on the 1st of the following month
    const actualStartDate = format(startDate, 'd') === '1' ? startDate : startOfMonth(add(startDate, { months: 1 }));
    const yearlyPeriods = getYearlyPeriods(actualStartDate, years);

    return yearlyPeriods.reduce(
        (acc, { start, end }, index, array) => {
            const { amount, interest } = getInterestForYear({
                startDate: start,
                endDate: end,
                interestRates,
                monthlyAmount,
                startAmount: acc.amount,
            });

            return {
                // If we're on the last item, just return the values, don't keep adding them
                amount: index === array.length - 1 ? amount : amount + interest,
                interest,
            };
        },
        {
            amount: 0,
            interest: 0,
        }
    );
};

// This is not working!
// export const calculateMonthlyPayment = ({
//     totalAmount,
//     interestRates,
//     years = 1,
//     startDate = new Date(),
// }: {
//     totalAmount: number;
//     interestRates: InterestType;
//     years?: number;
//     startDate?: Date;
// }) => {
//     // If it's not the first of the month, start the payments on the 1st of the following month
//     const actualStartDate = format(startDate, 'd') === '1' ? startDate : startOfMonth(add(startDate, { months: 1 }));
//     const yearlyPeriods = getYearlyPeriods(actualStartDate, years);

//     const { interest } = yearlyPeriods.reduce(
//         (acc, { start, end }, index, array) => {
//             return {
//                 amount: 0,
//                 interest: 0,
//             };
//         },
//         {
//             amount: 0,
//             interest: 0,
//         }
//     );

//     const monthlyPayment = totalAmount / (years * 12);
//     const totalMonthlyPaymentWithInterest = monthlyPayment + interest / (years * 12);

//     return {
//         monthlyPayment: totalMonthlyPaymentWithInterest,
//         totalInterest: interest,
//     };
// };
