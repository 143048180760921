import { Button } from '@/isomorphic/components/typography/button/button';
import { PropsType } from '../types';

export const ManualApplication = ({ getLabel }: PropsType) => (
    <div className="r-relative r-max-w-xl r-space-y-4 lg:r-mx-auto">
        <img src="/assets/images/business-loan/manual-application.svg" className="r-w-20" />
        <div className="r-space-y-3 r-pt-2 r-font-semibold">
            <h2>{getLabel('savingsAccountBusiness')}</h2>
            <h3 className="r-text-3xl">{getLabel('applyManually')}</h3>
        </div>
        <div className="r-prose" dangerouslySetInnerHTML={{ __html: getLabel('applyManuallyText', getLabel('manualApplicationLink')) }} />
        {getLabel('manualApplicationLink') ? (
            <div className="r-pt-2">
                <Button to={getLabel('manualApplicationLink')}>{getLabel('savingsAccountForYourOrg')}</Button>
            </div>
        ) : null}
    </div>
);
