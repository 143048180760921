import Slider from 'rc-slider';
import { useEffect, useState } from 'react';

type Slider = {
    handleSize?: number;
    min: number;
    max: number;
    value: number | null;
    step: number;
    onChange: (value: number) => void;
};

export const NewSlider = ({ handleSize = 32, min = 1, max = 10, value = 5, step = 1, onChange }: Slider) => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    const handleSizeAndPositioning = (preferredDiameter: number) => {
        const marginTop = (preferredDiameter / 2 - 2) * -1;
        return {
            width: preferredDiameter,
            height: preferredDiameter,
            marginTop,
        };
    };

    const handleStyle = {
        ...handleSizeAndPositioning(handleSize),
        backgroundColor: 'white',
        border: 'none',
        filter: 'drop-shadow(0px 2px 8px rgba(52, 58, 64, 0.20))',
        opacity: 1,
    };

    const trackStyle = {
        backgroundColor: '#00807B',
    };

    const railStyle = {
        backgroundColor: '#ADB5BD',
    };

    return isClient ? (
        <Slider
            min={min}
            max={max}
            value={value || 0}
            step={step}
            handleStyle={handleStyle}
            trackStyle={trackStyle}
            railStyle={railStyle}
            onChange={value => {
                if (typeof value === 'number') {
                    onChange(value);
                }
            }}
        />
    ) : null;
};
