import { Button } from '../typography/button/button';

type BannerProps = {
    link?: {
        title: string;
        url: string;
        target: string;
    };
    text?: string;
    title?: string;
};

export const Banner = ({ link, text, title }: BannerProps) => (
    <div
        data-component="banner"
        className="r-fixed r-bottom-4 r-left-4 r-right-4 r-z-[1001] r-flex r-max-h-[calc(100vh-4rem)] r-flex-col r-items-center r-justify-between r-rounded-2xl r-bg-white r-bg-opacity-95 r-p-4 r-text-black r-shadow-[0_2px_16px_0_rgba(0,0,0,.15)] sm:r-bottom-8 sm:r-left-8 sm:r-right-8 sm:r-translate-x-0 sm:r-translate-y-0 md:r-left-auto md:r-min-w-[528px] md:r-px-8"
    >
        <div className="r-w-full">
            <div className="r-container r-flex r-items-center r-justify-between r-gap-4">
                <div>
                    <p className="r-font-bold md:r-text-xl">{title}</p>
                </div>
                <div>
                    <Button type="primary" size="small" to={link?.url} fullWidth>
                        {text}
                    </Button>
                </div>
            </div>
        </div>
    </div>
);
