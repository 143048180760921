import { PropsWithChildren, useEffect } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import imgUrl from '@/assets/logos/new/resurs-logo-with-symbol-2022.svg';
import { VerticalWave, Wave } from './svg';

type Props = PropsWithChildren & {
    desktopImage: string;
    backLink?: {
        url: string;
        text: string;
    };
    mobileImage?: string;
    mobileImageHeader?: string;
};

export const LoginOverlay = ({ desktopImage, backLink, mobileImage, mobileImageHeader, children }: Props) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    return (
        <div className="r-fixed r-inset-0 r-z-[2000] r-h-screen r-overflow-auto r-bg-white">
            <Link to="/" target="_blank" className="max-sm:r-block max-sm:r-p-4 sm:r-absolute sm:r-left-4 sm:r-top-4">
                <img src={imgUrl} className="r-w-32" />
            </Link>
            {backLink ? (
                <Link
                    type="link"
                    to={backLink.url}
                    className="r-flex r-items-center r-gap-2 r-text-primary-300 r-underline r-underline-offset-2 max-sm:r-p-4 max-sm:r-pt-0 sm:r-absolute sm:r-left-4 sm:r-top-14"
                >
                    <span>
                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1520_11789)">
                                <path
                                    className="r-fill-primary-300"
                                    d="M0.293701 7.29414C-0.0969238 7.68477 -0.0969238 8.31914 0.293701 8.70977L5.2937 13.7098C5.68433 14.1004 6.3187 14.1004 6.70933 13.7098C7.09995 13.3191 7.09995 12.6848 6.70933 12.2941L3.41245 9.00039H13C13.5531 9.00039 14 8.55352 14 8.00039C14 7.44727 13.5531 7.00039 13 7.00039H3.41558L6.7062 3.70664C7.09683 3.31602 7.09683 2.68164 6.7062 2.29102C6.31558 1.90039 5.6812 1.90039 5.29058 2.29102L0.290576 7.29102L0.293701 7.29414Z"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1520_11789">
                                    <rect width="14" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                    <span>{backLink.text}</span>
                </Link>
            ) : null}
            <div
                className={clsx('r-relative r-grid r-bg-cover r-bg-center r-bg-no-repeat sm:r-hidden', mobileImageHeader ? 'r-h-80' : 'r-h-64')}
                style={{
                    backgroundImage: `url("${mobileImage}")`,
                }}
            >
                {mobileImageHeader ? (
                    <h3 className="r-w-64 r-pl-4 r-pt-12 r-text-3xl r-font-semibold r-leading-normal r-text-white">{mobileImageHeader}</h3>
                ) : null}
                <div className="r-absolute -r-bottom-px r-w-full">
                    <Wave />
                </div>
            </div>
            <div className="max-sm:r-pt-8 sm:r-flex sm:r-h-screen sm:r-gap-8">
                <div className="r-scrollbar-hide r-overflow-scroll r-bg-white r-px-4 max-sm:r-pb-20 sm:r-flex sm:r-w-1/2 sm:r-flex-col sm:r-items-center sm:r-justify-center">
                    <div className="sm:r-w-full md:r-py-24 lg:r-w-[450px]">{children}</div>
                </div>
                <div className="max-sm:r-hidden sm:r-relative sm:r-w-1/2">
                    <img src={desktopImage} alt="Background" className="r-absolute r-inset-0 r-h-full r-w-full r-object-cover" />
                    <VerticalWave />
                </div>
            </div>
        </div>
    );
};
