import { useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AppContext } from '@/contexts/app-context';
import { REDIRECTS_QUERY_KEY, getRedirects } from '@/queries/redirects';

export function useRedirects() {
    const context = useContext(AppContext);

    const redirects = useQuery({
        queryKey: [REDIRECTS_QUERY_KEY],
        queryFn: () => getRedirects(context),
        enabled: false,
    });

    const allRedirects = useMemo(() => {
        if (!Array.isArray(redirects?.data?.redirects)) {
            return [];
        }
        return (
            redirects?.data?.redirects?.map(item => ({
                from: item.from?.replace(/https?:\/\/[^/]+/, '')?.replace(/\/$/, ''),
                to: item.to?.replace(/https?:\/\/[^/]+/, '')?.replace(/\/$/, ''),
                code: parseInt(item.code),
            })) || []
        );
    }, [redirects?.data?.redirects]);

    const getRedirectForPath = (path: string) => {
        const sanitizedPath = path.replace(/\/$/, '');
        const match = allRedirects.find(({ from }) => from === sanitizedPath);

        if (match) {
            // Landing page needs to redirect to slash, not en empty string
            if (match.to === '') {
                match.to = '/';
            }

            return match.to;
        }

        return null;
    };

    return {
        getRedirectForPath,
    };
}
