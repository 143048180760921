import { useContext } from 'react';
import { useBeforeMount } from '@24hr/react-hooks';
import { useSupremeCards } from '@/hooks/use-supreme-cards';
import { Card } from './card';
import { AppContext } from '@/contexts/app-context';
import { SUPREME_CARDS_QUERY_KEY, getSupremeCards } from '@/queries/supreme-cards';

export type CardOverviewProps = {
    readMoreButtonText: string;
};

export const CardOverview = ({ readMoreButtonText }: CardOverviewProps) => {
    const context = useContext(AppContext);
    const {
        supremeCards: { data },
    } = useSupremeCards();

    useBeforeMount(() => {
        context.prefetch({ queryKey: [SUPREME_CARDS_QUERY_KEY], queryFn: () => getSupremeCards(context) });
    });

    if (!data) {
        return null;
    }

    const cardsWithCampaign = data.filter(card => card.has_campaign);
    const cardsWithoutCampaign = data.filter(card => !card.has_campaign);

    // Find the midpoint of the array
    const midPoint = Math.floor(cardsWithoutCampaign.length / 2);

    // Split the array into two at the midpoint
    const firstHalf = cardsWithoutCampaign.slice(0, midPoint);
    const secondHalf = cardsWithoutCampaign.slice(midPoint);

    // Add the cards with a campaign in the middle of the two halves
    const sortedData = [...firstHalf, ...cardsWithCampaign, ...secondHalf];

    return (
        <div
            data-component="card-overview"
            className="r-flex r-w-full r-flex-col r-flex-wrap r-items-end r-justify-center r-gap-2 sm:r-flex-row sm:r-flex-wrap sm:r-gap-4 lg:r-gap-0"
        >
            {sortedData.map((card, index) => (
                <Card key={index} content={card} primary={card.has_campaign} buttonText={readMoreButtonText.replace(/&amp;/g, '&')} />
            ))}
        </div>
    );
};
