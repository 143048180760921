import { useGlobalQueries } from '@/hooks/use-global-queries';
import { removeEnclosingSlashes } from '@/utils/strings';
import { Link, useLocation } from 'react-router-dom';
import imgUrl from '@/assets/logos/new/resurs-logo-with-symbol-2022.svg';
import { Login } from '../login';

type HeaderProps = {
    setShowMenu: (show: boolean) => void;
};

export const Header = ({ setShowMenu }: HeaderProps) => {
    const { getMarketType } = useGlobalQueries();
    const { pathname } = useLocation();

    const getHomeLink = () => {
        if (getMarketType() !== 'cluster') {
            return '/';
        }

        return removeEnclosingSlashes(pathname).split('/')[0] ?? '';
    };
    return (
        <div className="r-flex r-h-24 r-items-center r-justify-between r-px-4 md:r-hidden">
            <div className="r-max-w-[160px]">
                <Link to={getHomeLink()}>
                    <img src={imgUrl} title="Resurs Bank" alt="Resurs Bank" width={190} height={29} />
                </Link>
            </div>
            <div className="r-flex r-gap-4">
                <Login />
                <div className="r-flex r-items-center r-space-x-6">
                    <img
                        onClick={() => setShowMenu(true)}
                        src="/assets/icons/hamburger-2.svg"
                        alt="menu"
                        className="r-h-8 r-w-8"
                        width={32}
                        height={32}
                    />
                </div>
            </div>
        </div>
    );
};
