import { getTailwindColor } from '@/utils/colors';
import clsx from 'clsx';

export const Wave = (props: { fill: string }) => (
    <svg viewBox="0 -1 1441 95" xmlns="http://www.w3.org/2000/svg" className={clsx(getTailwindColor(props.fill, 'fill'))}>
        <path d="M0 0V95H1441V0C1225.69 60.1229 980.57 94.1402 720.5 94.1402C460.43 94.1402 215.306 60.1229 0 0Z" width="100%" height="100%" />
    </svg>
);

export const VerticalWave = (props: { fill: string }) => (
    <svg width="60" viewBox="0 0 60 1024" xmlns="http://www.w3.org/2000/svg" className={clsx('r-h-full', getTailwindColor(props.fill, 'fill'))}>
        <path d="M59.1985 0L0.000427246 2.95451e-06L0.000464462 1024L59.1985 1024C23.6323 871 3.509108 696.81 3.509101 512C3.509095 327.19 23.6323 153 59.1985 0Z" />
    </svg>
);
