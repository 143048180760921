import { ApiFetchType } from '@/server/api-fetch';

export type RedirectsApiResponse = {
    redirects: {
        from: string;
        to: string;
        code: '301' | '302';
    }[];
};

export const REDIRECTS_QUERY_KEY = 'redirects';

export const getRedirects = (context: ApiFetchType) => (context.fetch ? context.fetch<RedirectsApiResponse>('api/general/redirects') : null);
