import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import {
    INSIGHTS_QUERY_KEY,
    INSIGHTS_SETTINGS_KEY,
    INSIGHT_CATEGORIES_QUERY_KEY,
    InsightCategoryType,
    InsightGroup,
    getInsightCategories,
    getInsightSettings,
    getInsights,
} from '@/queries/insights';
import { AppContext } from '@/contexts/app-context';

export function useInsights() {
    const NUM_POSTS = 12;
    const [showPosts, setShowPosts] = useState(NUM_POSTS);
    const location = useLocation();
    const context = useContext(AppContext);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        setShowPosts(NUM_POSTS);
    }, [location.pathname, location.search]);

    const insightsSettings = useQuery({
        queryKey: [INSIGHTS_SETTINGS_KEY],
        queryFn: () => getInsightSettings(context),
        placeholderData: keepPreviousData,
    });

    const insightsData = useQuery({
        queryKey: [INSIGHTS_QUERY_KEY],
        queryFn: () => getInsights(context),
        placeholderData: keepPreviousData,
    });

    const insightCategoriesData = useQuery({
        queryKey: [INSIGHT_CATEGORIES_QUERY_KEY],
        queryFn: () => getInsightCategories(context),
        placeholderData: keepPreviousData,
    });

    const getCurrentGroup = () => searchParams.get('group') ?? 'private';

    const getCurrentTerm = () => {
        const basePath = insightsSettings.data?.slug.replace(/\/$/, '');
        const pathname = location.pathname.replace(/\/$/, '');
        if (!basePath || basePath === pathname) {
            return null;
        }

        return pathname.replace(`${basePath}/`, '');
    };

    const getCurrentTermObject = () => {
        const slug = getCurrentTerm();
        if (!slug) {
            return null;
        }

        return insightCategoriesData.data?.find(term => term.slug === slug);
    };

    const getShowAllTermId = () => insightsSettings.data?.show_all_category ?? null;
    const getShowAllTerm = () => getTermFromId(getShowAllTermId());
    const getTermFromId = (termId?: number | null) => {
        return termId ? insightCategoriesData.data?.find(({ id }) => id === termId) ?? null : null;
    };

    const getSortedTerms = () => {
        const privateTerms = new Set<number>();
        const corporateTerms = new Set<number>();

        insightsData.data?.forEach(({ categories, group }) => {
            if (group.includes('corporate')) {
                categories.forEach(term => corporateTerms.add(term));
            }
            if (group.includes('private')) {
                categories.forEach(term => privateTerms.add(term));
            }
        });

        return {
            privateTerms,
            corporateTerms,
        };
    };

    const getSortedActiveTerms = () => {
        const { privateTerms, corporateTerms } = getSortedTerms();
        const showAllTerm = getShowAllTerm();

        if (!showAllTerm) {
            return [];
        }

        const currentTerms = getCurrentGroup() === 'corporate' ? corporateTerms : privateTerms;
        const sortedTerms =
            insightCategoriesData.data?.filter(({ id }) => currentTerms.has(id))?.sort((a, b) => a.title.localeCompare(b.title)) ?? [];

        return [showAllTerm, ...sortedTerms];
    };

    const getTermsFromTermIds = (termIds: number[]) => {
        const terms: InsightCategoryType[] = [];

        termIds.forEach(termId => {
            const term = getTermFromId(termId);
            if (term) {
                terms.push(term);
            }
        });

        return terms;
    };

    const hasMore = () => getFilteredInsights().length > showPosts;
    const loadMore = () => setShowPosts(showPosts + NUM_POSTS);

    const getFilteredInsights = () =>
        insightsData.data
            ?.filter(item => {
                const group = getCurrentGroup() as InsightGroup | null;
                if (group && !item.group.includes(group)) {
                    return false;
                }

                const term = getCurrentTermObject();
                if (!term) {
                    return true;
                }

                return item.categories.includes(term.id);
            })
            ?.map(({ id, main_image: image, short_title: shortTitle, post_title: postTitle, short_preamble: preamble, categories, slug }) => ({
                id,
                slug,
                title: shortTitle || postTitle,
                image,
                preamble,
                terms: getTermsFromTermIds(categories),
            })) ?? [];

    const getPaginatedInsights = () => getFilteredInsights().slice(0, showPosts);

    const getTermMessage = () => {
        const currentTerm = getCurrentTermObject();
        const term = currentTerm ?? insightCategoriesData.data?.find(({ id }) => id === getShowAllTermId());

        if (!term?.messages?.length) {
            return null;
        }

        if (term.id === getShowAllTermId()) {
            return term.messages.find(message => (getCurrentGroup() === 'corporate' ? message.show_corporate : message.show_private));
        }

        return term.messages[0];
    };

    return {
        hasMore,
        loadMore,
        insightsData,
        getCurrentGroup,
        insightsSettings,
        getSortedTerms,
        getSortedActiveTerms,
        getCurrentTerm,
        getTermMessage,
        getShowAllTerm,
        insightCategoriesData,
        getPaginatedInsights,
    };
}
