import { ApiFetchType } from '@/server/api-fetch';

type SocialLink = {
    network: string;
    href: string;
};

type Column = {
    header: string;
    open: boolean;
    content: string;
};

export type FooterApiTypeResponse = {
    columns: Column[];
    copyright: string;
    social_links: SocialLink[];
    cluster_columns: Column[];
    cluster_copyright: string;
    cluster_social_links: SocialLink[];
};

export const FOOTER_QUERY_KEY = 'footer';

export const getFooter = async (context: ApiFetchType) => {
    if (!context.fetch) {
        return null;
    }

    const {
        columns,
        copyright,
        social_links: socialLinks,
        cluster_columns: clusterColumns,
        cluster_social_links: clusterSocialLinks,
        cluster_copyright: clusterCopyRight,
    } = await context.fetch<FooterApiTypeResponse>('api/general/footer');

    return {
        columns,
        copyright,
        socialLinks,
        clusterColumns,
        clusterSocialLinks,
        clusterCopyRight,
    };
};
