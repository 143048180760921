import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import queryString from 'query-string';
import { AppContext } from '@/contexts/app-context';
import { Button } from '@/isomorphic/components/typography/button/button';
import { useLocalization } from '@/hooks/use-localization';

export type GiftCardBalanceProps = {
    labels: {
        cardNumberInputErrorMessage: string;
        cardNumberInputInfoText: string;
        cardNumberLabel: string;
        tooManyAttemptsError: string;
        unknownCardError: string;
        serverError: string;
        expirationDateLabel: string;
        formText: string;
        headline: string;
        submitButtonText: string;
        tryAgainButtonText: string;
        currentBalanceLabel: string;
    };
};

type GiftCardApiResponse = {
    balance: {
        expirationDate?: string;
        balance?: string;
        code?: string;
        message?: string;
    };
};

export const GiftCardBalance = ({ labels }: GiftCardBalanceProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const context = useContext(AppContext);
    const { localize } = useLocalization();
    const [loading, setLoading] = useState(false);
    const [balance, setBalance] = useState<string | null>(null);
    const [expirationDate, setExpirationDate] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        const { cardnumber: cardNumber } = queryString.parse(location.search);
        if (cardNumber && typeof cardNumber === 'string') {
            setInputValue(cardNumber);
        }

        if (context.user?.isAuthenticated && cardNumber) {
            requestBalance(cardNumber as string);
        }
    }, []);

    async function requestBalance(cardNumber: string) {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch('/api/gift-card-balance', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify({
                    cardNumber,
                    countryCode: context.country.toUpperCase(),
                }),
            });
            const { balance } = (await response.json()) as GiftCardApiResponse;

            if (balance.expirationDate && balance.balance) {
                setExpirationDate(balance?.expirationDate || null);
                setBalance(Intl.NumberFormat(context.locale, { style: 'currency', currency: context.currency }).format(parseFloat(balance.balance)));
            }

            if (balance.code === '404') {
                setError(balance?.message ?? localize('error-generic'));
            }
        } catch (error) {
            setError(localize('error-generic'));
        } finally {
            setLoading(false);
        }
    }

    const isInputValueValid = () => inputValue.length === 16 && /^\d*$/.test(inputValue);

    const onSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        if (!isInputValueValid()) {
            return;
        }

        if (context.user?.isAuthenticated) {
            requestBalance(inputValue);
        } else {
            login();
        }
    };

    const reset = () => {
        setBalance(null);
        setError(null);
        setInputValue('');
        const query = queryString.parse(location.search);
        delete query.cardnumber;

        let url = location.pathname;
        url += location.search.length ? '?' + queryString.stringify(query) : '';

        navigate(url, { replace: true });
    };

    const login = () =>
        (window.location.href = `/login?customRedirectUrl=${context.url}${location.pathname}%3Fcardnumber=${inputValue}&scope=giftCardScope`);

    const getContent = () => {
        if (error) {
            return (
                <>
                    <p className="r-mb-4 r-bg-secondary-500 r-p-4 r-text-white">{error}</p>
                    <Button onClick={reset} className="gift-card-balance--result--button">
                        {labels.tryAgainButtonText}
                    </Button>
                </>
            );
        }

        if (balance) {
            return (
                <>
                    <div className="r-flex r-items-end r-justify-between r-pb-2">
                        <div>{labels.currentBalanceLabel}</div>
                        <div className="r-text-2xl r-font-bold r-text-primary">{balance}</div>
                    </div>
                    <div className="r-flex r-justify-between r-pb-2">
                        <div>{labels.cardNumberLabel}</div>
                        <div className="r-font-bold">{inputValue}</div>
                    </div>
                    {expirationDate ? (
                        <div className="r-flex r-justify-between r-pb-2">
                            <div>{labels.expirationDateLabel}</div>
                            <div className="r-font-bold">{expirationDate}</div>
                        </div>
                    ) : null}
                    <Button onClick={reset} className="r-mt-8">
                        {labels.tryAgainButtonText}
                    </Button>
                </>
            );
        }

        return (
            <form onSubmit={onSubmit} className={clsx({ 'r-opacity-70': loading })}>
                <div className="r-mb-4">{labels.formText}</div>

                <label htmlFor="card-number" className="r-font-bold">
                    {labels.cardNumberLabel}
                </label>

                <input
                    required
                    className="r-mt-1 r-block r-w-full r-border r-border-gray-300 r-p-2"
                    id="card-number"
                    name="card-number"
                    onChange={({ target }) =>
                        setInputValue(
                            target.value
                                .split(/[^\d]+/)
                                .join('')
                                .slice(0, 16)
                        )
                    }
                    value={inputValue}
                />

                <div className="r-mt-8">
                    <Button disabled={inputValue.length !== 16 || loading} isSubmit>
                        {labels.submitButtonText}
                    </Button>
                </div>
            </form>
        );
    };

    return (
        <div data-component="gift-card-balance">
            <h2 className="r-mb-4 r-text-3xl r-font-bold">{labels.headline}</h2>
            {getContent()}
        </div>
    );
};
