import { removeTrailingSlash } from './../utils/strings';
import { ApiFetchType } from '@/server/api-fetch';
import { MarketType } from './page';

export const HEADER_QUERY_KEY = 'header';
export const MOBILE_HEADER_QUERY_KEY = 'header-mobile';

export type MenuItemType = {
    ID: number;
    attr_title: string;
    children?: MenuItemType[];
    has_children: boolean;
    menu_item_parent: string;
    menu_order: number;
    market_type?: MarketType;
    place_in_top_menu?: boolean;
    object: string;
    object_id: string;
    post_excerpt: string;
    post_name: string;
    post_title: string;
    post_type: string;
    target: string;
    title: string;
    type: string;
    type_label: string;
    url: string;
    xfn: string;
};

export enum HeaderMenus {
    HEADER_MENU_PRIVATE = 'header_menu_private',
    HEADER_MENU_CORPORATE = 'header_menu_corporate',
    MOBILE_MENU_PRIVATE = 'mobile_menu_private',
    MOBILE_MENU_CORPORATE = 'mobile_menu_corporate',
    HEADER_MENU_CLUSTER = 'header_menu_cluster',
    MARKET_MENU = 'market_menu',
}

const removeTrailingSlashFromMenuItem = (item: MenuItemType): MenuItemType => ({
    ...item,
    url: removeTrailingSlash(item.url),
    children: item?.children?.map(removeTrailingSlashFromMenuItem),
});
const removeTrailingSlashFromMenuItems = (menu: MenuItemType[]) => menu.map(removeTrailingSlashFromMenuItem);

export const getHeaderMenuType = ({
    marketType = 'private',
    context = 'desktop',
}: {
    marketType?: MarketType | null;
    context?: 'desktop' | 'mobile';
}) => {
    switch (marketType) {
        case 'corporate':
            return context === 'desktop' ? HeaderMenus.HEADER_MENU_CORPORATE : HeaderMenus.MOBILE_MENU_CORPORATE;
        case 'cluster':
            return HeaderMenus.HEADER_MENU_CLUSTER;
    }

    return context === 'desktop' ? HeaderMenus.HEADER_MENU_PRIVATE : HeaderMenus.MOBILE_MENU_PRIVATE;
};

export const getHeader = async (menuSlug: HeaderMenus, context: ApiFetchType) => {
    if (!context.fetch) {
        return [];
    }

    const { menu } = await context.fetch<{ menu: MenuItemType[] }>(`api/header/${menuSlug}`);
    return removeTrailingSlashFromMenuItems(menu);
};

export const getClusterHeader = async (pageSlug: string, context: ApiFetchType) => {
    if (!context.fetch) {
        return [];
    }

    const parts = pageSlug.split('/');
    // For cluster pages, we always only want the base URL
    const { menu } = await context.fetch<{ menu: MenuItemType[] }>(`api/header/cluster/${parts[0]}`);
    return removeTrailingSlashFromMenuItems(menu);
};
