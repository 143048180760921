import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGlobalQueries } from '@/hooks/use-global-queries';
import { AppContext } from '@/contexts/app-context';

const COOKIE = 'resurs_show_edit_link=true';

export const EditLink = () => {
    const { url } = useContext(AppContext);
    const { pageQuery } = useGlobalQueries();
    const [searchParams] = useSearchParams();
    const [pageLink, setPageLink] = useState('');
    const [showLink, setShowLink] = useState(false);

    function setCookie() {
        const oneYear = 365 * 24 * 60 * 60;
        let cookie = `${COOKIE}; path=/; max-age=${oneYear};`;

        if (window?.location?.protocol === 'https:') {
            const domain = `${window?.location?.host?.replace(/^(www|draft)\./, '') || ''}`;
            cookie += ` domain=.${domain}; SameSite=Lax; Secure;`;
        }

        document.cookie = cookie;
    }

    function shouldShowLink() {
        return typeof document !== 'undefined' && document?.cookie?.includes(COOKIE);
    }

    function getEnvironment() {
        if (url.includes('local')) {
            return 'local';
        }

        return url.includes('stage') ? 'stage' : 'prod';
    }

    function getCountry() {
        const suffix = url.replace('.local', '').split('.').pop();
        return suffix === 'se' ? '' : `${suffix}.`;
    }

    function getCMSLink() {
        const country = getCountry();

        switch (getEnvironment()) {
            case 'local':
                return `http://${country}wp.resursbank.se.local`;
            case 'stage':
                return `https://${country}stage.wp.resursbank.se`;
            case 'prod':
                return `https://${country}wp.resursbank.se`;
        }
    }

    useEffect(() => {
        if (shouldShowLink()) {
            setShowLink(true);
        }

        const cmsLink = getCMSLink();
        if (pageQuery.data?.id && cmsLink) {
            setPageLink(pageQuery.data?.id ? `${cmsLink}/wp-admin/post.php?post=${pageQuery?.data?.id}&action=edit` : '');
        }
    }, [pageQuery, url]);

    useEffect(() => {
        if (searchParams.get('resurs_show_edit_link') === 'true') {
            setCookie();
        }
    }, [searchParams]);

    if (!showLink || !pageLink) {
        return null;
    }

    return (
        <div
            data-component="show-link"
            className="r-fixed r-left-4 r-top-20 r-z-[9999] r-rounded-xl r-bg-primary-200 r-bg-opacity-95 r-px-4 r-py-1 r-font-bold r-text-white r-shadow-lg md:r-left-6 md:r-top-8"
        >
            <a href={pageLink} target="_blank">
                Edit this page
            </a>
        </div>
    );
};
