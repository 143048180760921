import { getTailwindColor } from '@/utils/colors';
import clsx from 'clsx';

export type ListProps = {
    content: {
        innerHTML: TrustedHTML;
        innerContent: [TrustedHTML];
    }[];
    index: number;
    hash: string;
    ordered?: boolean;
    useDottedUnorderedList?: boolean;
    useStandardStyle?: boolean;
    useCheckMarkStyle?: boolean;
    textColor?: string;
    unorderedListColor?:
        | {
              color: string;
              name: string;
          }
        | string;
    backgroundColor?: string;
};

export const List = ({ content, ...props }: ListProps) => {
    // Backwards compat: If you resave a page, this will be updated
    // to the new markup where content is an array of <li />
    if (typeof content === 'string') {
        return <div dangerouslySetInnerHTML={{ __html: content }} />;
    }

    if (!Array.isArray(content)) {
        return null;
    }

    const ListTag = props.ordered ? 'ol' : 'ul';
    const classNames = clsx(
        'styled-link r-relative r-mb-4',
        getTailwindColor(props.textColor, 'text'),
        getTailwindColor(props.backgroundColor, 'bg'),
        getTailwindColor(props.unorderedListColor ?? 'black', 'border'),
        {
            'r-border-l-4 r-pl-8 r-ml-8': !props.useStandardStyle && !props.useDottedUnorderedList && !props.useCheckMarkStyle,
            'r-list-disc r-pl-4': props.useStandardStyle,
            'r-list-image-none': props.useCheckMarkStyle,
        }
    );

    return (
        <ListTag className={classNames}>
            {content.map(({ innerHTML }, index) => {
                const matches = innerHTML.toString().match(/<li>(.*?)<\/li>/);
                if (!Array.isArray(matches) || !matches[1]) {
                    return null;
                }

                return (
                    <li
                        key={index}
                        className={clsx({
                            "r-pl-8 before:r-absolute before:r-left-0 before:r-block before:r-content-['—']": props.useDottedUnorderedList,
                            'r-bg-[url(/assets/icons/list-image-check.svg)] r-bg-no-repeat r-pb-4 r-pl-10 r-leading-[16px]': props.useCheckMarkStyle,
                        })}
                        dangerouslySetInnerHTML={{ __html: matches[1] }}
                    />
                );
            })}
        </ListTag>
    );
};
