import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { MainMenuSub } from './main-menu-sub';
import imgUrl from '@/assets/logos/new/resurs-logo-with-symbol-2022.svg';
import { useGlobalQueries } from '@/hooks/use-global-queries';
import { useNavItem } from '@/hooks/use-nav-item';
import { useLocalization } from '@/hooks/use-localization';
import { useNavMenus } from '@/hooks/use-nav-menus';
import { removeEnclosingSlashes } from '@/utils/strings';
import { Login } from './login';

export const MainMenu = ({ onSearchButtonClick }: { onSearchButtonClick: () => void }) => {
    const { getMarketType } = useGlobalQueries();
    const { getMainMenuItems, getSubMenuItems } = useNavMenus();
    const { localize } = useLocalization();
    const { pathname } = useLocation();
    const { isCurrentItemActive } = useNavItem();

    const getHomeLink = () => {
        if (getMarketType() !== 'cluster') {
            return '/';
        }

        return removeEnclosingSlashes(pathname).split('/')[0] ?? '';
    };

    return (
        <div data-component="main-menu">
            <div className="r-container r-px-8 r-py-6">
                <div className="r-flex r-items-center r-justify-between">
                    <div className="r-max-w-[190px]">
                        <Link to={getHomeLink()}>
                            <img src={imgUrl} title="Resurs Bank" alt="Resurs Bank" width={190} height={29} />
                        </Link>
                    </div>
                    <div className="r-flex r-gap-6">
                        <ul className="r-flex r-items-center r-gap-5 r-font-bold">
                            {getMainMenuItems().map((item, index) => (
                                <li
                                    key={index}
                                    className={clsx('r-rounded-xl r-px-2 r-py-1 r-transition-all r-duration-200 hover:r-bg-[#aed5d5]', {
                                        'r-bg-[#aed5d5]': isCurrentItemActive(item),
                                    })}
                                >
                                    <Link to={item.url.replace(/\/$/, '')} target={item.target || '_self'}>
                                        {item.title}
                                    </Link>
                                </li>
                            ))}
                            <li>
                                <button className="r-flex r-items-center r-gap-[2px]" role="button" onClick={() => onSearchButtonClick()}>
                                    <img src={`/assets/icons/search.svg`} height={24} width={24} alt="" />
                                    <span>{localize('search')}</span>
                                </button>
                            </li>
                        </ul>
                        <Login />
                    </div>
                </div>
            </div>
            <MainMenuSub menu={getSubMenuItems()} />
            <MainMenuSub menu={getSubMenuItems(2)} depth={2} />
        </div>
    );
};
