import { useState } from 'react';
import { useLocalization } from '@/hooks/use-localization';
import { Field } from '../forms/components/field';
import { Button } from '../typography/button/button';
import { DocumentItem as DocumentItemType, UrlVersion } from '@/queries/document';

type DocumentItemProps = {
    title: string;
    items: DocumentItemType[];
};

export const DocumentItem = ({ title, items }: DocumentItemProps) => {
    const { localize } = useLocalization();
    const [expandedItemId, setExpandedItemId] = useState<number | null>(null);
    const [dateInput, setDateInput] = useState('');
    const [hasCheckedDocument, setHasCheckedDocument] = useState(false);
    const [timeSensitiveDocument, setTimeSensitiveDocument] = useState<UrlVersion | null>(null);

    if (!items.length) {
        return null;
    }

    const getDateSpecificUrl = (urlVersions: UrlVersion[], date: string) => {
        const enteredDateTime = new Date(date).getTime() / 1000;

        return urlVersions.reduce(
            (bestMatch, url) => {
                const emptyBestMatch = !bestMatch.document_url;
                const urlDateTime = url['document_url_date'];

                if (enteredDateTime >= urlDateTime) {
                    if (emptyBestMatch) {
                        bestMatch = url;
                    } else {
                        const bestMatchTime = bestMatch['document_url_date'];

                        if (urlDateTime > bestMatchTime) {
                            bestMatch = url;
                        }
                    }
                }
                return bestMatch;
            },
            // eslint-disable-next-line camelcase
            { document_url: '', document_url_date: 0 }
        );
    };

    const dateValidation = (date: string) => /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(date);

    const handleNext = (urlVersions: UrlVersion[], date: string) => {
        const isValid = dateValidation(dateInput);

        if (isValid) {
            setHasCheckedDocument(true);
            setTimeSensitiveDocument(getDateSpecificUrl(urlVersions, date));
        } else {
            setTimeSensitiveDocument(null);
        }
    };

    const renderTimeSensitiveDocumentLink = (item: DocumentItemType) => {
        if (timeSensitiveDocument?.document_url) {
            return (
                <div className="r-flex r-items-start r-space-x-2 r-py-2">
                    <img src="/assets/icons/document-handler.svg" width={20} height={20} />
                    <a href={timeSensitiveDocument.document_url?.replaceAll('&amp;', '&')} target="_blank" className="r-text-sm">
                        {item.name}
                    </a>
                </div>
            );
        }

        if (hasCheckedDocument) {
            return <p>{localize('no-documents-for-date')}</p>;
        }
    };

    return (
        <div className="r-w-full r-space-y-5">
            <h2 className="r-text-xl r-font-bold">{title}</h2>
            <ul className="r-flex r-w-full r-flex-col r-items-center r-space-y-3 ">
                {items.map((item: DocumentItemType) => {
                    const hasMultipleVersions = item.url_versions.length > 1;

                    return (
                        <li className="r-flex r-w-full r-flex-col" key={item.id}>
                            <div className="r-flex r-w-full r-items-start r-justify-between">
                                <div className="r-flex r-items-start r-space-x-2">
                                    <img src="/assets/icons/document-handler.svg" width={20} height={20} alt="Document" />
                                    <a href={item.url_versions[0].document_url?.replaceAll('&amp;', '&')} target="_blank" className="r-text-sm">
                                        {item.name}
                                    </a>
                                </div>
                                {hasMultipleVersions ? (
                                    <img
                                        src={`/assets/icons/document-${expandedItemId === item.id ? 'expanded' : 'expand'}.svg`}
                                        className="r-cursor-pointer"
                                        onClick={() => setExpandedItemId(prevId => (prevId === item.id ? null : item.id))}
                                        height={25}
                                        width={25}
                                        alt={expandedItemId === item.id ? 'Collapse' : 'Expand'}
                                    />
                                ) : null}
                            </div>
                            {hasMultipleVersions && <p className="r-ml-7 r-text-xs">{localize('has-versions-text')}</p>}
                            {expandedItemId === item.id && (
                                <div className="r-ml-7 r-mt-2 r-flex r-flex-col r-items-start r-space-y-2 r-border r-bg-gray-100 r-px-6 r-py-4">
                                    <div className="r-space-y-5">
                                        <p className="r-text-sm">{localize('has-versions-instruction')}</p>
                                        <Field
                                            fieldName="Datum"
                                            label={localize('date')}
                                            onChange={e => setDateInput(e as string)}
                                            value={dateInput}
                                            placeholder={localize('date-placeholder')}
                                            validation={dateValidation}
                                            errorMessage={localize('invalid-date')}
                                        />
                                        <Button type="primary" size="small" onClick={() => handleNext(item['url_versions'], dateInput)}>
                                            {localize('generate')}
                                        </Button>
                                        {renderTimeSensitiveDocumentLink(item)}
                                    </div>
                                </div>
                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
