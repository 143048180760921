import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { hexToRgba, mapColor } from '@/utils/colors';
import { SectionProps } from '../section/section';
import { Size } from '@/utils/typography';

export type ColumnProps = {
    hash: string;
    index: number;
    maxWidth: string;
    maxWidthUnit: '%' | 'px';
    alignment?: 'left' | 'center' | 'right';
    direction?: 'column' | 'row';
    contentPadding?: Size;
    paddingBottom?: Omit<Size, 'small'>;
    paddingTop?: Omit<Size, 'small'>;
    verticalOffsetBottom?: Omit<Size, 'small'>;
    verticalOffsetTop?: Omit<Size, 'small'>;
    backgroundColor?: string;
    backgroundColorOpacity?: string;
    parentProps: SectionProps;
};

export const Column = ({ children, ...props }: PropsWithChildren<ColumnProps>) => {
    const classNames = clsx('r-flex r-rounded-3xl r-w-full styled-column', {
        'r-text-center': props.alignment === 'center',
        'r-items-end': props.alignment === 'right',
        'r-text-left': props.alignment === 'left',
        'r-pb-4 md:r-pb-8': props.paddingBottom === 'medium',
        'r-pb-8 md:r-pb-16': props.paddingBottom === 'large',
        'r-pt-4 md:r-pt-8': props.paddingTop === 'medium',
        'r-pt-8 md:r-pt-16': props.paddingTop === 'large',
        'r-px-2 md:r-px-4': props.contentPadding === 'small',
        'r-px-4 md:r-px-8': props.contentPadding === 'medium',
        'r-px-8 md:r-px-16': props.contentPadding === 'large',
        'r-justify-end': props.parentProps.verticalAlignment === 'bottom',
        'r-justify-center': props.parentProps.verticalAlignment === 'center',
        'r-mx-auto': props.alignment === 'center',
        'r--mt-24': props.verticalOffsetTop === 'medium',
        'r--mt-48': props.verticalOffsetTop === 'large',
        'r--mb-24': props.verticalOffsetBottom === 'medium',
        'r--mb-48': props.verticalOffsetBottom === 'large',
        'r-flex-row': props.direction === 'row',
        'r-flex-col': props.direction !== 'row',
    });

    // These properties have to be dynamic so we can't do it in tailwind.
    const style: React.CSSProperties = {};

    if (props.backgroundColor) {
        const color = mapColor(props.backgroundColor) ?? props.backgroundColor;
        style.backgroundColor = hexToRgba(color, props.backgroundColorOpacity ? parseInt(props.backgroundColorOpacity) : 1);
    }

    if (props.maxWidth) {
        style.maxWidth = `${props.maxWidth}${props.maxWidthUnit}`;
    }

    return (
        <div data-component="column" data-index={props.index + 1} className={classNames} style={style}>
            {children}
        </div>
    );
};
