import { useId, useState } from 'react';
import clsx from 'clsx';
import { FaqClusterItem } from '../faq-cluster';

export function FaqItem({ question, answer, index }: FaqClusterItem & { index: number }) {
    const id = useId();
    const [isOpen, setIsOpen] = useState(false);
    if (!question || !answer) {
        return null;
    }

    const accordionId = `accordion-${id}-${index}`;
    const sectionId = `section-${id}-${index}`;

    return (
        <div className="r-border-b">
            <dt
                id={accordionId}
                onClick={() => setIsOpen(!isOpen)}
                className="r-flex r-w-full r-justify-between r-py-4 r-align-middle r-font-bold r-text-primary-300 hover:r-cursor-pointer"
            >
                <span>{question}</span>
                <img
                    alt=""
                    width={14}
                    height={22}
                    src="/assets/icons/plus.svg"
                    className={clsx('r-mt-[2px] r-transition r-duration-200', {
                        'r-rotate-45': isOpen,
                    })}
                />
            </dt>
            <dd
                className={clsx('r-grid r-transition-[grid-template-rows] r-duration-300', {
                    'r-grid-rows-[1fr]': isOpen,
                    'r-grid-rows-[0fr]': !isOpen,
                })}
            >
                <div id={sectionId} className="r-row-span-2 r-overflow-hidden">
                    <p className="r-pb-2" dangerouslySetInnerHTML={{ __html: answer }} />
                </div>
            </dd>
        </div>
    );
}
