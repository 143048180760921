import clsx from 'clsx';
import { PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react';
import { Button } from '../typography/button/button';
import { useLocalization } from '@/hooks/use-localization';

type Props = {
    actions?: ReactNode;
    open: boolean;
    onClose: () => void;
    closeIcon?: boolean;
    className?: clsx.ClassValue;
    disableBackdropClick?: boolean;
    paddingDisabled?: boolean;
};

export const Modal = ({
    children,
    actions,
    open,
    onClose,
    className = '',
    disableBackdropClick = false,
    paddingDisabled = false,
    closeIcon = false,
}: PropsWithChildren<Props>) => {
    const [isOpen, setIsOpen] = useState(open);
    const { getLanguage } = useLocalization();
    const containerRef = useRef<HTMLDivElement>(null);
    const scrollButtonRef = useRef<HTMLDivElement>(null);
    const actionButtonsRef = useRef<HTMLDivElement>(null);
    const ddRef = useRef<HTMLDivElement>(null);
    const [showScrollDownButton, setShowScrollDownButton] = useState(false);

    const getScrollDownButtonText = () => {
        const dict = {
            sv: 'Scrolla ner',
            da: 'Rul ned',
            fi: 'Rullaa alas',
            no: 'Bla nedover',
        };

        return dict[getLanguage()];
    };

    useEffect(() => {
        setIsOpen(open);

        document.body.style.overflow = open ? 'hidden' : 'unset';

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [open]);

    const addWheelEventListener = (event: WheelEvent) => {
        if (containerRef.current) {
            containerRef.current.scrollTop += event.deltaY; // Scroll the modal when user scrolls
        }
    };

    useEffect(() => {
        if (open) {
            document.addEventListener('wheel', addWheelEventListener);
        } else {
            document.removeEventListener('wheel', addWheelEventListener);
        }

        return () => {
            document.removeEventListener('wheel', addWheelEventListener);
        };
    }, [open]);

    const scrollDownButton = () => {
        const containerHeight = containerRef?.current?.getBoundingClientRect()?.height;
        if (containerHeight && containerHeight > window?.outerHeight) {
            setShowScrollDownButton(true);
        }
    };

    const onScrollButtonClick = () => actionButtonsRef?.current?.scrollIntoView({ behavior: 'smooth' });

    useEffect(() => {
        if (!actions || !isOpen) {
            return;
        }

        const observer = new IntersectionObserver(
            e => {
                e.forEach(item => {
                    if (item.isIntersecting && showScrollDownButton) {
                        setShowScrollDownButton(false);
                    }

                    if (!item.isIntersecting && !showScrollDownButton) {
                        setShowScrollDownButton(true);
                    }
                });
            },
            {
                threshold: 0.1,
                root: containerRef.current,
            }
        );

        observer.observe(actionButtonsRef.current as Element);
    }, [showScrollDownButton, isOpen]);

    useEffect(() => {
        if (open) {
            scrollDownButton();
        }
    }, [open]);

    if (!isOpen) {
        return null;
    }

    return (
        <div
            ref={ddRef}
            role="dialog"
            aria-modal="true"
            aria-label="Modal"
            data-component="modal"
            aria-labelledby="modal-title"
            onClick={!disableBackdropClick ? onClose : undefined}
            className="r-fixed r-inset-0 r-z-[2500] max-sm:r-pt-4"
        >
            <div className="r-relative r-flex r-min-h-screen r-items-center r-justify-center r-px-4 r-pb-8 r-pt-0 r-text-center sm:r-block">
                <div className="r-fixed r-inset-0 r-bg-gray-800 r-bg-opacity-70 r-transition-opacity" aria-hidden="true" />

                <span className="r-hidden sm:r-inline-block sm:r-h-screen sm:r-align-middle" aria-hidden="true">
                    &#8203;
                </span>

                <div
                    ref={containerRef}
                    className={clsx(
                        'scrollbar-hide r-inline-block r-max-h-[calc(100vh-2rem)] r-min-w-64 r-transform r-overflow-scroll r-rounded-xl r-text-left r-align-middle r-shadow-xl r-transition-all sm:r-align-middle md:r-max-h-[calc(100vh-4rem)]',
                        className
                    )}
                    onClick={e => e.stopPropagation()}
                >
                    {closeIcon && (
                        <div className="r-absolute r-right-0 r-top-0 r-pr-4 r-pt-4">
                            <button
                                className="r-bg-gray-50 r-text-gray-400 hover:r-text-gray-500 focus:r-outline-none focus:r-ring-2 focus:r-ring-indigo-500 focus:r-ring-offset-2"
                                onClick={onClose}
                            >
                                <span className="r-sr-only">Close</span>
                                <svg
                                    className="r-h-6 r-w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    )}
                    <div
                        className={clsx('r-bg-white', {
                            'r-px-4 r-pb-4 r-pt-5 sm:r-p-6 sm:r-pb-4': !paddingDisabled,
                        })}
                    >
                        {children}
                    </div>
                    {actions && (
                        <div ref={actionButtonsRef} className="r-bg-gray-50 r-px-4 r-py-3 sm:r-flex sm:r-flex-row-reverse sm:r-px-6">
                            {actions}
                        </div>
                    )}
                </div>
            </div>

            <div
                ref={scrollButtonRef}
                className={clsx(
                    'r-fixed r-bottom-8 r-left-0 r-flex r-w-full r-justify-center r-transition-opacity r-duration-300 md:r-bottom-16',
                    showScrollDownButton ? 'r-opacity-100' : 'r-h-0 r-w-0 r-opacity-0'
                )}
            >
                <Button className="r-shadow-lg" type="secondary" size="small" onClick={onScrollButtonClick}>
                    {getScrollDownButtonText()}
                </Button>
            </div>
        </div>
    );
};
