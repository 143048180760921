type CloseProps = {
    setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Close = ({ setShowMenu }: CloseProps) => {
    return (
        <div id="close-button-container" className="r-flex r-justify-end">
            <img onClick={() => setShowMenu(false)} src="/assets/icons/close.svg" alt="close" className="r-mt-2 r-h-8 r-w-8" />
        </div>
    );
};
