import { ApiFetchType } from '@/server/api-fetch';

type GetCookiesResponse = {
    overview_description: string;
    edit_description: string;
    mandatory_text: string;
    types: Type[];
    labels: Labels;
    max_age: string;
    allowed_urls: AllowedUrls;
    modal_url: string;
    guid: string;
};

type Type = {
    title: string;
    name: string;
    description: string;
    can_toggle: boolean;
    cookie_requires: string;
};

type Labels = {
    accept_button: string;
    only_necessary_cookies_button: string;
    edit_button: string;
    cancel_button: string;
    save_button: string;
};

type AllowedUrls = {
    '/cookies': boolean;
};

export const COOKIE_CONSENT_QUERY_KEY = 'cookie_consent';

export const getCookies = (context: ApiFetchType) => (context?.fetch ? context.fetch<GetCookiesResponse>('api/general/cookie-consent') : null);
