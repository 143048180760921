import { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Field } from '@/isomorphic/components/forms/components/field';
import { Button } from '@/isomorphic/components/typography/button/button';
import { useLocalization } from '@/hooks/use-localization';
import { validators } from '@/hooks/use-validation';
import { Checkbox } from '@/isomorphic/components/forms/components/checkbox';
import { Select } from '@/isomorphic/components/forms/components/select';
import type { MandatorType } from '../use-business-loan-application';
import { StepProps } from './step-one';
import { GoogleRecaptchaInfo } from '@/isomorphic/components/google-recaptcha-info/google-recaptcha-info';

export const StepThree = ({
    fields,
    updateField,
    labels,
    updateProgress,
    onSubmit,
    submitError,
    setSubmitError,
}: StepProps & {
    submitError?: string | null;
    setSubmitError: (error: string) => void;
}) => {
    const { getLanguage } = useLocalization();
    const errorDivRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (errorDivRef.current && !errorDivRef.current.contains(event.target as Node)) {
                // Click occurred outside the error message div
                // You can run your function here
                setSubmitError('');
            }
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const isButtonDisabled = () => {
        if (!fields.accountNumber || !fields.pepOptions) {
            return true;
        }

        let disabled = false;

        fields.mandator.forEach(mandator => {
            Object.values(mandator).forEach(value => {
                if (!value) {
                    disabled = true;
                }
            });
        });

        return disabled;
    };

    const addMandator = () => {
        if (fields.mandator.length === 4) {
            return;
        }

        updateField({
            mandator: [
                ...fields.mandator,
                {
                    mandatorOwnership: '',
                    name: '',
                    personalNumber: '',
                    phone: '',
                    email: '',
                    mandatorAcceptGuarantor: false,
                    yearlyIncome: '',
                },
            ],
        });
    };

    const updateMandator = (args: Partial<MandatorType>, index: number) => {
        const allMandators = [...fields.mandator];
        allMandators[index] = { ...allMandators[index], ...args };
        updateField({ mandator: allMandators });
    };

    const removeMandator = (index: number) => {
        const allMandators = [...fields.mandator];
        // Safety first!
        if (allMandators.length === 1) {
            return;
        }

        allMandators.splice(index, 1);
        updateField({ mandator: allMandators });
    };

    return (
        <div data-component="step-three">
            {fields.mandator.map((mandator, index) => (
                <div
                    key={index}
                    className={clsx('r-relative r-mb-4 r-space-y-4', {
                        'r-pt-4': index > 0,
                    })}
                >
                    {index > 0 ? (
                        <button onClick={() => removeMandator(index)} className="r-absolute r-right-0 r-top-0">
                            <img src="/assets/icons/close.svg" />
                        </button>
                    ) : null}
                    <Field
                        required
                        infoText={labels.mandatorOwnershipInfoText}
                        errorMessage={labels.mandatorOwnershipErrorText}
                        label={labels.mandatorOwnership}
                        value={mandator.mandatorOwnership}
                        onChange={value => updateMandator({ mandatorOwnership: value }, index)}
                        validation={value => {
                            const val = parseInt(`${value}`.replace(/%/g, ''));
                            return !!Number(val) && val >= 25 && val <= 100;
                        }}
                    />
                    <Field
                        required
                        infoText={labels.nameInfoText}
                        errorMessage={labels.nameErrorText}
                        label={labels.name}
                        value={mandator.name}
                        onChange={value => updateMandator({ name: value }, index)}
                    />
                    <Field
                        required
                        infoText={labels.personalNumberInfoText}
                        errorMessage={labels.personalNumberErrorText}
                        label={labels.personalNumber}
                        value={mandator.personalNumber}
                        validation={value => validators.socialSecurityNumber(value, getLanguage())}
                        onChange={value => updateMandator({ personalNumber: value }, index)}
                    />
                    <Field
                        required
                        infoText={labels.phoneInfoText}
                        errorMessage={labels.phoneErrorText}
                        label={labels.phone}
                        value={mandator.phone}
                        validation={value => validators.phone(value, getLanguage())}
                        onChange={value => updateMandator({ phone: value }, index)}
                    />
                    <Field
                        required
                        errorMessage={labels.emailErrorText}
                        label={labels.email}
                        value={mandator.email}
                        validation={value => validators.email(value)}
                        onChange={value => updateMandator({ email: value }, index)}
                    />
                    <Field
                        required
                        infoText={labels.yearlyIncomeInfoText}
                        errorMessage={labels.yearlyIncomeErrorText}
                        label={labels.yearlyIncome}
                        value={mandator.yearlyIncome}
                        type="number"
                        onChange={value => updateMandator({ yearlyIncome: value }, index)}
                    />
                    <Checkbox
                        label={labels.mandatorAcceptGuarantor}
                        checked={mandator.mandatorAcceptGuarantor}
                        onChange={() => updateMandator({ mandatorAcceptGuarantor: !mandator.mandatorAcceptGuarantor }, index)}
                    />
                </div>
            ))}

            <div
                className={clsx('r-flex r-justify-center r-font-bold r-text-blue-800', {
                    'r-hidden': fields.mandator.length === 4,
                })}
            >
                <button disabled={fields.mandator.length === 4} role="button" onClick={() => addMandator()}>
                    <span className="r-text-xl">+</span> {labels.mandatorAdd}
                </button>
            </div>

            <div className="r-my-8">
                <Field
                    required
                    errorMessage={labels.accountNumberErrorText}
                    infoText={labels.accountNumberInfoText}
                    label={labels.accountNumber}
                    value={fields.accountNumber}
                    validation={value => {
                        if (getLanguage() === 'fi') {
                            return validators.iban(value);
                        }
                        if (getLanguage() === 'sv') {
                            return validators.bankAccountNumber(value);
                        }
                        return validators.bankAccountAccountNumber(value);
                    }}
                    onChange={value => updateField({ accountNumber: value })}
                />
            </div>
            <div className="r-my-8">
                <h2 className="r-pb-4 r-text-2xl">{labels.pepTitle}</h2>
                <p className="r-pb-4">{labels.pep}</p>
                <Select
                    required
                    label={labels.pepLabel}
                    value={fields.pepOptions}
                    onChange={value => updateField({ pepOptions: value as 'false' | 'true' })}
                    placeholder={labels.pepPlaceholder}
                    options={[
                        {
                            value: 'true',
                            label: labels.yes,
                        },
                        {
                            value: 'false',
                            label: labels.no,
                        },
                    ]}
                />
            </div>

            <div className="r-mb-4">
                <GoogleRecaptchaInfo />
            </div>

            {submitError ? (
                <div className="r-mb-2" ref={errorDivRef}>
                    <p className="r-mb-1 r-text-sm r-text-red-500">{submitError}</p>
                </div>
            ) : null}

            <div className="r-flex r-justify-between">
                <Button onClick={() => updateProgress(2)}>{labels.back}</Button>
                <Button disabled={isButtonDisabled()} onClick={() => onSubmit(3)}>
                    {labels.continue}
                </Button>
            </div>
        </div>
    );
};
