import { useLocation } from 'react-router-dom';
import { useGlobalQueries } from './use-global-queries';
import { removeTrailingSlash } from '@/utils/strings';
import { HUMANY_FAQ_QUERY_KEY, humanyFaqQuery } from '@/queries/humany';
import { useQuery } from '@tanstack/react-query';

export function useHumany({ widgetType = 'private', widgetSlug = 'kundservice' }) {
    const location = useLocation();
    const { getSetting } = useGlobalQueries();
    const humanySettings = getSetting('humany_settings');
    const pageLink = humanySettings?.humany_page_link?.replace(/\/$/g, '') ?? '';
    const pageLinkBusiness = humanySettings?.humany_page_link_business?.replace(/\/$/g, '') ?? '';
    const baseUrl = widgetType === 'private' ? pageLink : pageLinkBusiness;
    const path = removeTrailingSlash(location.pathname.replace(baseUrl, ''));

    const humanyData = useQuery({
        queryKey: [HUMANY_FAQ_QUERY_KEY, widgetSlug, path || '/'],
        queryFn: () => humanyFaqQuery(widgetSlug, `${path}${location.search}`, baseUrl),
        enabled: false,
    });

    return {
        path,
        baseUrl,
        humanyData,
        search: location.search,
    };
}
