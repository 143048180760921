import { useEffect, useState } from 'react';
import Slider from 'rc-slider';
import { Button } from '@/isomorphic/components/typography/button/button';
import { useLocalization } from '@/hooks/use-localization';
import { StepOneProps } from './step-one';
import { CardTerms } from './card-terms';
import { SupremeCardOfferType, SupremeCardUpdatedOfferType } from '../types';
import 'rc-slider/assets/index.css';

type StepTwoProps = StepOneProps & {
    onAmountChange: (amount: number) => void;
    onAfterAmountChange: (amount: number) => void;
    offer: SupremeCardOfferType | null;
    updatedOffer: SupremeCardUpdatedOfferType | null;
};

export const StepTwo = ({ fields, onAmountChange, onAfterAmountChange, labels, onSubmit, offer, isLoading }: StepTwoProps) => {
    const { formatNumber } = useLocalization();
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    const sliderProps = {
        step: 5000,
        min: offer?.minLimit?.amount ?? 0,
        max: offer?.maxLimit?.amount ?? 0,
        value: fields.amount,
        onChange: (value: number | number[]) => onAmountChange(value as number),
        onChangeComplete: (value: number | number[]) => onAfterAmountChange(value as number),
        style: {
            backgroundColor: 'transparent',
        },
        handleStyle: {
            background: '#003c3c',
            opacity: 1,
            border: 'none',
            boxShadow: 'none',
            width: '24px',
            height: '24px',
            marginTop: '-8px',
        },
        railStyle: {
            height: '9px',
            background: '#eee',
        },
        trackStyle: {
            height: '9px',
            background: '#ccd8d8',
        },
        dotStyle: {
            opacity: 0,
        },
    };

    if (!offer) {
        return <div>{labels.loadingApplicationText}</div>;
    }

    return (
        <div className="r-mb-8">
            <h2 className="r-mb-2 r-text-lg r-font-bold">{labels.selectCreditAmount}</h2>
            {labels.selectCreditAmountOfferdText && (
                <p className="r-mb-4 r-font-bold">{labels.selectCreditAmountOfferdText.replace('{amount}', formatNumber(fields.amount))}</p>
            )}
            {labels.selectCreditAmountOfferdAdditionalText && <p>{labels.selectCreditAmountOfferdAdditionalText}</p>}

            <div className="r-mb-8 r-mt-20 r-flex r-flex-col">
                {isClient ? (
                    <Slider
                        {...sliderProps}
                        handleRender={renderProps => (
                            <div {...renderProps.props}>
                                <div className="r-absolute -r-left-[43px] -r-top-16 r-flex r-w-[110px] r-flex-col r-bg-primary-600 r-py-2 r-text-center r-text-sm r-text-white">
                                    <span>{labels.selectCreditAmountTooltipText}</span>
                                    <strong>+ {formatNumber(fields.amount)}</strong>
                                </div>
                                <div className="r-absolute -r-top-[8px] r-left-[6px] r-h-0 r-w-0 r-border-l-[5px] r-border-r-[5px] r-border-t-[5px] r-border-l-transparent r-border-r-transparent r-border-t-primary-600" />
                            </div>
                        )}
                    />
                ) : null}
                <div className="r-flex r-justify-between r-py-6 r-text-gray-900">
                    <p>{formatNumber(sliderProps.min)}</p>
                    <p>{formatNumber(sliderProps.max)}</p>
                </div>
                <CardTerms />
            </div>
            <Button fullWidth disabled={isLoading} variant="bankidwhite" onClick={() => onSubmit(2)}>
                {labels.buttonSelectCreditAndSign}
            </Button>
        </div>
    );
};
