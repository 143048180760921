import { useLocalization } from '@/hooks/use-localization';
import { StepProps } from './step-one';

export const StepFour = (props: StepProps) => {
    const { formatNumber, localize } = useLocalization();

    const classNames = 'r-mb-8 r-grid md:r-mb-16 md:r-grid-cols-2';
    const renderItem = (key: string, value: string | number) => (
        <>
            <span className="max-sm:r-pt-4 max-sm:r-font-bold">{key}</span>
            <span>{value}</span>
        </>
    );

    return (
        <div data-component="step-four">
            <div className={classNames}>
                {renderItem(props.labels.loanAmount, formatNumber(props.fields.amount))}
                {renderItem(props.labels.paybackPeriod, `${props.fields.paybackPeriod} ${localize('months')}`)}
                {renderItem(props.labels.purpose, props.fields.purpose)}
            </div>
            <div className={classNames}>
                {renderItem(props.labels.organizationNumber, props.fields.organizationNumber)}
                {renderItem(props.labels.companyName, props.fields.companyName)}
                {renderItem(props.labels.contactPerson, props.fields.contactPerson)}
                {renderItem(props.labels.phone, props.fields.phone)}
                {renderItem(props.labels.email, props.fields.email)}
            </div>
            {props.fields.mandator.map((mandator, index) => (
                <div className={classNames} key={index}>
                    {renderItem(props.labels.mandatorOwnership, mandator.mandatorOwnership)}
                    {renderItem(props.labels.name, mandator.name)}
                    {renderItem(props.labels.personalNumber, mandator.personalNumber)}
                    {renderItem(props.labels.phone, mandator.phone)}
                    {renderItem(props.labels.email, mandator.email)}
                    {renderItem(props.labels.yearlyIncome, mandator.yearlyIncome)}
                    {renderItem(props.labels.mandatorAcceptGuarantor, mandator.mandatorAcceptGuarantor ? localize('yes') : localize('no'))}
                </div>
            ))}
            <div className={classNames}>{renderItem(props.labels.accountNumber, props.fields.accountNumber)}</div>
        </div>
    );
};
