import { ApiFetchType } from '@/server/api-fetch';

export const RATE_CALCULATOR_QUERY_KEY = 'rate_calculator';

export interface RateCalculatorRes {
    rate_calculator_info_text_highlight_vars: boolean;
    rate_calculator_info_text_labels: RateCalculatorInfoTextLabels;
    rate_calculator_info_text: string;
    rate_calculator_apply_link: string;
    guid: string;
}

export interface RateCalculatorInfoTextLabels {
    month_suffix: string;
    year_suffix: string;
}

export const getRateCalculator = (context: ApiFetchType) => (context.fetch ? context.fetch<RateCalculatorRes>('api/general/rate-calculator') : null);
