import { AppContext } from '@/contexts/app-context';
import { useContext, useEffect } from 'react';
import './styles.css';

/* eslint-disable */

declare global {
    interface Window {
        _MF: {
            push: (args: unknown) => void;
        } & Record<string | number | symbol, unknown> & {
                data: unknown[];
            };
    }
}

export const CisionSubscribe = () => {
    const context = useContext(AppContext);
    const TOKEN = '6bfab585-f36d-40a5-8fe5-6961aec4d16e';

    useEffect(() => {
        (function (widgets: unknown) {
            var url = 'https://widget.datablocks.se/api/rose';
            if (!window._MF) {
                var b = document.createElement('script');
                b.type = 'text/javascript';
                b.async = true;
                b.src = url + '/assets/js/loader-v3.js';
                document.getElementsByTagName('body')[0].appendChild(b);
            }
            window._MF = window._MF || {
                data: [],
                url: url,
                ready: !!0,
                render: function () {
                    window._MF.ready = !0;
                },
                // @ts-nocheck
                push: function (w: unknown) {
                    window._MF.data.push(w);
                },
            };
            window._MF.push(widgets);
        })([
            {
                query: '#subscribe-module',
                widget: 'subscribe-v2',
                locale: context.language,
                token: TOKEN,
            },
        ]);
    });
    return <div data-component="cision-subscribe" id="subscribe-module" className="subscribe-module"></div>;
};
