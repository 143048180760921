import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { HEADER_QUERY_KEY, HeaderMenus, MenuItemType, getClusterHeader, getHeader, getHeaderMenuType } from '@/queries/header';
import { useGlobalQueries } from './use-global-queries';
import { AppContext } from '@/contexts/app-context';
import { removeTrailingSlash } from '@/utils/strings';
import { HEADER_SETTINGS_QUERY_KEY, getHeaderSettings } from '@/queries/header-settings';

export function useNavMenus() {
    const { getMarketType, pageQuery } = useGlobalQueries();
    const { pathname } = useLocation();
    const context = useContext(AppContext);
    const marketType = getMarketType();

    const mainMenu = useQuery({
        queryKey: [HEADER_QUERY_KEY, getHeaderMenuType({ marketType, context: 'desktop' })],
        queryFn: ({ queryKey }) => {
            if (queryKey[1] === 'header_menu_cluster') {
                const slug = pageQuery.data?.permalink.replace(/^\/|\/$/g, '').split('/')[0];
                return getClusterHeader(slug ?? '', context);
            }
            return getHeader(queryKey[1] as HeaderMenus, context);
        },
        staleTime: Infinity,
        enabled: !!marketType && !!pageQuery.data,
    });

    const marketMenu = useQuery({
        queryKey: [HEADER_QUERY_KEY, HeaderMenus.MARKET_MENU],
        queryFn: ({ queryKey }) => getHeader(queryKey[1] as HeaderMenus, context),
        staleTime: Infinity,
        enabled: !!marketType,
    });

    const headerSettings = useQuery({
        queryKey: [HEADER_SETTINGS_QUERY_KEY],
        queryFn: () => getHeaderSettings(context),
        enabled: false,
    });

    const getTopMenuItems = () => ({
        marketMenu: marketMenu?.data || [],
        mainMenu: mainMenu?.data?.filter(menuItem => menuItem.place_in_top_menu) || [],
    });

    const getMainMenuItems = () => mainMenu.data?.filter(menuItem => !menuItem.place_in_top_menu) || [];

    const getActiveMenuBranch = (children: MenuItemType[] = []): boolean => {
        return !!children?.find(childItem => {
            const match = parseInt(childItem.object_id) === pageQuery.data?.id || parseInt(childItem.menu_item_parent) === pageQuery.data?.id;
            if (match) {
                return true;
            }

            if (removeTrailingSlash(childItem.url) === removeTrailingSlash(pathname)) {
                return true;
            }

            return getActiveMenuBranch(childItem.children);
        });
    };

    const getCurrentMenuItem = () =>
        getMainMenuItems()?.find(item => {
            // Parent menu item
            if (parseInt(item.object_id) === pageQuery.data?.id) {
                return true;
            }

            return getActiveMenuBranch(item.children);
        });

    function getSubMenuItems(level: 1 | 2 = 1) {
        const currentItem = getCurrentMenuItem();
        if (level === 1) {
            return currentItem?.children ?? [];
        }

        // In the children of the current active menu item
        const children = currentItem?.children?.find(item => {
            // Check if one of THEIR children matches the current page
            return item.children?.find(child => parseInt(child.object_id) === pageQuery.data?.id);
        });

        // Then we can show the childrens children of the current menu item
        return children?.children ?? [];
    }

    return {
        mainMenu,
        marketMenu,
        headerSettings,
        getTopMenuItems,
        getMainMenuItems,
        getSubMenuItems,
    };
}
