import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import clsx from 'clsx';
import { useBeforeMount } from '@24hr/react-hooks';
import { useInsights } from '@/hooks/use-insights';
import { INSIGHTS_SETTINGS_KEY, INSIGHT_CATEGORIES_QUERY_KEY, InsightGroup, getInsightCategories, getInsightSettings } from '@/queries/insights';
import { AppContext } from '@/contexts/app-context';
import { getTailwindColor } from '@/utils/colors';
import { useLocalization } from '@/hooks/use-localization';

export type SearchSortProps = {
    labels: {
        privateLabel: string;
        corporateLabel: string;
        showAllLabel: string;
        mostPopularLabel: string;
        latestLabel: string;
    };
};

export const SearchSort = ({ labels }: SearchSortProps) => {
    const context = useContext(AppContext);
    const navigate = useNavigate();
    const { localize } = useLocalization();
    const { pathname, search } = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const { getCurrentTerm, getSortedActiveTerms, insightsSettings, getCurrentGroup, getShowAllTerm } = useInsights();

    useBeforeMount(() => {
        context.prefetch({ queryKey: [INSIGHTS_SETTINGS_KEY], queryFn: () => getInsightSettings(context) });
        context.prefetch({ queryKey: [INSIGHT_CATEGORIES_QUERY_KEY], queryFn: () => getInsightCategories(context) });
    });

    const getSelectOptions = () =>
        getSortedActiveTerms()?.map(({ id, title, slug }) => (
            <option key={id} value={slug}>
                {title}
            </option>
        )) ?? [];

    const setTerm = (slug: string) => {
        const basePath = insightsSettings.data?.slug.replace(/\/$/, '');
        if (!basePath) {
            return;
        }

        const newPath = getShowAllTerm()?.slug === slug ? basePath : `${basePath}/${slug}`;
        if (pathname !== newPath) {
            navigate(`${newPath}${search}`);
        }
    };

    const setGroup = (group: InsightGroup) => {
        const basePath = insightsSettings.data?.slug.replace(/\/$/, '');
        if (!basePath || getCurrentGroup() === group) {
            return;
        }

        navigate(`${basePath}?group=${group}`);
    };

    return (
        <div data-component="search-sort">
            <div
                className={clsx('r-flex r-justify-end r-text-white md:r-hidden', {
                    'max-md:r-py-4': !isOpen,
                    'r-hidden': isOpen,
                })}
            >
                <img className="r-w-6" onClick={() => setIsOpen(true)} src="/assets/icons/filter-menu.svg" alt="" />
            </div>

            <div
                className={clsx('r-transition-height scrollbar-hide r-overflow-hidden r-duration-200', {
                    'max-md:r-max-h-64': isOpen,
                    'max-md:r-max-h-0': !isOpen,
                })}
            >
                <div className="r-flex r-items-center r-justify-end r-gap-1 r-text-white max-md:r-pt-4 md:r-hidden" onClick={() => setIsOpen(false)}>
                    <span className="r-text-xs">{localize('close')}</span>
                    <img src="/assets/icons/close-cross.svg" alt="" />
                </div>

                <div className="r-grid r-grid-cols-1 r-items-center r-justify-between r-gap-4 r-py-4 r-text-white md:r-grid-cols-2">
                    <h1 className="r-text-xl max-md:r-hidden">Resurs Insights</h1>

                    <div className="r-grid r-items-center r-justify-items-end r-gap-4 md:r-grid-cols-2 md:r-gap-8">
                        {insightsSettings.data?.enable_groups ? (
                            <div className="r-grid r-grid-cols-2 r-justify-items-end r-gap-4 md:r-gap-8">
                                <div className="r-flex r-items-center r-gap-2">
                                    <input
                                        type="checkbox"
                                        id="private"
                                        value="private"
                                        className={clsx(
                                            getTailwindColor('primaryLightest', 'text'),
                                            'r-form-checkbox r-h-6 r-w-6 r-rounded focus:r-ring-0 focus:r-ring-white'
                                        )}
                                        name="insights-group"
                                        checked={getCurrentGroup() === 'private'}
                                        onChange={event => setGroup(event.currentTarget.value as InsightGroup)}
                                    />
                                    <label htmlFor="private">{labels.privateLabel}</label>
                                </div>
                                <div className="r-flex r-items-center r-gap-2">
                                    <input
                                        type="checkbox"
                                        id="corporate"
                                        value="corporate"
                                        className={clsx(
                                            getTailwindColor('primaryLightest', 'text'),
                                            'r-form-checkbox r-h-6 r-w-6 r-rounded focus:r-ring-0 focus:r-ring-white'
                                        )}
                                        name="insights-group"
                                        checked={getCurrentGroup() === 'corporate'}
                                        onChange={event => setGroup(event.currentTarget.value as InsightGroup)}
                                    />
                                    <label htmlFor="corporate">{labels.corporateLabel}</label>
                                </div>
                            </div>
                        ) : null}
                        <div className="r-relative r-w-full">
                            <select
                                value={getCurrentTerm() ?? ''}
                                onChange={({ target }) => setTerm(target.value)}
                                className="r-form-select r-w-full r-rounded r-border r-border-white r-bg-primary r-p-2 focus:r-border-white focus:r-ring-white"
                            >
                                {getSelectOptions()}
                            </select>
                            {/* Let's just use tailwind forms default arrow here, even though it's the wrong color. We'll just skip this for now but we COULD apply custom styles if we really need to later */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
