import { useContext } from 'react';
import queryString from 'query-string';
import { AppContext } from '@/contexts/app-context';

export const useLogin = () => {
    const context = useContext(AppContext);

    const getLoginHintQuery = async (governmentId: number | string) => {
        const url = 'api/bank/login-hint';
        const parsed = queryString.parseUrl(url);

        parsed.query.siteId = context.siteId as string;
        parsed.query.role = context.role as string;
        parsed.query.deviceType = context.deviceType as string;

        const fullUrl = `/${parsed.url}?${queryString.stringify(parsed.query)}`;
        const apiResponse = await fetch(fullUrl, {
            headers: {
                'content-type': 'application/json',
                credentials: 'include',
            },
            method: 'POST',
            body: JSON.stringify({
                governmentId,
                languageCode: context.language,
                countryCode: context.country.toUpperCase(),
            }),
        });

        return apiResponse.json();
    };

    return {
        getLoginHintQuery,
    };
};
