import clsx, { ClassValue } from 'clsx';

export const Wave = () => (
    <svg viewBox="0 -1 1441 95" xmlns="http://www.w3.org/2000/svg" className="r-fill-white">
        <path d="M0 0V95H1441V0C1225.69 60.1229 980.57 94.1402 720.5 94.1402C460.43 94.1402 215.306 60.1229 0 0Z" width="100%" height="100%" />
    </svg>
);

export const VerticalWave = ({ className = 'r-h-screen' }: { className?: ClassValue }) => (
    <svg fill="none" viewBox="0 0 57 1024" xmlns="http://www.w3.org/2000/svg" className={clsx('r-absolute r-top-0 -r-ml-px', className)}>
        <path
            d="M56.1985 0L0.000427246 2.95451e-06L0.000464462 1024L56.1985 1024C20.6323 871 0.509108 696.81 0.509101 512C0.509095 327.19 20.6323 153 56.1985 0Z"
            fill="white"
        />
    </svg>
);
