import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '@/contexts/app-context';
import { sendAsGoal } from '@/hooks/use-ab-tests';
import { DynamicWidget } from '../../dynamic-widget/dynamic-widget';
import { removeTrailingSlash } from '@/utils/strings';
import './style.css';

export type ConsumerloanCalculatorAppProps = {
    labels: object;
    settings: Record<string, unknown> & {
        removeBackgroundImage?: 'yes' | 'no';
        anchorLink?: string;
        callbackUrl?: string;
        secciLink?: {
            url: string;
        };
        commonTermsLink?: {
            url: string;
        };
        legacyConsumerloanBaseUrl?:
            | string
            | {
                  url: string;
              };
    };
    abFirstGoal?: {
        useAsGoal: boolean;
        abTestUuid: string;
    };
};

export const ConsumerloanCalculatorApp: React.FC<ConsumerloanCalculatorAppProps> = ({ labels, settings, abFirstGoal }) => {
    const { pathname } = useLocation();
    const context = useContext(AppContext);
    const protocol = context.isProd ? 'https' : 'http';
    const url = `${protocol}://${context.host}`;
    const [isClient, setIsClient] = useState(false);

    const handleCounterModuleSubmit = () => {
        if (abFirstGoal?.useAsGoal) {
            sendAsGoal(abFirstGoal.abTestUuid);
        }
    };

    const handleCounterModuleBeginApply = () => {
        if (abFirstGoal?.useAsGoal) {
            sendAsGoal(abFirstGoal?.abTestUuid, 'started');
        }
    };

    useEffect(() => {
        setIsClient(true);

        document.body.addEventListener('counter-module-submit', handleCounterModuleSubmit);
        document.body.addEventListener('counter-module-begin-apply', handleCounterModuleBeginApply);

        return () => {
            document.body.removeEventListener('counter-module-submit', handleCounterModuleSubmit);
            document.body.removeEventListener('counter-module-begin-apply', handleCounterModuleBeginApply);
        };
    });

    const getFormattedLocale = () => {
        const localeParts = context.locale.split('-');
        return `${localeParts[0]}_${localeParts[1]?.toUpperCase()}`;
    };

    const getCallbackUrl = () => {
        if (!settings.callbackUrl) {
            return `${url}${pathname}`;
        }

        const callbackUrl = settings.callbackUrl.replace(/http?s:\/\/[^/]+/, '');

        return removeTrailingSlash(`${url}${callbackUrl}`);
    };

    const getLegacyConsumerloanBaseUrl = () => {
        if (typeof settings.legacyConsumerloanBaseUrl === 'string' && settings.legacyConsumerloanBaseUrl !== '') {
            return settings.legacyConsumerloanBaseUrl.replaceAll('&amp;', '&');
        }

        if (typeof settings.legacyConsumerloanBaseUrl !== 'string' && settings.legacyConsumerloanBaseUrl?.url) {
            return settings.legacyConsumerloanBaseUrl.url.replaceAll('&amp;', '&');
        }

        return 'https://apply.resursbank.se/epl';
    };

    const getSettings = () => ({
        ...settings,
        callbackUrl: getCallbackUrl(),
        secciLink: settings?.secciLink?.url ? settings.secciLink.url?.replaceAll('&amp;', '&') : '',
        commonTermsLink: settings?.commonTermsLink?.url ? settings.commonTermsLink.url?.replaceAll('&amp;', '&') : '',
        legacyConsumerloanBaseUrl: getLegacyConsumerloanBaseUrl(),
        countryCode: context.country.toUpperCase(),
        locale: getFormattedLocale(),
        lang: context.language.toUpperCase(),
        currency: context.currency,
    });

    return isClient ? (
        <DynamicWidget
            next
            widget="consumerloan-calculator-app"
            className={settings?.removeBackgroundImage === 'yes' ? 'consumerloan-no-bg' : ''}
            id={settings.anchorLink}
            dataAttributes={{
                labels,
                settings: getSettings(),
            }}
        />
    ) : null;
};
