import { useEffect, useMemo, useState } from 'react';
import { Loading } from '../../bank-open-savings-form-business/components/svg';
import { useAccountNumber } from '../hooks/use-account-number';
import { Copied, Copy } from './svg';
import { Labels } from '../types';

type Props = {
    labels: Labels;
};

export const AccountNumber = ({ labels }: Props) => {
    const [isCopied, setIsCopied] = useState(false);
    const [isClient, setIsClient] = useState(false);
    const { accountDetails, isFetchingAccountDetails } = useAccountNumber();

    useEffect(() => setIsClient(true), []);

    const hasError = useMemo(() => {
        if (!isClient) {
            return false;
        }

        return !isFetchingAccountDetails && !accountDetails.accountNumber;
    }, [accountDetails, isFetchingAccountDetails, isClient]);

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(`${accountDetails.clearingNumber} ${accountDetails.accountNumber}`);
            setIsCopied(true);
        } catch (error) {
            console.error(error);
        }
    };

    if (!isClient) {
        return null;
    }

    if (isFetchingAccountDetails) {
        return (
            <div className="r-pb-4" role="status">
                <div
                    className="r-prose r-pb-4 prose-p:r-text-black"
                    dangerouslySetInnerHTML={{
                        __html:
                            labels?.loadingAccountDetails ||
                            `<p>Just nu håller vi på att skapa ditt konto vilket kan ta en liten stund. När kontot är klart kommer både ditt clearingnummer och kontonummer att visas här.</p>
                                <p>Tack för att du väntar!</p>`,
                    }}
                />
                <Loading />
            </div>
        );
    }

    if (hasError) {
        return (
            <div className="r-mt-8 r-border-l-8 r-border-red-600 r-p-2 r-pl-4">
                <div
                    className="r-prose prose-p:r-text-black"
                    dangerouslySetInnerHTML={{
                        __html:
                            labels?.unableToFindAccountNumber ||
                            '<p>Vi kunde tyvärr inte hämta ditt nya kontonummer för tillfället.<br />Vänligen kontakta kundtjänst på <a href="tel:+4622563733">22 56 37 33</a>.</p>',
                    }}
                />
            </div>
        );
    }

    return accountDetails.accountNumber && accountDetails.clearingNumber ? (
        <div data-component="account-number">
            <h3 className="r-py-2 r-text-lg r-font-bold">{labels?.youNewAccountDetails || 'Dina nya kontouppgifter'}</h3>
            <div className="r-relative r-mb-2 r-rounded-xl r-border r-border-gray-200 r-bg-gray-100 r-p-4 r-align-middle r-shadow-sm max-md:r-space-y-4 md:r-flex md:r-items-center md:r-justify-between md:r-gap-6">
                <div className="r-space-y-1">
                    <p>
                        <strong>{labels?.clearingNumber || 'Clearingnummer:'}</strong> {accountDetails.clearingNumber}
                    </p>
                    <p>
                        <strong>{labels?.accountNumber || 'Kontonummer:'}</strong> {accountDetails.accountNumber}
                    </p>
                </div>
                <button
                    role="button"
                    onClick={() => copyToClipboard()}
                    className="r-hover:bg-gray-100 r-cursor-pointer r-items-center r-justify-center r-rounded-xl r-border r-border-gray-400 r-bg-white r-p-3"
                >
                    {isCopied ? (
                        <span className="r-flex r-items-center r-gap-2">
                            <span className="r-text-sm r-font-semibold r-text-primary-200">{labels?.copiedAccountDetails || 'Kopierad'}</span>
                            <Copied />
                        </span>
                    ) : (
                        <span className="r-flex r-items-center r-gap-2">
                            <span className="r-text-sm r-font-semibold">{labels?.copyAccountDetails || 'Kopiera kontodetaljer'}</span>
                            <Copy />
                        </span>
                    )}
                </button>
            </div>
        </div>
    ) : null;
};
