import { useLocalization } from '@/hooks/use-localization';

type Props = {
    results?: {
        amount: number;
        year: number;
        text: string;
    } | null;
};

export const Results = ({ results }: Props) => {
    const { formatCurrency, localize } = useLocalization();

    const year = results?.year === 1 ? localize('year') : localize('years');
    const getText = () => results?.text.replace('{years}', `${results?.year || 1} ${year.toLocaleLowerCase()}`);
    const formattedAmount = results?.amount ? formatCurrency(results.amount) : '';

    return (
        <>
            <div className="r-flex r-items-center r-justify-between r-pb-4 lg:r-hidden">
                <p className="r-font-semibold r-text-primary-500">{getText()}</p>
                <p className="r-text-2xl r-font-semibold r-text-primary-500">{formattedAmount}</p>
            </div>
            <div className="r-absolute r--right-48 r-top-0 r-hidden r-h-44 r-w-44 r-space-y-1 r-rounded-full r-bg-primary-400 r-text-white lg:r-flex lg:r-flex-col lg:r-items-center lg:r-justify-center">
                <p className="r-text-sm r-font-semibold ">{getText()}</p>
                <p className="r-text-2xl r-font-semibold ">{formattedAmount}</p>
            </div>
        </>
    );
};
