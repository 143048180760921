import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { HUMANY_SEARCH_QUERY_KEY, humanySearchQuery } from '@/queries/search';
import { useGlobalQueries } from './use-global-queries';

export function useHumanySearch(searchValue: string) {
    const { getSetting } = useGlobalQueries();
    const humanySettings = getSetting('humany_settings');

    const humanyApi = humanySettings?.humany_search_api;
    const humanySearchResults = useQuery({
        queryKey: [HUMANY_SEARCH_QUERY_KEY, humanyApi, searchValue],
        queryFn: () => humanySearchQuery(humanyApi, searchValue),
        placeholderData: keepPreviousData,
        enabled: !!humanyApi && !!searchValue,
    });

    return {
        humanySearchResults,
    };
}
