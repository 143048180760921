import { hydrateRoot } from 'react-dom/client';
import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { App } from '../isomorphic/app/app';
import { AppContextProvider } from '@/contexts/app-context';

/* eslint-disable  @typescript-eslint/no-non-null-assertion */
const queryCache = JSON.parse(document?.getElementById('_query-cache')!.innerText);
const contextCache = JSON.parse(document?.getElementById('_context-cache')!.innerText);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        },
    },
});
const rootDiv = document.getElementById('root') as HTMLDivElement;

const apiFetch = async (url: string) => {
    try {
        const apiResponse = await fetch(`/${url}`, {
            headers: {
                credentails: 'include',
            },
        });

        const response = await apiResponse.json();
        return response.data;
    } catch (e: unknown) {
        console.error(e);
        throw e;
    }
};

hydrateRoot(
    rootDiv,
    <AppContextProvider {...contextCache} fetch={apiFetch}>
        <QueryClientProvider client={queryClient}>
            <HydrationBoundary state={queryCache}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </HydrationBoundary>
        </QueryClientProvider>
    </AppContextProvider>
);
