import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalQueries } from '@/hooks/use-global-queries';
import { AppContext } from '@/contexts/app-context';
import { removeTrailingSlash } from '@/utils/strings';

export const FooterBottom = () => {
    const { footerQuery } = useGlobalQueries();
    const navigate = useNavigate();
    const context = useContext(AppContext);
    const iconMappings = {
        facebook: 'facebook.svg',
        instagram: 'instagram.svg',
        linkedin: 'linkedin.svg',
        twitter: 'twitter.svg',
    };

    const handleContentClick = (event: React.MouseEvent<HTMLElement>) => {
        const targetLink = (event.target as HTMLElement).closest('a');
        if (!targetLink) {
            return;
        }

        const url = new URL(targetLink.href);
        const target = targetLink.getAttribute('target');
        const isOpenedInSameWindow = !target || target === '_self';
        const isLeftButtonClick = event.button === 0;
        const isInternalLink = typeof window !== 'undefined' && window.location.origin === url.origin;

        // E.g. Ctrl-clicking a link opens it in a new tab
        // so let the browser handle such clicks
        const isModifierKeyPressed = event.altKey || event.ctrlKey || event.metaKey || event.shiftKey;

        if (isInternalLink && isOpenedInSameWindow && isLeftButtonClick && !isModifierKeyPressed) {
            event.preventDefault();
            navigate(removeTrailingSlash(url.pathname));
        }
    };

    return (
        <div
            data-component="footer-bottom"
            className="r-container r-relative r-z-30 r-justify-start r-px-8 r-pb-8 r-text-white max-md:r-pt-16 sm:r-justify-items-center"
        >
            <div className="r-py-4">
                <div className="r-flex r-gap-4">
                    {footerQuery.data?.socialLinks?.map((link, index) => (
                        <a
                            key={index}
                            href={link.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="r-grow-1 r-flex r-h-10 r-w-10 r-shrink-0 r-basis-auto r-items-center r-justify-center r-rounded-full r-bg-white"
                            onKeyUp={() => handleContentClick}
                            tabIndex={0}
                        >
                            <img
                                src={`/assets/icons/${iconMappings[link.network as keyof typeof iconMappings]}`}
                                alt={link.network}
                                width={22}
                                height={22}
                            />
                        </a>
                    ))}
                </div>
            </div>

            <p className="r-ml-auto r-text-sm">{footerQuery.data?.copyright}</p>
            <p className="r-text-xs max-md:r-pb-4 max-md:r-pt-4 md:r-absolute md:r-bottom-8 md:r-right-8">v{context.version}</p>
        </div>
    );
};
