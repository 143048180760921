import { useContext } from 'react';
import { AppContext } from '@/contexts/app-context';

export function useMyEconomyDrawer() {
    const { myEconomyDrawer, setMyEconomyDrawer } = useContext(AppContext);

    return {
        myEconomyDrawer,
        setMyEconomyDrawer,
    };
}
