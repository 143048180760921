import clsx from 'clsx';
import { PropsWithChildren, CSSProperties } from 'react';
import { getImageUrl } from '@/utils/images';
import { getTailwindColor } from '@/utils/colors';

type GridTemplate = {
    width: '1/1' | '1/2' | '1/3' | '2/3' | '1/4';
};

export type SectionProps = {
    backgroundType: null | 'image' | 'color';
    verticalPadding?: 'big' | 'none';
    mobileDesktopFilter: 'all' | 'mobile' | 'desktop';
    template: GridTemplate[];
    templateID: number;
    hash: string;
    equalHeight?: boolean;
    removeGridGap?: boolean;
    wave?: boolean;
    waveColor?: string;
    anchorEnable?: boolean;
    anchorName?: string;
    fullWidth?: 'stretch' | 'noStretch';
    width?: 'full' | 'constrained';
    verticalAlignment?: 'center' | 'bottom';
    focus?: {
        x: null | number;
        y: null | number;
    };
    backgroundValue?:
        | string
        | {
              height: number;
              width: number;
              url: string;
              __isimage: boolean;
          };
};

function calculateGridColumns(templates: GridTemplate[]) {
    const strTemplates = templates.map(({ width }) => width).join('-');

    switch (strTemplates) {
        case '1/2-1/2':
            return 'md:r-grid-cols-2';
        case '1/3-2/3':
            return 'md:r-grid-cols-[1fr_2fr]';
        case '2/3-1/3':
            return 'md:r-grid-cols-[2fr_1fr]';
        case '1/3-1/3-1/3':
            return 'md:r-grid-cols-3';
        case '1/4-1/2-1/4':
            return 'md:r-grid-cols-[1fr_2fr_1fr]';
        case '1/4-1/4-1/4-1/4':
            return 'md:r-grid-cols-4';
    }

    return 'md:r-grid-cols-1';
}

export const Section = ({ children, ...props }: PropsWithChildren<SectionProps>) => {
    const classNames = clsx('r-px-4 r-relative md:r-px-8 r-grid styled-section-inner r-bg-transparent', calculateGridColumns(props.template), {
        'r-py-12': props?.verticalPadding === 'big',
        'r-py-8': !props.verticalPadding,
        'r-gap-8': !props.removeGridGap,
        'md:r-container': props.width !== 'full',
        'r-items-center': props.verticalAlignment === 'center',
        'r-items-end': props.verticalAlignment === 'bottom',
        'r-items-stretch': props.equalHeight,
        'r-items-start': !props.equalHeight,
        'r-min-h-[350px] md:r-min-h-[540px]': props.wave,
    });

    const style: CSSProperties = {};
    if (props.backgroundType === 'image' && props.backgroundValue && typeof props.backgroundValue !== 'string') {
        style.backgroundImage = `url(${getImageUrl(props.backgroundValue.url, '1920xAUTO')})`;
    }

    if (props.focus?.x && props.focus?.y) {
        style.backgroundPositionX = `${props.focus?.x * 100}%`;
        style.backgroundPositionY = `${props.focus?.y * 100}%`;
    }

    return (
        <div
            data-component="section"
            className={clsx(
                'scrollbar-hide r-relative r-w-full r-overflow-hidden r-bg-cover r-bg-center r-bg-no-repeat',
                getTailwindColor(props.backgroundValue, 'bg')
            )}
            id={props.anchorName}
        >
            <div
                style={style}
                className={clsx('r-absolute r-top-0 r-w-full r-bg-cover r-bg-top r-bg-no-repeat', {
                    'r-bottom-0': !props.wave,
                    'r-h-[380px] md:r-h-[450px] xl:r-h-[520px]': props.wave,
                })}
            />
            {props.wave && (
                <div className="r-pointer-events-none r-absolute r-bottom-0 r-right-0 r-top-[280px] r-w-full md:r-top-[350px]">
                    <svg viewBox="0 -1 1441 95" xmlns="http://www.w3.org/2000/svg" className={`${getTailwindColor(props.waveColor, 'fill')}`}>
                        <path d="M0 0V95H1441V0C1225.69 60.1229 980.57 94.1402 720.5 94.1402C460.43 94.1402 215.306 60.1229 0 0Z" />
                    </svg>
                    <div className={clsx('-r-mt-[1px] r-h-full', getTailwindColor(props.waveColor, 'bg'))} />
                </div>
            )}
            <div className={classNames}>{children}</div>
        </div>
    );
};
