import { useContext, useEffect, useMemo, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FileWithPath } from 'react-dropzone';
import * as Dropzone from 'react-dropzone';
import { AllFieldTypeProps } from './field-types';
import { Field, FieldProps } from '../../forms/components/field';
import { ValidatorType, useValidation } from '@/hooks/use-validation';
import { Button } from '@/isomorphic/components/typography/button/button';
import { useGlobalQueries } from '@/hooks/use-global-queries';
import { useForm } from '@/hooks/use-form';
import { AppContext } from '@/contexts/app-context';
import { Checkbox } from '@/isomorphic/components/forms/components/checkbox';
import { Select } from '@/isomorphic/components/forms/components/select';
import { useLocalization } from '@/hooks/use-localization';
import { useGtm } from '@/hooks/use-gtm';
import { GoogleRecaptchaInfo } from '../../google-recaptcha-info/google-recaptcha-info';

export type AllContactMeFormsProps = FormSettings & AllFieldTypeProps;

type FormSettings = {
    generalRequiredErrorMessage?: string;
    generalSubmitErrorMessage?: string;
    generalSubmitButtonText?: string;
    bankAutoFillCustomer?: boolean;
    autoFillFields?: {
        bankAutofillCustomerName: boolean;
        bankAutofillCustomerEmail: boolean;
        bankAutofillCustomerAddress: boolean;
        bankAutofillCustomerHomePhone: boolean;
        bankAutofillCustomerMobilePhone: boolean;
    };
    autoFillAccount?: {
        bankAutofillCustomerAccountEnable: boolean;
        bankAutofillCustomerAccountTypes: []; // two possible items, "loans" and "credits"
        bankAutofillCustomerLoanType: 'All' | 'Private' | 'Ellos';
    };
    formId?: string;
    permalink?: string;
    resetLink?: object;
    gtmOptions?: {
        gtmPaymentInsurance: boolean;
        gtmPaymentInsuranceType: 'Consumerloan' | 'Ellos';
    };
    successTitle?: string;
    successMessage?: string;
};

const MAX_FILE_SIZE = 15728640;

export const ContactForm = (props: AllContactMeFormsProps) => {
    const { parsePropsToFieldObjects } = useForm();

    const parsedFields = parsePropsToFieldObjects(props);
    const formSettings = {
        formId: props.formId,
        successTitle: props.successTitle,
        successMessage: props.successMessage,
    };

    return <RForm parsedFields={parsedFields} formSettings={formSettings} />;
};

const RForm = ({ parsedFields, formSettings }: { parsedFields: FieldProps[]; formSettings: FormSettings | undefined }) => {
    const { role, siteId, deviceType, locale } = useContext(AppContext);
    const { getValidatorByInputName } = useValidation();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [formValues, setFormValues] = useState({});
    const permalink = useGlobalQueries().getPermalink();
    const [files, setFiles] = useState<FileWithPath[]>([]);
    const { localize } = useLocalization();
    const formId = formSettings?.formId || '';
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [finished, setFinished] = useState(false);
    const [isClient, setIsClient] = useState(false);
    const { sendFormSubmitEvent } = useGtm();

    useEffect(() => {
        setIsClient(true);
    }, []);

    const getRemoveText = () => {
        switch (locale) {
            case 'sv-se':
                return 'Ta bort';
            case 'da-dk':
            case 'nb-no':
                return 'Fjerne';
            case 'fi-fi':
                return 'Poista';
        }
    };

    const handleChange = (fieldName: string, newValue: string | number | null | boolean) => {
        setFormValues(prevState => ({ ...prevState, [fieldName]: newValue }));
    };

    const isSubmitDisabled = useMemo(() => {
        if (loading) {
            return true;
        }

        let disabled = false;
        parsedFields.forEach(field => {
            if (!field.fieldName) {
                return;
            }

            const fieldName = field.fieldName || '';
            const fieldValue = formValues[fieldName as keyof typeof formValues] || '';

            // Don't attempt to validate if the field is not required and does not have a value
            if (!field.required && !fieldValue) {
                return;
            }

            // Special case for file uploads
            if (field.fieldName === 'files') {
                if (field.required && !files.length) {
                    disabled = true;
                }
                return;
            }

            if ((field.fieldName as keyof typeof formValues) in formValues === false) {
                disabled = true;
            }

            if (!formValues[field.fieldName as keyof typeof formValues]) {
                disabled = true;
            }

            const validator = getValidatorByInputName(field.fieldName as ValidatorType);
            if (validator && !validator(formValues[field.fieldName as keyof typeof formValues])) {
                disabled = true;
            }
        });

        return disabled;
    }, [parsedFields, formValues, files, loading]);

    const handleSubmit = async () => {
        if (loading) {
            return;
        }

        if (!executeRecaptcha) {
            setError(localize('invalid-captcha'));
            return;
        }

        const token = await executeRecaptcha();
        if (!token) {
            setError(localize('invalid-captcha'));
            return;
        }

        const formData = new FormData();
        formData.set('permalink', permalink);
        formData.set('formId', formId || '');
        Object.entries(formValues).forEach(([key, value]) => {
            formData.append(key, value as string);
        });
        if (files.length > 0) {
            files.forEach(file => {
                formData.append('files', file);
            });
        }

        setError('');
        setLoading(true);
        sendFormSubmitEvent();

        const response = await fetch(`/api/form`, {
            headers: {
                Accept: 'application/json',
                credentials: 'include',
                'x-role': role,
                'x-site-id': siteId,
                'x-device-type': deviceType,
                'x-recaptcha-token': JSON.stringify(token),
            },
            method: 'POST',
            body: formData,
        });

        setLoading(false);

        if (response.ok === false) {
            const data = await response.json();

            if (response.status === 498) {
                if (data?.error === 'INVALID_CAPTCHA_TOKEN') {
                    setError(localize('invalid-captcha'));
                } else {
                    setError(localize('invalid-token'));
                }
            } else {
                if (data.error) {
                    setError(formSettings?.generalSubmitErrorMessage || localize('error-generic') || 'There was an error. Please try again later.');
                }
            }

            return response;
        }

        setFinished(true);
        return response;
    };

    const onDrop = (acceptedFiles: FileWithPath[]) => {
        const newFiles = acceptedFiles.filter(acceptedFile => {
            return !files.find(file => file.lastModified === acceptedFile.lastModified && file.name === acceptedFile.name);
        });

        const totalFilesSize = newFiles.reduce((acc, file) => acc + file.size, 0);
        if (totalFilesSize > MAX_FILE_SIZE) {
            return;
        }

        if (newFiles.length >= 0) {
            const filess = files.concat(newFiles);
            setFiles(filess);
        }
    };

    const handleRemove = (fileName: string) => {
        const updatedFiles = files.filter(file => file.name !== fileName);
        setFiles(updatedFiles);
    };

    return (
        <div data-component="resurs/contact-form" className="r-flex r-max-w-xl r-flex-col r-gap-4">
            {!finished ? (
                parsedFields.map((field, index) => {
                    const fieldName = field.fieldName || '';
                    const fieldValue = formValues[fieldName as keyof typeof formValues] || '';
                    switch (field.type) {
                        case 'input':
                            return (
                                <Field
                                    key={`${fieldName}--${index}`}
                                    placeholder={field.label}
                                    errorMessage={field.errorMessage || 'enter a valid value'}
                                    value={fieldValue}
                                    {...field}
                                    onChange={value => handleChange(fieldName, value)}
                                    validation={getValidatorByInputName(fieldName as ValidatorType)}
                                />
                            );

                        case 'textarea':
                            return (
                                <Field
                                    key={`${fieldName}--${index}`}
                                    placeholder={field.label}
                                    required={field.required}
                                    value={fieldValue}
                                    {...field}
                                    onChange={value => handleChange(fieldName, value)}
                                    validation={getValidatorByInputName(fieldName as ValidatorType)}
                                />
                            );

                        case 'checkbox':
                            return (
                                <Checkbox
                                    key={`${fieldName}--${index}`}
                                    {...field}
                                    type="checkbox"
                                    onChange={checked => handleChange(fieldName, checked)}
                                />
                            );

                        case 'select':
                            return (
                                <Select
                                    key={`${fieldName}--${index}`}
                                    // placeholder={field.placeholder}
                                    label={field.label || field.placeholder}
                                    options={
                                        (field.list &&
                                            field.list.map((item: { name: string; email: string }) => ({ label: item.name, value: item.name }))) ||
                                        []
                                    }
                                    {...field}
                                    value={fieldValue ?? ''}
                                    onChange={value => handleChange(fieldName, value)}
                                />
                            );

                        case 'file':
                            return isClient ? (
                                <Dropzone.default key={`${fieldName}--${index}`} onDrop={onDrop} maxSize={MAX_FILE_SIZE}>
                                    {({ getRootProps, getInputProps, isDragActive }) => (
                                        <div>
                                            <p className="r-text-sm r-font-bold">{localize(fieldName)}</p>
                                            {files.length > 0 && (
                                                <div className="r-py-1">
                                                    <ul className="r-space-y-2">
                                                        {files.map((acceptedFile: FileWithPath) => (
                                                            <li key={acceptedFile.name}>
                                                                <div className="r-flex r-items-center r-justify-between">
                                                                    <p>{acceptedFile.path}</p>
                                                                    <button
                                                                        className="r-rounded-full r-bg-danger r-p-2 r-text-sm r-text-white"
                                                                        onClick={() => handleRemove(acceptedFile.name)}
                                                                    >
                                                                        {getRemoveText()}
                                                                    </button>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                            <section>
                                                <div
                                                    {...getRootProps()}
                                                    className={`r-flex r-w-full r-cursor-pointer r-justify-center r-rounded-md r-border-2 r-border-dashed r-border-[#999] r-p-9 r-transition-colors r-duration-300 hover:r-bg-gray-300 ${
                                                        isDragActive && 'r-bg-gray-300'
                                                    }`}
                                                >
                                                    <input {...getInputProps()} />
                                                    <p>{field.dropText || 'Click or drag here to add files'}</p>
                                                </div>
                                            </section>
                                        </div>
                                    )}
                                </Dropzone.default>
                            ) : null;

                        default:
                            return null;
                    }
                })
            ) : (
                <div className="r-space-y-4 r-border-b r-pb-12">
                    <p className="r-text-4xl r-font-bold">{formSettings?.successTitle}</p>
                    <p>{formSettings?.successMessage}</p>
                </div>
            )}

            {error ? <div className="r-row-start-4 r-mt-2 r-bg-red-700 r-p-4 r-text-sm r-text-white">{error}</div> : null}
            <GoogleRecaptchaInfo />
            {!finished && (
                <Button disabled={isSubmitDisabled} onClick={handleSubmit}>
                    {localize('submit')}
                </Button>
            )}
        </div>
    );
};
