import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useState } from 'react';
import { useLocalization } from '@/hooks/use-localization';

const USER_DATA = {
    fullName: '',
    address: '',
    postalArea: '',
    postalCode: '',
    email: '',
    phone: '',
};

export type UserData = typeof USER_DATA;

export const useJula = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { localize, getCountry } = useLocalization();
    const [applicationError, setApplicationError] = useState('');
    const [userData, setUserData] = useState({ ...USER_DATA });

    const get = async () => {
        try {
            const response = await fetch('/api/bank/jula-account-verification');
            const responseJson = await response.json();
            if (responseJson.status === 'ok') {
                const user = responseJson.user;
                setUserData(user);
            }
        } catch (error) {
            setApplicationError(localize('error-generic'));
            console.error(error);
        }
    };

    const put = async (consentApproved: boolean) => {
        try {
            const response = await fetch('/api/bank/jula-account-verification', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    phone: userData.phone,
                    email: userData.email,
                    consentApproved,
                }),
            });

            if (response.status === 200) {
                const responseJson = await response.json();
                if (responseJson.status === 'ok') {
                    return {
                        success: true,
                    };
                }
            }
        } catch (error) {
            console.error(error);
        }

        return {
            success: false,
        };
    };

    const sendEmail = async (email: string) => {
        const returnObj = { success: false, message: 'invalid captcha' };
        if (!executeRecaptcha) {
            return returnObj;
        }

        const token = await executeRecaptcha();
        if (!token) {
            return returnObj;
        }

        if (!userData.email) {
            return { ...returnObj, message: 'userData was not available' };
        }

        try {
            const formData = new FormData();
            formData.append('email', email);
            formData.append('country', getCountry());

            const response = await fetch('/api/jula-voucher', {
                method: 'POST',
                headers: {
                    'x-recaptcha-token': JSON.stringify(token),
                },
                body: formData,
            });

            if (response.status === 200) {
                return {
                    success: true,
                    message: 'Voucher sent by mail',
                };
            }

            if (response.status === 498) {
                return returnObj;
            }
        } catch (error) {
            console.error(error);
        }

        return { ...returnObj, message: 'Failed to send voucher by mail' };
    };

    return {
        get,
        put,
        sendEmail,
        userData,
        setUserData,
        applicationError,
        setApplicationError,
    };
};
