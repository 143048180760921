import clsx from 'clsx';
import { Size } from '@/utils/typography';

export type SpacerProps = {
    size: Size;
};

export const Spacer = ({ size }: SpacerProps) => (
    <div
        data-component="spacer"
        className={clsx({
            'r-h-8': size === 'small',
            'r-h-16': size === 'medium',
            'r-h-24': size === 'large',
        })}
    />
);
