import clsx, { ClassValue } from 'clsx';

export const Check = () => (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_25_9981)">
            <rect width="80" height="80" fill="white" fillOpacity="0.01" />
            <rect width="80" height="80" rx="40" fill="#228830" fillOpacity="0.12" />
            <g clipPath="url(#clip1_25_9981)">
                <g clipPath="url(#clip2_25_9981)">
                    <path
                        d="M40 62C52.1516 62 62 52.1516 62 40C62 27.8484 52.1516 18 40 18C27.8484 18 18 27.8484 18 40C18 52.1516 27.8484 62 40 62ZM49.7109 35.9609L38.7109 46.9609C37.9031 47.7687 36.5969 47.7687 35.7977 46.9609L30.2977 41.4609C29.4898 40.6531 29.4898 39.3469 30.2977 38.5477C31.1055 37.7484 32.4117 37.7398 33.2109 38.5477L37.25 42.5867L46.7891 33.0391C47.5969 32.2312 48.9031 32.2312 49.7023 33.0391C50.5016 33.8469 50.5102 35.1531 49.7023 35.9523L49.7109 35.9609Z"
                        fill="#228830"
                    />
                </g>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_25_9981">
                <rect width="80" height="80" fill="white" />
            </clipPath>
            <clipPath id="clip1_25_9981">
                <rect width="44" height="44" fill="white" transform="translate(18 18)" />
            </clipPath>
            <clipPath id="clip2_25_9981">
                <rect width="44" height="44" fill="white" transform="translate(18 18)" />
            </clipPath>
        </defs>
    </svg>
);

export const Loading = () => (
    <svg
        aria-hidden="true"
        className="r-dark:text-gray-600 r-h-8 r-w-8 r-animate-spin r-fill-primary-100 r-text-gray-200"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
        />
        <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
        />
    </svg>
);

export const Pencil = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23.3156 2.83383C24.1922 3.71227 24.1922 5.13727 23.3156 6.01383L8.25 21.0795C7.67344 21.6138 6.96094 22.0779 6.17812 22.3076L0.480935 23.9857C0.34917 24.0232 0.206857 23.9857 0.109826 23.892C0.0128089 23.7935 -0.023477 23.6529 0.0152511 23.517L1.69125 17.8216C1.9214 17.0388 2.34469 16.3263 2.92125 15.7076L17.9859 0.685547C18.8625 -0.193359 20.2875 -0.193359 21.1641 0.685547L23.3156 2.83383ZM15.3797 4.34977L19.65 8.62008L22.7859 5.48414C23.3719 4.8982 23.3719 3.94992 22.7859 3.36398L20.6344 1.2157C20.0484 0.629766 19.1016 0.629766 18.4734 1.2157L15.3797 4.34977ZM14.85 4.87945L3.4514 16.2795C2.96344 16.767 2.60578 17.367 2.41078 18.0326L0.928592 23.0716L5.96719 21.5904C6.63281 21.3935 7.23281 21.0373 7.72031 20.5498L19.1203 9.14977L14.85 4.87945Z"
            fill="rgb(0,100,100)"
        />
    </svg>
);

export const Close = () => (
    <svg className="r-h-6 r-w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
    </svg>
);

export const Plus = ({ fill = '#00807B', className = '' }: { fill?: string; className?: ClassValue }) => (
    <svg className={clsx(className)} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8 2.5C8 1.94687 7.55312 1.5 7 1.5C6.44688 1.5 6 1.94687 6 2.5V7H1.5C0.946875 7 0.5 7.44688 0.5 8C0.5 8.55312 0.946875 9 1.5 9H6V13.5C6 14.0531 6.44688 14.5 7 14.5C7.55312 14.5 8 14.0531 8 13.5V9H12.5C13.0531 9 13.5 8.55312 13.5 8C13.5 7.44688 13.0531 7 12.5 7H8V2.5Z"
            fill={fill}
        />
    </svg>
);
