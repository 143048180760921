import { PropsWithChildren, useState } from 'react';
import { LoginOverlay } from '../../login-overlay/login-overlay';
import { getImageUrl } from '@/utils/images';
import { Select } from '../../forms/components/select';
import { Button } from '../../typography/button/button';
import { Link } from 'react-router-dom';

type Link = {
    title: string;
    url: string;
    target: string;
    rel: string;
};

export type LoginScreenProps = {
    image: {
        url: string;
    } | null;
    mobileImage: {
        url: string;
    } | null;
    otherUrls: Link[];
    goBackUrl: Link;
    urls: {
        sweden: Link | null;
        norway: Link | null;
        denmark: Link | null;
        finland: Link | null;
    };
    header: string;
    subHeader: string;
    buttonText: string;
    selectLabel: string;
};

export const LoginScreen = (props: PropsWithChildren<LoginScreenProps>) => {
    const [selectedCountry, setSelectedCountry] = useState<string>(() => props.urls.sweden?.url || '');

    const imageUrl = () => {
        const size = '1920xAUTO';
        const defaultImage = getImageUrl('/inlaning/man-bike-large.jpeg', size);
        if (props.image) {
            const url = new URL(props.image.url);
            return url.pathname ? getImageUrl(url.pathname, size) : defaultImage;
        }

        return defaultImage;
    };

    const mobileImageUrl = () => {
        const size = '400xAUTO';
        const defaultImage = getImageUrl('/inlaning/man-bike-large.jpeg', size);

        if (props.mobileImage) {
            const url = new URL(props.mobileImage.url);
            return url.pathname ? getImageUrl(url.pathname, size) : defaultImage;
        }

        if (props.image) {
            const url = new URL(props.image.url);
            return url.pathname ? getImageUrl(url.pathname, size) : defaultImage;
        }

        return defaultImage;
    };

    const selectOptions = () => {
        type CountryKeys = keyof LoginScreenProps['urls'];
        const options: CountryKeys[] = ['sweden', 'norway', 'denmark', 'finland'];

        return options
            .map(key => ({
                value: props.urls?.[key]?.url ?? '',
                label: props.urls?.[key]?.title ?? '',
            }))
            .filter(p => p.value !== '');
    };

    return (
        <div data-component="login-screen">
            <LoginOverlay
                desktopImage={imageUrl()}
                mobileImage={mobileImageUrl()}
                backLink={{ url: props.goBackUrl?.url ?? '/', text: props.goBackUrl?.title ?? 'Startsidan' }}
            >
                {props.header ? <h1 className="r-mb-4 r-text-3xl r-font-bold">{props.header}</h1> : null}
                {props.subHeader ? <p className="r-mb-4 r-font-light r-text-[#212529]">{props.subHeader}</p> : null}
                <div className="r-pb-4">
                    <Select
                        label={props.selectLabel || 'Välj land'}
                        value={selectedCountry}
                        options={selectOptions()}
                        onChange={value => setSelectedCountry(value as string)}
                    />
                </div>
                <Button fullWidth to={selectedCountry} target="_blank">
                    {props.buttonText || 'Logga in'}
                </Button>
                <div className="underlined-links r-pb-16 r-pt-12">{props.children}</div>
                {props.otherUrls?.length ? (
                    <div className="r-flex r-flex-wrap r-gap-6 r-pb-8">
                        {props.otherUrls.map((item, index) => (
                            <Link
                                className="r-underline r-underline-offset-2"
                                key={index}
                                to={item.url}
                                target={item?.target || '_self'}
                                rel={item?.rel || ''}
                            >
                                {item.title}
                            </Link>
                        ))}
                    </div>
                ) : null}
            </LoginOverlay>
        </div>
    );
};
