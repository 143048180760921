import { StepProps } from './step-one';
import { Field } from '@/isomorphic/components/forms/components/field';
import { Button } from '@/isomorphic/components/typography/button/button';
import { useLocalization } from '@/hooks/use-localization';
import { validators } from '@/hooks/use-validation';
import { useState } from 'react';
import { GoogleRecaptchaInfo } from '@/isomorphic/components/google-recaptcha-info/google-recaptcha-info';

export const StepTwo = ({
    fields,
    updateField,
    labels,
    updateProgress,
    onSubmit,
    creditCheckError,
}: StepProps & { creditCheckError?: null | string }) => {
    const { getLanguage, getLocale } = useLocalization();
    const [isLoading, setIsLoading] = useState(false);

    const submit = async () => {
        setIsLoading(true);
        await onSubmit(2);
        setIsLoading(false);
    };

    const isButtonDisabled = () =>
        !!creditCheckError || !fields.organizationNumber || !fields.companyName || !fields.contactPerson || !fields.phone || !fields.email;

    return (
        <div data-component="step-two" className="r-space-y-4">
            <Field
                required
                infoText={labels.organizationNumberInfoText}
                errorMessage={labels.organizationNumberErrorText}
                label={labels.organizationNumber}
                value={fields.organizationNumber}
                onChange={value => updateField({ organizationNumber: value })}
                validation={value =>
                    validators.companyRegistrationNumber(value, getLanguage(), {
                        sv: { strict: true },
                    })
                }
            />
            <Field required label={labels.companyName} value={fields.companyName} onChange={value => updateField({ companyName: value })} />
            <Field required label={labels.contactPerson} value={fields.contactPerson} onChange={value => updateField({ contactPerson: value })} />
            <Field
                required
                label={labels.phone}
                value={fields.phone}
                errorMessage={labels.phoneErrorText}
                onChange={value => updateField({ phone: value })}
                validation={value => {
                    if (getLanguage() === 'sv') {
                        return /^(0|\+46|0046)[ |-]?(200|20|70|73|76|74|[1-9][0-9]{0,2})([ |-]?[0-9]){5,8}$/.test(value);
                    }
                    return validators.phone(value, getLocale());
                }}
            />
            <Field
                required
                label={labels.email}
                value={fields.email}
                errorMessage={labels.emailErrorText}
                onChange={value => updateField({ email: value })}
                validation={value => validators.email(value)}
            />
            <GoogleRecaptchaInfo />
            <div className="r-flex r-justify-between">
                <Button onClick={() => updateProgress(1)}>{labels.back}</Button>
                <Button disabled={isButtonDisabled() || isLoading} onClick={submit}>
                    {labels.continue}
                </Button>
            </div>
        </div>
    );
};
