import { PropsType } from '../types';

export const formatInterest = (interest: string) => (interest.includes('%') ? interest : `${interest}%`);

export const Circle = ({ getLabel }: Pick<PropsType, 'getLabel'>) => (
    <div className="r-flex r-h-[200px] r-w-[200px] r-flex-col r-items-center r-justify-center r-space-y-2 r-rounded-full r-bg-primary-300 r-font-semibold r-text-white">
        <p>{getLabel('interest')}</p>
        <p className="r-text-3xl">{formatInterest(getLabel('interestRate'))}</p>
    </div>
);
