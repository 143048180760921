import { useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AppContext } from '@/contexts/app-context';
import { getMfn } from '@/queries/mfn';

export type FilterType = {
    limit: number;
    offset: number;
    type: string;
    lang?: string;
    filter?: string;
    tags?: string;
    'not-tag'?: string;
    from?: string;
    to?: string;
};

export const MFN_QUERY_KEY = 'MFN_QUERY_KEY';

export function useMfn() {
    const { language } = useContext(AppContext);

    const getInitialFilters = () => {
        const filters: FilterType = {
            limit: 150,
            offset: 0,
            type: 'all',
        };

        if (language === 'sv') {
            filters.lang = 'sv';
        } else {
            filters.filter = `(and(or(.properties.lang="${language}")(.properties.lang="en")))`;
        }

        return filters;
    };

    const [filters, setFilters] = useState<FilterType>(getInitialFilters());
    const context = useContext(AppContext);
    const mfn = useQuery({
        queryKey: [MFN_QUERY_KEY, filters],
        queryFn: () => getMfn(context, filters),
    });

    return {
        mfn,
        setFilters,
        filters,
    };
}
