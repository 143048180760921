import { Button } from '../../typography/button/button';
import { BenefitDesktop } from './benefit-desktop';
import { CardContent } from '@/queries/supreme-cards';
import { CardComparison, CardCtaLink } from './card-comparison';
import { useLocalization } from '@/hooks/use-localization';
import { Image } from '../../image/image';

export type Card = {
    card: CardContent;
    cardComparisonData: CardComparison[];
    ctaLink?: CardCtaLink;
    applyText: string;
    hideCardImages?: boolean;
};

export const Card = ({ card, cardComparisonData, applyText, ctaLink, hideCardImages = false }: Card) => {
    const { formatCurrency } = useLocalization();

    const renderCardImage = () => {
        if (hideCardImages || !card.image) {
            return null;
        }

        if (!card.horizontal_image) {
            return <Image src={card.image.src} size="225xAUTO" />;
        }

        return (
            <>
                <div className="md:r-hidden">
                    <Image src={card.image.src} size="225xAUTO" />
                </div>
                <div className="r-hidden md:r-block">
                    <Image src={card.horizontal_image.src} size="400xAUTO" />
                </div>
            </>
        );
    };

    const formattedFee = () => {
        if (!card.fee?.period) {
            return '';
        }

        return `${formatCurrency(card.fee.price)}/${card.fee.period}`;
    };

    return (
        <div className="r-flex r-w-full r-flex-col r-items-center">
            <div className="r-text-center">{renderCardImage()}</div>
            <div className="r-flex r-w-full r-flex-col r-items-center md:r-items-start">
                <p className="r-hidden r-text-base r-text-primary-500 md:r-block">{card.card_credit_text || ''}</p>
                <p className="r-hidden r-pb-1 md:r-block lg:r-flex lg:r-gap-1 lg:r-align-middle">
                    <span className="r-text-xl r-font-bold r-text-primary-500 max-lg:r-block">{card.fullname}</span>
                    {card.card_cta ? (
                        <span className="r-rounded-2xl r-bg-[rgba(255,223,158,1)] r-p-2 r-text-xs r-leading-none r-text-[rgba(120,89,0,1)] max-lg:r-inline-block lg:r-grid lg:r-place-content-center lg:r-px-2 lg:r-py-0">
                            {card.card_cta}
                        </span>
                    ) : null}
                </p>
                <div className="r-text-center max-md:r-space-y-1 md:r-hidden">
                    <p className="r-text-xl r-font-bold">{card.title}</p>
                    <div className="r-text-sm">{formattedFee()}</div>
                </div>
                <p className="r-hidden r-text-base r-text-primary-500 md:r-block">{formattedFee()}</p>
            </div>

            {ctaLink?.url ? (
                <div className="md:r-w-full">
                    <Button
                        fullWidth
                        size="small"
                        target={ctaLink?.target || '_self'}
                        className="r-mb-3 r-mt-2 md:r-my-6"
                        to={`${ctaLink.url.replace(/\/$/, '')}?selectedCard=${card.api_name}`}
                    >
                        {applyText}
                    </Button>
                </div>
            ) : (
                <div className="r-h-4" />
            )}

            <div className="r-hidden r-w-full r-space-y-6 md:r-block">
                {cardComparisonData.map(cardComparison => (
                    <BenefitDesktop activeCard={card} key={cardComparison.title} cardComparisonData={cardComparison} />
                ))}
            </div>
            <div className="r-mt-6 r-hidden r-w-full md:r-block">
                <Button type="secondaryBorder" fullWidth size="small" to={card.comparison_link?.url}>
                    {card.comparison_link?.title}
                </Button>
            </div>
        </div>
    );
};
