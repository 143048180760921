import { PropsWithChildren } from 'react';
import { Button } from '../../typography/button/button';
import clsx from 'clsx';

export type ArticleCardsProps = {
    centerButtons: boolean;
    extraButton: boolean;
    extraButtonLink: {
        url: string;
        title: string;
        rel: string;
        target: string;
    };
    mobileCollapse: boolean;
};

export const ArticleCards = ({ children, ...props }: PropsWithChildren<ArticleCardsProps>) => (
    <div
        data-component="article-cards"
        className={clsx('r-pb-4 md:r-pb-8', {
            'r-rounded-2xl r-bg-white r-bg-opacity-80 md:r-bg-transparent md:r-bg-opacity-100': props.mobileCollapse,
        })}
    >
        <div
            className={clsx('r-flex r-items-stretch max-md:r-flex-col md:r-gap-8', {
                'r-gap-4': !props.mobileCollapse,
            })}
        >
            {children}
        </div>
        {props.extraButton && (
            <div
                className={clsx('r-pt-4 md:r-pt-8', {
                    'max-md:r-pl-4': props.mobileCollapse,
                })}
            >
                <Button target={props.extraButtonLink.target} to={props.extraButtonLink.rel} type="primary" size="small">
                    {props.extraButtonLink.title}
                </Button>
            </div>
        )}
    </div>
);
