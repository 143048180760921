import { useLocation } from 'react-router-dom';
import { MenuItemType } from '@/queries/header';
import { useGlobalQueries } from './use-global-queries';
import { removeTrailingSlash } from '@/utils/strings';

export function useNavItem() {
    const { pageQuery } = useGlobalQueries();
    const { pathname } = useLocation();

    const isCurrentItemActive = ({ object_id: objectId, url }: MenuItemType) => {
        if (removeTrailingSlash(url) === removeTrailingSlash(pathname)) {
            return true;
        }

        const meta = pageQuery.data?.__meta;
        if (!meta) {
            return false;
        }

        if (parseInt(meta.externalId) === parseInt(objectId) || parseInt(meta.parentId) === parseInt(objectId)) {
            return true;
        }

        return false;
    };

    return {
        isCurrentItemActive,
    };
}
