import { useContext } from 'react';
import { useBeforeMount } from '@24hr/react-hooks';
import { ComparisonRow } from './comparison-row';
import { CardDescription } from './card-description';
import { useSupremeCards } from '@/hooks/use-supreme-cards';
import { AppContext } from '@/contexts/app-context';
import { SUPREME_CARDS_QUERY_KEY, getSupremeCards } from '@/queries/supreme-cards';
import { Image } from '../../image/image';
import { Button } from '../../typography/button/button';
import { useLocalization } from '@/hooks/use-localization';

export interface CardComparisonLegacyProps {
    title: string;
    index: number;
    feeLabel: string;
    newLayout?: boolean;
    chooseCardTitle: string;
    cardComparisonData: CardComparisonData[];
}

interface CardComparisonData {
    subtext: string;
    title: string;
    cards: Card[];
}

interface Card {
    name: string;
    slug: string;
    id: number;
}

export const CardComparisonLegacy = ({ title, chooseCardTitle, cardComparisonData }: CardComparisonLegacyProps) => {
    const context = useContext(AppContext);
    const { getCurrencySymbol } = useLocalization();
    const {
        supremeCards: { data },
    } = useSupremeCards();

    useBeforeMount(() => {
        context.prefetch({ queryKey: [SUPREME_CARDS_QUERY_KEY], queryFn: () => getSupremeCards(context) });
    });

    if (!data) {
        return null;
    }

    return (
        <div data-component="card-comparison" className="r-flex r-flex-col">
            <h1 className="r-text-3xl r-font-bold lg:r-text-5xl">{title}</h1>

            {/* Card images shown on desktop */}
            <div className="r-hidden lg:r-flex">
                <div className="r-flex lg:r-w-[30%] lg:r-shrink-0 lg:r-grow-0 lg:r-basis-auto "></div>
                {data
                    .sort((a, b) => a.title.localeCompare(b.title))
                    .map((card, index) => (
                        <div key={index} className="r-flex r-flex-1 r-flex-col r-items-center">
                            <Image src={card.image.src} altText={card.image.alt_text} height={90} width={90} noShadow size="100xAUTO" />
                            <p className="r-font-bold">{card.title}</p>
                        </div>
                    ))}
            </div>

            {/* Markers description */}
            <div className="r-flex r-space-x-4 r-py-2 lg:r-hidden">
                <div className="r-flex r-space-x-4 r-py-6 lg:r-hidden">
                    {data.map((card, index) => {
                        return (
                            <div key={index} className="r-flex r-items-center r-space-x-1">
                                <div className={`r-h-5 r-w-5 r-rounded-full`} style={{ backgroundColor: card.color }}></div>
                                <p className="r-text-xs r-font-semibold">{card.post_title}</p>
                            </div>
                        );
                    })}
                </div>
            </div>

            {/* Rows container */}
            <div className="r-flex r-flex-col">
                {cardComparisonData.map((cardComparisonData, index) => {
                    return (
                        <ComparisonRow
                            key={index}
                            cards={cardComparisonData.cards}
                            supremeCards={data}
                            title={cardComparisonData.title}
                            subtext={cardComparisonData.subtext}
                        />
                    );
                })}
            </div>

            {/* Fee row */}
            <div className="r-hidden r-flex-col lg:r-flex">
                <div className="r-flex r-items-center r-justify-between r-border-b-2 r-py-4">
                    <div className="r-flex r-flex-1 r-flex-col lg:r-w-[30%] lg:r-shrink-0 lg:r-grow-0 lg:r-basis-auto lg:r-flex-col">
                        <p className="r-hidden r-font-bold lg:r-block">{'Avgift'}</p>
                    </div>

                    <div className="r-flex r-grow r-items-center r-justify-end ">
                        {[...data]
                            .sort((a, b) => a.title.localeCompare(b.title))
                            .map((card, index) => {
                                return (
                                    <div key={index} className="r-flex r-grow r-justify-center">
                                        {`${card.fee.price} ${getCurrencySymbol()}/${card.fee.period}`}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>

            {/* Choose card buttons */}
            <div className="r-mt-4 r-hidden lg:r-flex">
                <div className="r-flex-grow-3"></div>
                <div className="r-flex-grow-3"></div>
                {data.map((card, index) => {
                    return (
                        <Button to={card.overview_link.url} className="r-mx-2 r-grow last:r-mr-0" key={index} fullWidth size="small">
                            {card.comparison_link.title}
                        </Button>
                    );
                })}
            </div>

            {/* Card description container. Hidden on desktop. */}
            <div className="r-mt-8 r-flex r-flex-col lg:r-hidden">
                <h2 className="r-mb-4 r-text-3xl r-font-bold">{chooseCardTitle}</h2>
                {data.map((card, index) => {
                    return <CardDescription card={card} key={index} />;
                })}
            </div>
        </div>
    );
};
