import { CustomSelect, CustomSelectProps } from './custom-select';

type SelectProps = Omit<CustomSelectProps, 'options'> & {
    options?: {
        value: string | number;
        label: string;
    }[];
};

export const Select = ({ options, ...props }: SelectProps) => (
    <CustomSelect {...props} options={options ? options.map(({ value, label: name }) => ({ value, name })) : []} />
);
