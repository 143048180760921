type response = {
    values: {
        amount: string;
        months: string;
        monthly_installment: string;
        variable_debtor_interest: string;
        apr: string;
        total_cost: string;
        total_pay: string;
        initial_fee: string;
        variableInterest: string;
    };
};

export type clusterCalculatorData = {
    amount: string;
    mdr: string;
    monthlyFee: string;
    interestRate: string;
    openingFee: string;
    minOpeningFee: string;
    maxOpeningFee: string;
};

export const getClusterCalculatorData = async ({
    amount,
    mdr,
    monthlyFee,
    interestRate,
    openingFee,
    minOpeningFee,
    maxOpeningFee,
}: clusterCalculatorData) => {
    try {
        const host = typeof window === 'undefined' ? 'http://localhost' : '';
        const results = await fetch(
            `${host}/api/cluster-calculator/${amount}/${mdr}/${interestRate}/${monthlyFee}/${openingFee}/${minOpeningFee}/${maxOpeningFee}`
        );
        const { values } = (await results.json()) as response;

        if (!values) {
            throw new Error('getClusterCalculatorData' + ' fetch error');
        }
        return values;
    } catch (e: unknown) {
        console.error(e);
        throw e;
    }
};
