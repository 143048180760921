import { ImageSizes } from '@/isomorphic/components/image/image';
import { removeEnclosingSlashes } from './strings';

export function getImageUrl(url?: string, size: ImageSizes = '1200xAUTO') {
    if (!url) {
        return '';
    }

    if (url.startsWith('http')) {
        return url;
    }

    const localUrl = removeEnclosingSlashes(url);

    return `/image/${size}/${localUrl}`;
}
