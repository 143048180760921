export const Wave = () => {
    return (
        <svg viewBox="0 0 1440 300" xmlns="http://www.w3.org/2000/svg">
            <g fillRule="nonzero" fill="none">
                <path d="M0 0v300h1440V0c-215.16 60.123-460.111 94.14-720 94.14S215.156 60.123 0 0Z" fill="#003C3C" />
                <path
                    d="M0 266.596V300h1439.87V0a2986.72 2986.72 0 0 1-5.91 3.123c-283.97 169.756-697.132 276.63-1157.062 276.63-94.512 0-187.05-4.513-276.898-13.157Z"
                    fill="#015050"
                />
            </g>
        </svg>
    );
};
