/// <reference types="@types/google.analytics" />

import { useGlobalQueries } from './use-global-queries';
import { setDataLayer } from './use-gtm';

export type Variant = {
    name: string;
    id: string;
    winner?: boolean;
};

type AbTest = {
    uuid: string;
    name: string;
    startDate: Date;
    endDate: Date;
    goalType: string;
    goalPageID: number;
    goalPageTitle: string;
    testPurpose: string;
    exposurePercentage: number;
    variants: Variant[];
    status: string;
    targetGroup: string;
    market: number;
    createdAt: Date;
    updatedAt: Date;
};

type Conversion = {
    abTestUuid: string;
    abTestName: string;
    variant: string;
    pageId: number;
    convertedThisSession?: boolean;
};

type Assignment = Conversion & {
    participant: number;
};

export type DecoratedData = {
    abTests: {
        userAssignments?: Assignment[];
        testsWithPageAsGoal?: AbTest[];
        possibleConversions?: Conversion[];
    };
};

type QueueItem = { hitType: string; eventCategory: string; eventAction: string; eventLabel: string };

declare global {
    interface Window {
        abFirst: DecoratedData['abTests'] & {
            queue: QueueItem[];
        };
        google_tag_manager: Record<string | number, unknown>;
    }
}

export const sendAsGoal = (uuid: string, eventAction?: string) => {
    const assignment = window.abFirst?.userAssignments?.find(variant => variant.abTestUuid === uuid);

    if (assignment?.participant) {
        setDataLayer({
            event: 'ab_first',
            eventInfo: {
                type: assignment.abTestName,
                decision: `${assignment.variant}:${eventAction ? eventAction : 'converted'}`,
            },
        });
    }
};

export const checkConversion = (previousPageId: number | null) => {
    if (typeof window === 'undefined' || !previousPageId || !window.abFirst?.possibleConversions?.length) {
        return;
    }

    window.abFirst.possibleConversions.forEach(possibleConversion => {
        if (!possibleConversion.convertedThisSession && possibleConversion.pageId === previousPageId) {
            setDataLayer({
                event: 'ab_first',
                eventInfo: {
                    type: possibleConversion.abTestName,
                    decision: `${possibleConversion.variant}:converted`,
                },
            });
            possibleConversion.convertedThisSession = true;
        }
    });
};

export function useAbTests() {
    const { pageQuery } = useGlobalQueries();

    function setWindowData() {
        const decorated = pageQuery.data?.decorated ?? null;
        if (typeof window === 'undefined' || !decorated?.abTests) {
            return;
        }

        window.abFirst = window?.abFirst ?? {};

        if (decorated.abTests.userAssignments) {
            window.abFirst.userAssignments = [...new Set([...(window.abFirst.userAssignments || []), ...decorated.abTests.userAssignments])];
        }

        if (decorated.abTests.testsWithPageAsGoal) {
            window.abFirst.testsWithPageAsGoal = [
                ...new Set([...(window.abFirst.testsWithPageAsGoal || []), ...decorated.abTests.testsWithPageAsGoal]),
            ];
        }

        if (decorated.abTests.possibleConversions) {
            window.abFirst.possibleConversions = [
                ...new Set([...(window.abFirst.possibleConversions || []), ...decorated.abTests.possibleConversions]),
            ];
        }
    }

    return {
        setWindowData,
    };
}
