interface Card {
    name: string;
    slug: string;
    id: number;
    color?: string; // Add color property to the Card interface
}

interface SupremeCard {
    title: string;
    color: string;
}

type ComparisonRowProps = {
    title: string;
    subtext: string;
    cards: Card[];
    supremeCards: SupremeCard[];
};

export const ComparisonRow = ({ title, subtext, cards, supremeCards }: ComparisonRowProps) => {
    const cardTypesDynamic = supremeCards.map(supremeCard => supremeCard.title.toLowerCase());

    const getColor = (name: string) => {
        const supremeCard = supremeCards.find(supremeCard => supremeCard.title.toLowerCase() === name);
        return supremeCard ? supremeCard.color : '';
    };

    return (
        <div data-component="comparison-row" className="r-flex r-items-center r-justify-between r-border-b-2 r-py-4">
            <div className="r-flex r-flex-1 r-flex-col lg:r-flex lg:r-w-[30%] lg:r-shrink-0 lg:r-grow-0 lg:r-basis-auto lg:r-flex-col">
                <p className="r-text-xs lg:r-hidden">{`${title}${subtext ? ` (${subtext})` : ''}`}</p>
                <p className="r-hidden r-font-bold lg:r-block">{title}</p>
                <p className="r-hidden r-text-xs lg:r-block">{subtext}</p>
            </div>

            <div className="r-flex r-grow r-items-center r-justify-end r-space-x-2 lg:r-space-x-0">
                {cardTypesDynamic.map(cardType => {
                    return cards.find(card => card.slug === cardType) ? (
                        <div key={cardType} className="r-flex lg:r-grow lg:r-justify-center">
                            <div className="r-h-5 r-w-5 r-rounded-full" style={{ backgroundColor: getColor(cardType) }}></div>
                        </div>
                    ) : (
                        <div key={cardType} className="r-flex lg:r-grow lg:r-justify-center">
                            <div className="r-h-5 r-w-5 r-rounded-full "></div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
