import { useQuery } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { COOKIE_CONSENT_QUERY_KEY, getCookies } from '@/queries/cookie-consent';
import { AppContext } from '@/contexts/app-context';

export function useCookies() {
    const context = useContext(AppContext);
    const location = useLocation();
    const [URLSearchParams] = useSearchParams();
    const COOKIE_PAGE = '/cookie-modal';
    const [isOpen, setIsOpen] = useState(false);

    const isEnabled = () => {
        // If we have a GET parameter OK , we have just now before this page load
        // set the cookies. So do not fetch data in that case.
        const cookiesJustSet = URLSearchParams.get('cookies_set') === 'OK';
        if (cookiesJustSet) {
            return false;
        }

        return typeof document !== 'undefined'
            ? !document.cookie.match(/^(.*;)?\s*cookie_consent\s*=\s*[^;]+(.*)?$/) || location.pathname === COOKIE_PAGE
            : false;
    };

    const cookiesData = useQuery({
        queryKey: [COOKIE_CONSENT_QUERY_KEY],
        queryFn: () => getCookies(context),
        enabled: isOpen || isEnabled(),
    });

    return {
        cookiesData,
        COOKIE_PAGE,
        isOpen,
        setIsOpen,
    };
}
