import { useMemo, useState } from 'react';
import { AddressType, CustomerType, OrganizationType, PersonLookupType, SearchResult } from '../types';
import { useLocalization } from '@/hooks/use-localization';
import { KycSurvey } from '@/queries/kyc-survey';

export const useCorporateData = () => {
    const { getCountry, localize } = useLocalization();
    const [hasError, setHasError] = useState<string | null>(null);
    const [isLoadingCompanyInfo, setIsLoadingCompanyInfo] = useState(false);
    const [isLoadingCorporateReg, setIsLoadingCorporateReg] = useState(false);
    const [isLoadingApplicantInfo, setIsLoadingApplicantInfo] = useState(false);
    const [isLoadingCorporateRegistryApplications, setIsLoadingCorporateRegistryApplications] = useState(false);
    const [isLoadingKyc, setIsLoadingKyc] = useState(false);

    const isFetchingData = useMemo(
        () => isLoadingCompanyInfo || isLoadingCorporateReg || isLoadingKyc,
        [isLoadingCompanyInfo, isLoadingCorporateReg, isLoadingKyc]
    );

    const request = (endpoint: string, body: object, method: 'POST' | 'PUT' = 'POST') =>
        fetch(`/api/bank/${endpoint}`, {
            headers: {
                'content-type': 'application/json',
                credentials: 'include',
            },
            method,
            body: JSON.stringify(body),
        });

    const post = (endpoint: string, body: object) => request(endpoint, body);
    const put = (endpoint: string, body: object) => request(endpoint, body, 'PUT');

    function getErrorMessage(error: unknown) {
        if (error instanceof Error) {
            return error.message;
        }

        return String(error) ?? localize('error-generic');
    }

    const getCompanyInformation = async (govId: string) => {
        if (isLoadingCompanyInfo) {
            return;
        }

        try {
            setIsLoadingCompanyInfo(true);
            const request = await post('govid', {
                governmentId: govId.replace(/\D/g, ''),
                customerType: CustomerType.LEGAL,
                countryCode: getCountry().toLocaleUpperCase(),
            });
            setIsLoadingCompanyInfo(false);

            const response = (await request.json()) as {
                status?: string;
                response?: AddressType;
            };

            if (response?.status !== 'ok') {
                throw new Error('govid error');
            }

            return (response?.status === 'ok' && response?.response) || null;
        } catch (error) {
            console.error(error);
            setHasError(getErrorMessage(error));
            return null;
        }
    };

    const getApplicantInformation = async (govId: string) => {
        if (isLoadingApplicantInfo) {
            return;
        }

        try {
            setIsLoadingApplicantInfo(true);
            const request = await post('govid', {
                governmentId: govId.replace(/\D/g, ''),
                customerType: CustomerType.NATURAL,
                countryCode: getCountry().toLocaleUpperCase(),
            });
            setIsLoadingApplicantInfo(false);

            const response = (await request.json()) as {
                status?: string;
                response?: PersonLookupType;
            };

            if (response?.status !== 'ok') {
                throw new Error('govid error');
            }

            return (response?.status === 'ok' && response?.response) || null;
        } catch (error) {
            console.error(error);
            // setHasError(getErrorMessage(error));
            return null;
        }
    };

    async function getCorporateRegistryDetails(govId: string) {
        if (isLoadingCorporateReg) {
            return;
        }

        try {
            setIsLoadingCorporateReg(true);
            const request = await post('corporate-registry-service/roles/search', {
                governmentId: {
                    id: govId.replace(/\D/g, ''),
                    customerType: CustomerType.LEGAL,
                    countryCode: getCountry().toLocaleUpperCase(),
                },
            });
            setIsLoadingCorporateReg(false);

            const response = (await request.json()) as {
                response?: OrganizationType;
                status?: string;
            };

            if (response?.status !== 'ok') {
                throw new Error('corporate-registry-service/roles/search error');
            }

            return (response?.status === 'ok' && response?.response) || null;
        } catch (error) {
            console.error(error);
            setHasError(getErrorMessage(error));
            return null;
        }
    }

    async function searchCorporateRegistryApplications(govId: string) {
        if (isLoadingCorporateRegistryApplications) {
            return;
        }

        try {
            setIsLoadingCorporateRegistryApplications(true);
            const request = await put('corporate-registry-service/applications/search', {
                governmentId: govId.replace(/\D/g, ''),
                countryCode: getCountry().toLocaleUpperCase(),
            });
            setIsLoadingCorporateRegistryApplications(false);

            if (request.status === 404) {
                return SearchResult.APPLICATION_NOT_FOUND;
            }

            return SearchResult.APPLICATION_FOUND;
        } catch (error) {
            console.error(error);
            setHasError(getErrorMessage(error));
            return null;
        }
    }

    async function getKycSurveyData() {
        if (isLoadingKyc) {
            return;
        }

        try {
            setIsLoadingKyc(true);
            const apiResponse = await fetch('/api/bank/kyc', {
                headers: {
                    'content-type': 'application/json',
                    credentials: 'include',
                },
            });
            setIsLoadingKyc(false);
            const response = (await apiResponse.json()) as {
                response?: KycSurvey;
                status?: string;
            };

            if (response?.status !== 'ok') {
                throw new Error('kyc error');
            }

            return (response?.status === 'ok' && response?.response) || null;
        } catch (error) {
            console.error(error);
            setHasError(getErrorMessage(error));
            return null;
        }
    }

    return {
        hasDataFetchError: hasError,
        isFetchingData,
        getKycSurveyData,
        getCompanyInformation,
        getApplicantInformation,
        getCorporateRegistryDetails,
        searchCorporateRegistryApplications,
    };
};
