export type Level = 1 | 2 | 3 | 4 | 5 | 6;
export type HeaderSize = 0.8 | 1 | 1.5; // Small | Medium | Large in WP
export type Margin = 'none' | 'bottom' | 'top' | 'topAndBottom';
export type Size = 'small' | 'medium' | 'large';
export type ContentPadding = 'none' | 'tiny' | 'smaller' | 'small' | 'base' | 'medium' | 'large' | 'xlarge' | 'indent';

export function getHeaderFontSize(level: Level, size: HeaderSize) {
    switch (`${level}/${size}`) {
        case '4/0.8':
        case '5/0.8':
        case '6/0.8':
            return 'r-text-l';
        case '3/0.8':
        case '6/1':
            return 'r-text-1xl';
        case '2/0.8':
        case '3/1':
        case '6/1.5':
            return 'r-text-2xl';
        case '1/0.8':
        case '2/1':
        case '4/1.5':
        case '5/1.5':
            return 'r-text-3xl';
        case '1/1':
        case '3/1.5':
            return 'r-text-4xl';
        case '2/1.5':
            return 'r-text-5xl r-leading-[1.1]';
        case '1/1.5':
            return 'r-text-6xl r-leading-[1.2]';
        case '4/1':
        case '5/1':
            return 'r-text-[20px]';
    }

    return '';
}

export function getMargin(margin: Margin, marginSize: Size) {
    if (margin === 'none') {
        return '';
    }

    switch (`${margin}/${marginSize}`) {
        case 'top/small':
            return 'r-mt-2';
        case 'top/medium':
            return 'r-mt-3';
        case 'top/large':
            return 'r-mt-4';
        case 'bottom/small':
            return 'r-mb-2';
        case 'bottom/medium':
            return 'r-mb-3';
        case 'bottom/large':
            return 'r-mb-4';
        case 'topAndBottom/small':
            return 'r-my-2';
        case 'topAndBottom/medium':
            return 'r-my-3';
        case 'topAndBottom/large':
            return 'r-my-4';
    }

    return '';
}

export function getParagraphPadding(padding: ContentPadding) {
    switch (padding) {
        case 'tiny':
            return 'r-p-1';
        case 'smaller':
            return 'r-p-2';
        case 'small':
            return 'r-p-3';
        case 'base':
            return 'r-p-4';
        case 'medium':
            return 'r-p-6';
        case 'large':
            return 'r-p-8';
        case 'xlarge':
            return 'r-p-12';
        case 'indent':
            return 'r-p-4';
    }

    return '';
}
