import { ApiFetchType } from '@/server/api-fetch';

export type UrlVersion = {
    document_url: string;
    document_url_date: number;
};

export type DocumentItem = {
    id: number;
    title: string;
    ID: number;
    name: string;
    description: string;
    url_versions: UrlVersion[];
    type: string;
    guid: number;
};

export type DocumentProps = {
    categoriesToDisplay: string[];
    handlerId: string;
};

export const DOCUMENT_QUERY_KEY = 'document-query';
export const getDocument = (context: ApiFetchType, options: DocumentProps) => {
    return context?.fetch
        ? context.fetch<DocumentItem[]>(`api/document?handlerId=${options.handlerId}&categoriesToDisplay=${options.categoriesToDisplay.join(',')}`)
        : null;
};
