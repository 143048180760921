import { useEffect } from 'react';
import { useLogout } from '@/hooks/use-logout';
import { DynamicModuleGenerator } from '@/isomorphic/components/dynamic-module-generator/dynamic-module-generator';
import { BlockBase } from '@/queries/page';

export const StepThree = ({ blocks = [] }: { blocks: BlockBase[] }) => {
    const { logout } = useLogout();
    let timeout: NodeJS.Timeout | null = null;

    useEffect(() => {
        timeout = setTimeout(() => logout(), 4000);

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, []);

    return (
        <div className="r-mb-8">
            <DynamicModuleGenerator blocks={blocks} />
        </div>
    );
};
