import { Link } from 'react-router-dom';
import { getImageUrl } from '@/utils/images';
import { BlockBase } from '@/queries/page';
import { AppContextType } from '@/contexts/app-context';
import { Image as ImageType } from './jula-account-verification-types';
import { DynamicModuleGenerator } from '../../dynamic-module-generator/dynamic-module-generator';
import { Button } from '../../typography/button/button';
import { LoginOverlay } from '../../login-overlay/login-overlay';

type StepOneProps = {
    country: AppContextType['country'];
    loginButtonLabel: string;
    mobileLoginPageImage: ImageType;
    desktopLoginPageImage: ImageType;
    loginPageInnerBlocks: BlockBase[];
    gdprText: string;
    gdprLink: {
        url: string;
        title: string;
        target?: string;
        rel?: string;
    };
    handleLogin: () => void;
};

export const StepOne = (props: StepOneProps) => (
    <div data-component="step-one">
        <LoginOverlay
            mobileImage={getImageUrl(props.mobileLoginPageImage.src, '750xAUTO')}
            desktopImage={getImageUrl(props.mobileLoginPageImage.src, '1920xAUTO')}
        >
            <div className="[&_strong]:r-text-primary-200">
                <DynamicModuleGenerator blocks={props.loginPageInnerBlocks} />
                <div className="r-mb-6 r-mt-4">
                    <Button
                        variant={props.country === 'se' ? 'bankidwhite' : 'bankidnorwaywhite'}
                        fullWidth={true}
                        onClick={() => {
                            props.handleLogin();
                        }}
                    >
                        {props.loginButtonLabel}
                    </Button>
                    <div className="r-mt-6 r-text-sm">
                        <p>
                            {props.gdprText}{' '}
                            <span className="r-font-bold r-text-primary-200 r-underline">
                                <Link target="_blank" to={props.gdprLink.url}>
                                    {props.gdprLink.title || 'Resurs och GDPR'}
                                </Link>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </LoginOverlay>
    </div>
);
