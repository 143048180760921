import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useBeforeMount } from '@24hr/react-hooks';
import { Image } from '../../image/image';
import { Terms } from './components/terms';
import { Button } from '../../typography/button/button';
import { useInsights } from '@/hooks/use-insights';
import { INSIGHTS_QUERY_KEY, getInsights } from '@/queries/insights';
import { AppContext } from '@/contexts/app-context';
import { useLocalization } from '@/hooks/use-localization';
import { getImageUrl } from '@/utils/images';

export const Insights = () => {
    const context = useContext(AppContext);
    const { localize } = useLocalization();
    const { pathname, search } = useLocation();
    const { getPaginatedInsights, insightsSettings, hasMore, loadMore, getTermMessage } = useInsights();
    const [termMessage, setTermMessage] = useState(getTermMessage());

    useBeforeMount(() => {
        context.prefetch({ queryKey: [INSIGHTS_QUERY_KEY], queryFn: () => getInsights(context) });
    });

    useEffect(() => {
        setTermMessage(getTermMessage());
    }, [pathname, search]);

    const rootSlug = () => insightsSettings.data?.slug.replace(/\/$/, '');

    return (
        <div data-component="insights">
            <div className="r-grid r-gap-y-16 md:r-grid-cols-2 md:r-gap-x-8 lg:r-grid-cols-3">
                {getPaginatedInsights().map(({ id, image, title, preamble, terms = [], slug }, index) => (
                    <div className="max-md:r-col-span-2" key={id} style={{ order: index + 1 }}>
                        <Link to={`${rootSlug()}/${slug}`}>
                            {image ? (
                                <Image src={image.src} width={image.originalSize.width} height={image.originalSize.height} size="500xAUTO" />
                            ) : null}
                            <h3 className="r-mb-4 r-text-2xl r-font-bold">{title}</h3>
                            <div className="r-mb-4" dangerouslySetInnerHTML={{ __html: preamble }} />
                        </Link>
                        <Terms terms={terms} />
                    </div>
                ))}
                {termMessage ? (
                    <div
                        className="r-order-3 r-col-span-2 r-rounded-2xl r-bg-cover r-bg-center r-bg-no-repeat r-p-4 r-shadow-lg md:r-min-h-96 md:r-p-12 md:max-lg:r-order-4 lg:r-p-16"
                        style={{
                            backgroundImage: `url(${getImageUrl(termMessage.image.src, '500xAUTO')})`,
                        }}
                    >
                        <h4 className="r-mb-16 r-max-w-md r-text-3xl r-font-bold r-text-white md:r-text-4xl">{termMessage.message}</h4>
                        <Button to={termMessage.link.url} target={termMessage.link.target ?? '_self'}>
                            {termMessage.link.title}
                        </Button>
                    </div>
                ) : null}
            </div>

            <div className="r-flex r-justify-center r-pb-4 r-pt-16">
                {hasMore() ? (
                    <Button type="secondaryBorder" onClick={loadMore}>
                        {localize('load-more')}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};
