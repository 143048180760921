import { useContext } from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { AppContext } from '@/contexts/app-context';
import { SEARCH_QUERY_KEY, searchQuery } from '@/queries/search';

export function useSearch({ searchValue, page }: { searchValue: string; page: number }) {
    const context = useContext(AppContext);

    const searchResults = useQuery({
        queryKey: [SEARCH_QUERY_KEY, searchValue, page],
        queryFn: () => searchQuery(searchValue, page, context),
        placeholderData: keepPreviousData,
        enabled: !!searchValue,
    });

    return {
        searchResults,
    };
}
