import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { Image } from '../../image/image';

export type ArticleCardProps = {
    height: number;
    width: number;
    sourceUrl: string;
    altText: null | string;
    parentProps: {
        centerButtons: boolean;
        extraButton: boolean;
        extraButtonLink: {
            url: string;
            title: string;
            rel: string;
        };
        mobileCollapse: boolean;
    };
};

export const ArticleCard = ({ children, ...props }: PropsWithChildren<ArticleCardProps>) => (
    <div
        data-component="article-card"
        className={clsx(
            'r-w-full r-rounded-2xl r-bg-opacity-90 r-p-4 max-md:r-flex md:r-flex-col md:r-bg-white md:r-bg-opacity-90 md:r-pb-8 md:r-shadow-lg',
            {
                'md:r-pt-8': props.sourceUrl,
                'r-bg-white r-shadow-lg': !props.parentProps.mobileCollapse,
            }
        )}
    >
        {props.sourceUrl ? (
            <div className="r-mr-4 r-flex r-w-16 r-shrink-0 r-justify-center md:r-mx-auto md:r-w-24 md:r-pb-4">
                <Image
                    noShadow
                    noMargin
                    src={props.sourceUrl}
                    altText={props.altText || ''}
                    width={props.width}
                    height={props.height}
                    size="225xAUTO"
                />
            </div>
        ) : null}
        <div
            className={clsx('r-flex r-flex-grow r-flex-col r-justify-center', {
                '[&>.resurs-button]:md:r-mx-auto': props.parentProps.centerButtons,
                'max-sm:r-pt-3': props.sourceUrl,
            })}
        >
            {children}
        </div>
    </div>
);
