import { useEffect, useState } from 'react';
import { useLocalization } from '@/hooks/use-localization';
import { CountryType, CustomerType, FieldValuesType } from '../types';
import { Steps } from '../components/steps/step-layout';

export const SESSION_STORAGE_KEY = 'businessFormValues';

export const useValues = () => {
    const { getCountry, getLanguage } = useLocalization();

    const getLanguageCode = () => {
        const lang = getLanguage();
        return lang == 'no' ? 'nb' : lang;
    };

    const fieldValues: FieldValuesType = {
        countryCode: getCountry().toLocaleUpperCase() as CountryType,
        languageCode: getLanguageCode(),
        productCode: 'X0082083',
        productType: 'DEPOSIT',
        company: {
            governmentId: {
                id: '',
                customerType: CustomerType.LEGAL,
                countryCode: getCountry().toLocaleUpperCase() as CountryType,
            },
            organizationName: '',
            organizationType: '',
            email: '',
            phoneNumber: '',
            address: {
                street: '',
                city: '',
                zipCode: '',
                countryCode: getCountry().toLocaleUpperCase() as CountryType,
            },
        },
        applicant: {
            name: {
                first: '',
                last: '',
            },
            governmentId: {
                id: '',
                customerType: CustomerType.NATURAL,
                countryCode: getCountry().toLocaleUpperCase() as CountryType,
            },
            email: '',
            phoneNumber: '',
        },
        payoutAccount: {
            accountHolderBank: '',
            clearingNumber: '',
            accountNumber: '',
        },
        // Verklig huvudman. NB We have to set this data from the KYC values
        // so it's not updated in the application, rather we get it by checking
        // against the KYC 'OWNERSHIP_AND_CONTROL' fields
        ownerships: [],
        // Nyckelpersoner
        keyRoles: [],
        allKeyRoles: [],
        // Undertecknare
        signatoryCombinations: [],
    };

    const [step, setStep] = useState<Steps>(0);
    const [values, setValues] = useState(fieldValues);
    const [manualApplicationOnly, setManualApplicationOnly] = useState(false);

    const getValuesFromStorage = () => {
        const session = sessionStorage?.getItem(SESSION_STORAGE_KEY);
        if (!session || typeof session !== 'string') {
            return null;
        }

        return JSON.parse(session) ?? null;
    };

    useEffect(() => {
        const parsedValues = getValuesFromStorage();
        if (parsedValues) {
            setValues(parsedValues);
        }
    }, []);

    useEffect(() => {
        // Save to session storage every time values change
        sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(values));
    }, [values]);

    return {
        values,
        setValues,
        step,
        setStep,
        getValuesFromStorage,
        manualApplicationOnly,
        setManualApplicationOnly,
    };
};
