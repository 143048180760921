import { ChangeEvent } from 'react';

type SelectOption = {
    value: string;
    text: string;
};

type SelectInputProps = {
    name: string;
    value: string;
    handleChange: (event: ChangeEvent<HTMLSelectElement>) => void;
    options: SelectOption[];
};

export const SelectInput = ({ name, value, handleChange, options }: SelectInputProps) => (
    <select name={name} id={`${name}-select`} className="r-h-10 r-w-full r-border md:r-w-48" onChange={handleChange} value={value || 'All'}>
        {options.map(option => (
            <option key={option.value} value={option.value}>
                {option.text}
            </option>
        ))}
    </select>
);
