import { Link } from 'react-router-dom';
import { InsightCategoryType } from '@/queries/insights';
import { useInsights } from '@/hooks/use-insights';
import { Button } from '@/isomorphic/components/typography/button/button';

export type AllTermsProps = {
    labels: {
        title?: string;
        showAllButtonText: string;
    };
};

export const AllTerms = ({ labels }: AllTermsProps) => {
    const { insightsSettings, getSortedTerms, insightCategoriesData } = useInsights();
    const rootSlug = () => insightsSettings.data?.slug.replace(/\/$/, '');

    const { privateTerms, corporateTerms } = getSortedTerms();

    const terms = (group: 'private' | 'corporate' = 'private') => {
        const terms = group === 'private' ? privateTerms : corporateTerms;
        return insightCategoriesData?.data?.filter(({ id }) => terms.has(id))?.sort((a, b) => a.title.localeCompare(b.title)) ?? [];
    };

    const renderTerm = ({ id, slug, title }: InsightCategoryType, group: 'private' | 'corporate' = 'private') => (
        <Link
            key={id}
            to={`${rootSlug()}/${slug}?group=${group}`}
            className="r-rounded-md r-border r-border-secondary r-p-4 r-font-bold r-text-secondary"
        >
            {title}
        </Link>
    );

    return (
        <div data-component="all-terms" className="r-container r-mb-8 r-px-4 r-py-8">
            {labels?.title ? <h3 className="r-pb-6 r-text-3xl r-font-bold">{labels.title}</h3> : null}
            <div className="r-flex r-max-w-3xl r-flex-wrap r-gap-4 r-pb-6">
                {terms().map(term => renderTerm(term))}
                {terms('corporate').map(term => renderTerm(term, 'corporate'))}
            </div>
            <Button to={rootSlug()}>{labels.showAllButtonText}</Button>
        </div>
    );
};
