import { useLocalization } from '@/hooks/use-localization';
import { AllContactMeFormsProps } from '@/isomorphic/components/wp-blocks/contact-form/contact-form';
import { FieldProps } from '@/isomorphic/components/forms/components/field';

export function useForm() {
    const { localize } = useLocalization();

    const formatName = (name: string) => {
        let translatedName = localize(name);

        if (name === 'newsletter') {
            translatedName = localize('newsletter-sign-up-phrase');
        } else if (name === 'optIn') {
            translatedName = localize('opt-in');
        }
        return `${translatedName.charAt(0).toUpperCase()}${translatedName.substring(1)}`;
    };

    // Since our current WP block does not support the ability to rearrange fields, we need to do it here. It's a quickfix, but it works.
    const rearrangeFields = (enabledFields: string[]) => {
        // Separate checkbox fields
        const checkboxFields = ['optIn', 'newsletter'];
        const nonCheckboxFields = enabledFields.filter(field => !checkboxFields.includes(field));
        const fieldsWithCheckboxes = enabledFields.filter(field => checkboxFields.includes(field));

        // move socialSecurityNumber to the top
        const ssnIndex = nonCheckboxFields.indexOf('socialSecurityNumber');
        if (ssnIndex >= 0) {
            nonCheckboxFields.unshift(nonCheckboxFields.splice(ssnIndex, 1)[0]);
        }

        // then move products to the top...
        const productsIndex = nonCheckboxFields.indexOf('products');
        if (productsIndex >= 0) {
            nonCheckboxFields.unshift(nonCheckboxFields.splice(productsIndex, 1)[0]);
        }

        // if accountNumberIndex is higher than genericTextIndex, set genericTextIndex one higher than accountNumberIndex
        const accountNumberIndex = nonCheckboxFields.indexOf('accountNumber');
        const genericTextIndex = nonCheckboxFields.indexOf('genericText');

        if (accountNumberIndex >= 0 && genericTextIndex >= 0 && accountNumberIndex > genericTextIndex) {
            nonCheckboxFields.splice(genericTextIndex, 0, nonCheckboxFields.splice(accountNumberIndex, 1)[0]);
        }

        // Append checkbox fields at the end
        return [...nonCheckboxFields, ...fieldsWithCheckboxes];
    };

    const parsePropsToFieldObjects = (props: AllContactMeFormsProps) => {
        const enabledFields = Object.keys(props)
            .filter(prop => prop.includes('Enable'))
            .filter(key => props[key as keyof typeof props])
            .map(key => key.split('Enable')[0]);

        return rearrangeFields(enabledFields).map(field => {
            const objects = Object.fromEntries(
                Object.keys(props)
                    .filter(key => key.includes(field))
                    .map(key => {
                        const newKey = key.split(field)[1];
                        const formattedKey = `${newKey.charAt(0).toLowerCase()}${newKey.substring(1)}`;
                        return [formattedKey, props[key as keyof typeof props]];
                    })
            );

            const isRequired = props[`${field}Required` as keyof typeof props] === true;
            let label = localize(field);
            let placeholder = '';

            let type = 'input';
            let dropText = '';
            switch (field) {
                case 'genericText':
                    type = 'input';
                    if (props[`${field}Label`]) {
                        label = props[`${field}Label`];
                    }
                    break;
                case 'optIn':
                    type = 'checkbox';
                    label = formatName(field);
                    break;
                case 'newsletter':
                    type = 'checkbox';
                    label = formatName(field);
                    break;
                case 'products':
                    type = 'select';
                    if (props[`${field}Label` as keyof typeof props] === true) {
                        label = props[`${field}Label`];
                    }
                    if (props[`${field}DefaultSelectionText`]) {
                        placeholder = props[`${field}DefaultSelectionText`];
                    }
                    break;
                case 'message':
                    type = 'textarea';
                    break;
                case 'files':
                    type = 'file';
                    if (props[`${field}DropText`]) {
                        dropText = props[`${field}DropText`];
                    }
                    break;
                case 'phone':
                    type = 'input';
            }

            return {
                fieldName: field,
                dropText,
                placeholder,
                type,
                label,
                required: isRequired,
                ...objects,
            } as FieldProps & { dropText: string };
        });
    };

    return {
        parsePropsToFieldObjects,
    };
}
