import { useContext } from 'react';
import { useSupremeCards } from '@/hooks/use-supreme-cards';
import { AppContext } from '@/contexts/app-context';

export const CardTerms = () => {
    const { supremeCards } = useSupremeCards();
    const { supremeCardApplication } = useContext(AppContext);

    const currentCard = supremeCards.data?.find(({ api_name: apiName }) => apiName === supremeCardApplication.selectedCard);
    if (!currentCard) {
        return null;
    }

    const { general_terms: generalTerms, sekki } = currentCard?.documents || {};

    return (
        <div data-component="terms" className="r-flex r-flex-col r-space-y-2 r-text-red-800">
            {generalTerms && (
                <a target="_blank" href={generalTerms.url}>
                    {generalTerms.title || generalTerms.url}
                </a>
            )}
            {sekki && (
                <a target="_blank" href={sekki.url}>
                    {sekki.title || sekki.url}
                </a>
            )}
        </div>
    );
};
