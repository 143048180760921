import { useMemo, useState } from 'react';
import { useLocalization } from '@/hooks/use-localization';
import { Button } from '@/isomorphic/components/typography/button/button';
import { StepLayout } from './step-layout';
import { ExplainerModal } from '../explainer-modal';
import { CountryType, CustomerType, KeyRoleType, PropsType, KeyRole as RoleType } from '../../types';
import { Plus } from '../svg';
import { useFieldValidation } from '../../hooks/use-field-validation';
import { validators } from '@/hooks/use-validation';
import { KeyRole } from '../key-role';
import clsx from 'clsx';

export const StepFour = ({ fields: { keyRoles, allKeyRoles, signatoryCombinations }, updateFields, getLabel, ...rest }: PropsType) => {
    const { getCountry, getLocale } = useLocalization();
    const { validate } = useFieldValidation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const nonPrimaryKeyRoles = useMemo(
        () => [...allKeyRoles].filter(role => !keyRoles.find(keyRole => keyRole.governmentId.id === role.governmentId.id)),
        [keyRoles, allKeyRoles]
    );

    const validateField = (fieldName: string, value: string | boolean | string[] | Record<string, string>, isEditable: boolean) => {
        if (fieldName === 'phoneNumber') {
            return validators.phoneMobile(value as string, getLocale());
        }

        if (typeof value === 'string') {
            return validate(fieldName, value);
        }

        return Object.keys(value).every(key => {
            if (fieldName === 'governmentId' && key === 'id') {
                if (!isEditable) {
                    return true;
                }

                return validate('socialSecurityNumber', value[key as keyof typeof value]);
            }

            return validate(key, value[key as keyof typeof value]);
        });
    };

    const hasRequiredRole = useMemo(() => !!keyRoles.find(({ roles }) => roles.includes(RoleType['RIGHT_OF_USE'])), [keyRoles]);

    const isSubmitDisabled = useMemo(() => {
        return !keyRoles.every(keyRole => {
            return Object.keys(keyRole).every(key => {
                if (key === 'isEditable') {
                    return true;
                }

                if (key === 'roles') {
                    return keyRole['roles'].length > 0;
                }

                return validateField(key, keyRole[key as keyof typeof keyRole], keyRole.isEditable);
            });
        });
    }, [keyRoles]);

    const updateKeyRole = (keyRole: KeyRoleType, index: number) => {
        const updatedKeyRoles = [...keyRoles];
        updatedKeyRoles.splice(index, 1, keyRole);
        updateFields({ keyRoles: updatedKeyRoles });
    };

    const deleteKeyRole = (index: number) => {
        const updatedKeyRoles = [...keyRoles];
        updatedKeyRoles.splice(index, 1);
        updateFields({ keyRoles: updatedKeyRoles });
    };

    const addKeyRole = (
        keyRole: KeyRoleType = {
            roles: [],
            email: '',
            isEditable: true,
            phoneNumber: '',
            name: {
                first: '',
                last: '',
            },
            governmentId: {
                id: '',
                customerType: CustomerType.NATURAL,
                countryCode: getCountry().toLocaleUpperCase() as CountryType,
            },
        }
    ) => {
        const updatedKeyRoles = [...keyRoles];
        updatedKeyRoles.push(keyRole);
        updateFields({ keyRoles: updatedKeyRoles });
    };

    const isSignatory = (keyRole: KeyRoleType) => !!signatoryCombinations.find(signatory => signatory.governmentId.id === keyRole.governmentId.id);

    return (
        <>
            <StepLayout title={getLabel('stepFour')} isSubmitDisabled={isSubmitDisabled || !hasRequiredRole} getLabel={getLabel} {...rest}>
                <div className="-r-mt-2 r-border-b r-border-b-gray-200 r-pb-4">
                    <div className="r-prose r-mb-4" dangerouslySetInnerHTML={{ __html: getLabel('principalExplainer') }} />
                    <Button type="link" onClick={() => setIsModalOpen(true)}>
                        {getLabel('whatDoTheRolesMean')}
                    </Button>
                </div>
                {keyRoles.map((keyRole, index) => (
                    <div key={index} className="r-border-b r-border-b-gray-200 r-py-6">
                        <KeyRole
                            keyRole={keyRole}
                            getLabel={getLabel}
                            isSignatory={isSignatory(keyRole)}
                            onUpdate={values => updateKeyRole(values, index)}
                            onDelete={() => deleteKeyRole(index)}
                        />
                    </div>
                ))}
                <div className="r-space-y-4 r-py-6 r-pt-6">
                    {nonPrimaryKeyRoles.map((keyRole, index) => (
                        <div key={index}>
                            <button
                                className="r-flex r-w-full r-cursor-pointer r-items-center r-gap-2 r-rounded-xl r-bg-[#e5f2f2] r-p-4 r-transition-all hover:r-bg-opacity-75"
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    addKeyRole(keyRole);
                                }}
                            >
                                <Plus className="r-h-4 r-w-4" fill="#222" />
                                <div>
                                    Lägg till{' '}
                                    <strong>
                                        {keyRole.name.first} {keyRole.name.last}
                                    </strong>
                                </div>
                            </button>
                        </div>
                    ))}
                    <Button type="secondary" onClick={() => addKeyRole()}>
                        <span className="r-flex r-items-center r-gap-2">
                            <Plus />
                            {getLabel('addPrincipal')}
                        </span>
                    </Button>
                </div>
                <div
                    className={clsx('r-mt-8 r-rounded-r-xl r-border-l-[.5rem] r-border-l-danger r-bg-gray-50 r-p-3', {
                        'r-hidden': hasRequiredRole,
                    })}
                >
                    {getLabel('noDispositionRoleMessage')}
                </div>
            </StepLayout>

            <ExplainerModal
                open={isModalOpen}
                className="r-max-w-4xl"
                onClose={() => setIsModalOpen(false)}
                header={getLabel('whatDoTheRolesMean')}
                imageSrc="/assets/images/business-loan/key.svg"
                imageSrcMobile="/assets/images/business-loan/key-mobile.svg"
            >
                <div className="r-space-y-4" dangerouslySetInnerHTML={{ __html: getLabel('principalModalExplainer') }} />
            </ExplainerModal>
        </>
    );
};
