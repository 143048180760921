import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { DynamicWidget } from '@/isomorphic/components/dynamic-widget/dynamic-widget';
import { AppContext } from '@/contexts/app-context';
import { sendAsGoal } from '@/hooks/use-ab-tests';

export type ConsumerLoanCounterModuleProps = {
    labels: object;
    settings: object;
    commonTermsUrl: string;
    sekkiUrl: string;
    thirdTermsUrl: string;
    applicationFormUrl?: {
        url: string;
    };
    abFirstGoal: {
        useAsGoal: boolean;
        abTestUuid: string;
    };
};

export const ConsumerLoanCounterModule: React.FC<ConsumerLoanCounterModuleProps> = ({
    labels,
    settings,
    commonTermsUrl,
    sekkiUrl,
    thirdTermsUrl,
    applicationFormUrl,
    abFirstGoal,
}) => {
    const { url, isProd } = useContext(AppContext);
    const location = useLocation();
    const { amount, time } = queryString.parse(location.search);

    useEffect(() => {
        document.body.addEventListener('counter-module-submit', () => {
            if (abFirstGoal && abFirstGoal.useAsGoal) {
                sendAsGoal(abFirstGoal.abTestUuid);
            }
        });

        document.body.addEventListener('counter-module-begin-apply', () => {
            if (abFirstGoal && abFirstGoal.useAsGoal) {
                sendAsGoal(abFirstGoal?.abTestUuid, 'started');
            }
        });
    }, []);

    const loanAppSettings: Record<string, unknown> = {
        ...settings,
        callbackUrl: `${url}${location.pathname}`,
        commonTermsUrl,
        sekkiUrl,
        thirdTermsUrl,
        applicationFormPath: applicationFormUrl?.url,
    };

    if (amount) {
        loanAppSettings.amount = amount;
    }
    if (time) {
        loanAppSettings.year = time;
    }

    return (
        <DynamicWidget
            widget="consumerloan-counter-module"
            dataAttributes={{
                labels,
                environment: isProd ? 'production' : 'development',
                settings: loanAppSettings,
            }}
        />
    );
};
