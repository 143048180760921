import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { Size } from '@/utils/typography';

export type GridLayoutContainerProps = {
    nCols: 1 | 2 | 3 | 4;
    nRows: 1 | 2 | 3 | 4;
    gapSize: Size;
};

export const GridLayoutContainer = ({ nCols = 1, nRows = 1, gapSize = 'medium', children }: PropsWithChildren<GridLayoutContainerProps>) => (
    <div
        className={clsx('r-mt-4 r-grid', {
            'r-gap-4': gapSize === 'small',
            'r-gap-8': gapSize === 'medium',
            'r-gap-12': gapSize === 'large',
            'md:r-grid-cols-1': nCols === 1,
            'md:r-grid-cols-2': nCols === 2,
            'md:r-grid-cols-3': nCols === 3,
            'md:r-grid-cols-4': nCols === 4,
            'md:r-grid-rows-1': nRows === 1,
            'md:r-grid-rows-2': nRows === 2,
            'md:r-grid-rows-3': nRows === 3,
            'md:r-grid-rows-4': nRows === 4,
        })}
    >
        {children}
    </div>
);
