const defaultLabels = {
    // ---------------------
    // --- Introduction ----
    // ---------------------
    interestRate: '4,10',
    openSavingsAccount: 'Öppna sparkonto',
    savingsAccountBusiness: 'Sparkonto företag',
    socialSecurityNumber: 'Personnummer',
    identifyWithBankId: 'Identifiera dig med BankID',
    businessLogIn: 'Logga in som företagare',
    welcomeUser: 'Välkommen %s!',
    interest: 'Sparränta',
    close: 'Stäng',
    select: 'Välj',
    change: 'Ändra',
    back: 'Tillbaka',
    next: 'Nästa',
    introductoryText: `<p>Vi har höjt sparräntan på våra rörliga sparkonton till 4,10 %. Du får ränta från första kronan och obegränsat med fria uttag.</p>
    <p>Vårt sparkonto för företag är ett av marknadens bästa sparkonton. Det är framtaget för dig som vill ha din ekonomiska buffert så tryggt placerad som möjligt och samtidigt få en hög ränta.</p>
    <p>Att spara hos Resurs är tryggt. Om något oförutsett skulle hända är dina pengar hos oss skyddade. Den statliga insättningsgarantin ger dig rätt till ersättning för din sammanlagda kontobehållning i banken med belopp på upp till 1 050 000 kr.</p>`,
    loginPageText: '',
    errorText:
        '<p>Vi kan tyvärr inte hantera din ansökan. Vänligen kontakta kundtjänst på <a href="tel:+4622563733">22 56 37 33</a> eller <a href="https://documenthandler.resurs.com/Dokument.pdf?customerType=legal&docType=spara-foretag-oppna-sparkonto&land=SE&language=sv">skicka in en ansökan via blankett</a>.</p>',
    applicationExistsText: `<p>Hej, ditt företag har redan ett sparkonto hos Resurs, om ni vill lägga till flera konton gör ni det via <a href="https://onlinebank.resursbank.se/">mina sidor</a>.</p>
    <p>Vänliga hälsningar<br />Resurs</p>`,

    // ---------------------
    // ----- Steps ------
    // ---------------------
    stepOne: 'Företagsinformation',
    stepTwo: 'Ägarskap',
    stepThree: 'Undertecknare',
    stepFour: 'Nyckelpersoner',
    stepFive: 'Summering',
    // ---------------------
    // ----- Step One ------
    // ---------------------
    companyInformation: 'Företagsinformation',
    companyForm: 'Ange bolagsform',
    companyName: 'Företagsnamn',
    provideYourOrgNumber: 'Ange ert organisationsnummer',
    orgNumber: 'Organisationsnummer',
    postCode: 'Postnummer',
    city: 'Ort',
    address: 'Adress',
    email: 'E-post',
    phone: 'Telefonnummer',
    yourEmail: 'Din e-post',
    yourPhone: 'Ditt telefonnummer',
    addMore: 'Lägg till fler',
    paymentAccount: 'Föranmält konto för utbetalning',
    paymentAccountInfo: 'För att du ska kunna ta ut de insatta pengarna behöver vi veta vilket konto pengarna ska överföras till.',
    firstLastName: 'För- och efternamn',
    bankName: 'Banknamn',
    clearingNumber: 'Clearingnummer',
    accountNumber: 'Kontonummer',
    contactDetails: 'Dina kontaktuppgifter',
    // ---------------------
    // ----- Step Two ------
    // ---------------------
    detailsRegardingBenificialOwners: 'Uppgifter om företagets huvudman',
    alternativeBenificialOwner: 'Ange alternativ verklig huvudman',
    addBefinicialOwners: 'Lägg till fler verkliga huvudmän',
    benificialOwner: 'Verklig huvudman',
    removeBenificialOwner: 'Ta bort verklig huvudman',
    whoDoYouWishToAdd: 'Vem vill du lägga till?',
    regardingBenificialOwners: 'Om verklig huvudman?',
    benificialOwnerExplainer:
        '<p>Verklig huvudman är en fysisk person som genom direkt eller indirekt ägande eller kontroll över mer än 25% av aktierna ytterst kontrollerar företaget. Om det saknas en verklig huvudman bör styrelseordförande eller VD anses vara bolagets verkliga huvudman, då en så kallad "alternativ verklig huvudman".</p>',
    // ---------------------
    // -------- KYC --------
    // ---------------------
    selectCountry: 'Välj land',
    provideCountry: 'Ange land',
    addMoreCountries: 'Lägg till fler länder',
    removeCountry: 'Ta bort land',
    customerKnowledge: 'Kundkännedom',
    customerKnowledgeKYC: 'Kundkännedom (KYC)',
    aboutCustomerKnowledge: 'Om Kundkännedom',
    customerKnowledgeModalExplainer:
        '<p>Penningtvättslagen ställer höga krav på att vi har god kännedom om er som kund. Vi måste förstå syftet med affärsförhållandet och även med de transaktioner som ni vill utföra. Därför måste vi ställa frågor till er när ni ska bli ny kund hos oss eller utföra era bankärenden. Den information banken får om er behandlas konfidentiellt och omfattas av banksekretessen och personuppgiftslagen.</p>',
    // ---------------------
    // ---------------------
    // ---- Step Three -----
    // ---------------------
    principalExplainer:
        '<p>För att ansöka om sparkonto till ert företag behöver vi veta mer om nyckelpersonerna inom företaget.</p><p>Dessa nyckelpersoner <strong>behöver du lägga till</strong>:</p><ul><li><strong>Samtliga firmatecknare</strong></li><li><strong>Minst en person med dispositionsrätt</strong></li></ul><p>Person med <strong>informationsrätt är frivillig</strong> att lägga till.</p>',
    principalModalExplainer: `<p><strong>Firmatecknare</strong></p>
        <p>Firmatecknare är det personer som har rätt att skriva under i företagets namn. Om firman tecknas av flera personer bör samtliga läggas till.</p>
        <p><strong>Dispositionsrätt</strong></p>
        <p>Rollen dispositionsrätt ges till den person som har rätt att administrera företagets konto. Personen har rätt att förfoga över de medel som finns på sparkontot samt ta del av tillgänglig information om sparkontot. Dispositionsrätt behöver inte vara firmatecknare, utan den person som företaget utser för att administrera sparkontot.</p>
        <p><strong>Informationsrätt</strong></p>
        <p>Rollen informationsrätt är en person som får ta del av tillgänglig information om företagets sparkonto. Exempelvis kan personen se kontots saldo och kontoutdrag. Informationsrätt behöver inte vara firmatecknare, utan den person som företaget utser för att ta del av information om sparkontot.</p>`,
    roleInCompany: 'Roll i företaget',
    whatDoTheRolesMean: 'Vad innebär rollerna?',
    addPrincipal: 'Lägg till fler nyckelpersoner',
    removePrincipal: 'Ta bort nyckelperson',
    doesThePersonHaveAnotherRole: 'Vill du tilldela personen ytterligare roll?',
    rightOfUse: 'Dispositionsrätt',
    rightOfInformation: 'Informationsrätt',
    no: 'Nej',
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    providePrincipalsRole: 'Ange personens roll',
    companyPrincipal: 'Firmatecknare',
    savePrincipal: 'Spara nyckelperson',
    principalDetails: 'Personuppgifter',
    noDispositionRoleMessage: 'Glöm inte att lägga till minst en person med dispositionsrätt.',
    // ---------------------
    // ----= Step Four -----
    // ---------------------
    signees: 'Undertecknare',
    whoWillSignSelect:
        '<p><strong>Vem ska signera?</strong></p><p>En kombination av  personer krävs för signering. Välj den kombination som passar er bäst.</p>',
    whoWillSign:
        '<p><strong>Vem ska signera?</strong></p><p>Vänligen fyll i kontaktuppgifter till personer som ska signera, SMS och e-post kommer att skickas med önskemål att signera ansökan.</p>',
    // ---------------------
    // ----= Step Five -----
    // ---------------------
    summany: 'Summering',
    savingsAccount: 'Sparkonto',
    companyDetails: 'Företagsuppgifter',
    transferAccount: 'Överföringskonto',
    account: 'Konto',
    currently: 'För närvarande:',
    interestLabel: 'Rörlig ränta',
    checkDetailsText: 'Vänligen kontrollera att uppgifterna stämmer innan du skickar in ansökan.',
    checkDetailsHelp:
        '<p>Undertecknad har skriftligen tagit del av samt mottagit kopia av Särskilda villkor (KONTOINFORMATION SPARKONTO FÖRETAG/FÖRENING), ALLMÄNNA VILLKOR ONLINEBANKEN och Resurs Banks ALLMÄNNA VILLKOR SPARKONTO FÖRETAG, vars bestämmelser härmed accepteras. Avtalet måste skrivas under av behöriga firmatecknare. Jag bekräftar att alla frågor besvarats sanningsenligt och att jag kommer att underrätta Resurs Bank om eventuella förändringar.</p>',
    checkboxToc: 'Jag har tagit del av produktvillkoren och de allmänna villkoren.',
    checkboxGuarantee: 'Jag har tagit del om informationen om insättningsgaratin.',
    submitForm: 'Skicka in ansökan',
    // ---------------------
    // --Manual Application-
    // ---------------------
    applyManually: 'Ansök med blankett',
    applyManuallyText: `<p>För din organisation behöver du <a href="%s">ansöka manuellt</a>.</p><p>Du är välkommen att kontakta oss på <a href="tel:0771-11 22 33">0771-11 22 33</a> om du har frågor om ansökan.</p>`,
    savingsAccountForYourOrg: 'Sparkonto för din organisation',
    manualApplicationLink:
        'https://documenthandler.resurs.com/Dokument.pdf?customerType=legal&docType=spara-foretag-oppna-sparkonto&land=SE&language=sv',
    // ---------------------
    // ----- Complete ------
    // ---------------------
    completeHeader: 'Tack för din ansökan om sparkonto!',
    completeText:
        '<p>Din ansökan om sparkonto behandlas av våra företagsrådgivare, <strong>vi återkommer inom 24 timmar med besked om ditt sparkonto.</strong></p>',
    landingPage: 'Till startsidan',
};

export type LabelsType = typeof defaultLabels;

export const getLabel = (labels: LabelsType, key: keyof LabelsType, replacement?: string) => {
    const label = labels?.[key] || defaultLabels[key];

    const returnValue = replacement ? label.replace('%s', replacement) : label;

    return returnValue.replace(/\s*style="[^"]*"/g, '');
};
