import { useContext } from 'react';
import { AppContext } from '@/contexts/app-context';
import { useSupremeCards } from '@/hooks/use-supreme-cards';
import { DynamicWidget } from '@/isomorphic/components/dynamic-widget/dynamic-widget';
import { useLocation } from 'react-router-dom';

export type SupremeCardNewProps = {
    apiUrl: string;
    ignoreSelfSignedCert?: boolean;
    requestTimeout?: number;
    defaultCard?: string;
    deferredSigningUrl: string;
    thankyouPageContent: string;
};

export const SupremeCardNew = ({
    apiUrl = 'https://apigw.integration.resurs.com/api',
    ignoreSelfSignedCert = true,
    requestTimeout = 60000,
    defaultCard,
    deferredSigningUrl,
}: SupremeCardNewProps) => {
    const context = useContext(AppContext);
    const location = useLocation();
    const { supremeCards } = useSupremeCards();
    const { language, country, bankApiKey, signicatClientId, url } = useContext(AppContext);

    const getCountrySettings = () => {
        // We have 'no' as language for norway, this application expects 'nb'
        const languageCode = language === 'no' ? 'nb' : language;

        return {
            languageCode,
            countryCode: country.toUpperCase(),
            locale: `${languageCode}-${country.toUpperCase()}`,
        };
    };

    const getViewportSettings = () => {
        if (typeof window === 'undefined') {
            return {
                width: 390,
                height: 844,
                isMobileDevice: context.deviceType === 'mobile',
                initiated: true,
                headerMenuHeight: 95,
            };
        }

        return {
            width: window.innerWidth,
            height: window.innerHeight,
            isMobileDevice: context.deviceType === 'mobile',
            initiated: true,
            headerMenuHeight: 95,
        };
    };

    if (!supremeCards.data) {
        return null;
    }

    const props = {
        customer: {
            // 99% sure we don't need this :P
            customerData: null,
        },
        auth: {
            accessToken: context?.user?.accessToken,
            refreshToken: context?.user?.refreshToken,
            refreshTokenExpire: context?.user?.tokenExpires,
        },
        location,
        isBankPage: false,
        supremeCards: {
            cards:
                supremeCards.data?.map(card => ({
                    title: card.title,
                    type: card.api_name,
                })) ?? [],
            selectedCard: defaultCard,
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        selectCardType: () => {},
        creditSliderStepAmount: 500,
        pepIdentifier: null,
        postponedSignatureRedirectUrl: deferredSigningUrl,
        settings: {
            apiUrl,
            apikey: bankApiKey,
            clientId: signicatClientId,
            ignoreSelfSignedCert,
            requestTimeout,
            siteUrl: url,
            loginUrlOverride: '',
        },
        country: getCountrySettings(),
        viewport: getViewportSettings(),
        context: {
            state: null,
            setState: null,
        },
        defaultCard,
        deferredSigningUrl,
        labels: null,
        theme: null,
    };

    return <DynamicWidget widget="supreme-card" dataAttributes={props} />;
};
