import { AppContextType } from '@/contexts/app-context';
import { ApiFetchType } from '@/server/api-fetch';

export type Rate = {
    accountFee: number;
    adminFee: number;
    arrangementFee: number;
    effectiveInterest: number;
    montlyAmount: number;
    numberOfMonths: number;
    totalLoanCost: number;
    totalRepaymentAmount: number;
    yearlyInterest: number;
};

export type Rates = {
    amount: number;
    rates: Rate[];
};

export const RATES_QUERY_KEY = 'RATES_QUERY_KEY';

export const getRates = async (context: ApiFetchType & { country: AppContextType['country'] }, amount: number | null = 0) => {
    try {
        const rates = context.fetch ? await context.fetch<Rates>('api/rates/' + context.country + '/' + amount) : null;
        if (!rates) {
            return null;
        }

        rates.rates.reverse();
        return rates;
    } catch (e: unknown) {
        console.error(e);
        throw e;
    }
};
