import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { MenuItemType } from '@/queries/header';
import { useNavItem } from '@/hooks/use-nav-item';

// temporary fix for the menu awaiting new color palette
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MainMenuSub = ({ menu, depth = 1 }: { menu: MenuItemType[]; depth?: number }) => {
    const { isCurrentItemActive } = useNavItem();
    if (!menu.length) {
        return null;
    }

    return (
        <div data-component="main-menu-sub" className="r-bg-white r-text-sm r-text-white r-shadow-sub-menu">
            <div className="r-border-t-2"></div>
            <div className="r-container r-flex r-justify-center r-px-8 r-py-4">
                <ul className="r-flex r-items-center r-gap-2 r-text-black">
                    {menu.map((item, index) => (
                        <li
                            key={index}
                            className={clsx('r-rounded-xl r-px-2 r-py-1 r-font-semibold r-transition-all r-duration-200 hover:r-bg-[#aed5d5]', {
                                'r-rounded-xl r-bg-[#aed5d5]  r-font-semibold': isCurrentItemActive(item),
                            })}
                        >
                            <Link to={item.url.replace(/\/$/, '')} target={item.target || '_self'}>
                                {item.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
