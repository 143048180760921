import { Product } from './kyc-survey';

export const getProducts = async ({ bankApiKey, resursApiUrl }: { bankApiKey: string; resursApiUrl: string }) => {
    try {
        const apiResponse = await fetch(`${resursApiUrl}/savings_onboarding_service/onboarding/products`, {
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                apiKey: bankApiKey,
            },
            method: 'GET',
        });

        const response = await apiResponse.json();
        if (!apiResponse || apiResponse.status !== 200) {
            throw new Error('getProducts' + ' unauthorized');
        }

        return response.products as Product[];
    } catch (e: unknown) {
        throw new Error('Error getting products');
    }
};
