import { UseQueryResult } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { useLocalization } from '@/hooks/use-localization';
import { HumanySearchResponse } from '@/queries/search';
import { useGlobalQueries } from '@/hooks/use-global-queries';
import { SearchResultsText } from './search-results-text';

type HumanySearchResultsProps = {
    data: UseQueryResult<HumanySearchResponse>;
    searchTerm: string;
    close: () => void;
};

export const HumanySearchResults = ({ data: { data }, searchTerm, close }: HumanySearchResultsProps) => {
    const { localize } = useLocalization();
    const { getSetting } = useGlobalQueries();
    // eslint-disable-next-line camelcase
    const { humany_page_link: pageLink } = getSetting('humany_settings') || {};

    if (!data || searchTerm === '') {
        return null;
    }

    return (
        <div data-component="humany-search-results">
            {data.TotalMatches === 0 ? (
                <p className="r-mb-4">
                    <SearchResultsText t10n="search-humany-no-results" searchValue={searchTerm} />
                </p>
            ) : (
                <>
                    <p className="r-mb-4">
                        <SearchResultsText t10n="search-humany-result-intro" searchValue={searchTerm} />
                    </p>
                    <div className="r-mb-4">
                        <ul className="r-list-image-[url(/assets/icons/chevron-right.svg)] r-pl-4">
                            {data.Matches.map(item => (
                                <li key={item.Id} className="r-pl-2">
                                    <Link
                                        onClick={() => close()}
                                        className="r-mb-2 r-block r-text-primary-300"
                                        to={`${pageLink}${item.RelativeUrl.replace(/^(\d+)/, 'g$1').replace('/', '-')}`}
                                    >
                                        {item.Title}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {data.TotalMatches > 10 ? (
                        <Link to={pageLink + `search?phrase=${searchTerm}`} className="r-font-semibold r-text-primary-300" onClick={() => close()}>
                            {localize('search-humany-result-show-more')}
                        </Link>
                    ) : null}
                </>
            )}
        </div>
    );
};
