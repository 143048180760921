import { useLocalization } from '@/hooks/use-localization';
import { Dispatch, SetStateAction, useEffect } from 'react';

type TabMenuProps = {
    activeTab: string;
    setActiveTab: Dispatch<SetStateAction<string>>;
};

export const TabMenu = ({ activeTab, setActiveTab }: TabMenuProps) => {
    const { localize } = useLocalization();
    useEffect(() => {
        if (typeof window === 'undefined') return;
        window.localStorage.setItem('activeTab', activeTab);
    }, [activeTab]);

    const corporate = localize('corporate');
    const privatee = localize('private');
    return (
        <div className="r-flex r-border-b-2 r-text-xl r-font-medium">
            <div
                onClick={() => setActiveTab(privatee)}
                className={`r-mb-[-2px] r-flex-grow r-pb-3 ${activeTab === privatee ? 'r-border-b-4  r-border-b-green-800' : ''} r-p-1`}
            >
                <p className="r-ml-2">{privatee}</p>
            </div>
            <div
                onClick={() => setActiveTab(corporate)}
                className={`r-mb-[-2px] r-flex-grow r-pb-3  ${activeTab === corporate ? 'r-border-b-4 r-border-b-green-800' : ''} r-p-1`}
            >
                <p className="r-ml-2">{corporate}</p>
            </div>
        </div>
    );
};
