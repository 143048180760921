export function formatIntro(introHtml: string, wordCount = 20) {
    const text = introHtml.replace(/(<([^>]+)>)/gi, '');

    return text.split(' ').slice(0, wordCount).join(' ');
}

export function getCategories(lang = 'sv') {
    switch (lang) {
        case 'sv':
        case 'se':
            return {
                all: 'Alla',
                pressrelease: 'Pressmeddelanden',
                regulatory: 'Rapporter',
                news: 'Övriga nyheter',
            };
        case 'no':
        case 'nb':
            return {
                all: 'Alle',
                pressrelease: 'Pressemeldinger',
                regulatory: 'Rapporter',
                news: 'Andre nyheter',
            };
        case 'da':
        case 'dk':
            return {
                all: 'Alle',
                pressrelease: 'Selskabsmeddelelser',
                regulatory: 'Rapporter',
                news: 'Andre nyheder',
            };
        case 'fi':
            return {
                all: 'Kaikki',
                pressrelease: 'Lehdistötiedotteet',
                regulatory: 'Tulostiedotteet',
                news: 'Muut tiedotteet',
            };
    }

    return {
        all: 'All',
        pressrelease: 'Press Release',
        regulatory: 'Rapports',
        news: 'Other news',
    };
}

export const getYears = (allText = 'All') => {
    const years = [
        {
            text: allText,
            value: '',
        },
    ];

    for (let year = new Date().getFullYear(); year >= 2008; year--) {
        years.push({ text: year.toString(), value: year.toString() });
    }

    return years;
};

export const getFilters = (language: string) => {
    const local = getCategories(language);

    return Object.entries(local).map(([value, text]) => ({
        value,
        text,
    }));
};
