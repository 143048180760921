import clsx from 'clsx';
import { Image } from '../../image/image';
import { Link } from 'react-router-dom';

export type ShortcutsProps = {
    attrs: {
        shortcuts: {
            image?: string | null;
            link?: {
                url: string;
                title: string;
                target?: string;
            } | null;
        }[];
    };
};

export const Shortcuts = ({ attrs }: ShortcutsProps) => (
    <div data-component="shortcuts">
        <div className="scrollbar-hide -r-ml-4 r-flex r-flex-shrink-0 r-gap-8 r-overflow-x-scroll r-pl-4 r-align-top max-md:r-w-[100vw] max-sm:r-pr-4 md:r-gap-16">
            {attrs.shortcuts
                .filter(shortcut => !!shortcut.image || !!shortcut.link)
                .map(({ image, link }, index) => (
                    <Link
                        key={index}
                        to={link?.url || ''}
                        target={link?.target || '_self'}
                        className={clsx('r-flex r-w-32 r-flex-shrink-0 r-flex-col r-items-center r-text-center', {
                            'r-ml-auto': index === 0,
                            'r-mr-auto': index === attrs.shortcuts.length - 1,
                        })}
                    >
                        <Image src={image || ''} size="100xAUTO" width={64} height={64} noMargin />
                        <span className="r-pt-2 r-font-bold r-text-primary-200">{link?.title}</span>
                    </Link>
                ))}
        </div>
    </div>
);
