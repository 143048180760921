import { Field } from '@/isomorphic/components/forms/components/field';
import { validators } from '@/hooks/use-validation';
import { useLocalization } from '@/hooks/use-localization';
import { KeyRole as RoleType, KeyRoleType, PropsType } from '../types';
import { FieldGroup } from './field-group';
import { Button } from '@/isomorphic/components/typography/button/button';
import { Checkbox } from '@/isomorphic/components/forms/components/checkbox';

type Props = {
    onDelete?: () => void;
    isSignatory?: boolean;
    keyRole: KeyRoleType;
    getLabel: PropsType['getLabel'];
    onUpdate: (value: KeyRoleType) => void;
};

export const KeyRole = ({ keyRole, onUpdate, onDelete, getLabel, isSignatory = false }: Props) => {
    const { getLanguage, getLocale } = useLocalization();

    const updateKeyRole = (fields: Partial<KeyRoleType>) =>
        onUpdate({
            ...keyRole,
            ...fields,
        });

    const updateSsn = (value: string) =>
        updateKeyRole({
            ...keyRole,
            governmentId: {
                ...keyRole.governmentId,
                id: value,
            },
        });

    const updateName = (value: string, key: 'first' | 'last' = 'first') =>
        updateKeyRole({
            name: {
                ...keyRole.name,
                [key]: value,
            },
        });

    const checkboxRoles = [
        {
            label: getLabel('rightOfUse'),
            role: RoleType.RIGHT_OF_USE,
        },
        {
            label: getLabel('rightOfInformation'),
            role: RoleType.RIGHT_OF_INFORMATION,
        },
    ];

    const toggleRoles = (role: RoleType, checked = true) => {
        const roles = new Set([...keyRole.roles]);

        checkboxRoles.forEach(role => roles.delete(role.role));
        if (checked) {
            roles.add(role);
        }

        updateKeyRole({ roles: [...roles] });
    };

    return (
        <div>
            {!isSignatory ? (
                <div className="r-flex r-justify-end r-pb-4">
                    <Button type="link" onClick={onDelete}>
                        {getLabel('removePrincipal')}
                    </Button>
                </div>
            ) : null}
            {keyRole.isEditable ? (
                <FieldGroup>
                    <div className="r-pb-4">
                        <p className="r-font-bold">{getLabel('whoDoYouWishToAdd')}</p>
                    </div>
                    <Field
                        grouped="top"
                        validation={v => !!v}
                        value={keyRole.name.first}
                        label={getLabel('firstName')}
                        onChange={value => updateName(value)}
                    />
                    <Field
                        grouped="middle"
                        validation={v => !!v}
                        value={keyRole.name.last}
                        label={getLabel('lastName')}
                        onChange={value => updateName(value, 'last')}
                    />
                    <Field
                        grouped="middle"
                        value={keyRole.governmentId.id}
                        onChange={value => updateSsn(value)}
                        label={getLabel('socialSecurityNumber')}
                        validation={value => validators.socialSecurityNumber(value, getLanguage())}
                    />
                    <Field
                        grouped="middle"
                        label={getLabel('phone')}
                        value={keyRole.phoneNumber}
                        validation={value => validators.phoneMobile(value, getLocale())}
                        onChange={value => updateKeyRole({ phoneNumber: value })}
                    />
                    <Field
                        grouped="bottom"
                        value={keyRole.email}
                        label={getLabel('email')}
                        validation={value => validators.email(value)}
                        onChange={value => updateKeyRole({ email: value })}
                    />
                </FieldGroup>
            ) : (
                <>
                    <div className="r-space-y-4 r-rounded-xl r-bg-[#e5f2f2] r-p-4">
                        <div className="r-flex r-items-center r-justify-between">
                            <div className="r-font-bold">
                                {keyRole.name.first} {keyRole.name.last}
                            </div>
                        </div>
                        <div>{getLabel('companyPrincipal')}</div>
                    </div>
                    <FieldGroup className="r-pt-4">
                        <Field
                            grouped="top"
                            label={getLabel('phone')}
                            value={keyRole.phoneNumber}
                            validation={value => validators.phoneMobile(value, getLocale())}
                            onChange={value => updateKeyRole({ phoneNumber: value })}
                        />
                        <Field
                            grouped="bottom"
                            value={keyRole.email}
                            label={getLabel('email')}
                            validation={value => validators.email(value)}
                            onChange={value => updateKeyRole({ email: value })}
                        />
                    </FieldGroup>
                </>
            )}
            <FieldGroup
                className="r-mt-6 r-space-y-4"
                legend={keyRole.isEditable ? 'Vilken roll vill du tilldela personen?' : getLabel('doesThePersonHaveAnotherRole')}
            >
                {checkboxRoles.map((checkbox, index) => (
                    <Checkbox
                        key={index}
                        type="radio"
                        deselectable
                        value={checkbox.role}
                        label={checkbox.label}
                        checked={keyRole.roles.includes(checkbox.role)}
                        onChange={(checked: boolean) => toggleRoles(checkbox.role, checked)}
                    />
                ))}
            </FieldGroup>
        </div>
    );
};
