import { getImageUrl } from '@/utils/images';
import { Button } from '@/isomorphic/components/typography/button/button';
import { LoginOverlay } from '@/isomorphic/components/login-overlay/login-overlay';
import { Check } from '../svg';
import { PropsType } from '../../types';

export const Complete = ({ getLabel }: PropsType) => (
    <LoginOverlay
        mobileImage={getImageUrl('/inlaning/man-bike-2-desk.jpg', '500xAUTO')}
        desktopImage={getImageUrl('/inlaning/man-bike-2-desk.jpg', '1920xAUTO')}
    >
        <div className="r-space-y-4 sm:r-w-full">
            <Check />
            <h2 className="r-pb-2 r-text-4xl r-font-bold">{getLabel('completeHeader')}</h2>
            <div className="r-space-y-4" dangerouslySetInnerHTML={{ __html: getLabel('completeText') }} />
            <Button className="max-sm:r-hidden [&>*]:r-text-white" to="/">
                {getLabel('landingPage')}
            </Button>
            <Button className="sm:r-hidden [&>*]:r-text-white" fullWidth to="/">
                {getLabel('landingPage')}
            </Button>
        </div>
    </LoginOverlay>
);
