import { useMemo, useState } from 'react';
import { Modal } from '@/isomorphic/components/modal/modal';
import { Button } from '@/isomorphic/components/typography/button/button';
import { PropsType } from '../../types';
import { StepLayout } from './step-layout';
import { ExplainerModal } from '../explainer-modal';
import { Check, Close } from '../svg';
import { Kyc } from '../kyc';

export const StepTwo = ({ getLabel, kyc, ...rest }: PropsType) => {
    const KYC_GROUP = 'OWNERSHIP_AND_CONTROL';
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    const [confirmedModal, setConfirmedModal] = useState<null | string>(null);
    const isSubmitDisabled = useMemo(() => !kyc.isGroupValid(KYC_GROUP), [kyc]);

    return (
        <>
            <StepLayout title={getLabel('detailsRegardingBenificialOwners')} isSubmitDisabled={isSubmitDisabled} getLabel={getLabel} {...rest}>
                <div className="-r-mt-4 r-mb-8">
                    <Button type="link" onClick={() => setIsInfoModalOpen(true)}>
                        {getLabel('regardingBenificialOwners')}
                    </Button>
                </div>
                <Kyc type={KYC_GROUP} kyc={kyc} getLabel={getLabel} />
            </StepLayout>

            <Modal open={!!confirmedModal} onClose={() => setConfirmedModal(null)}>
                <div className="r-flex r-justify-end">
                    <button onClick={() => setConfirmedModal(null)}>
                        <Close />
                    </button>
                </div>
                <div className="r-grid r-max-w-md r-place-content-center r-p-4 r-pt-0 r-text-center md:r-p-8 md:r-pt-0">
                    <div className="r-grid r-place-content-center r-pb-8">
                        <Check />
                    </div>
                    <p className="r-text-2xl r-font-semibold">{confirmedModal}</p>
                </div>
            </Modal>

            <ExplainerModal
                open={isInfoModalOpen}
                className="r-max-w-xl"
                onClose={() => setIsInfoModalOpen(false)}
                header={getLabel('regardingBenificialOwners')}
                imageSrc="/assets/images/business-loan/key.svg"
                imageSrcMobile="/assets/images/business-loan/key-mobile.svg"
            >
                <div dangerouslySetInnerHTML={{ __html: getLabel('benificialOwnerExplainer') }} className="r-space-y-4" />
            </ExplainerModal>
        </>
    );
};
