import clsx from 'clsx';

export const ProgressBar = ({ step = 1, oldSkool = false }) => {
    const getClassesForStep = (currentStep: number) => {
        if (currentStep < step) {
            return 'r-bg-primary';
        }

        if (currentStep === step) {
            return clsx('r-bg-white', {
                'r-border-primary r-border-[3px]': !oldSkool,
                'r-border-red-800 r-text-red-800 r-border-2': oldSkool,
            });
        }

        return clsx('r-bg-[#adb5bd] r-text-white');
    };

    const renderItem = (currentStep = 1, classNames = '') => (
        <div
            className={clsx(
                'r-grid r-place-items-center r-rounded-full',
                getClassesForStep(currentStep),
                {
                    '-r-mt-[16px] r-h-[35px] r-w-[35px]': !oldSkool,
                    '-r-mt-[12px] r-h-[24px] r-w-[24px]': oldSkool,
                },
                classNames
            )}
        >
            {currentStep < step ? <img className="r-w-4" src="/assets/icons/checkbox.svg" /> : currentStep}
        </div>
    );

    if (step > 3) {
        return null;
    }

    return (
        <div
            data-component="progress-bar"
            className={clsx('r-my-2 r-flex r-w-full r-place-content-between r-bg-[#adb5bd]', {
                'r-h-1 r-text-xl': !oldSkool,
                'r-h-px r-text-sm': oldSkool,
            })}
        >
            {renderItem(1, '-r-ml-1')}
            {renderItem(2)}
            {renderItem(3, '-r-mr-1')}
        </div>
    );
};
