import { clsx } from 'clsx';
import { getTailwindColor } from '@/utils/colors';
import { DynamicModuleGenerator } from '../../dynamic-module-generator/dynamic-module-generator';
import { BlockBase } from '@/queries/page';
import { getImageUrl } from '@/utils/images';
import { VerticalWave, Wave } from './svg';

export type HeroProps = {
    backgroundColor: string;
    innerBlocks: BlockBase[];
    imageWaveType: 'half' | 'full';
    image: { src: string; width: number; height: number; __isimage: boolean };
};
export const Hero = (props: HeroProps) => (
    <div className={clsx(getTailwindColor(props.backgroundColor, 'bg'), 'r-relative')}>
        <div className="r-container r-relative r-grid md:r-grid-cols-[50%,50%]">
            <div
                className={clsx(
                    { 'r-rounded-bl-[140px]': props.imageWaveType === 'half' },
                    'r-relative r-h-[220px] r-bg-cover r-bg-no-repeat md:r-hidden'
                )}
                style={{
                    backgroundImage: `url(${getImageUrl(props.image.src, '500xAUTO')})`,
                }}
            >
                {props.imageWaveType === 'full' ? (
                    <>
                        <div className="r-absolute r-bottom-0 r-w-full">
                            <Wave fill={props.backgroundColor} />
                        </div>
                    </>
                ) : null}
            </div>
            {/* special handling of heading sizes in hero component */}
            <div className="r-px-4 r-py-6 md:r-px-8 md:r-py-12 [&>h1]:r-text-[28px] [&>h1]:r-leading-[40px] md:[&>h1]:r-text-[54px] md:[&>h1]:r-leading-[75px]">
                <DynamicModuleGenerator blocks={props.innerBlocks} />
            </div>
        </div>
        <div
            className={clsx('r-absolute r-bottom-0 r-right-0 r-top-0 r-order-1 r-w-[50%] r-bg-cover r-bg-center max-md:r-hidden', [
                { 'r-rounded-bl-[360px]': props.imageWaveType === 'half' },
            ])}
            style={{
                backgroundImage: `url(${getImageUrl(props.image.src, '1920xAUTO')})`,
            }}
        >
            {props.imageWaveType === 'full' ? (
                <>
                    <div className="r-absolute r-bottom-0 r-w-full md:r-hidden">
                        <Wave fill={props.backgroundColor} />
                    </div>
                    <div className="r-absolute r-bottom-0 r-left-[-16px] r-top-0 r-h-full max-md:r-hidden">
                        <VerticalWave fill={props.backgroundColor} />
                    </div>
                </>
            ) : null}
        </div>
    </div>
);
