import { PropsType } from '../types';
import { useLocalization } from '@/hooks/use-localization';

export const ApplicationError = ({ getLabel }: PropsType) => {
    const { localize } = useLocalization();
    return (
        <div className="r-relative r-max-w-xl r-space-y-4 r-border-l-8 r-border-l-red-600 r-pl-4 lg:r-mx-auto">
            <img src="/assets/images/business-loan/manual-application.svg" className="r-w-20" />
            <div className="r-space-y-3 r-pt-2 r-font-semibold">
                <h2>{getLabel('savingsAccountBusiness')}</h2>
                <h3 className="r-text-3xl">{localize('error-generic').replace(/\.$/, '')}</h3>
            </div>
            <div className="r-prose" dangerouslySetInnerHTML={{ __html: getLabel('errorText') }} />
        </div>
    );
};
