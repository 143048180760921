import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalQueries } from '@/hooks/use-global-queries';
import { useGtm } from '@/hooks/use-gtm';
import { removeEnclosingSlashes } from '@/utils/strings';
import { useAbTests } from '@/hooks/use-ab-tests';
import { checkConversion } from '@/hooks/use-ab-tests';
import { useRedirects } from '@/hooks/use-redirects';

export const OnPageNavigate = () => {
    const navigate = useNavigate();
    const { setDataLayer } = useGtm();
    const { setWindowData } = useAbTests();
    const { pageQuery } = useGlobalQueries();
    const { pathname, hash } = useLocation();
    const { getRedirectForPath } = useRedirects();
    const [previousPageId, setPreviousPageId] = useState<number | null>(null);
    let timeout: NodeJS.Timeout | undefined;

    const sendGtmPageEvent = () => {
        const title = pageQuery.data?.page_title || pageQuery.data?.post_title;
        const eventPath = /^start(\/?)(\?.*)?$/.test(pathname) ? '' : removeEnclosingSlashes(pathname);

        setDataLayer({
            event: 'pageview',
            pageInfo: {
                title,
                path: `/${eventPath}`,
            },
        });
    };

    useEffect(() => {
        // This needs a delay for some reason. I guess this is just
        // the standard fix for a race condition in JavaScript 😅
        timeout = setTimeout(() => {
            checkConversion(previousPageId);
            setPreviousPageId(pageQuery.data?.id ?? null);
        }, 1000);

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [pageQuery.data?.id]);

    useEffect(() => {
        document.body.style.overflow = 'visible';
        sendGtmPageEvent();
        setWindowData();

        if (hash) {
            const element = document.getElementById(hash.replace('#', ''));
            if (element) {
                element?.scrollIntoView({
                    behavior: 'smooth',
                });
                return;
            }
        }

        window.scrollTo(0, 0);
    }, [pageQuery.data, hash]);

    useEffect(() => {
        const redirect = getRedirectForPath(pathname);
        if (redirect) {
            navigate(redirect);
        }
    }, [pathname]);

    return null;
};
