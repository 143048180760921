import { Field } from '@/isomorphic/components/forms/components/field';
import { Select } from '@/isomorphic/components/forms/components/select';
import { useLocalization } from '@/hooks/use-localization';
import { Button } from '@/isomorphic/components/typography/button/button';
import { BusinessLoanApplicationProps } from '../business-loan-application';
import type { FieldsType } from '../use-business-loan-application';
import { GoogleRecaptchaInfo } from '@/isomorphic/components/google-recaptcha-info/google-recaptcha-info';

export type StepProps = BusinessLoanApplicationProps & {
    fields: FieldsType;
    onSubmit: (step: 1 | 2 | 3 | 4, recaptchaToken?: string) => Promise<void>;
    updateProgress: (step: 1 | 2 | 3 | 4) => void;
    updateField: (args: Partial<FieldsType>) => void;
};

export const StepOne = ({ fields, updateField, labels, updateProgress, ...rest }: StepProps) => {
    const { localize } = useLocalization();

    const minAmount = +rest.minAmount;
    const maxAmount = +rest.maxAmount;

    const validateAmount = (value: string) => {
        const amount = +value;
        return amount >= minAmount && amount <= maxAmount;
    };

    const onBlurAmount = (value: string) => {
        // Make sure the value isn't out of bounds and force it when appropriate
        let newValue = +value;

        if (newValue < minAmount) {
            newValue = minAmount;
        }

        if (newValue > maxAmount) {
            newValue = maxAmount;
        }

        updateField({ amount: newValue });
    };

    const getSelectOptions = () =>
        [12, 18, 24, 36, 48, 60].map(months => ({
            value: months,
            label: `${months} ${localize('months')}`,
        }));

    const isButtonDisabled = () => {
        return !fields.amount || !fields.paybackPeriod || !fields.purpose;
    };

    return (
        <div data-component="step-one" className="r-space-y-4">
            <Field
                required
                type="number"
                validation={validateAmount}
                label={labels.loanAmount}
                value={fields.amount}
                onChange={value => updateField({ amount: +value })}
                onBlur={onBlurAmount}
            />
            <Select
                required
                label={labels.paybackPeriod}
                value={fields.paybackPeriod}
                options={getSelectOptions()}
                onChange={value => updateField({ paybackPeriod: +value })}
                dropdownClass="!r-max-h-[12rem]"
            />
            <Field required type="textarea" label={labels.purpose} value={fields.purpose} onChange={value => updateField({ purpose: value })} />

            <div className="r-flex r-justify-between">
                <GoogleRecaptchaInfo />
                <Button disabled={isButtonDisabled()} onClick={() => updateProgress(2)}>
                    {labels.continue}
                </Button>
            </div>
        </div>
    );
};
