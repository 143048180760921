import { AppContextType } from '@/contexts/app-context';
import { getProducts } from '@/queries/get-products';
import { useQuery } from '@tanstack/react-query';

export const useGetProducts = ({ bankApiKey, resursApiUrl }: AppContextType, fetchProductInformation: boolean) => {
    const { data, isLoading } = useQuery({
        queryKey: ['B2C-deposit', 'products'],
        queryFn: () => getProducts({ bankApiKey, resursApiUrl }),
        enabled: fetchProductInformation,
    });

    return {
        data,
        isLoading,
    };
};
