import { useState } from 'react';

type InfoButtonProps = {
    infoText: string;
    label: string;
};

export const InfoButton = ({ label, infoText }: InfoButtonProps) => {
    const [showInfoText, setShowInfoText] = useState(false);

    return (
        <div className="r-flex r-flex-col">
            <div className="r-flex r-items-center r-space-x-2" onClick={() => setShowInfoText(val => !val)}>
                <div className="r-flex r-h-4 r-w-4 r-flex-shrink-0 r-cursor-pointer r-items-center r-justify-center">
                    <img src="/assets/icons/info.svg" alt="" />
                </div>
                <p>{label}</p>
            </div>
            {showInfoText && infoText && (
                <div className="r-mt-4 r-w-full r-bg-gray-100 r-p-3 r-text-sm">
                    <p>{infoText}</p>
                </div>
            )}
        </div>
    );
};
