import { useEffect, useState } from 'react';
import Slider, { SliderProps } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useLocalization } from '@/hooks/use-localization';

export type RangeSliderProps = Partial<Pick<SliderProps, 'marks'>> & {
    value: number | string;
    min: number | string;
    max: number | string;
    step: number;
    label?: string;
    setValue: (amount: number) => void;
    editable?: boolean;
    currency?: boolean;
    valueSuffix?: string;
    disabled?: boolean;
};

export const RangeSlider = ({
    step,
    setValue,
    label,
    valueSuffix,
    marks,
    editable = false,
    currency = false,
    disabled = false,
    ...rest
}: RangeSliderProps) => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    const value = +rest.value;
    const min = +rest.min;
    const max = +rest.max;

    const [editMode, setEditMode] = useState(false);
    const { formatNumber } = useLocalization();

    const formatValue = (value: number | string) => (currency ? formatNumber(value) : `${value} ${valueSuffix}`);

    const onInputChange = (value?: string) => {
        const newValue = value ? +value : min;
        setValue(newValue);
    };

    const onBlur = () => {
        setEditMode(false);

        // Make sure the value isn't out of bounds and force it when appropriate
        let newValue = value;

        if (newValue < min) {
            newValue = min;
        }

        if (newValue > max) {
            newValue = max;
        }

        setValue(newValue);
    };

    const sliderProps: SliderProps = {
        min,
        max,
        step,
        value,
        marks,
        disabled,
        onChange: (v: number | number[]) => setValue(v as number),
        style: {
            backgroundColor: 'transparent',
        },
        handleStyle: {
            background: '#e1bf00',
            opacity: 1,
            border: 'none',
            boxShadow: 'none',
            width: '30px',
            height: '30px',
            marginTop: '-12px',
        },
        railStyle: {
            height: '9px',
            background: 'rgb(0, 130, 130)',
        },
        trackStyle: {
            height: '9px',
            background: '#e1bf00',
        },
        dotStyle: {
            opacity: 0,
        },
    };

    return (
        <div className="r-flex r-flex-col">
            <div className="r-mb-6 r-flex r-w-full r-items-center r-justify-between">
                {label ? <p className="r-text-xl r-font-semibold r-text-[#e1bf00]">{label}</p> : null}
                {editable ? (
                    editMode && !disabled ? (
                        <input
                            disabled={disabled}
                            className="[appearance-textfield] r-flex r-h-12 r-w-36 r-appearance-none r-items-center r-justify-center r-rounded-lg r-bg-white r-pl-2 r-outline-none"
                            type="number"
                            value={value}
                            min={min}
                            max={max}
                            onChange={({ target }) => onInputChange(target.value)}
                            onBlur={onBlur}
                        />
                    ) : (
                        <div
                            className="r-flex r-h-12 r-w-36 r-items-center r-justify-center r-rounded-lg r-bg-white"
                            onClick={() => setEditMode(true)}
                        >
                            <p>{formatValue(value)}</p>
                        </div>
                    )
                ) : (
                    <p className="r-text-xl r-font-semibold r-text-[#e1bf00]">{formatValue(value)}</p>
                )}
            </div>
            <div className="r-flex r-flex-col">
                {isClient ? <Slider {...sliderProps} /> : null}
                <div className="r-flex r-justify-between r-py-6 r-text-[#e1bf00]">
                    <p>{formatValue(min)}</p>
                    <p>{formatValue(max)}</p>
                </div>
            </div>
        </div>
    );
};
