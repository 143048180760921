const re = /^\d{4}-\d{2}-\d{2}$/;

export const longDate = (date: string | Date, locale = 'sv-se', year = true) => {
    if (!date) {
        return '';
    }

    if (!(date instanceof Date)) {
        // new Date('2019-01-01') gives a date with iso string 2019-01-01T00:00:00.000Z
        // which will return 31 december 2018 for all countries with GMT > +0
        // We need to replace dashes with slashes beacause Safari can't
        // create a date object from a string with dashes
        date = new Date(re.test(date) ? `${date.replace(/-/g, '/')} 00:00:00` : date);
    }

    return date.toLocaleString(locale, {
        ...(year && { year: 'numeric' }),
        month: 'long',
        day: 'numeric',
    });
};
