import { useLocalization } from '@/hooks/use-localization';
import { NewSlider } from './slider';
import { SavingsCalculatorSelectorProps } from './savings-calculator';
import { SavingsInput } from './savings-input';

export const GoalSavings = ({ state, updateState, ...props }: SavingsCalculatorSelectorProps) => {
    const { getCurrencySymbol, localize } = useLocalization();

    return (
        <>
            <div className="r-flex r-flex-col">
                <SavingsInput
                    label={props.goalSavingsAmountLabel}
                    suffix={getCurrencySymbol()}
                    min={props.goalSavingsMinAmount}
                    max={props.goalSavingsMaxAmount}
                    value={state.goalSavingsAmount}
                    onChange={value => updateState({ goalSavingsAmount: value })}
                />
                <NewSlider
                    step={props.goalSavingsStepAmount}
                    min={props.goalSavingsMinAmount}
                    max={props.goalSavingsMaxAmount}
                    value={state.goalSavingsAmount}
                    onChange={value => updateState({ goalSavingsAmount: value })}
                />
            </div>
            <div className="r-flex r-flex-col">
                <SavingsInput
                    label={props.goalSavingsYearLabel}
                    suffix={localize('year').toLowerCase()}
                    min={props.goalSavingsMinYear}
                    max={props.goalSavingsMaxYear}
                    value={state.goalSavingsYear}
                    onChange={value => updateState({ goalSavingsYear: value })}
                />
                <NewSlider
                    step={props.goalSavingsStepYear}
                    min={props.goalSavingsMinYear}
                    max={props.goalSavingsMaxYear}
                    value={state.goalSavingsYear}
                    onChange={value => updateState({ goalSavingsYear: value })}
                />
            </div>
        </>
    );
};
