import { Link } from 'react-router-dom';
import { useLocalization } from '@/hooks/use-localization';
import { Image } from '../../image/image';

type CardDescriptionProps = {
    card: {
        title: string;
        card_slogan: string;
        fee: {
            price: string;
            period: string;
        };
        image: {
            src: string;
            alt_text: string;
        };
        overview_link: {
            url: string;
        };
    };
};

export const CardDescription = ({ card }: CardDescriptionProps) => {
    const currencySymbol = useLocalization().getCurrencySymbol();
    return (
        <Link data-component="card-description" to={card.overview_link.url} className="r-flex r-space-x-4 r-border-b-2 r-py-2 last:r-border-b-0">
            <div className="r-pl-2">
                <Image src={card.image.src} width={80} height={120} noShadow altText={card.image.alt_text} size="100xAUTO" />
            </div>
            <div className="r-space-y-1 r-p-2">
                <p className="r-text-md r-font-bold">{card.title}</p>
                <div>
                    <p className="r-text-xs">{card.card_slogan}</p>
                    <p className="r-text-xs">{`${card.fee.price} ${currencySymbol}/${card.fee.period}`}</p>
                </div>
            </div>
        </Link>
    );
};
