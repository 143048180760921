import { ChangeEvent } from 'react';

type Props = {
    label: string;
    suffix: string;
    min: number;
    max: number;
    value: number | null;
    onChange: (value: number | null) => void;
};

export const SavingsInput = ({ label, suffix, value, onChange, min, max }: Props) => {
    const updateValue = ({ target }: ChangeEvent<HTMLInputElement>) => {
        onChange(target?.value ? parseInt(target.value) : null);
    };

    const onBlur = ({ target }: ChangeEvent<HTMLInputElement>) => {
        if (!target?.value) {
            onChange(min);
        }

        const value = parseInt(target.value);
        if (value < min) {
            onChange(min);
        }
        if (value > max) {
            onChange(max);
        }
    };

    return (
        <div className="r-mb-6 r-flex r-items-end r-justify-between">
            <p>{label}</p>
            <div className="r-relative">
                <input
                    value={value || ''}
                    min={min}
                    max={max}
                    type="number"
                    onBlur={onBlur}
                    onChange={updateValue}
                    className="r-h-[56px] r-w-[128px] r-rounded-2xl r-border r-border-[#adb5bd] r-pr-8 r-text-end after:r-mr-5 after:r-content-['kr']"
                />
                <span className="r-absolute r-right-3 r-top-1/2 r--translate-y-1/2">{suffix}</span>
            </div>
        </div>
    );
};
