import { removeLeadingSlash } from './../utils/strings';
export const HUMANY_FAQ_QUERY_KEY = 'humany-faq';

export const humanyFaqQuery = async (widgetSlug: string, path = '', baseUrl: string) => {
    const sanitizedPath = removeLeadingSlash(path).replace('?', '');

    try {
        const host = typeof window === 'undefined' ? 'http://localhost' : '';
        const url = `${host}/api/one-widget/${widgetSlug}/${sanitizedPath}?seoBaseUrl=${baseUrl}`;
        const response = await fetch(url);
        const data = await response.json();

        return data;
    } catch (e: unknown) {
        console.error(e);
        throw e;
    }
};
