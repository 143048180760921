import { ApiFetchType } from '@/server/api-fetch';
import { PageType } from './page';

type MessageType = {
    message: string;
    image: {
        src: string;
        alt_text: string;
        originalSize: {
            width: number;
            height: number;
        };
        focus: {
            x: number;
            y: number;
        };
        image_id: number;
    };
    link: {
        title: string;
        url: string;
        target: string;
    };
    show_private: boolean;
    show_corporate: boolean;
};

export type InsightCategoryType = {
    id: number;
    title: string;
    guid: string;
    slug: string;
    messages: MessageType[];
    permalink: string;
    redirect_to_page: null | string;
    exclude_from_search: boolean;
    exclude_from_sitemap: boolean;
    iframe_token: boolean;
};

export type InsightGroup = 'private' | 'corporate';

export type InsightType = PageType & {
    short_title: string;
    short_preamble: string;
    long_preamble: string;
    slug: string;
    categories: number[];
    main_image?: {
        src: string;
        alt_text: string;
        originalSize: {
            width: number;
            height: number;
        };
        focus: {
            x: number;
            y: number;
        };
    };
    group: InsightGroup[];
};

export type InsightSettingsType = {
    slug: string;
    show_all_category: number;
    article_page: string;
    enable_groups: boolean;
};

export const INSIGHTS_QUERY_KEY = 'insights-query';
export const INSIGHTS_SETTINGS_KEY = 'insights-settings';
export const INSIGHT_CATEGORIES_QUERY_KEY = 'insights-categories';

export const getInsights = async (context: ApiFetchType) => (context.fetch ? context.fetch<InsightType[]>('api/insights') : []);

export const getInsightSettings = async (context: ApiFetchType) =>
    context.fetch ? context.fetch<InsightSettingsType>('api/general/resursbanken') : null;

export const getInsightCategories = async (context: ApiFetchType) =>
    context.fetch ? context.fetch<InsightCategoryType[]>('api/insight-categories') : [];
