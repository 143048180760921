import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

export type VideoProps = {
    link: string;
    autoplay?: boolean;
    controls?: boolean;
    elAspect?: string;
    loop?: boolean;
    playFromTimestamp?: string;
};

const hhMMSSToSeconds = (timestamp: string) => {
    const [hour, minute, seconds] = timestamp.split(':');

    return +hour * 60 * 60 + +minute * 60 + +seconds;
};

export const Video = ({ link, autoplay, controls, elAspect, loop, playFromTimestamp }: VideoProps) => {
    const [hasWindow, setHasWindow] = useState(false);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            setHasWindow(true);
        }
    }, []);

    let playerWrapperStyle = {};
    if (elAspect === '43') {
        playerWrapperStyle = { position: 'relative', paddingBottom: '75%' };
    } else if (elAspect === '169') {
        playerWrapperStyle = { position: 'relative', paddingBottom: '56.25%' };
    }

    return (
        <div data-component="video">
            <div className="r-h-full r-w-full" style={playerWrapperStyle}>
                {hasWindow ? (
                    <ReactPlayer
                        url={link}
                        loop={loop}
                        controls={controls}
                        muted={autoplay}
                        playing={autoplay}
                        style={{ position: 'absolute' }}
                        width="100%"
                        height="100%"
                        config={{
                            youtube: {
                                playerVars: { start: hhMMSSToSeconds(playFromTimestamp || '00:00:00') },
                            },
                        }}
                    />
                ) : null}
            </div>
        </div>
    );
};
