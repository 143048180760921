import { ApiFetchType } from '@/server/api-fetch';

type DocumentType = {
    title: string;
    url: string;
    target: string;
};

export type CardContent = {
    id: number;
    post_title: string;
    permalink: string;
    guid: string;
    title_type: string;
    breadcrumb_title_type: string;
    back_button: string;
    remove_header: boolean;
    remove_footer: boolean;
    canonical_url: string;
    iframe_token: boolean;
    title: string;
    fullname: string;
    shortname: string;
    api_name: string;
    card_slogan: string;
    card_cta?: string;
    card_credit_text: string;
    usps: boolean;
    color: string;
    fee: Fee;
    comparison_link: ComparisonLink;
    overview_link: OverviewLink;
    has_campaign: boolean;
    campaign_details: CampaignDetails;
    show_as_pink: boolean;
    horizontal_image?: Image;
    image: Image;
    documents?: {
        general_terms?: DocumentType;
        sekki?: DocumentType;
    };
};

type Fee = {
    price: string;
    period: string;
};

type ComparisonLink = {
    title: string;
    url: string;
    target: string;
};

type OverviewLink = {
    title: string;
    url: string;
    target: string;
};

type CampaignDetails = {
    campaign_title: string;
};

type Image = {
    src: string;
    alt_text: string;
    originalSize: OriginalSize;
    focus: Focus;
    image_id: number;
    service: string;
    aws_bucket: string;
    height_aspect_ratio: number;
    original_height: number;
    original_width: number;
};

type OriginalSize = {
    width: number;
    height: number;
};

type Focus = {
    x: number;
    y: number;
};

export const SUPREME_CARDS_QUERY_KEY = 'supreme_cards_key';

export const getSupremeCards = async (context: ApiFetchType) => (context?.fetch ? context.fetch<CardContent[]>('api/supreme-cards') : []);
