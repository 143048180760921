import { PropsWithChildren } from 'react';
import { Button } from '../../typography/button/button';
import { Benefit } from './benefit';
import clsx from 'clsx';

export type CardDetailsProps = {
    primaryButtonLink: {
        title: string;
        url: string;
        target?: string;
    };
    secondaryButtonLink: {
        title: string;
        url: string;
    };
    title?: string;
    content?: string;
    children: JSX.Element;
    benefits?: {
        title: string;
        subtext: string;
    }[];
};

export const CardDetails = ({ children, primaryButtonLink, title, content, benefits = [] }: PropsWithChildren<CardDetailsProps>) => (
    <div
        data-component="card-details"
        className="r-grid r-grid-cols-1 r-overflow-hidden r-rounded-2xl r-shadow-md md:r-grid-cols-3 md:r-gap-10 md:r-overflow-visible md:r-rounded-none md:r-shadow-none"
    >
        {benefits?.length ? (
            <div className="r-order-last r-col-span-1 r-mb-1 r-flex r-flex-col r-space-y-5 r-rounded-t-2xl r-bg-white r-px-8 r-py-6 r-opacity-95 md:r-order-first md:r-rounded-2xl md:r-px-4 md:r-shadow-md">
                {benefits.map((benefit, index) => (
                    <Benefit key={index} title={benefit.title} subtext={benefit.subtext} />
                ))}
            </div>
        ) : null}
        <div className="r-col-span-2">
            <div className="r-align-self-start r-flex r-flex-shrink-0 r-flex-col r-space-y-6 r-bg-white r-px-4 r-pt-8 r-opacity-95 md:r-order-last md:r-flex-row md:r-rounded-2xl md:r-px-8 md:r-pb-8 md:r-pt-12 md:r-shadow-md">
                <div
                    className={clsx('r-order-first r-space-y-6 md:r-w-3/6 md:r-flex-auto', {
                        'r-pb-4': !primaryButtonLink?.url,
                    })}
                >
                    {title ? <h2 className="r-w-full r-pb-2 r-text-3xl r-font-bold md:r-pb-0 md:r-text-4xl">{title}</h2> : null}
                    {children.props.blocks.length ? children : <p>{content}</p>}
                    {primaryButtonLink?.url ? (
                        <div className="r-flex r-w-full r-flex-col r-space-y-2 md:r-flex-row md:r-justify-end md:r-space-x-6 md:r-space-y-0">
                            <Button
                                fullWidth
                                type="primary"
                                to={primaryButtonLink.url}
                                className="r-flex r-grow md:r-mt-8"
                                target={primaryButtonLink?.target || '_self'}
                            >
                                {primaryButtonLink.title}
                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    </div>
);
