import { useMemo, useState } from 'react';
import { SavingsCalculatorProps } from './savings-calculator';
import { useLocalization } from '@/hooks/use-localization';
import { calculateInterest } from './interest-calculations';

export type CalculatorStateType = {
    monthlySavingsAmount: number | null;
    monthlySavingsYear: number | null;
    goalSavingsAmount: number | null;
    goalSavingsYear: number | null;
};

export function useSavingsCalculator(props: SavingsCalculatorProps) {
    const { getLanguage } = useLocalization();
    const [activeTab, setActiveTab] = useState(1);
    const interestRates =
        props.interestRates?.map(rate => ({
            ...rate,
            rate: rate.rate / 100,
        })) || [];

    const [state, setState] = useState<CalculatorStateType>({
        monthlySavingsAmount: props.monthlySavingsDefaultAmount,
        monthlySavingsYear: props.monthlySavingsDefaultYear,
        goalSavingsAmount: props.goalSavingsDefaultAmount,
        goalSavingsYear: props.goalSavingsDefaultYear,
    });

    const totalSaved = useMemo(() => {
        if (!state.monthlySavingsAmount || !state.monthlySavingsYear) {
            return null;
        }

        const interest = calculateInterest({
            interestRates,
            monthlyAmount: state.monthlySavingsAmount,
            years: state.monthlySavingsYear,
        });

        return {
            text: props.monthlySavingsResultsText,
            amount: interest.amount + interest.interest,
            year: state.monthlySavingsYear,
        };
    }, [state, activeTab]);

    const toSavePerMonth = useMemo(() => {
        if (!state.goalSavingsAmount || !state.goalSavingsYear) {
            return null;
        }

        // @TODO IMPLEMENT
        // const interestRate = (product === 1 ? props.product1Rate : props.product2Rate) / 100;
        // const monthlySavings = calculateMonthlySavingsWithInterest(state.goalSavingsAmount, interestRate, state.goalSavingsYear);

        return {
            text: props.goalSavingsResultsText,
            amount: 0,
            year: state.goalSavingsYear,
        };
    }, [state, activeTab]);

    const savingsInfo = useMemo(() => {
        if (!state.monthlySavingsAmount || !state.monthlySavingsYear || !state.goalSavingsAmount || !state.goalSavingsYear) {
            return null;
        }

        if (activeTab === 1) {
            const interest = calculateInterest({
                interestRates,
                monthlyAmount: state.monthlySavingsAmount,
                years: state.monthlySavingsYear,
            });

            return {
                totalSaved: interest.amount + interest.interest,
                totalGrowth: interest.interest,
                year: state.monthlySavingsYear,
            };
        }

        const futureValue = 0; // calculateFutureValue(state.goalSavingsAmount, interestRate, state.goalSavingsYear);

        return {
            totalSaved: state.goalSavingsAmount,
            totalGrowth: futureValue - state.goalSavingsAmount,
            year: state.goalSavingsYear,
        };
    }, [state, activeTab, props]);

    const ctaLink = useMemo(() => {
        try {
            const url = new URL(activeTab === 1 ? props.monthlySavingsCTALink?.url : props.goalSavingsCTALink?.url);

            url.searchParams.set(
                'amount',
                activeTab === 1 ? state.monthlySavingsAmount?.toString() || '' : state.goalSavingsAmount?.toString() || ''
            );
            url.searchParams.set('year', activeTab === 1 ? state.monthlySavingsYear?.toString() || '' : state.goalSavingsYear?.toString() || '');
            url.searchParams.set('lang', getLanguage());
            url.searchParams.set('tenor', '9');
            url.searchParams.set('account_type', props.productAccountType);

            return url.toString();
        } catch (error) {
            return '';
        }
    }, [state, activeTab]);

    return {
        activeTab,
        setActiveTab,
        state,
        setState,
        savingsInfo,
        ctaLink,
        getResults: () => (activeTab === 1 ? totalSaved : toSavePerMonth),
    };
}
