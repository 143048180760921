import { useContext, useState } from 'react';
import { FilterType, useMfn } from '@/hooks/use-mfn';
import { AppContext } from '@/contexts/app-context';
import { getFilters, getYears } from './cision-utils';
import { FeedItems } from './feed-items';
import { SelectInput } from './select-input';
import { useLocalization } from '@/hooks/use-localization';

export type CisionProps = {
    transformAttributes: {
        single_item_link: string;
    };
};

export const Cision = ({ transformAttributes: { single_item_link: singleItemLink } }: CisionProps) => {
    const { language } = useContext(AppContext);
    const { localize } = useLocalization();
    const [selectedYear, setSelectedYear] = useState('All');
    const [selectedCategory, setSelectedCategory] = useState('All');
    const {
        mfn: { data },
        filters,
        setFilters,
    } = useMfn();

    const handleChange = (type: string) => (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        if (type === 'year') {
            const selectedYear = parseInt(value, 10);
            setSelectedYear(value);
            const from = `${selectedYear}-01-01T00:00:00.000Z`;
            const to = `${selectedYear}-12-31T23:59:59.999Z`;

            setFilters(prevFilters => ({
                ...prevFilters,
                from,
                to,
            }));
        } else if (type === 'category') {
            const updatedFilters: FilterType = {
                ...filters,
                tags: undefined,
                'not-tag': undefined,
            };

            setSelectedCategory(value);

            switch (value) {
                case 'all':
                    updatedFilters.type = 'all';
                    break;
                case 'regulatory':
                    updatedFilters.tags = ':regulatory';
                    delete updatedFilters['not-tag'];
                    break;
                case 'pressrelease':
                    updatedFilters['not-tag'] = ':regulatory';
                    delete updatedFilters.tags;
                    break;
                case 'news':
                    updatedFilters.type = 'pr';
                    break;
            }

            setFilters(updatedFilters);
        }
    };

    return (
        <div data-component="cision">
            <div className="md:r-space-x-4">
                <SelectInput name="year" value={selectedYear} handleChange={handleChange('year')} options={getYears(localize('all-years'))} />
                <SelectInput name="category" value={selectedCategory} handleChange={handleChange('category')} options={getFilters(language)} />
            </div>
            <FeedItems items={data?.items || []} singleItemLink={singleItemLink} />
        </div>
    );
};
