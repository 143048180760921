import { useLocalization } from '@/hooks/use-localization';
import { useState } from 'react';
import { useNavMenus } from '@/hooks/use-nav-menus';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useGlobalQueries } from '@/hooks/use-global-queries';

export const Login = () => {
    const { getMarketType } = useGlobalQueries();
    const [isActive, setIsActive] = useState(false);
    const { headerSettings } = useNavMenus();
    const { localize } = useLocalization();

    if (getMarketType() === 'cluster' || !headerSettings.data?.login_choices?.length) {
        return null;
    }

    return (
        <div data-component="login">
            {isActive ? (
                <div
                    className="r-fixed r-left-0 r-top-0 r-h-full r-w-full r-bg-black r-opacity-10 md:r-opacity-40"
                    onClick={() => setIsActive(false)}
                />
            ) : null}
            <div className="r-relative">
                <button
                    className={clsx(
                        'r-rounded-3xl r-border-2 r-border-gray-900 r-px-6 r-py-1 r-text-sm r-font-bold r-text-gray-900 hover:r-bg-[#aed5d5] md:r-px-8 md:r-py-2 md:r-text-base',
                        {
                            isActive: 'r-bg-[#aed5d5]',
                        }
                    )}
                    onClick={() => setIsActive(!isActive)}
                >
                    {localize('log-in')}
                </button>
                {isActive ? (
                    <div className="r-absolute -r-right-14 r-top-10 r-min-w-[160px] r-rounded-2xl r-bg-white md:r-right-0 md:r-top-20 md:r-min-w-[300px]">
                        {headerSettings.data.login_choices.map(
                            ({ login_link_url: link, login_link_text: text, login_link_text_mobile: mobileText }, index) => (
                                <Link
                                    className="r-flex r-items-center r-justify-between r-gap-4 r-border-b r-border-b-gray-300 r-px-4 r-py-3 r-text-gray-900 last:r-border-b-0 hover:r-text-primary-400 hover:r-underline hover:r-decoration-primary-400 md:r-px-5 md:r-py-6"
                                    to={link?.url || '/'}
                                    target={link?.target || '_self'}
                                    key={index}
                                >
                                    <span className="r-hidden md:r-block">{text}</span>
                                    <span className="md:r-hidden">{mobileText || text}</span>
                                    <img height={15} width={15} src="/assets/icons/arrow-right-small.svg" alt="" />
                                </Link>
                            )
                        )}
                    </div>
                ) : null}
            </div>
        </div>
    );
};
