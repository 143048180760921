import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '@/contexts/app-context';
import { FieldsType } from '@/isomorphic/components/wp-blocks/bank-open-savings-form/types';

const ACCOUNT_LOOKUP_URL = 'b2c-application-lookup-url';

type ApiResponseType = {
    signUrl?: string | null;
    _links?: {
        'sign-redirect'?: {
            href: string;
            access: string;
        };
        account?: {
            href: string;
        };
    };
    status?: number;
    message?: string;
};

export function useBankOpenSavingsForm() {
    const { url } = useContext(AppContext);
    const { pathname } = useLocation();

    const sanitizeNumber = (value: string) => value?.replace(/[^\d]+/g, '');

    const submit = async (values: FieldsType, kycValues: Record<string, Record<string, string[]>>, fakeEmptyRedirectLinkResponse = false) => {
        const body = {
            depositGuaranteeAccepted: values.acceptDepositGuarantee,
            termsConditionsAccepted: values.acceptCommonTerms,
            productCode: values.productCode || '',
            email: values.contactEmail,
            address: {
                street: values.streetAddress,
                zipCode: values.zipCode,
                city: values.city,
            },
            phoneNumbers: {
                homePhoneNumber: values.phoneDay,
                mobilePhoneNumber: values.phoneMobile,
            },
            kycAnswers: kycValues,
            disbursementAccount: {
                clearingNumber: sanitizeNumber(values.clearingNumber),
                accountNumber: sanitizeNumber(values.accountNumber),
            },
            ...(values['fixedValue'] && {
                autogiroAccount: {
                    clearingNumber: sanitizeNumber(values.directDebitClearingNumber),
                    accountNumber: sanitizeNumber(values.directDebitAccountNumber),
                    withdrawalAmount: { amount: values.directDebitAmount, currency: 'SEK' },
                    withdrawalDate: values.directDebitDay,
                    accountHolderIdentity: values.directDebitBankName,
                },
            }),
            onCancelledUri: `${url}${pathname}?response=cancelled`,
            onSuccessUri: `${url}${pathname}?response=success`,
        };

        const response = await fetch('/api/bank/savings-account-form/apply', {
            headers: {
                'content-type': 'application/json',
                credentials: 'include',
            },
            method: 'POST',
            body: JSON.stringify(body),
        });

        const data = (await response.json()) as ApiResponseType;

        if (fakeEmptyRedirectLinkResponse) {
            data.signUrl = null;
        }

        if (data?._links?.account?.href) {
            window?.sessionStorage?.setItem(ACCOUNT_LOOKUP_URL, data?._links?.account?.href);
        }

        if (data.signUrl) {
            window.location.href = data.signUrl;
        }

        return data;
    };

    const getApplicationLookupUrl = () => {
        if (typeof window === 'undefined') {
            return null;
        }

        return window?.sessionStorage?.getItem(ACCOUNT_LOOKUP_URL) ?? null;
    };

    const removeApplicationLookupUrl = () => {
        if (typeof window !== 'undefined') {
            window?.sessionStorage?.removeItem(ACCOUNT_LOOKUP_URL);
        }
    };

    return {
        submit,
        getApplicationLookupUrl,
        removeApplicationLookupUrl,
    };
}
