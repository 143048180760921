import { useEffect } from 'react';
import { Labels } from './types';
import { Button } from '../../typography/button/button';
import { useLocalization } from '@/hooks/use-localization';
import { useLogout } from '@/hooks/use-logout';
import { useGtm } from '@/hooks/use-gtm';
import { AccountNumber } from './components/account-number';

type Props = {
    labels: Labels;
    fetchAccountInformation?: boolean;
};

export const StepFive = ({ labels, fetchAccountInformation = false }: Props) => {
    const { logout } = useLogout();
    const { localize } = useLocalization();
    const { sendPageInteraction } = useGtm();

    useEffect(() => {
        sendPageInteraction({ action: 'Open savings account', label: 'Done' });

        return () => {
            logout();
        };
    }, []);

    return (
        <div className="r-space-y-4">
            <h3 className="r-text-2xl r-font-bold">{labels.applicationSentTitle}</h3>
            <p>{labels.applicationSentDescription}</p>
            {fetchAccountInformation ? <AccountNumber labels={labels} /> : null}
            <Button relativePath to="..">
                {localize('close')}
            </Button>
        </div>
    );
};
