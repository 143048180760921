import { useState } from 'react';
import { TopMenu } from './components/top-menu';
import { MainMenu } from './components/main-menu';
import { Search } from '../search/search';
import { MobileMenu } from './components/mobile-menu/mobile-menu';
import { Alerts } from '@/isomorphic/components/page/components/alerts';
import { useGlobalQueries } from '@/hooks/use-global-queries';

export const Header = () => {
    const { pageQuery } = useGlobalQueries();
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    if (pageQuery?.data?.remove_header) {
        return null;
    }

    return (
        <header className="r-sticky r-top-0 r-z-[1000] r-bg-white">
            <Alerts />
            <div className="r-block lg:r-hidden">
                <MobileMenu />
            </div>
            <div className="r-hidden md:r-block">
                <TopMenu />
                <MainMenu onSearchButtonClick={() => setIsSearchOpen(!isSearchOpen)} />
                <div
                    className={`scrollbar-hide r-fixed r-bottom-0 r-right-0 r-top-0 r-z-50 r-w-[600px] r-transform r-overflow-auto r-bg-white r-transition-all r-ease-in-out ${
                        isSearchOpen ? 'r-translate-x-0' : 'r-translate-x-full'
                    }`}
                >
                    <Search isSearchOpen={isSearchOpen} close={() => setIsSearchOpen(false)} />
                </div>
                {isSearchOpen && (
                    <div
                        className="r-fixed r-inset-0 r-z-40 r-bg-black r-opacity-50 r-transition-all r-ease-in-out"
                        onClick={() => setIsSearchOpen(false)}
                    />
                )}
            </div>
        </header>
    );
};
