import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AppContext } from '@/contexts/app-context';
import { ALERTS_QUERY_KEY, getAlerts } from '@/queries/alerts';

const KEY = 'dismissed_alerts';

export function useAlerts() {
    const context = useContext(AppContext);

    const alertsQuery = useQuery({
        queryKey: [ALERTS_QUERY_KEY],
        queryFn: () => getAlerts(context),
    });

    const setAlertDismissed = (alertId: number) => {
        try {
            const storage = localStorage?.getItem(KEY);
            if (!storage) {
                return localStorage?.setItem(KEY, JSON.stringify([alertId]));
            }

            const alerts = new Set(JSON.parse(storage));
            if (alerts.has(alertId)) {
                return;
            }
            alerts.add(alertId);

            localStorage?.setItem(KEY, JSON.stringify([...alerts]));
        } catch (error) {
            // Too bad
        }
    };

    const getDismissed = () => {
        const storage = localStorage?.getItem(KEY);
        return storage ? new Set(JSON.parse(storage)) : new Set();
    };

    const getActiveAlerts = () =>
        alertsQuery?.data?.filter(({ activated, id }) => {
            if (!activated) {
                return false;
            }

            return !getDismissed().has(id);
        }) ?? [];

    return {
        getActiveAlerts,
        setAlertDismissed,
    };
}
