import { ApiFetchType } from '@/server/api-fetch';

export type AlertsApiResponse = {
    activated: boolean;
    id: number;
    title: string;
    bold_start_of_message: string;
    message: string;
}[];

export const ALERTS_QUERY_KEY = 'alerts';

export const getAlerts = async (context: ApiFetchType) => (context.fetch ? context.fetch<AlertsApiResponse>('api/alerts') : null);
