import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { T10NS_QUERY_KEY, getT10ns } from '@/queries/t10ns';
import { AppContext } from '@/contexts/app-context';

export function useLocalization() {
    const context = useContext(AppContext);

    const t10nsQuery = useQuery({
        queryKey: [T10NS_QUERY_KEY],
        queryFn: () => getT10ns(context),
        enabled: false,
    });

    const getLocale = () => context.locale;
    const getLanguage = () => context.language;
    const getCurrencyCode = () => context.currency;
    const getCountry = () => context.country;

    const localize = (key: string) => {
        if (!t10nsQuery.data) {
            return '';
        }

        return t10nsQuery?.data[key] ?? `No translation for ${key}`;
    };

    const getCurrencySymbol = () =>
        new Intl.NumberFormat(getLocale(), { style: 'currency', currency: context.currency }).format(0).replace(/[0-9.,]/g, '');

    const formatNumber = (nrToFormat: number | string, decimals = false) => {
        if (typeof nrToFormat !== 'number') {
            nrToFormat = parseFloat(nrToFormat);
        }

        return new Intl.NumberFormat(getLocale(), {
            style: 'currency',
            currency: context.currency,
            maximumFractionDigits: decimals ? 2 : 0,
        })
            .format(nrToFormat)
            .replace('kr.', 'kr');
    };

    return {
        localize,
        getCurrencySymbol,
        getLocale,
        getLanguage,
        formatNumber,
        formatCurrency: formatNumber,
        getCurrencyCode,
        getCountry,
    };
}
