import { useGlobalQueries } from '@/hooks/use-global-queries';
import { useLocalization } from '@/hooks/use-localization';
import { AppContext } from '@/contexts/app-context';
import { useContext, useEffect, useState } from 'react';
import { BusinessLoanApplicationLabels } from './business-loan-application';
import { useGtm } from '@/hooks/use-gtm';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const PREFIX = 'business-loan-application';

function getStorage<T>(key: string, defaultValue: T) {
    try {
        return (sessionStorage?.getItem(`${PREFIX}-${key}`) as T) ?? defaultValue;
    } catch (error) {
        return defaultValue;
    }
}

function setStorage(key: string, value: string) {
    try {
        return sessionStorage?.setItem(`${PREFIX}-${key}`, value);
    } catch (error) {
        // No biggie
    }
}

export type MandatorType = {
    mandatorOwnership: string;
    name: string;
    personalNumber: string;
    phone: string;
    email: string;
    yearlyIncome: string;
    mandatorAcceptGuarantor: boolean;
};

export type FieldsType = {
    formId: string;
    permalink: string;
    amount: number;
    purpose: string;
    paybackPeriod: number;
    organizationNumber: string;
    companyName: string;
    contactPerson: string;
    phone: string;
    email: string;
    pepOptions: 'false' | 'true' | '';
    accountNumber: string;
    mandator: MandatorType[];
};

const analyticsEvents: string[] = [];

export function useBusinessLoanApplication({
    formId,
    minAmount,
    labels,
}: {
    formId: string;
    minAmount: number;
    labels: BusinessLoanApplicationLabels;
}) {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { getLocale, getLanguage, localize } = useLocalization();
    const { siteId, deviceType } = useContext(AppContext);
    const { setDataLayer, sendFormSubmitEvent } = useGtm();
    const { pageQuery } = useGlobalQueries();
    const [creditCheckError, setCreditCheckError] = useState<null | string>(null);
    const [submitError, setSubmitError] = useState<null | string>(null);

    const getMandatorValue = () => {
        const value = getStorage('mandator', '');
        return value ? JSON.parse(value) : null;
    };

    const [progress, setProgress] = useState<1 | 2 | 3 | 4>(1);
    const [fields, setFields] = useState<FieldsType>({
        formId,
        permalink: pageQuery.data?.permalink ?? '',
        amount: minAmount,
        purpose: '',
        paybackPeriod: 12,
        organizationNumber: '',
        companyName: '',
        contactPerson: '',
        phone: '',
        email: '',
        pepOptions: '',
        accountNumber: '',
        mandator: [
            {
                mandatorOwnership: '',
                name: '',
                personalNumber: '',
                phone: '',
                email: '',
                mandatorAcceptGuarantor: false,
                yearlyIncome: '',
            },
        ],
    });

    useEffect(() => {
        const mandator = getMandatorValue() ?? fields.mandator;

        setFields({
            ...fields,
            ...{
                purpose: getStorage('purpose', ''),
                paybackPeriod: getStorage('paybackPeriod', 12),
                organizationNumber: getStorage('organizationNumber', ''),
                companyName: getStorage('companyName', ''),
                contactPerson: getStorage('contactPerson', ''),
                phone: getStorage('phone', ''),
                email: getStorage('email', ''),
                pepOptions: getStorage('pepOptions', ''),
                accountNumber: getStorage('accountNumber', ''),
                yearlyIncome: getStorage('yearlyIncome', ''),
                mandator,
            },
        });

        setProgress(+getStorage('progress', 1) as 1 | 2 | 3 | 4);
    }, []);

    useEffect(() => {
        const args = {
            event: 'step',
            eventInfo: {
                type: 'B2Bloan application',
                step: `Step ${progress}`,
            },
        };

        const analyticsEvent = JSON.stringify(args);
        const existingEvent = analyticsEvents.find(event => event === analyticsEvent);
        if (existingEvent) {
            return;
        }

        analyticsEvents.push(analyticsEvent);
        setDataLayer(args);
    }, [progress]);

    const creditCheck = async () => {
        try {
            const response = await fetch(`/api/business-loan/credit-check?siteId=${siteId}`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'x-site-id': siteId,
                },
                body: JSON.stringify({
                    formId,
                    amount: fields.amount,
                    creditPurpose: fields.purpose,
                    applicantGovernmentId: fields.organizationNumber,
                    applicantContactName: fields.contactPerson,
                    contactTelephoneNumber: fields.phone,
                    applicantEmailAddress: fields.email,
                }),
            });

            const data = await response.json();
            if (data?.data?.decision === 'APPROVED' || data?.data?.decision === 'MANUAL_INSPECTION') {
                return true;
            }

            if (data?.statusCode && data?.statusCode === 500 && data?.message) {
                setCreditCheckError(data.message);
            } else {
                setCreditCheckError(labels.creditCheckErrorTitle ?? localize('error'));
            }

            return false;
        } catch (error) {
            setCreditCheckError(localize('error'));
            return false;
        }
    };

    const onSubmit = async (step: number) => {
        if (step === 2) {
            if (getLocale() !== 'sv-se') {
                updateProgress(3);
                return;
            }

            const creditCheckPassed = await creditCheck();
            if (creditCheckPassed) {
                updateProgress(3);
            }
        }

        if (step === 3) {
            if (!executeRecaptcha) {
                setSubmitError(localize('invalid-captcha'));
                return;
            }

            const token = await executeRecaptcha();
            if (!token) {
                setSubmitError(localize('invalid-captcha'));
                return;
            }

            const response = await submit(token);

            if (!response.error) {
                updateProgress(4);
            } else {
                if (response.error === 'INVALID_CAPTCHA_TOKEN') {
                    const message = localize('invalid-captcha') || 'There was a problem validating the captcha. Please try again.';
                    setSubmitError(message);
                } else {
                    setSubmitError(localize('error-generic') || 'There was an error. Please try again later.');
                }
            }
        }
    };

    const updateField = (args: Partial<FieldsType>) => {
        setFields({ ...fields, ...args });

        for (const [key, value] of Object.entries(args)) {
            if (typeof value === 'string' || typeof value === 'number') {
                setStorage(key, `${value}`);
            } else {
                setStorage(key, JSON.stringify(value));
            }
        }
    };

    const updateProgress = (step: 1 | 2 | 3 | 4) => {
        setStorage('progress', step.toString());
        setProgress(step);
    };

    const getFormData = () => {
        const formData = new FormData();

        for (const [key, value] of Object.entries(fields)) {
            if (key === 'mandator') {
                formData.set(key, JSON.stringify(value));
                continue;
            }

            formData.set(key, `${value}`);
        }

        return formData;
    };

    const submit = async (recaptchaToken: string) => {
        const formData = getFormData();
        sendFormSubmitEvent('B2Bloan application');
        const response = await fetch('/api/business-loan-form', {
            method: 'POST',
            body: formData,
            headers: {
                'x-site-language': getLanguage(),
                'x-site-id': siteId,
                'x-device-type': deviceType,
                'x-recaptcha-token': JSON.stringify(recaptchaToken),
            },
        });

        return response.json();
    };

    return {
        fields,
        updateField,
        onSubmit,
        progress,
        updateProgress,
        creditCheckError,
        submitError,
        setSubmitError,
    };
}
