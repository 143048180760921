import { Dispatch, SetStateAction } from 'react';
import { LabelsType } from './labels';
import { Steps } from './components/steps/step-layout';
import { OnKycChangeArgs } from './hooks/use-kyc';
import { KycSurvey } from '@/queries/kyc-survey';

export enum CustomerType {
    NATURAL = 'NATURAL',
    LEGAL = 'LEGAL',
}
export enum KeyRole {
    SIGNATORY = 'SIGNATORY',
    RIGHT_OF_INFORMATION = 'RIGHT_OF_INFORMATION',
    RIGHT_OF_USE = 'RIGHT_OF_USE',
}
export enum OwnerRole {
    BENEFICIAL_OWNER = 'BENEFICIAL_OWNER',
    ALT_BENEFICIAL_OWNER = 'ALT_BENEFICIAL_OWNER',
    LEGAL_BENEFICIAL_OWNER = 'LEGAL_BENEFICIAL_OWNER',
}
export type CountryType = 'SE' | 'FI' | 'NO' | 'DK';
export type SsnType = {
    id: string;
    customerType: CustomerType;
    countryCode: CountryType;
};
export type NameType = {
    first: string;
    last: string;
};
export type GenericPersonType = {
    name: NameType;
    governmentId: SsnType;
};
export type SignatoryType = GenericPersonType & {
    phoneNumber: string;
    email: string;
};
export type EditableType = {
    isEditable: boolean;
};
export type OwnerType = GenericPersonType & { role: OwnerRole };
export type KeyRoleType = SignatoryType & EditableType & { roles: KeyRole[] };
export type KycGroupType = 'CORPORATE_SAVINGS' | 'COMPANY' | 'OWNERSHIP_AND_CONTROL';
export type KycValuesQuestionType = {
    id: string;
    question: string;
    answer: string[];
    readOnly: boolean;
};
export type KycValuesType = Record<
    KycGroupType,
    {
        id: number | string;
        group: KycGroupType;
        questions: {
            [key: number]: KycValuesQuestionType;
        };
    }
>;

export type FieldValuesType = {
    countryCode: CountryType;
    languageCode: 'sv' | 'nb' | 'fi' | 'da';
    productCode: string;
    productType: string;
    applicant: Omit<SignatoryType, 'role'>;
    company: {
        governmentId: SsnType;
        organizationName: string;
        organizationType: string;
        phoneNumber: string;
        email: string;
        address: {
            street: string;
            city: string;
            zipCode: string;
            countryCode: CountryType;
        };
    };
    payoutAccount: {
        accountHolderBank: string;
        clearingNumber: string;
        accountNumber: string;
    };
    ownerships: OwnerType[];
    keyRoles: KeyRoleType[];
    allKeyRoles: KeyRoleType[];
    signatoryCombinations: SignatoryType[];
};

export type KycType = {
    kycValues: KycValuesType;
    kycSurveyData: KycSurvey | null;
    onChange: (args: OnKycChangeArgs) => void;
    isGroupValid: (type: KycGroupType) => boolean;
    setKycValues: Dispatch<SetStateAction<KycValuesType>>;
    getValue: (questionId: number, type: KycGroupType) => string;
    getValues: (questionId: number, type: KycGroupType) => string[];
    isDisabled: (questionId: number, type: KycGroupType) => boolean;
    getGroupQuestions: (type: KycGroupType) => KycSurvey['groups'][number] | null;
    updateKycSurvyData: (data: KycSurvey, corporateData: CorporateRegistryType, company: FieldValuesType['company']) => void;
};

export type PropsType = {
    kyc: KycType;
    step: Steps;
    setStep: Dispatch<SetStateAction<Steps>>;
    fields: FieldValuesType;
    updateFields: (value: Partial<FieldValuesType>) => void;
    showError: boolean;
    setShowError: Dispatch<SetStateAction<boolean>>;
    getValuesFromStorage: () => FieldValuesType | null;
    setManualApplicationOnly: Dispatch<SetStateAction<boolean>>;
    corporateRegistryDetails: CorporateRegistryType;
    setCorporateRegistryDetails: Dispatch<SetStateAction<CorporateRegistryType>>;
    getLabel: (key: keyof LabelsType, replacement?: string) => string;
};

export type AddressType = {
    name: string;
    addresses: {
        streetNameWithNumber: string;
        postalCode: string;
        city: string;
        countryCode: CountryType;
    }[];
    phoneNumbers: {
        number: string;
        type: string;
    }[];
};

export type PersonLookupType = Omit<AddressType, 'name'> & {
    fullName: string;
    names: {
        firstNames: string[];
        preferredFirstName: string;
        additionalName: string;
        familyName: string;
    };
    email: string;
};

export type OrganizationType = {
    organizationName: string;
    organizationTypeCode: string;
    beneficialOwners: BeneficialOwner[];
    allSignatories: Signatory[];
    combinations: {
        signatories: Signatory[];
    }[];
};

export enum ApplicationSearchStatus {
    APPLICATION_COMPLETED = 'APPLICATION_COMPLETED',
    APPLICATION_DECLINED = 'APPLICATION_DECLINED',
}
export enum SearchResult {
    APPLICATION_NOT_FOUND = 'APPLICATION_NOT_FOUND',
    APPLICATION_FOUND = 'APPLICATION_FOUND',
}

export type ApplicationSearchType = {
    applicationReference: string;
    status: ApplicationSearchStatus;
    applicationData: Record<string, unknown>;
    lastmodified: string;
    creted: string;
};

export type CorporateRegistryType = Omit<OrganizationType, 'organizationName' | 'organizationTypeCode'>;

type BeneficialOwner = {
    governmentId: SsnType;
    name: NameType;
};

type Signatory = BeneficialOwner & {
    role: 'UNKNOWN' | 'BOARD_LEADER' | 'BOARD_MEMBER' | 'ACCOUNTANT';
};
