import { InsightCategoryType, InsightGroup } from '@/queries/insights';
import { PageType } from '@/queries/page';
import { DynamicModuleGenerator } from '@/isomorphic/components/dynamic-module-generator/dynamic-module-generator';
import { Terms } from './components/terms';
import { Share } from './components/share';
import { Image } from '../../image/image';

export type InsightPost = PageType & {
    short_title: string;
    short_preamble: string;
    long_preamble: string;
    post_date: string;
    slug: string;
    main_image: {
        src: string;
        alt_text: string;
        originalSize: {
            width: number;
            height: number;
        };
        focus: {
            x: number;
            y: number;
        };
        image_id: number;
        original_height: number;
        original_width: number;
    };
    categories: InsightCategoryType[];
    group: InsightGroup[];
};

export type InsightProps = {
    labels: {
        shareLabel: string;
    };
    attributes: {
        transformAttributes: {
            post: InsightPost;
        };
    };
};

export const Insight = (props: InsightProps) => {
    const post = props?.attributes?.transformAttributes?.post;
    if (!post) {
        return null;
    }

    const context = () => {
        if (!post.group?.length) {
            return null;
        }

        return post.group[0];
    };

    return (
        <div data-component="insight" className="r-container r-max-w-3xl r-px-4 r-py-8">
            <div className="r-mb-4">
                <Terms terms={post.categories} context={context()} />
            </div>
            <h1 className="r-pb-4 r-text-3xl r-font-bold">{post.post_title}</h1>
            <div className="r-pb-12" dangerouslySetInnerHTML={{ __html: post.long_preamble }} />
            <div className="r-mb-8 r-border-l-4 r-border-secondary-400 r-pl-4">
                <Share label={props.labels.shareLabel} pageTitle={post.post_title} />
            </div>
            {post.main_image ? (
                <Image src={post.main_image.src} width={post.main_image.original_width} height={post.main_image.original_height} size="750xAUTO" />
            ) : null}
            <DynamicModuleGenerator blocks={post?.blocks} />
            <div className="r-border-b r-border-t r-border-gray-300 r-py-2">
                <Share label={props.labels.shareLabel} pageTitle={post.post_title} />
            </div>
        </div>
    );
};
