import { ApiFetchType } from '@/server/api-fetch';

export type HeaderSettingsApiResponse = {
    login_choices: {
        login_link_text: string;
        login_link_text_mobile?: string;
        login_link_url?: {
            title: string;
            url: string;
            target: string;
        };
    }[];
};

export const HEADER_SETTINGS_QUERY_KEY = 'header-settings';

export const getHeaderSettings = (context: ApiFetchType) => (context.fetch ? context.fetch<HeaderSettingsApiResponse>('api/general/header') : null);
