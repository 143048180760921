import { KYCResponse, Option } from '@/queries/kyc-survey';
import { FieldsType, Labels } from './types';
import { Checkbox } from '../../forms/components/checkbox';
import { Field } from '../../forms/components/field';
import { Button } from '../../typography/button/button';
import { getCountrySelectionOptions } from './countries';
import { useLocalization } from '@/hooks/use-localization';
import { Select } from '../../forms/components/select';
import { Arrow } from './components/svg';

type Props = {
    labels: Labels;
    values: FieldsType;
    step: number;
    setStep: (step: number) => void;
    kycResponse: KYCResponse | undefined;
    handleKycChange: (groupId: string, questionId: number, optionText: string, multiple: boolean, isChecked?: boolean) => void;
    kycValues: Record<string, Record<number, Array<string | number | boolean>>>;
};

export const StepThree = ({ labels, kycResponse, handleKycChange, kycValues, step, setStep }: Props) => {
    const { getLanguage } = useLocalization();
    const countrySelectOptions = getCountrySelectionOptions(getLanguage());

    const getButtonOptions = (options?: Option[]) => {
        if (!options?.length) {
            return [];
        }

        return [
            { value: '', label: ' -- Välj -- ' }, // This is only available in Sweden at the moment
            ...options.map(({ text }) => ({ value: text, label: text })),
        ];
    };

    const areAllKycValuesFilled = (
        kycResponse: KYCResponse | undefined,
        kycValues: Record<string, Record<number, Array<string | number | boolean>>>
    ) => {
        if (!kycResponse?.result) {
            return false;
        }

        for (const group of kycResponse.result.kycSurvey.groups) {
            for (const question of group.questions) {
                if (!question.displayCondition) {
                    if (!kycValues?.[group.id]?.[question.id]?.length) {
                        return false;
                    }
                }
            }
        }

        return true;
    };

    if (!kycResponse?.result) {
        return null;
    }

    return (
        <>
            <div className="r-mb-10 r-mt-4 r-space-y-2 r-border-b r-pb-10">
                <p className="r-text-2xl r-font-bold r-text-primary-400">
                    {step}. {labels.formStepThreeTitle}
                </p>
                <p>{labels.formStepThreeDescription}</p>
            </div>
            <div>
                {kycResponse?.result?.kycSurvey.groups.map((group, index) => {
                    const isConditionMet = (displayCondition: { questionId: number; optionId: number }) => {
                        const optionText = group.questions
                            .find(question => question.id === displayCondition.questionId)
                            ?.options?.find(option => option.id === displayCondition.optionId)?.text;

                        if (!optionText) {
                            return false;
                        }

                        return kycValues?.[group.id]?.[displayCondition.questionId]?.includes(optionText);
                    };

                    return (
                        <div key={index}>
                            <p className="r-mb-2 r-text-lg r-font-bold md:r-mb-4">{group.title}</p>
                            {group.id === 'TAXES' && <p className="r-pb-6 r-font-medium">{labels.formStepThreeDescription} </p>}
                            {group.id === 'PEP' && <p className="r-pb-6 r-font-medium">{labels.kycPepInfo} </p>}
                            {group.questions
                                .filter(question => {
                                    if (!question.displayCondition) {
                                        return true;
                                    }

                                    return isConditionMet(question.displayCondition);
                                })
                                .map((question, index) => (
                                    <div key={index} className="r-mb-4 r-grid r-grid-cols-1 r-gap-4 md:r-grid-cols-2 md:r-gap-10 md:r-py-6">
                                        <p className="r-text-base r-font-bold">{question.text}</p>
                                        <div className="r-space-y-2">
                                            {question.type === 'FREE_TEXT' && (
                                                <Field
                                                    value={(kycValues?.[group.id]?.[question.id]?.[0] as string | number | undefined) || ''}
                                                    onChange={v => handleKycChange(group.id, question.id, v, false)}
                                                />
                                            )}
                                            {question.type === 'COUNTRY_CODE_SELECTION' && (
                                                <Select
                                                    searchable
                                                    label={question.text}
                                                    options={countrySelectOptions}
                                                    value={(kycValues?.[group.id]?.[question.id]?.[0] as string) || ''}
                                                    onChange={v => handleKycChange(group.id, question.id, v as string, false)}
                                                />
                                            )}
                                            {question.type === 'BUTTON' && (
                                                <Select
                                                    label={question.text}
                                                    value={(kycValues?.[group.id]?.[question.id]?.[0] as string) || ''}
                                                    options={getButtonOptions(question.options)}
                                                    onChange={v => handleKycChange(group.id, question.id, v as string, false)}
                                                />
                                            )}
                                            {question.options &&
                                                question.options.map((option, index) => {
                                                    const answer = kycValues?.[group.id]?.[question.id] || [];
                                                    switch (question.type) {
                                                        case 'MULTIPLE_SELECTION':
                                                            return (
                                                                <Checkbox
                                                                    onChange={isChecked =>
                                                                        handleKycChange(group.id, question.id, option.text, true, isChecked)
                                                                    }
                                                                    checked={answer.includes(option.text)}
                                                                    key={index}
                                                                    label={option.text}
                                                                />
                                                            );
                                                        case 'SINGLE_SELECTION':
                                                            return (
                                                                <Checkbox
                                                                    type="radio"
                                                                    onChange={() => handleKycChange(group.id, question.id, option.text, false)}
                                                                    checked={answer.includes(option.text)}
                                                                    key={index}
                                                                    label={option.text}
                                                                />
                                                            );
                                                        case 'COUNTRY_CODE_SELECTION':
                                                            return (
                                                                <Select
                                                                    searchable
                                                                    key={index}
                                                                    value={(answer?.[0] as string) || ''}
                                                                    options={countrySelectOptions}
                                                                    onChange={v => handleKycChange(group.id, question.id, v as string, false)}
                                                                />
                                                            );
                                                        default:
                                                            return null;
                                                    }
                                                })}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    );
                })}
            </div>
            <div className="r-flex r-justify-center r-gap-4 md:r-gap-8">
                <Button type="secondary" fullWidth onClick={() => setStep(step - 1)}>
                    <div className="r-flex r-space-x-1">
                        <Arrow className="r-w-4 r-rotate-180" />
                        <p>{labels.back}</p>
                    </div>
                </Button>
                <Button type="tertiary" fullWidth disabled={!areAllKycValuesFilled(kycResponse, kycValues)} onClick={() => setStep(step + 1)}>
                    <div className="r-flex r-space-x-1">
                        <p>{labels.toSigning}</p>
                        <Arrow className="r-w-4" />
                    </div>
                </Button>
            </div>
        </>
    );
};
