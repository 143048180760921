import { PropsWithChildren, useState } from 'react';
import { getImageUrl } from '@/utils/images';
import { BlockBase } from '@/queries/page';

type CardImage = {
    src: string;
    alt_text: string;
    originalSize: {
        width: number;
        height: number;
    };
    focus: {
        x: number;
        y: number;
    };
    image_id: number;
    service: string;
    aws_bucket: string;
    height_aspect_ratio: number;
    original_height: number;
    original_width: number;
    __isimage: boolean;
};

export type CardCollapseProps = {
    close: string;
    cardTitle: string;
    cardImage: CardImage;
    contentInnerBlocks: BlockBase[];
};

export const CardCollapse = ({ close, cardTitle, cardImage, children }: PropsWithChildren<CardCollapseProps>) => {
    const [isOpen, setIsOpen] = useState(false);

    if (!cardTitle) {
        return null;
    }

    return (
        <div data-component="card-collapse" className="r-relative r-mx-auto r-w-full r-overflow-hidden r-shadow-xl">
            <div
                className="r-relative r-flex r-h-72 r-w-full r-cursor-pointer r-flex-col r-justify-end r-bg-cover r-bg-center r-bg-no-repeat r-p-4"
                style={{ backgroundImage: `url(${getImageUrl(cardImage.src)})` }}
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className="r-flex r-items-center r-justify-between r-p-4">
                    <span className="r-text-2xl r-font-bold r-text-white">{cardTitle}</span>
                    {!isOpen ? <img src="/assets/icons/plus-white.svg" width={25} /> : <img src="/assets/icons/close-dash-white.svg" width={25} />}
                </div>
            </div>
            <div className={`r-overflow-hidden r-transition-all r-duration-1000 ${isOpen ? 'r-max-h-[613px]' : 'r-max-h-0'}`}>
                <div className="r-mx-8 r-mt-8">{children}</div>
                <div className="r-mx-8 r-mb-8 r-text-red-700 hover:r-cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
                    {close}
                </div>
            </div>
        </div>
    );
};
