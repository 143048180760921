import { getImageUrl } from '@/utils/images';
import { useInsights } from '@/hooks/use-insights';
import { InsightPost } from '../insight';
import { Link } from 'react-router-dom';

export type RelatedPostsProps = {
    attributes?: {
        transformAttributes?: {
            relatedPosts?: InsightPost[];
        };
    };
};

export const RelatedPosts = ({ attributes }: RelatedPostsProps) => {
    const { insightsSettings } = useInsights();
    const rootSlug = () => insightsSettings.data?.slug.replace(/\/$/, '');

    if (!attributes?.transformAttributes?.relatedPosts?.length) {
        return null;
    }

    return (
        <div data-component="related-posts">
            <div className="r-container r-mb-8 r-mt-8 r-grid r-gap-8 r-px-4 md:r-grid-cols-3 md:r-px-8">
                {attributes?.transformAttributes?.relatedPosts?.map(post => (
                    <Link
                        key={post.id}
                        to={`${rootSlug()}/${post.slug}`}
                        className="r-grid r-h-64 r-items-end r-rounded-2xl r-bg-cover r-bg-center r-bg-no-repeat r-shadow-lg"
                        style={{
                            backgroundImage: `url(${getImageUrl(post.main_image.src, '500xAUTO')})`,
                        }}
                    >
                        <div className="r-grid r-max-w-[80%] r-rounded-bl-xl r-bg-white/95 r-p-4 md:r-p-6">
                            <h4 className="r-text-2xl r-font-bold">{post.short_title || post.post_title}</h4>
                            <img className="r-w-6 r-place-self-end" src="/assets/icons/arrow-right.svg" />
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};
