import { useContext } from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { removeTrailingSlash } from './../utils/strings';
import { MarketType, PAGE_QUERY_KEY, getPage } from '@/queries/page';
import { GENERAL_SETTINGS_QUERY_KEY, GeneralSettingsApiResponse, getGeneralSettings } from '@/queries/general-settings';
import { FOOTER_QUERY_KEY, getFooter } from '@/queries/footer';
import { AppContext } from '@/contexts/app-context';
import { useCookies } from './use-cookies';

export function useGlobalQueries() {
    const { pathname } = useLocation();
    const { COOKIE_PAGE } = useCookies();
    const context = useContext(AppContext);

    const generalSettings = useQuery({
        queryKey: [GENERAL_SETTINGS_QUERY_KEY],
        queryFn: () => getGeneralSettings(context),
        enabled: false,
    });

    const getSetting = <K extends keyof GeneralSettingsApiResponse>(key: K): GeneralSettingsApiResponse[K] | null => {
        if (!generalSettings.data) {
            return null as GeneralSettingsApiResponse[K];
        }

        return generalSettings.data[key];
    };

    const humanySettings = getSetting('humany_settings');
    const humanyPageLink = humanySettings?.humany_page_link?.replace(/\/$/g, '') ?? '';
    const humanyPageLinkBusiness = humanySettings?.humany_page_link_business?.replace(/\/$/g, '') ?? '';

    const getPathname = () => {
        if (!pathname) {
            return '/';
        }

        if (pathname === COOKIE_PAGE) {
            const cookiePage = getSetting('cookie_read_more_page');
            return cookiePage ? removeTrailingSlash(cookiePage) : '/';
        }

        return pathname;
    };

    const pageQuery = useQuery({
        queryKey: [PAGE_QUERY_KEY, getPathname()],
        queryFn: ({ queryKey }) => getPage(queryKey[1], context, { humanyPageLink, humanyPageLinkBusiness }),
        placeholderData: keepPreviousData,
        enabled: true,
        staleTime: 1000 * 60 * 10,
    });

    const footerQuery = useQuery({
        queryKey: [FOOTER_QUERY_KEY],
        queryFn: () => getFooter(context),
        enabled: false,
    });

    const getMarketType = (): MarketType | undefined | null =>
        pageQuery.data?.parent_market_type ? pageQuery.data.parent_market_type : pageQuery.data?.market_type;

    const getPermalink = () => (pageQuery.data?.permalink ? pageQuery.data.permalink : '');

    return {
        pageQuery,
        footerQuery,
        getPermalink,
        getMarketType,
        getSetting,
    };
}
