import { useContext, useState } from 'react';
import clsx from 'clsx';
import { Select } from '@/isomorphic/components/forms/components/select';
import { Checkbox } from '@/isomorphic/components/forms/components/checkbox';
import { Field } from '@/isomorphic/components/forms/components/field';
import { Quote } from './quote';
import { InfoButton } from './info-button';
import { TermsBox } from './terms-box';
import { Button } from '@/isomorphic/components/typography/button/button';
import { useSupremeCards } from '@/hooks/use-supreme-cards';
import { validators } from '@/hooks/use-validation';
import { useLocalization } from '@/hooks/use-localization';
import { CardTerms } from './card-terms';
import { FieldsType, SupremeCardApplicationLabels } from '../types';
import { AppContext } from '@/contexts/app-context';

export type StepOneProps = {
    fields: FieldsType;
    defaultCard: string;
    isLoading: boolean;
    updateField: (field: Partial<FieldsType>) => void;
    labels: SupremeCardApplicationLabels;
    onSubmit: (step: number) => void;
};

export const StepOne = ({ fields, updateField, labels, onSubmit, isLoading }: StepOneProps) => {
    const [cardInfoOpen, setCardInfoOpen] = useState(false);
    const { getLanguage, getLocale } = useLocalization();
    const context = useContext(AppContext);

    const {
        supremeCards: { data: cards },
    } = useSupremeCards();

    const updateCardChoice = (card: string) => {
        updateField({ selectedCard: card as string });
        context.setSupremeCardApplication({ selectedCard: card });
    };

    const isSumbitDisabled = () =>
        isLoading || !fields.personalNumber || !fields.contactEmail || !fields.phoneMobile || !fields.pepOptions || !fields.acceptTerms;

    return (
        <>
            <div className="r-mb-8 r-space-y-6">
                {/* The SSR makes this weird for some reason, so we'll wait until the cards are loaded before rendering */}
                {cards?.length ? (
                    <Select
                        value={fields.selectedCard}
                        infoText={labels.selectCardInfoText}
                        label={labels.selectCard}
                        onChange={value => updateCardChoice(value as string)}
                        options={cards?.map(({ api_name: value, post_title: label }) => ({ value, label })) || []}
                    />
                ) : null}
                <div
                    className={clsx('r-cursor-pointer r-bg-gray-200 r-px-4 r-py-2 md:r-hidden', {
                        'r-hidden': !context.supremeCardApplication.progressText.title,
                    })}
                    onClick={() => setCardInfoOpen(!cardInfoOpen)}
                >
                    <div className="r-flex r-items-center r-justify-between">
                        <h4 className="r-font-bold">{context.supremeCardApplication.progressText.title}</h4>
                        <span className="r-text-3xl">{cardInfoOpen ? '-' : '+'}</span>
                    </div>
                    <div
                        className={clsx({
                            'r-hidden': !cardInfoOpen,
                        })}
                    >
                        <div
                            className="styled-list r-mt-2 r-pb-2"
                            dangerouslySetInnerHTML={{ __html: context.supremeCardApplication.progressText.content }}
                        />
                    </div>
                </div>
                <div>
                    <Field
                        grouped="top"
                        value={fields.personalNumber}
                        label={`${labels.personalNumber} *`}
                        fieldName="personalNumber"
                        infoText={labels.personalNumberInfoText}
                        onChange={value => updateField({ personalNumber: value })}
                        validation={value => {
                            if (getLanguage() === 'sv') {
                                return validators.socialSecurityNumber(value.replace(/[- ]+/, ''), getLanguage());
                            }
                            return validators.socialSecurityNumber(value, getLanguage());
                        }}
                    />
                    <Field
                        grouped="middle"
                        value={fields.contactEmail}
                        label={`${labels.contactEmail} *`}
                        fieldName="contactEmail"
                        infoText={labels.contactEmailInfoText}
                        onChange={value => updateField({ contactEmail: value })}
                        validation={validators.email}
                    />
                    <Field
                        grouped="bottom"
                        value={fields.phoneMobile}
                        label={`${labels.phoneMobile} *`}
                        fieldName="phoneMobile"
                        infoText={labels.phoneMobileInfoText}
                        onChange={value => updateField({ phoneMobile: value })}
                        validation={value => validators.phoneMobile(value, getLocale())}
                    />
                </div>
            </div>
            <div className="r-space-y-6">
                <Quote quote={labels.pepInfoBoxContent.replace(/(<([^>]+)>)/gi, '')} />
                <div>
                    <label className="r-block r-pb-2 r-font-bold">{labels.pepQuestion}</label>
                    <Select
                        infoText={labels.selectCardInfoText}
                        label={labels.pepAnswersPlaceholder}
                        placeholder={labels.pepAnswersPlaceholder}
                        value={fields.pepOptions}
                        onChange={value => updateField({ pepOptions: value as string })}
                        options={labels.pepAnswers.split(',').map(answer => ({ value: answer, label: answer }))}
                    />
                </div>
                <InfoButton label={labels.pepInfoBox} infoText={labels.pepInfoBoxContent.replace(/(<([^>]+)>)/gi, '')} />
                <InfoButton label={labels.knownEmployeeTitle} infoText={labels.knownEmployeeContent.replace(/(<([^>]+)>)/gi, '')} />
                <InfoButton label={labels.familyMemberTitle} infoText={labels.familyMemberContent.replace(/(<([^>]+)>)/gi, '')} />
                <div className="r-space-y-2">
                    <p className="r-text-sm r-font-bold">{labels.acceptTerms}</p>
                    <TermsBox terms={labels.termsContent.replace(/(<([^>]+)>)/gi, '')} />
                    <div className="r-pt-4">
                        <Checkbox label={labels.acceptTerms} checked={fields.acceptTerms} onChange={value => updateField({ acceptTerms: value })} />
                    </div>
                </div>
                <CardTerms />
                <Button fullWidth disabled={isSumbitDisabled()} variant="bankidwhite" onClick={() => onSubmit(1)}>
                    {labels.buttonLoginAndProceed}
                </Button>
            </div>
        </>
    );
};
