import { useQuery } from '@tanstack/react-query';
import { AppContext } from '@/contexts/app-context';
import { useContext } from 'react';
import { RATE_CALCULATOR_QUERY_KEY, getRateCalculator } from '@/queries/rate-calculator';

export function useRateCalculator() {
    const context = useContext(AppContext);

    const infoText = useQuery({
        queryKey: [RATE_CALCULATOR_QUERY_KEY],
        queryFn: () => getRateCalculator(context),
    });

    return infoText;
}
