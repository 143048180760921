import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalQueries } from '@/hooks/use-global-queries';
import { FooterTopCollapse } from './footer-top-collapse';
import { removeTrailingSlash } from '@/utils/strings';

type FooterColumn = {
    header: string;
    content: string;
    open?: boolean;
};

export const FooterTop = () => {
    const navigate = useNavigate();
    const { pageQuery, footerQuery } = useGlobalQueries();
    let footerContent = pageQuery?.data?.market_type === 'cluster' ? footerQuery?.data?.clusterColumns : footerQuery?.data?.columns;
    footerContent = !footerContent ? [] : footerContent;

    const handleContentClick = (event: React.MouseEvent<HTMLElement>) => {
        const targetLink = (event.target as HTMLElement).closest('a');
        if (!targetLink) {
            return;
        }

        const url = new URL(targetLink.href);
        const target = targetLink.getAttribute('target');
        const isOpenedInSameWindow = !target || target === '_self';
        const isLeftButtonClick = event.button === 0;
        const isInternalLink = typeof window !== 'undefined' && window.location.origin === url.origin;

        // E.g. Ctrl-clicking a link opens it in a new tab
        // so let the browser handle such clicks
        const isModifierKeyPressed = event.altKey || event.ctrlKey || event.metaKey || event.shiftKey;

        if (isInternalLink && isOpenedInSameWindow && isLeftButtonClick && !isModifierKeyPressed) {
            event.preventDefault();
            navigate(removeTrailingSlash(url.pathname));
        }
    };

    const renderFooterTopDesktop = (column: FooterColumn) => (
        <div>
            <h2 className="r-mb-2 r-text-lg r-font-bold">{column.header}</h2>
            <div
                onClick={handleContentClick}
                className="r-space-y-2 r-text-sm r-font-medium r-leading-6"
                dangerouslySetInnerHTML={{ __html: column.content }}
                onKeyUp={() => handleContentClick}
                tabIndex={0}
            />
        </div>
    );

    const renderFooterTopMobile = (column: FooterColumn, index: number) => (
        <FooterTopCollapse title={column.header} index={index + 1} content={column.content} />
    );

    return (
        <div data-component="footer-top" className="r-bg-primary r-text-white">
            <div
                className={`r-container r-z-50 r-flex r-flex-col r-flex-wrap r-justify-between r-gap-4 r-px-8 r-pt-12 sm:r-flex-row sm:r-justify-items-center`}
            >
                {footerContent.map((column, index: number) => (
                    <Fragment key={index}>
                        <div className="r-z-20 r-w-44 sm:r-hidden">{renderFooterTopMobile(column, index)}</div>
                        <div className="r-hidden sm:r-block">{renderFooterTopDesktop(column)}</div>
                    </Fragment>
                ))}
            </div>
        </div>
    );
};
