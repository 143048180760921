import { useState } from 'react';
import { useAlerts } from '@/hooks/use-alerts';
import clsx from 'clsx';

export const Alerts = () => {
    const { getActiveAlerts, setAlertDismissed } = useAlerts();
    const [isDismissed, setIsDismissed] = useState<null | boolean>(null);
    const [pauseAnimation, setPauseAnimation] = useState(false);

    if (!getActiveAlerts().length) {
        return null;
    }

    const dismissAlerts = () => {
        setIsDismissed(true);
        getActiveAlerts().forEach(({ id }) => setAlertDismissed(id));
    };

    return (
        <div
            onMouseEnter={() => setPauseAnimation(true)}
            onMouseLeave={() => setPauseAnimation(false)}
            className={clsx('scrollbar-hide r-relative r-w-full r-overflow-hidden r-bg-secondary-400 r-px-4 r-py-6 r-text-white', {
                'r-hidden': isDismissed === true,
            })}
        >
            <button className="r-absolute -r-top-2 r-right-1 r-text-3xl" onClick={dismissAlerts} role="button">
                &times;
            </button>
            <div
                style={{ animationPlayState: pauseAnimation ? 'paused' : 'running' }}
                className="r-flex r-animate-[ticker_15s_linear_infinite] r-gap-8 r-whitespace-nowrap r-px-2 md:r-animate-[ticker_20s_linear_infinite] xl:r-animate-[ticker_25s_linear_infinite]"
            >
                {getActiveAlerts().map(({ id, message, bold_start_of_message: boldStart }) => (
                    <div key={id} className="r-flex r-gap-2">
                        <div className="r-font-bold" dangerouslySetInnerHTML={{ __html: boldStart }} />
                        <div dangerouslySetInnerHTML={{ __html: message.replace(/<br\s?\/?>/g, ' ') }} />
                    </div>
                ))}
            </div>
        </div>
    );
};
