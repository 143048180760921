import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AppContext } from '@/contexts/app-context';
import { HeaderMenus, getHeader } from '@/queries/header';

export function useMobileMenus({ privateEnabled, corporateEnabled }: { privateEnabled: boolean; corporateEnabled: boolean }) {
    const context = useContext(AppContext);

    const privateMenu = useQuery({
        queryKey: ['mobile-header-query-private'],
        queryFn: () => getHeader(HeaderMenus.MOBILE_MENU_PRIVATE, context),
        staleTime: Infinity,
        enabled: privateEnabled,
    });

    const corporateMenu = useQuery({
        queryKey: ['mobile-header-query-corporate'],
        queryFn: () => getHeader(HeaderMenus.MOBILE_MENU_CORPORATE, context),
        staleTime: Infinity,
        enabled: corporateEnabled,
    });

    return {
        privateMenu,
        corporateMenu,
    };
}
