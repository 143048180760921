import pmt from '@marcusthelin/pmt';
import { MarkObj } from 'rc-slider/lib/Marks';
import { useEffect, useState } from 'react';
import { useLocalization } from '@/hooks/use-localization';
import { RangeSlider } from '@/isomorphic/components/forms/components/range-slider';
import { BusinessLoanApplicationProps } from '../business-loan-application';
import { ProgressBar } from './progress-bar';
import type { FieldsType } from '../use-business-loan-application';

export type ComponentHeaderProps = BusinessLoanApplicationProps & {
    fields: FieldsType;
    progress: number;
    updateField: (args: Partial<FieldsType>) => void;
};

const FIXED_RATE = 9.9;

export const ComponentHeader = ({ fields, updateField, labels, minAmount, maxAmount, progress }: ComponentHeaderProps) => {
    const { localize, formatNumber } = useLocalization();
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    function getMonthlyAmount() {
        if (isNaN(fields.paybackPeriod) || isNaN(fields.amount)) {
            return null;
        }

        const monthlyRate = FIXED_RATE / 100 / 12;

        const pmtAmount = pmt(monthlyRate, fields.paybackPeriod, fields.amount, 0, 0, {
            positive: true,
            round: true,
        });

        return isNaN(pmtAmount) ? null : formatNumber(pmtAmount);
    }

    const getMarks = () => {
        const marks: Record<number, MarkObj> = {};

        // Necessary styles for RC slider
        [12, 18, 24, 36, 48, 60].forEach(number => {
            marks[number] = {
                label: number,
                style: {
                    display: 'none',
                },
            };
        });

        return marks;
    };

    return (
        <div data-component="component-header">
            <div className="r-bg-primary-500 r-px-6 r-py-8 md:r-px-12">
                {isClient ? (
                    <>
                        <RangeSlider
                            value={fields.amount}
                            min={minAmount}
                            max={maxAmount}
                            step={5000}
                            disabled={progress === 4}
                            label={labels.loanAmount}
                            setValue={value => updateField({ amount: value })}
                            editable
                            currency
                        />
                        <RangeSlider
                            label={labels.loanPeriod}
                            value={fields.paybackPeriod}
                            min={12}
                            max={60}
                            step={12}
                            disabled={progress === 4}
                            marks={getMarks()}
                            valueSuffix={localize('months')}
                            setValue={value => updateField({ paybackPeriod: value })}
                        />
                    </>
                ) : null}
            </div>
            <div>
                <div
                    className="-r-mt-1 r-h-[50px] r-bg-primary-500"
                    style={{
                        WebkitClipPath: 'polygon(100% 0%, 100% 30%, 50% 90%, 50% 90%, 0px 30%, 0px 0px)',
                        clipPath: 'polygon(100% 0%, 100% 30%, 50% 90%, 50% 90%, 0px 30%, 0px 0px)',
                    }}
                />
                <div className="r-px-6 r-pb-8 r-pt-4 md:r-px-12">
                    <div className="r-mb-4 r-flex r-place-content-between r-text-2xl r-font-bold r-text-primary">
                        <div className="">{labels.loanMonthlyAmount}</div>
                        <div className="">{getMonthlyAmount()}</div>
                    </div>
                    <div className="r-mb-8 r-text-sm">{labels.monthlyCostInformationText}</div>
                    <ProgressBar step={progress} />
                </div>
            </div>
        </div>
    );
};
