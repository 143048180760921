import { useDocument } from '@/hooks/use-document';
import { DocumentItem } from './document-item';
import { useLocalization } from '@/hooks/use-localization';

export type DocumentHandlerProps = {
    categoriesToDisplay: string[];
    handlerId: string;
};

export const DocumentHandler = ({ categoriesToDisplay, handlerId }: DocumentHandlerProps) => {
    const { localize } = useLocalization();
    const { data } = useDocument({
        categoriesToDisplay,
        handlerId,
    });

    if (!data) {
        return null;
    }

    return (
        <div className="r-flex r-flex-col r-items-center r-space-y-10 r-p-3 md:r-flex-row md:r-items-start md:r-space-x-10 md:r-space-y-0">
            <DocumentItem title={localize('forms')} items={data.filter(item => item.type === 'forms')} />
            <DocumentItem title={localize('terms-and-conditions')} items={data.filter(item => item.type === 'terms_and_conditions')} />
            <DocumentItem title={localize('information')} items={data.filter(item => item.type === 'information')} />
        </div>
    );
};
