type Props = {
    filename: string;
    filesize: string;
    filepath: string;
    title: string;
};

export const DownloadItem = ({ filepath, filename, filesize, title }: Props) => {
    return (
        <a download href={`/api/file-download${filepath}`} className="r-flex r-w-full r-items-center r-justify-between">
            <p className="r-font-bold">
                {title || filename.split('.').pop()} {filesize && `(${filesize})`}
            </p>
            <img height={20} width={20} src={'/assets/icons/arrow-right.svg'} />
        </a>
    );
};
