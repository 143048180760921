import { Link } from 'react-router-dom';
import { FeedItem, formatIntro } from './cision-landing';

export const NewsItem = ({ item, link, maxWords }: { item: FeedItem; link: string; maxWords: number }) => (
    <Link to={`${link}${item.news_id}`} className="r-flex r-flex-col r-space-y-4 r-bg-white r-p-4 r-text-sm">
        <p className="r-text-lg r-font-bold">{item.content.title}</p>
        <p>{formatIntro(item.content.html, maxWords)}</p>
        <div>
            <img className="r-float-right" src="/assets/icons/arrow.svg" alt={`Arrow icon pointing towards the news item: ${item.content.title}`} />
        </div>
    </Link>
);
