import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

type MenuItemProps = {
    title: string;
    children?: React.ReactNode;
    to: string;
    setShowMenu: (showMenu: boolean) => void;
};

export const MenuItem = ({ title, children, to, setShowMenu }: MenuItemProps) => {
    const [isOpen, setIsOpen] = useState(() => {
        if (typeof window === 'undefined') return false;
        const storedState = window.localStorage.getItem(`menuItem-${title}`);
        return storedState === 'open';
    });

    useEffect(() => {
        if (isOpen) {
            window.localStorage.setItem(`menuItem-${title}`, 'open');
        } else {
            window.localStorage.removeItem(`menuItem-${title}`);
        }
    }, [isOpen]);

    return (
        <div className={`r-border-b ${isOpen && children ? 'r-pb-4' : ''}`}>
            <div className="r-mb-3 r-flex r-justify-between">
                <Link to={to} className="r-text-xl r-font-medium" onClick={() => setShowMenu(false)}>
                    {title}
                </Link>
                {children && (
                    <img
                        src="/assets/icons/close.svg"
                        className={`r-h-7 r-w-7 r-transition-all ${isOpen ? '' : 'r-rotate-45'}`}
                        alt=""
                        onClick={() => children && setIsOpen(!isOpen)}
                    />
                )}
            </div>
            {isOpen && children && <div className="r-ml-5 r-space-y-6 r-pt-2">{children}</div>}
        </div>
    );
};
