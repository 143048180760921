import { useMyEconomyDrawer } from '@/hooks/use-my-economy-drawer';
import { MyEconomy } from '../wp-blocks/my-economy/my-economy';
import { useLocalization } from '@/hooks/use-localization';

export const MyEconomyDrawer = () => {
    const { myEconomyDrawer, setMyEconomyDrawer } = useMyEconomyDrawer();
    const { localize } = useLocalization();

    if (!myEconomyDrawer.isOpen) {
        return null;
    }

    return (
        <div data-component="my-economy-drawer">
            <div className="scrollbar-hide r-fixed r-bottom-0 r-right-0 r-top-0 r-z-[1200] r-w-[400px] r-translate-x-0 r-transform r-overflow-auto r-bg-white r-transition-all r-ease-in-out">
                <div className="scrollbar-hide r-relative r-right-0 r-top-0 r-z-[1200] r-min-h-screen r-overflow-scroll r-bg-white">
                    <div className="scrollbar-hide r-overflow-scroll">
                        <button
                            className="r-flex r-w-full r-items-center r-justify-end r-bg-gray-200 r-pr-2"
                            role="button"
                            onClick={() => setMyEconomyDrawer({ isOpen: false })}
                        >
                            <img src="/assets/icons/close.svg" alt={localize('close')} />
                            {localize('close')}
                        </button>
                        <MyEconomy startCourse={myEconomyDrawer.startCourse || 'start'} />
                    </div>
                </div>
            </div>
            <div
                className="r-fixed r-inset-0 r-z-[1100] r-bg-black r-opacity-50 r-transition-all r-ease-in-out"
                onClick={() => setMyEconomyDrawer({ isOpen: false })}
            />
        </div>
    );
};
