import { DynamicWidget, WidgetType } from '@/isomorphic/components/dynamic-widget/dynamic-widget';

export const GreenLoan: React.FC<{ module?: 'mortgage' | 'private' } & { [key: string]: unknown }> = ({ module = 'mortgage', ...props }) => {
    const widget = `green-loan-${module}` as WidgetType;

    if (module === 'mortgage') {
        // These current rates you can grab from https://www.skandia.se/epi-api/interests/mortgage
        // Don't worry about the effective rates as they will be calculated by the calculator
        // anyway so we can leave them as whatever they are here.
        // To check this is working ok, you can compare with the 'Flytta Bolån' tab
        // at https://www.skandia.se/lana/bolan/bolanekalkyl
        // Don't forget to check the 'tjänstepension' radio, this calculator assumes that is the case.
        props.currentRates = {
            '3 mån': { rate: 5.59, effectiveRate: 3.43 },
            '1 år': { rate: 5.89, effectiveRate: 4.52 },
            '2 år': { rate: 5.69, effectiveRate: 5.01 },
            '3 år': { rate: 5.44, effectiveRate: 5.01 },
            '5 år': { rate: 5.2, effectiveRate: 4.91 },
        };
    }

    return (
        <div className="r-z-0">
            <DynamicWidget widget={widget} dataAttributes={props} />
        </div>
    );
};
