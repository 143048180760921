import { useState, useEffect } from 'react';

type Type = {
    name: string;
    can_toggle: boolean;
};

type ToggleProps = {
    type: Type;
    enabledTypes: string[];
    setEnabledTypes: React.Dispatch<React.SetStateAction<string[]>>;
};

export const Toggle = ({ type, enabledTypes, setEnabledTypes }: ToggleProps) => {
    const [toggle, setToggle] = useState<boolean>(enabledTypes.includes(type.name));
    const toggleClass = 'r-transform r-translate-x-[16px]';

    useEffect(() => {
        if (enabledTypes.includes(type.name)) {
            setToggle(true);
        } else {
            setToggle(false);
        }
    }, [enabledTypes, type.name]);

    const handleToggle = () => {
        if (!type.can_toggle) {
            return;
        }
        setToggle(!toggle);

        if (!toggle) {
            setEnabledTypes(arr => [...arr, type.name]);
        } else {
            setEnabledTypes(arr => arr.filter(item => item !== type.name));
        }
    };

    return (
        <>
            <div className="r-flex r-flex-col r-items-center r-justify-center ">
                <div
                    className={`r-flex r-h-[24px] r-w-[40px] r-cursor-pointer r-items-center r-rounded-full r-transition-colors r-duration-300 ${
                        !toggle ? 'r-bg-[#ced4da]' : 'r-bg-[#a2cca9]'
                    } r-p-1`}
                    onClick={handleToggle}
                >
                    <div
                        className={
                            'r--ml-[2px] r-h-5 r-w-5 r-transform r-rounded-full r-bg-white r-shadow-md r-duration-300 r-ease-in-out' +
                            (!toggle ? null : toggleClass)
                        }
                    ></div>
                </div>
            </div>
        </>
    );
};
