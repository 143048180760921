import clsx from 'clsx';
import { getTailwindColor } from '@/utils/colors';
import { Level, Margin, Size, HeaderSize, getHeaderFontSize, getMargin } from '@/utils/typography';

export type HeadlineProps = {
    level: Level; // This is the same as tag
    tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    color: string;
    margin: Margin;
    marginSize: Size;
    size: HeaderSize;
    align: null | 'left' | 'center' | 'right';
    children: TrustedHTML;
    font: 'default' | 'secondary';
    index: number;
    hash: string;
};

export const Headline = ({ children, tag: Tag, ...props }: HeadlineProps) => {
    const classNames = clsx(
        getTailwindColor(props.color, 'text'),
        getMargin(props.margin, props.marginSize),
        getHeaderFontSize(props.level, props.size),
        'r-overflow-hidden md:r-overflow-visible styled-link',
        {
            'r-font-serif': props.font === 'secondary',
            'r-font-bold': props.level !== 6,
            'r-text-center': props.align === 'center',
            'r-text-right': props.align === 'right',
        }
    );

    if (!children) {
        return null;
    }

    return <Tag className={classNames} dangerouslySetInnerHTML={{ __html: children }} />;
};
