import { SupremeCardApplicationLabels } from '../types';

type Props = {
    labels: SupremeCardApplicationLabels;
    error?: string;
    errorTitle?: string | null;
};

export const SupremeError = ({ error = '', errorTitle = '', labels }: Props) => {
    if (!error) {
        return null;
    }

    return (
        <div className="r-underlined-links">
            <h4 className="r-mb-4 r-text-lg r-font-bold">{errorTitle || labels.errorGenericTitle}</h4>
            <div dangerouslySetInnerHTML={{ __html: error }} />
            {labels.customerServiceTitle && labels.customerServiceContent ? (
                <div className="r-mt-8 r-border-l-8 r-border-red-600 r-pl-4">
                    <div className="r-font-bold">{labels.customerServiceTitle}</div>
                    <div dangerouslySetInnerHTML={{ __html: labels.customerServiceContent }} />
                </div>
            ) : null}
        </div>
    );
};
