import { PropsWithChildren } from 'react';
import clsx, { ClassValue } from 'clsx';
import { Close } from './svg';
import { Modal } from '@/isomorphic/components/modal/modal';
import { VerticalWave, Wave } from '@/isomorphic/components/login-overlay/svg';

type Props = {
    open: boolean;
    header?: string;
    imageSrc?: string;
    onClose: () => void;
    className?: ClassValue;
    imageSrcMobile?: string;
};

export const ExplainerModal = ({
    open,
    header,
    onClose,
    children,
    imageSrc,
    imageSrcMobile,
    className = 'r-max-w-4xl',
}: PropsWithChildren<Props>) => {
    const style = imageSrc ? { backgroundImage: `url("${imageSrc}")` } : {};
    const mobileStyle = imageSrcMobile || imageSrc ? { backgroundImage: `url("${imageSrcMobile || imageSrc}")` } : {};

    return (
        <Modal open={open} onClose={onClose} className={className}>
            <div className="-r-mx-4 r-flex r-justify-between r-border-b r-border-gray-300 r-pb-4 r-pl-6 r-pr-8 sm:-r-mx-8 sm:r-px-8">
                {header ? <h5 className="r-text-xl r-font-semibold">{header}</h5> : null}
                <button onClick={() => onClose()}>
                    <span className="r-sr-only">Close</span>
                    <Close />
                </button>
            </div>
            <div>
                {imageSrc || imageSrcMobile ? (
                    <div className="r-relative -r-mx-4 r-grid r-h-64 r-bg-cover r-bg-center r-bg-no-repeat sm:r-hidden" style={mobileStyle}>
                        <div className="r-absolute r-bottom-0 r-w-full">
                            <Wave />
                        </div>
                    </div>
                ) : null}
                <div
                    className={clsx('scrollbar-hide r-overflow-hidden max-sm:r-mt-8 sm:-r-mx-6 sm:-r-mb-4', {
                        'sm:r-grid sm:r-grid-cols-[auto_18rem] sm:r-items-center': imageSrc,
                    })}
                >
                    <div className="r-space-y-4 r-pb-4 sm:r-p-6">{children}</div>
                    {imageSrc ? (
                        <div className="r-relative r-self-stretch max-sm:r-hidden">
                            <VerticalWave className="r-h-full" />
                            <div className="r-h-full r-bg-cover r-bg-center r-bg-no-repeat" style={style} />
                        </div>
                    ) : null}
                </div>
            </div>
        </Modal>
    );
};
