import { Fragment } from 'react';
import { BlockBase, BlockComponentProps } from '@/queries/page';
import { Headline, HeadlineProps } from '../typography/headline/headline';
import { Column, ColumnProps } from '../layout/column/column';
import { Section, SectionProps } from '../layout/section/section';
import { Paragraph, ParagraphProps } from '../typography/paragraph/paragraph';
import { List, ListProps } from '../typography/list/list';
import { Spacer, SpacerProps } from '../typography/spacer/spacer';
import OneWidgetFaq from '../wp-blocks/one-widget-faq/one-widget-faq';
import { OneWidgetFaqProps } from '../wp-blocks/one-widget-faq/one-widget-faq';
import { ResursButton, ButtonProps } from '../wp-blocks/button/resurs-button';
import { FaqCluster, FaqClusterProps } from '../wp-blocks/faq-cluster/faq-cluster';
import { Insights } from '../wp-blocks/insights/insights';
import { ResursImage, ResursImageProps } from '../wp-blocks/image/resurs-image';
import { CardOverview, CardOverviewProps } from '../wp-blocks/card-overview/card-overview';
import { Insight, InsightProps } from '../wp-blocks/insights/insight';
import { SearchSort, SearchSortProps } from '../wp-blocks/insights/components/search-sort';
import { CardDetails, CardDetailsProps } from '../wp-blocks/card-details/card-details';
import { CardComparison, CardComparisonProps } from '../wp-blocks/card-comparison/card-comparison';
import { AllContactMeFormsProps, ContactForm } from '../wp-blocks/contact-form/contact-form';
import { CardCollapse, CardCollapseProps } from '../wp-blocks/card-collapse/card-collapse';
import { WaveContent, WaveContentProps } from '../wp-blocks/wave-content/wave-content';
import { DocumentHandler, DocumentHandlerProps } from '../document-handler/document-handler';
import { IFrame, IFrameProps } from '../iframe/iframe';
import { Video, VideoProps } from '../video/video';
import { ClickableInfoboxes, ClickableInfoboxesProps } from '../wp-blocks/clickable-infoboxes/clickable-infoboxes';
import { ClusterCalculator, ClusterCalculatorProps } from '../wp-blocks/cluster-calculator/cluster-calculator';
import { RelatedPosts, RelatedPostsProps } from '../wp-blocks/insights/components/related-posts';
import { AllTerms, AllTermsProps } from '../wp-blocks/insights/components/all-terms';
import { Search, SearchProps } from '../wp-blocks/insights/components/search';
import { CisionSubscribe } from '../wp-blocks/cision-subscribe/cision-subscribe';
import { CisionLanding, CisionLandingProps } from '../wp-blocks/cision-landing/cision-landing';
import { CisionSingleItem, CisionSingleItemProps } from '../wp-blocks/cision-single-item/cision-single-item';
import { Cision, CisionProps } from '../wp-blocks/cision/cision';
import { GiftCardBalance, GiftCardBalanceProps } from '../wp-blocks/gift-card-balance/gift-card-balance';
import { Table, TableProps } from '../wp-blocks/table/table';
import { SupremeCardApplication } from '../wp-blocks/supreme-card-application/supreme-card-application';
import { MyEconomy, MyEconomyProps } from '../wp-blocks/my-economy/my-economy';
import { GreenLoan } from '../wp-blocks/green-loan/green-loan';
import { ConsumerLoanCounterModule, ConsumerLoanCounterModuleProps } from '../wp-blocks/consumerloan-counter-module/consumerloan-counter-module';
import { WidgetType } from '@/isomorphic/components/dynamic-widget/dynamic-widget';
import { SupremeCardNew, SupremeCardNewProps } from '../wp-blocks/supreme-card-new/supreme-card-new';
import { GridLayoutContainer, GridLayoutContainerProps } from '../wp-blocks/grid-layout-container/grid-layout-container';
import { BusinessLoanApplication, BusinessLoanApplicationProps } from '../wp-blocks/business-loan-application/business-loan-application';
import { RateCalculatorVertical, RateCalculatorVerticalProps } from '../wp-blocks/rate-calculator-vertical/rate-calculator-vertical';
import { DownloadModule, DownloadModuleProps } from '../wp-blocks/download-module/download-module';
import { SupremeCardInfo, SupremeCardInfoProps } from '../wp-blocks/supreme-card-info/supreme-card-info';
import { BankOpenSavingsForm } from '../wp-blocks/bank-open-savings-form/bank-open-savings-form';
import { SupremeCardApplicationProps } from '../wp-blocks/supreme-card-application/types';
import { BankOpenSavingsFormProps } from '../wp-blocks/bank-open-savings-form/types';
import { ArticleCards, ArticleCardsProps } from '../wp-blocks/article-cards/article-cards';
import { ArticleCard, ArticleCardProps } from '../wp-blocks/article-card/article-card';
import { Shortcuts, ShortcutsProps } from '../wp-blocks/shortcuts/shortcuts';
import { ConsumerloanCalculatorApp, ConsumerloanCalculatorAppProps } from '../wp-blocks/consumerloan-calculator-app/consumerloan-calculator-app';
import { SavingsCalculator, SavingsCalculatorProps } from '../wp-blocks/savings-calculator/savings-calculator';
import {
    BankOpenSavingsFormBusiness,
    BankOpenSavingsFormBusinessProps,
} from '../wp-blocks/bank-open-savings-form-business/bank-open-savings-form-business';
import { CardComparisonLegacy, CardComparisonLegacyProps } from '../wp-blocks/card-comparison-legacy/card-comparison-legacy';
import { Hero, HeroProps } from '../wp-blocks/hero/hero';
import { JulaAccountVerification, JulaAccountVerificationProps } from '../wp-blocks/jula-account-verification/jula-account-verification';
import { LoginScreen, LoginScreenProps } from '../wp-blocks/login-screen/login-screen';

declare global {
    interface Window {
        ResursWidgets: {
            init: (widget: WidgetType) => unknown;
            deactivate: (widget: WidgetType) => unknown;
        };
        ResursWidgetsNext: {
            init: (widget: WidgetType) => unknown;
            deactivate: (widget: WidgetType) => unknown;
        };
    }
}

export const DynamicModuleGenerator = ({ blocks = [] }: { blocks?: BlockBase[]; innerBlocks?: BlockBase[]; contentInnerBlocks?: BlockBase[] }) => {
    const renderBlock = (block: BlockBase, i: number) => <Fragment key={i}>{renderDynamicModule(block)}</Fragment>;

    const renderDynamicModule = ({ blockName, blocks = [], ...props }: BlockBase) => {
        const children = (parentProps: BlockComponentProps) => {
            return blocks?.map((block: BlockBase, j: number) => renderBlock({ ...block, parentProps } as BlockBase, j));
        };

        switch (blockName) {
            case 'next24hr/section':
                return <Section {...(props as SectionProps)}>{children(props)}</Section>;
            case 'next24hr/column':
                return <Column {...(props as ColumnProps)}>{children(props)}</Column>;
            case 'resurs/heading':
                return <Headline {...(props as HeadlineProps)} />;
            case 'resurs/paragraph':
                return <Paragraph {...(props as ParagraphProps)} />;
            case 'core/list':
                return <List {...(props as ListProps)} />;
            case 'resurs/spacer':
                return <Spacer {...(props as SpacerProps)} />;
            case 'resurs/button':
                return <ResursButton {...(props as ButtonProps)} />;
            case 'resurs/image':
                return <ResursImage {...(props as ResursImageProps)} />;
            case 'core/group':
                return <DynamicModuleGenerator blocks={props?.innerBlocks ?? []} />;
            case 'resurs/one-widget-faq':
                return <OneWidgetFaq {...(props as OneWidgetFaqProps)} />;
            case 'resurs/faq-cluster':
                return <FaqCluster {...(props as FaqClusterProps)} />;
            case 'resurs/search-and-sort':
                return <SearchSort {...(props as SearchSortProps)} />;
            case 'resurs/search':
                return <Search {...(props as SearchProps)} />;
            case 'resurs/resursbanken-catalog':
                return <Insights />;
            case 'resurs/resursbanken-post':
                return <Insight {...(props as InsightProps)} />;
            case 'resurs/resursbanken-related-posts':
                return <RelatedPosts {...(props as RelatedPostsProps)} />;
            case 'resurs/resursbanken-categories':
                return <AllTerms {...(props as AllTermsProps)} />;
            case 'resurs/card-overview':
                return <CardOverview {...(props as CardOverviewProps)} />;
            case 'resurs/card-details':
                return (
                    <CardDetails {...(props as CardDetailsProps)}>
                        <DynamicModuleGenerator blocks={props?.innerBlocks ?? []} />
                    </CardDetails>
                );
            case 'resurs/card-comparison':
                return 'newLayout' in props && props?.newLayout === true ? (
                    <CardComparison {...(props as CardComparisonProps)} />
                ) : (
                    <CardComparisonLegacy {...(props as CardComparisonLegacyProps)} />
                );
            case 'resurs/contact-form':
                return <ContactForm {...(props as AllContactMeFormsProps)} />;
            case 'resurs/card-collapse':
                return (
                    <CardCollapse {...(props as CardCollapseProps)}>
                        <DynamicModuleGenerator blocks={props?.contentInnerBlocks ?? []} />
                    </CardCollapse>
                );
            case 'resurs/wave-content':
                return <WaveContent {...(props as WaveContentProps)} />;
            case 'resurs/document-handler':
                return <DocumentHandler {...(props as DocumentHandlerProps)} />;
            case 'resurs/iframe':
                return <IFrame {...(props as IFrameProps)} />;
            case 'resurs/video':
                return <Video {...(props as VideoProps)} />;
            case 'resurs/clickable-infoboxes':
                return <ClickableInfoboxes {...(props as ClickableInfoboxesProps)} />;
            case 'resurs/cluster-calculator':
                return <ClusterCalculator {...(props as ClusterCalculatorProps)} />;
            case 'resurs/cision-subscribe':
                return <CisionSubscribe />;
            case 'resurs/cision-landing':
                return <CisionLanding {...(props as CisionLandingProps)} />;
            case 'resurs/cision-single-item':
                return <CisionSingleItem {...(props as CisionSingleItemProps)} />;
            case 'resurs/cision':
                return <Cision {...(props as CisionProps)} />;
            case 'resurs/gift-card-balance':
                return <GiftCardBalance {...(props as GiftCardBalanceProps)} />;
            case 'core/table':
                return <Table {...(props as TableProps)} />;
            case 'resurs/supreme-card-application':
                return <SupremeCardApplication {...(props as SupremeCardApplicationProps)} />;
            case 'resurs/supreme-card-info':
                return <SupremeCardInfo {...(props as SupremeCardInfoProps)} />;
            case 'resurs/myeconomy':
                return <MyEconomy {...(props as MyEconomyProps)} />;
            case 'resurs/gronalan-mortgage':
                return <GreenLoan {...props} />;
            case 'resurs/gronalan-privateloan':
                return <GreenLoan module="private" {...props} />;
            case 'resurs/loanapp':
                return <ConsumerLoanCounterModule {...(props as ConsumerLoanCounterModuleProps)} />;
            case 'resurs/consumerloan-calculator-app':
                return <ConsumerloanCalculatorApp {...(props as ConsumerloanCalculatorAppProps)} />;
            case 'resurs/supreme-card-application-new':
                return <SupremeCardNew {...(props as SupremeCardNewProps)} />;
            case 'resurs/grid-layout-container':
                return <GridLayoutContainer {...(props as GridLayoutContainerProps)}>{children(props)}</GridLayoutContainer>;
            case 'resurs/business-loan-application':
                return <BusinessLoanApplication {...(props as BusinessLoanApplicationProps)} />;
            case 'resurs/rate-calculator-vertical':
                return <RateCalculatorVertical {...(props as RateCalculatorVerticalProps)} />;
            case 'resurs/download-module':
                return <DownloadModule {...(props as DownloadModuleProps)} />;
            case 'resurs/bank-open-savings-form':
                return <BankOpenSavingsForm {...(props as BankOpenSavingsFormProps)} />;
            case 'resurs/bank-open-savings-form-business':
                return <BankOpenSavingsFormBusiness {...(props as BankOpenSavingsFormBusinessProps)} />;
            case 'resurs/article-cards':
                return <ArticleCards {...(props as ArticleCardsProps)}>{children(props)}</ArticleCards>;
            case 'resurs/article-card':
                return <ArticleCard {...(props as ArticleCardProps)}>{children(props)}</ArticleCard>;
            case 'resurs/shortcuts':
                return <Shortcuts {...(props as ShortcutsProps)} />;
            case 'resurs/savings-calculator':
                return <SavingsCalculator {...(props as SavingsCalculatorProps)} />;
            case 'resurs/hero':
                return <Hero {...(props as HeroProps)} />;
            case 'resurs/jula-account-verification':
                return <JulaAccountVerification {...(props as JulaAccountVerificationProps)} />;
            case 'resurs/login-screen':
                return (
                    <LoginScreen {...(props as LoginScreenProps)}>
                        <DynamicModuleGenerator blocks={props?.innerBlocks ?? []} />
                    </LoginScreen>
                );
        }

        return <div className="r-bg-red-500 r-p-4 r-text-white">No component for {blockName}</div>;
    };

    return <>{blocks.map(renderBlock)}</>;
};
