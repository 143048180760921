import clsx from 'clsx';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
    index: number;
    content: TrustedHTML;
    title: string;
};

export const FooterTopCollapse = ({ title, index, content }: Props) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const navigate = useNavigate();

    const handleContentClick = (event: React.MouseEvent<HTMLElement>) => {
        const targetLink = (event?.target as HTMLElement)?.closest('a');
        if (!targetLink) {
            return;
        }

        const url = new URL(targetLink.href);
        const target = targetLink.getAttribute('target');
        const isOpenedInSameWindow = !target || target === '_self';
        const isLeftButtonClick = event.button === 0;
        const isInternalLink = typeof window !== 'undefined' && window.location.origin === url.origin;

        // E.g. Ctrl-clicking a link opens it in a new tab
        // so let the browser handle such clicks
        const isModifierKeyPressed = event.altKey || event.ctrlKey || event.metaKey || event.shiftKey;

        if (isInternalLink && isOpenedInSameWindow && isLeftButtonClick && !isModifierKeyPressed) {
            event.preventDefault();
            navigate(url.pathname);
        }
    };

    return (
        <>
            <div
                id={`accordion-${index}-id`}
                aria-controls={`section-${index}`}
                onClick={() => setIsOpen(!isOpen)}
                className="r-flex r-w-full r-items-center r-space-x-3 r-py-1 r-font-bold hover:r-cursor-pointer"
            >
                <h2 className="r-mb-1 r-font-bold">{title}</h2>
                <img
                    alt=""
                    width={30}
                    height={30}
                    src="/assets/icons/caret.svg"
                    className={clsx('r-mb-[4px] r-fill-white r-transition r-duration-200', {
                        'r-rotate-180': isOpen,
                        'r-rotate-90': !isOpen,
                    })}
                />
            </div>
            <div
                role="region"
                id={`section-${index}`}
                aria-expanded={isOpen ? 'true' : 'false'}
                aria-labelledby={`accordion-${index}-id`}
                className={clsx('r-transition-height scrollbar-hide r-max-h-0 r-overflow-hidden r-duration-300', {
                    'r-max-h-full r-pb-2': isOpen,
                })}
            >
                <div dangerouslySetInnerHTML={{ __html: content }} onClick={handleContentClick} className="r-text-md r-font-medium r-leading-10" />
            </div>
        </>
    );
};
