import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { clusterCalculatorData, getClusterCalculatorData } from '@/queries/cluster-calculator';

export function useClusterCalculator({ amount, mdr, monthlyFee, interestRate, openingFee, minOpeningFee, maxOpeningFee }: clusterCalculatorData) {
    const queryResult = useQuery({
        queryKey: ['fetchData', amount, mdr, monthlyFee, interestRate, openingFee, minOpeningFee, maxOpeningFee],
        queryFn: ({ queryKey }) =>
            getClusterCalculatorData({
                amount: queryKey[1],
                mdr: queryKey[2],
                monthlyFee: queryKey[3],
                interestRate: queryKey[4],
                openingFee: queryKey[5],
                minOpeningFee: queryKey[6],
                maxOpeningFee: queryKey[7],
            }),
        staleTime: 1000,
        placeholderData: keepPreviousData,
    });

    if (queryResult.isError) {
        console.error(queryResult.error);
    }

    return queryResult;
}
