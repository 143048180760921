import clsx from 'clsx';
import { LabelsType } from '../labels';

type Props = {
    currentStep: number;
    getLabel: (key: keyof LabelsType, replacement?: string) => string;
};

export const ProgressBar = ({ currentStep, getLabel }: Props) => {
    const steps = [getLabel('stepOne'), getLabel('stepTwo'), getLabel('stepThree'), getLabel('stepFour'), getLabel('stepFive')];

    // This is a weird case but we have 2 steps for step 3: steps 3 and 3.5
    // Ah well: the pysical world is, after all, an illustion plus have you even SEEN Harry Potter?!
    const actualStep = currentStep === 3.5 ? 3 : currentStep;

    const renderItem = (title: string, itemStep: number) => {
        const isFutureStep = itemStep > actualStep;
        const isCurrentStep = itemStep === actualStep;

        return (
            <div
                key={itemStep}
                className={clsx('r-relative r-flex r-place-items-center r-gap-2 r-bg-white r-px-2', {
                    'r-pl-0': itemStep === 1,
                    'r-pr-0': itemStep === steps.length,
                })}
            >
                <div
                    className={clsx(
                        'r-grid r-h-6 r-w-6 r-place-items-center r-rounded-full max-md:r-text-xs md:r-h-8 md:r-w-8',
                        isFutureStep ? 'r-bg-gray-400 r-text-white' : 'r-bg-primary-300',
                        {
                            'r-border r-border-primary-300 r-bg-white r-text-primary-300': isCurrentStep,
                        }
                    )}
                >
                    {!isFutureStep && !isCurrentStep ? (
                        <img className="r-w-4" src="/assets/icons/checkbox.svg" alt="checkbox" />
                    ) : (
                        <div>{itemStep}</div>
                    )}
                </div>
                <div
                    className={clsx('r-hidden lg:r-block', {
                        'r-font-semibold': !isFutureStep,
                    })}
                >
                    {title}
                </div>
            </div>
        );
    };

    return (
        <div className="r-relative r-my-2 r-mb-4 r-flex r-place-content-between r-gap-2">
            <div className="r-absolute r-top-[50%] r-w-full r-border-b r-border-b-gray-400" />
            {steps.map((title, index) => renderItem(title, index + 1))}
        </div>
    );
};
