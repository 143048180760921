type QuoteProps = {
    quote: string;
};

export const Quote = ({ quote }: QuoteProps) => (
    <div className="r-relative r-flex r-items-start r-pl-6">
        <div className="r-absolute r-bottom-0 r-left-0 r-top-0 r-w-[7px] r-bg-red-700"></div>
        <p>{quote}</p>
    </div>
);
