import { Image } from '@/isomorphic/components/image/image';

export type ResursImageProps = {
    image?: {
        __isimage: boolean;
        url: string;
        width: number;
        height: number;
        focus: null | {
            x: number;
            y: number;
        };
    };
    imageText: null;
    link?: null | {
        title: string;
        url: string;
    };
    altText: string;
    noShadow: boolean;
};

export const ResursImage = (props: ResursImageProps) => {
    if (!props.image) {
        return null;
    }

    return (
        <Image
            {...{
                src: props.image.url,
                width: props.image.width,
                height: props.image.height,
                altText: props.altText,
                noShadow: props.noShadow,
                text: props.imageText,
                link: props.link,
                size: '1200xAUTO',
            }}
        />
    );
};
