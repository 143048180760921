import { ApiFetchType } from '@/server/api-fetch';

export const T10NS_QUERY_KEY = 't10ns';

export type TranslationsTypeApiResponse = { translation_strings: Record<string, string> };

export const getT10ns = async (context: ApiFetchType) => {
    if (!context.fetch) {
        return null;
    }

    const { translation_strings: t10ns } = await context.fetch<TranslationsTypeApiResponse>('api/general/translations');
    return t10ns;
};
