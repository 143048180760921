import clsx from 'clsx';
import { PropsWithChildren, ReactNode } from 'react';

type Props = {
    legend?: ReactNode;
    className?: clsx.ClassValue;
};

export const FieldGroup = ({ children, className, legend }: PropsWithChildren<Props>) => (
    <fieldset className={clsx(className)}>
        {legend ? <legend className="r-font-semibold">{legend}</legend> : null}
        {children}
    </fieldset>
);
