declare global {
    interface Window {
        dataLayer: Array<Record<string, unknown>>;
    }
}

type BaseEventType = Record<string, unknown> & {
    event?: string;
    eventInfo?: Record<string, unknown>;
};

export function setDataLayer(data: BaseEventType) {
    if (!data?.event) {
        data.event = 'eventTracking';
    }

    if (typeof window === 'undefined' || !window.dataLayer) {
        return;
    }

    if ('eventInfo' in data) {
        window.dataLayer.push({ eventInfo: null }); // Make sure that eventInfo always is null before pushing new data
    }

    window.dataLayer.push(data);
}

export function useGtm() {
    function sendPageInteraction(eventData = {}, event = 'Page_Interaction') {
        setDataLayer({
            event,
            eventInfo: {
                category: 'My pages',
                ...eventData,
            },
        });
    }

    function sendFormSubmitEvent(form = 'generic', id: string | null = null) {
        const data: {
            event: string;
            eventInfo: Record<string, unknown>;
        } = {
            event: 'form_submit',
            eventInfo: {
                form,
            },
        };

        if (id) {
            data.eventInfo.applicationID = id;
        }

        return setDataLayer(data);
    }

    return {
        setDataLayer,
        sendPageInteraction,
        sendFormSubmitEvent,
    };
}
