import { useLocalization } from '@/hooks/use-localization';
import { Attachment } from './attachment';
import { longDate } from '@/utils/format-date';
import './style.css';

export type CisionSingleItemProps = {
    transformAttributes: {
        post: {
            content?: {
                title: string;
                html: string;
                publish_date: string;
                slug: string;
                attachments: Attachment[];
            } | null;
        };
    };
};

type Attachment = {
    content_type: string;
    file_title: string;
    tags: string[];
    url: string;
};

export const CisionSingleItem = (props: CisionSingleItemProps) => {
    const { localize } = useLocalization();

    const content = props?.transformAttributes?.post?.content;
    if (!content?.title) {
        return null;
    }

    return (
        <div data-component="cision-single-item" className="r-space-y-6 md:r-p-20">
            <div className="r-border-b-2 r-pb-8">
                <h1 className="r-text-2xl r-font-bold">{content?.title}</h1>
                <div className="r-prose r-max-w-none r-break-inside-auto" dangerouslySetInnerHTML={{ __html: content?.html || '' }}></div>
            </div>
            <div>
                <p className="r-mb-3 r-text-2xl r-font-bold">{localize('related-documents')}</p>
                <div className="r-space-y-2 r-overflow-ellipsis">
                    {content?.attachments?.map((attachment: Attachment) => (
                        <Attachment key={attachment.url} fileTitle={attachment.file_title} url={attachment.url} />
                    ))}
                </div>
                <p className="r-mt-6">{longDate(content?.publish_date)}</p>
            </div>
        </div>
    );
};
