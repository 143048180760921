import clsx from 'clsx';
import { HeaderSize, getHeaderFontSize } from '@/utils/typography';
import { Button } from '../../typography/button/button';
import { getTailwindColor } from '@/utils/colors';

export type WaveContentProps = {
    headingColor: string;
    headingSize: HeaderSize;
    labels: {
        heading: string;
        paragraph: string;
        buttonText: string;
    };
    textColor: string;
    buttonText: string;
    buttonLink: [];
};
export const WaveContent = ({ headingSize, labels, textColor, buttonText }: WaveContentProps) => {
    const classNames = clsx('md:r-text-5xl r-font-serif r-font-bold', getHeaderFontSize(1, headingSize), getTailwindColor(textColor, 'text'));

    return (
        <div className="r-relative r-max-h-[1000px] r-min-h-[500px]">
            <div className="r-container r-mt-14 r-flex r-flex-col r-space-y-5 r-px-8">
                <h1 className={classNames}>{labels.heading}</h1>
                {labels.paragraph && <p className={getTailwindColor(textColor, 'text') ?? ''}>{labels.paragraph}</p>}
                {buttonText && <Button>{buttonText}</Button>}
            </div>
        </div>
    );
};
