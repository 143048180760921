import { StepOne } from './components/step-one';
import { StepTwo } from './components/step-two';
import { StepThree } from './components/step-three';
import { StepFour } from './components/step-four';
import { ComponentHeader } from './components/component-header';
import { useBusinessLoanApplication } from './use-business-loan-application';

export type BusinessLoanApplicationLabels = {
    continue: string;
    back: string;
    submit: string;
    stepOneTitle: string;
    stepTwoTitle: string;
    loanMonthlyAmount: string;
    loanApply: string;
    creditCheckErrorTitle: string;
    creditCheckErrorMessage: string;
    monthlyCostInformationText: string;
    socialSecurityNumber: string;
    socialSecurityNumberInfoText: string;
    socialSecurityNumberErrorText: string;
    recipientEmail: string;
    emailSubject: string;
    formStepOneTitle: string;
    loanAmount: string;
    amountErrorText: string;
    paybackPeriod: string;
    loanPeriod: string;
    purpose: string;
    purposeErrorText: string;
    formStepTwoTitle: string;
    organizationNumber: string;
    organizationNumberErrorText: string;
    companyName: string;
    companyNameErrorText: string;
    contactPerson: string;
    contactPersonErrorText: string;
    phone: string;
    phoneErrorText: string;
    phoneInfoText: string;
    email: string;
    emailErrorText: string;
    formStepThreeTitle: string;
    mandatorOwnership: string;
    mandatorOwnershipInfoText: string;
    mandatorOwnershipErrorText: string;
    mandatorTitle: string;
    mandatorDescription: string;
    mandatorAdd: string;
    name: string;
    nameInfoText: string;
    nameErrorText: string;
    personalNumber: string;
    personalNumberInfoText: string;
    personalNumberErrorText: string;
    mandatorAcceptGuarantor: string;
    mandatorAcceptGuarantorError: string;
    yearlyIncome: string;
    yearlyIncomeInfoText: string;
    yearlyIncomeErrorText: string;
    pepLabel: string;
    pepTitle: string;
    pep: string;
    yes: string;
    no: string;
    accountNumber: string;
    accountNumberInfoText: string;
    accountNumberErrorText: string;
    applicationSentTitle: string;
    applicationSentDescription: string;
    pepPlaceholder: string;
    organizationNumberInfoText: string;
};

export type BusinessLoanApplicationProps = {
    formId: string;
    resetLink: null | string;
    showGuarantor: null | string;
    minAmount: number;
    maxAmount: number;
    labels: BusinessLoanApplicationLabels;
    thankyouPageInnerBlocks: [];
};

export const BusinessLoanApplication = (props: BusinessLoanApplicationProps) => {
    const { fields, updateField, onSubmit, progress, updateProgress, creditCheckError, submitError, setSubmitError } = useBusinessLoanApplication({
        formId: props.formId,
        minAmount: props.minAmount,
        labels: props.labels,
    });

    const renderCurrentComponent = () => {
        const stepProps = { fields, updateField, updateProgress, onSubmit, ...props };

        switch (progress) {
            case 1:
                return <StepOne {...stepProps} />;
            case 2:
                return <StepTwo {...stepProps} creditCheckError={creditCheckError} />;
            case 3:
                return <StepThree {...stepProps} submitError={submitError} setSubmitError={setSubmitError} />;
            case 4:
                return <StepFour {...stepProps} />;
        }
    };

    return (
        <div data-component="business-loan-application" className="r-container r-max-w-2xl r-bg-gray-100">
            <ComponentHeader fields={fields} updateField={updateField} progress={progress} {...props} />
            {creditCheckError ? <div className="r-my-6 r-bg-red-400 r-p-6 md:r-px-12">{creditCheckError}</div> : null}
            <div className="r-px-6 r-pb-8 md:r-px-12">{renderCurrentComponent()}</div>
        </div>
    );
};
