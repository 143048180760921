type AttachmentProps = {
    fileTitle: string;
    url: string;
};

export const Attachment = ({ fileTitle, url }: AttachmentProps) => (
    <div className="hover:styled-link r-flex r-items-center r-gap-2">
        <img src="/assets/icons/document.svg" alt="Document icon" />
        <a href={url} target="_blank" className="r-break-all r-text-sm">
            {fileTitle}
        </a>
    </div>
);
