import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { Option } from '@/queries/kyc-survey';
import { useLocalization } from '@/hooks/use-localization';
import { Select } from '@/isomorphic/components/forms/components/select';
import { Checkbox } from '@/isomorphic/components/forms/components/checkbox';
import { Field, FieldProps } from '@/isomorphic/components/forms/components/field';
import { getCountrySelectionOptions } from '../../bank-open-savings-form/countries';
import { KycGroupType, PropsType } from '../types';
import { ExplainerModal } from './explainer-modal';
import { Button } from '@/isomorphic/components/typography/button/button';

type Props = Pick<PropsType, 'kyc' | 'getLabel'> & {
    type: KycGroupType;
};

const TEXT_TYPE = 'FREE_TEXT';

export const Kyc = ({ kyc: { onChange, getGroupQuestions, getValue, getValues, kycValues, isDisabled }, type, getLabel }: Props) => {
    const { getLanguage } = useLocalization();
    const countrySelectOptions = getCountrySelectionOptions(getLanguage());
    const [activeModal, setActiveModal] = useState<{ prompt: string; text: string } | null>(null);

    const getButtonOptions = (options?: Option[]) => {
        if (!options?.length) {
            return [];
        }

        return [{ value: '', label: ` -- ${getLabel('select')} --` }, ...options.map(({ text }) => ({ value: text, label: text }))];
    };

    const group = useMemo(() => getGroupQuestions(type), [type, kycValues]);
    if (!group) {
        return null;
    }

    const getGroupedParam = (questionIndex: number): FieldProps['grouped'] => {
        const currentQuestion = group.questions[questionIndex];
        if (currentQuestion.type !== TEXT_TYPE) {
            return false;
        }

        const previousQuestion = questionIndex === 0 ? null : group.questions[questionIndex - 1];
        const nextQuestion = questionIndex + 1 === group.questions.length ? null : group.questions[questionIndex + 1];

        if ((!previousQuestion || previousQuestion.type !== TEXT_TYPE) && nextQuestion?.type === TEXT_TYPE) {
            return 'top';
        }

        if (previousQuestion?.type === TEXT_TYPE && nextQuestion?.type === TEXT_TYPE) {
            return 'middle';
        }

        if (previousQuestion?.type === TEXT_TYPE && (!nextQuestion || nextQuestion?.type !== TEXT_TYPE)) {
            return 'bottom';
        }

        return false;
    };

    return (
        <div data-component="kyc">
            <div className="r-mb-4">
                <h4 className="r-mb-4 r-text-xl r-font-bold">{group.title}</h4>
                {group.questions.map((question, index) => {
                    return (
                        <div
                            key={index}
                            data-id={question.id}
                            className={clsx('last:r-pb-0', {
                                'r-pb-8': !getGroupedParam(index) || getGroupedParam(index) === 'bottom',
                            })}
                        >
                            <p
                                className={clsx('r-text-base r-font-bold', question.options?.length && !question?.info ? 'r-mb-4' : 'r-mb-2', {
                                    'r-hidden': question.type === TEXT_TYPE,
                                })}
                            >
                                {question.text}
                            </p>
                            {question?.info?.prompt ? (
                                <Button type="link" onClick={() => setActiveModal(question.info ?? null)} className="r-mb-4">
                                    {question.info.prompt}
                                </Button>
                            ) : null}
                            <div className="r-space-y-4">
                                {question.type === TEXT_TYPE && (
                                    <Field
                                        label={question.text}
                                        grouped={getGroupedParam(index)}
                                        value={getValue(question.id, type)}
                                        disabled={isDisabled(question.id, type)}
                                        onChange={value => onChange({ questionId: question.id, value, type })}
                                    />
                                )}
                                {question.type === 'COUNTRY_CODE_SELECTION' && (
                                    <Select
                                        searchable
                                        options={countrySelectOptions}
                                        value={getValue(question.id, type)}
                                        label={` -- ${getLabel('select')} --`}
                                        disabled={isDisabled(question.id, type)}
                                        onChange={value => onChange({ questionId: question.id, value: `${value}`, type })}
                                    />
                                )}
                                {question.type === 'BUTTON' && (
                                    <Select
                                        value={getValue(question.id, type)}
                                        label={` -- ${getLabel('select')} --`}
                                        disabled={isDisabled(question.id, type)}
                                        onChange={value => onChange({ questionId: question.id, value: `${value}`, type })}
                                        options={getButtonOptions(question.options)}
                                    />
                                )}

                                {question.options?.map((option, optionIndex) => {
                                    switch (question.type) {
                                        case 'MULTIPLE_SELECTION':
                                            return (
                                                <Checkbox
                                                    label={option.text}
                                                    value={option.text}
                                                    name={`${question.text}--${question.id}__${optionIndex}`}
                                                    key={`${index}-${optionIndex}`}
                                                    disabled={isDisabled(question.id, type)}
                                                    checked={getValues(question.id, type).includes(option.text) ?? false}
                                                    onChange={isChecked =>
                                                        onChange({ questionId: question.id, value: option.text, type, isMultiple: true, isChecked })
                                                    }
                                                />
                                            );
                                        case 'SINGLE_SELECTION':
                                            return (
                                                <Checkbox
                                                    type="radio"
                                                    label={option.text}
                                                    value={option.text}
                                                    name={`${question.text}--${question.id}__${optionIndex}`}
                                                    key={`${index}-${optionIndex}`}
                                                    disabled={isDisabled(question.id, type)}
                                                    checked={getValue(question.id, type) === option.text}
                                                    onChange={() => onChange({ questionId: question.id, value: option.text, type })}
                                                />
                                            );
                                        case 'COUNTRY_CODE_SELECTION':
                                            return (
                                                <Select
                                                    value={`${option.id}`}
                                                    options={countrySelectOptions}
                                                    key={`${index}-${optionIndex}`}
                                                    label={` -- ${getLabel('select')} --`}
                                                    onChange={value => onChange({ questionId: question.id, value: `${value}`, type })}
                                                />
                                            );
                                        default:
                                            return null;
                                    }
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
            <ExplainerModal open={!!activeModal} className="r-max-w-xl" onClose={() => setActiveModal(null)}>
                <div className="r-prose" dangerouslySetInnerHTML={{ __html: activeModal?.text ?? '' }} />
            </ExplainerModal>
        </div>
    );
};
