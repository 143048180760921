import clearingnummer from '@opendevtools/clearingnummer';

// NB: Sweden only
export const getBankNameByClearingNumber = (number: string | number) => {
    const clearingNumber = number.toString();
    if (clearingNumber.length < 4) {
        return '';
    }

    return clearingnummer.bankName(clearingNumber.replace(/\s/g, '') || '');
};
