import { ApiFetchType } from '@/server/api-fetch';
import { FilterType } from '@/hooks/use-mfn';

export type MfnApiType = {
    version: string;
    home_page_url: string;
    feed_url: string;
    next_url: string;
    items: Item[];
};

export type Item = {
    news_id: string;
    group_id: string;
    url: string;
    author: Author;
    subjects: Subject[];
    properties: Properties;
    content: Content;
    source: string;
};

type Author = {
    entity_id: string;
    slug: string;
    slugs: string[];
    name: string;
    brand_image_url: string;
    isins: string[];
    leis: string[];
    local_refs: string[];
};

type Subject = {
    entity_id: string;
    slug: string;
    slugs: string[];
    name: string;
    brand_image_url: string;
    isins: string[];
    leis: string[];
    local_refs: string[];
};

type Properties = {
    lang: string;
    type: string;
    scopes: string[];
    tags?: string[];
};

type Content = {
    title: string;
    slug: string;
    publish_date: string;
    preamble: string;
    html: string;
    attachments: Attachment[];
};

type Attachment = {
    file_title: string;
    content_type: string;
    url: string;
    tags: string[];
};

export const getMfn = async (context: ApiFetchType, filters: FilterType) => {
    const queryParams = new URLSearchParams();

    for (const key in filters) {
        if (Object.prototype.hasOwnProperty.call(filters, key)) {
            const filterKey = key as keyof FilterType;
            const filterValue = filters[filterKey];
            if (filterValue !== undefined) {
                queryParams.append(filterKey, String(filterValue));
            }
        }
    }

    return context.fetch ? context.fetch<MfnApiType>(`api/mfn?` + queryParams.toString()) : null;
};
